(function() {
  'use strict';

  var $date_due_field;
  var $invoiceType;

  window.setLineItemValuesFromParentLineItemForNbInvoice = function($row) {
    // Similar to _setLineItemValuesFromParentLineItem ... DRY?
    var $parent_li = $row.find('.invoice_line_items_select option:selected')
    var parent_li_data = $parent_li.data();
    $row.find('.split_tax_code_field').val(parent_li_data['taxCodeId']).trigger('chosen:updated');
    $row.find('.split_account').val(parent_li_data['nominalAccountId']).trigger('chosen:updated');
    // FARMPLAN - populate enterprise selector from line item on nb credit note with multiple lines
    $row.find('.enterprise_selector').val(parent_li_data['enterpriseId']).trigger('chosen:updated');
    // FARMPLAN END
    $row.find('.line_item_net_amount_tf').val(parent_li_data['netAmount']);
    $row.find('.line_item_tax_amount_tf').val(parent_li_data['taxAmount']);
    $row.find('.line_item_total_amount_tf').val(parent_li_data['totalAmount']);
    $row.find('.description').val(parent_li_data['description']);
    $row.find('.line-item-allocatable-id').val($parent_li.val())
    $row.find('.line-item-allocatable-type').val('LineItem')
  }

  /*
  @param {Object} arg
  @param {jQuery} arg.category_field
  @param {jQuery} arg.stock_item_field
  @param {jQuery} arg.type_field
  */
  window.setCategoryFromStock = function(arg){
    if (!arg.stock_item_field.val() || !window.stockItemInfoExists()) { return }
    var invType = arg.type_field.val() == 'PurchaseInvoice' ? 'purchase_account' : 'sales_account'
    var stock_info = window.parseStockItemInfo();
    var category = stock_info[arg.stock_item_field.val()][invType];
    arg.category_field.val(category).trigger('chosen:updated');
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.tax_code_field
  @param {jQuery} arg.stock_item_field
  @param {jQuery} arg.type_field
  */
  window.setTaxCodeFromStock = function(arg){
    if (!arg.stock_item_field.val() || !window.stockItemInfoExists()) { return }
    var taxType = arg.type_field.val() == 'PurchaseInvoice' ? 'purchase_tax_code' : 'sales_tax_code';
    var stock_info = window.parseStockItemInfo();
    var tax_code = stock_info[arg.stock_item_field.val()][taxType];
    arg.tax_code_field.val(tax_code).trigger('chosen:updated');
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.description_field
  @param {jQuery} arg.stock_item_field
  @param {jQuery} arg.type_field
  */
  window.setDescriptionFromStock = function(arg){
    if (!arg.stock_item_field.val() || !window.stockItemInfoExists()) { return }
    var descType = arg.type_field.val() == 'PurchaseInvoice' ? 'purchase_description' : 'sales_description';
    var stock_info = window.parseStockItemInfo();
    var description = stock_info[arg.stock_item_field.val()][descType];
    arg.description_field.typeahead('val', description);
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.project_field
  @param {jQuery} arg.stock_item_field
  */
  window.setProjectFromStock = function(arg){
    if (!arg.stock_item_field.val() || !window.stockItemInfoExists()) { return }
    var stock_info = window.parseStockItemInfo()
    var project = stock_info[arg.stock_item_field.val()]['project'];
    arg.project_field.val(project).trigger('chosen:updated');
  }

  function setJQueryReferences() {
    $invoiceType = $('#invoice_type');
    $date_due_field = $('#invoice_date_due');
  }

  function initInvoiceForm() {
    formatNestedFormTable();
    addNbInvEventListeners();
    allowEnterToTab();
    disableTaxAmountIfTaxCodeIsZero($('tr.line_item_row'), '.line_item_tax_amount_tf', '.split_tax_code_field');
    $invoiceType.focus();
    updateCustomerDocumentAmounts();
    checkCreditLimitOnSubmit();
  }

  function nbInvDatePickerChange() {
    var $elem = $(this);
    var datepicker = getDateTimePicker($elem);

    updateDateDue();
    addDatePeriodWarning($elem);
    addDateInFutureWarning($elem);
    updateConversionRate($('#cd_currency_tf').val(), datepicker.date());

    // If batch or credit note then we don't want to update tax code options based off of the date picker onChange.
    if (!$('.nb_form').length) { return ; }
    if ($('#new_sales_credit_note').length) { return ; }
    updateTaxCodeOptionsForNonBatch();
  }

  function nbInvCustomerSelectChange() {
    var $form = $('form');

    reloadCustomerSelectField($form);
    setNominalAccountAndUpdateCurrencyToCustomersCurrency();
    setInvCustomerPrefix($form);
    checkForEuVatCustomer();
    updateSaveAndRecurLink($form);
    if(!isCustomerGroupInvoice($form)) {
      updateDateDue();
      populateInvoiceRefs($form, 'customers'); //TODO: only do this if we're creating a credit note
    }
    updateTaxCodeOptionsForNonBatch();
  }

  function setInvCustomerPrefix(row) {
    if ($('#invoice_ref_tf').length === 0) { return }
    var invoice_ref = getInvoiceRefFromField();
    var $form = $('form');
    if (!isCustomerGroupInvoice($form)) {
      var customer_supplier_id = getCustomerSupplierSelect(row).val();
      if (customer_supplier_id) {
        var url = getCustomerSupplierUrl(row, customer_supplier_id);
        if ( typeof(url) === 'undefined' ) { return; }
        getJsonOrShowMaintenanceModal(url, function(customer) {
          var current_invoice_ref = customer.full_inv_ref_prefix + invoice_ref;
          addCompanyPrefix(current_invoice_ref);
        });
      } else {
        addCompanyPrefix(invoice_ref);
      }
    } else {
      addCompanyPrefix(invoice_ref);
    }
  }

  function addCompanyPrefix(current_invoice_ref) {
    var $invoiceRefTf = $('#invoice_ref_tf');
    var company_prefix = $invoiceRefTf.data('companyInvoicePrefix');
    if (company_prefix.length > 0) {
      $invoiceRefTf.val(company_prefix + '-' + current_invoice_ref);
    } else {
      $invoiceRefTf.val(current_invoice_ref);
    }
  }

  function getInvoiceRefFromField() {
    //E.g CO-CU-001 - remove from last hyphen
    var split_inv_ref = $('#invoice_ref_tf').val().split("-");
    return split_inv_ref[ split_inv_ref.length - 1 ];
  }

  function nbInvTypeChange() {
    switch ($invoiceType.val()) {
      case SALES_CREDIT_NOTE:
        changeToCreditNote();
        break;
      case SALES_INVOICE:
        changeToSalesInvoice();
        break;
    }
    allowEnterToTab();
  }

  function changeToCreditNote() {
    populateInvoiceRefs($('form'), 'customers');
    $('#sales_invoice_use_reminders:checked').trigger('click');
    $('#accept_payments_sf:checked').trigger('click');
  }

  function changeToSalesInvoice() {
    if (!$date_due_field.val()) {
      updateDateDue();
    }
    setInvoiceRefIfNeeded();
  }

  function triggerChosenUpdated($elem) {
    triggerChosen($elem, 'updated');
  }

  function addNbInvEventListeners() {

    onceAfterDatepickersComponentHaveBeenInitialised(function() {
      $('#customer_document_date_div').on('dp.change', nbInvDatePickerChange);
    });

    [ [ $("#customer_select_single"), nbInvCustomerSelectChange ],
      [ $("#customer_select_multiple"), nbInvCustomerSelectChange ],
      [ $(".currency_select.show-disabled-select"), custDocCurrencyChange ],
      [ $("#invoice_ref_selector"), nbInvoiceRefChange ],
      [ $('#cd_net_amount_tf'), updateCustDocTotal ],
      [ $('#cd_tax_amount_tf'), updateCustDocTotal ],
      [ $('#invoice_type'), nbInvTypeChange ]
    ].forEach(function(args) {
      errors.tryOn(args[ 0 ], 'change', args[ 1 ]);
    });

    $("#customer_select_single").change(setDefaultReminder);
    $("#customer_select_multiple").change(setDefaultReminder);

    if ($('.nb_form').data('invoice-id') == undefined) {
      setInvCustomerPrefix($('form'));
    }

    saveAcceptsPaymentsPreference();

    errors.tryOn($('.attachment_button'), 'click', openNonBatchUploadsModal);

    addLineItemEventListeners($('.line_item_row'));

    initCustomNestedFormForInvoiceLineItems();
    addEventListenerForAddingLineItems();
    addEventListenerForRemovingLineItems();

    var is_single_invoice = ($('#is_recurring_transaction_template_field').val() == "false");
    if (($("#invoice_ref_tf").val() == undefined) && is_single_invoice) {
      $("#customer_select_single").trigger('change');
    }
  }

  function setDefaultReminder(event) {
    var toggle = $(event.target).find(':selected').data('default-reminder'),
      $reminder_field = $("#reminder_checkbox");
    if ( typeof(toggle) === 'undefined' || $reminder_field.length == 0 ) { return; }
    $reminder_field.prop('checked', toggle)
  }

  var initCustomNestedFormForInvoiceLineItems = function() {
    // Custom behaviour of nested_form link_to_add in line_items
    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      // Insert new content before row with add new button
      var $new_row = $(content);
      var $last_row = $('tr.line_item_row:last');
      var row_id = $('tr.line_item_row').size() + 1;
      setRowDataAndTaxAmountId($new_row, row_id);
      $new_row.find("div.chosen-container").remove();
      updateNonBatchInvFieldStatesWithSection($new_row, row_id); // Update the blueprint row
      disableTaxAmountIfTaxCodeIsZero($new_row);
      $new_row.find('.nb_split__account select').html($last_row.find('.nb_split__account select').html()); // Copy category options from previous row
      $new_row.insertAfter($last_row);
      return $new_row;
    }
  }

  function setInvoiceRefIfNeeded() {
    if ($('#invoice_ref_tf:visible').length && !$('#invoice_ref_tf').val()) {
      getJsonOrShowMaintenanceModal("/invoices/get_next_invoice_ref.json", function(data) {
        var padded_val = ("000000" + data).slice(-Math.max(6, data.toString().length));
        $("#invoice_ref_tf").val(padded_val);
      });
    }
  }

  function nbInvoiceRefChange(event) { // Credit Note
    var selected_ref = $('#invoice_ref_selector').find('option:selected');
    _getInvoiceLineItemsAndPopulateOptions(selected_ref);
    updateTaxCodeOptionsForNonBatch();
  }

  function _getInvoiceLineItemsAndPopulateOptions(selected_ref) {
    var invoice_id = selected_ref.data('invoiceId');
    if (invoice_id) {
      getJsonOrShowMaintenanceModal(
        '/invoices/' + selected_ref.data('invoiceId') + '/line_items.json',
        _populateParentLineItemSelector()
      );
      if (!selected_ref.data('isSplit')) {
        _setCreditNoteValuesFromInvoice(selected_ref)
        _setLineItemAllocatableFromInvoice(selected_ref)
      }
    }
  }

  function _populateParentLineItemSelector() {
    return function(data) {
      // Populate each line item individually so that we don't replicate the value of the first LI
      $.each($('.invoice_line_items_select'), function(index, li_select) {
        $(li_select).selectPopulator(data, false);
      })
    }
  }

  function _setCreditNoteValuesFromInvoice(selected_ref) {
    _checkSuspenseAccountForSelectedInvoiceRef(selected_ref);
    _setTaxCodeIfAvailableOnExistingLineItems(selected_ref);
    // FARMPLAN - populate enterprise selector on nb credit note with single line item
    $('.enterprise_selector').val(selected_ref.data('enterpriseId')).trigger('chosen:updated');
    // FARMPLAN END
  }

  function _checkSuspenseAccountForSelectedInvoiceRef(selected_ref) {
    if (!selected_ref.data('suspenseInvoice')) {
      $('.split_account').val(selected_ref.data('nominalAccountId')).trigger('chosen:updated');
    }
  }

  function _setTaxCodeIfAvailableOnExistingLineItems(selected_ref) {
    var taxCodeId = selected_ref.data('taxCodeId');
    var taxCodeValue = "option[value=" + taxCodeId + "]";
    var taxCodeOptionField = $('.split_tax_code_field').first().find(taxCodeValue);

    if (!taxCodeOptionField.length) { return; };

    $('.split_tax_code_field').val(taxCodeId).trigger('chosen:updated').trigger('change');

    if (taxCodeOptionField[0].dataset.taxRate != "0.00") {
      $('.split_tax_code_field option').show();
    } else {
      $('.split_tax_code_field option').hide();
      $('.split_tax_code_field').each(function (i, selector) {
        $(selector).find(taxCodeValue).show();
      })
    };
  };


  function _setLineItemAllocatableFromInvoice(selected_ref) {
    $('.line-item-allocatable-id').val(selected_ref.data('invoiceId'))
    $('.line-item-allocatable-type').val('Invoice')
   }

  function updateDateDue() {
    if ($date_due_field.length === 0) {
      return
    }

    var customerId = $('#customer_select_single').val();
    if (customerId) {
      checkForCustomerDaysToPay(customerId);
    } else {
      checkForCompanyDaysToPay();
    }
  }

  function checkForCustomerDaysToPay(customerId) {
    getJsonOrShowMaintenanceModal('/customers/' + customerId + '/get_days_to_pay.json', function(days) {
      if (days !== null) {
        setDateDue(days);
      } else {
        checkForCompanyDaysToPay();
      }
    });
  }

  function checkForCompanyDaysToPay() {
    getJsonOrShowMaintenanceModal('/companies/get_days_to_pay.json', function(days) {
      if (days !== null) {
        setDateDue(days);
      } else {
        setDateDue(30);
      }
    });
  }

  function setDateDue(daysToPay) {
    var $dateInput = $('input[name="sales_invoice[date]"]');
    var invoiceDate = moment($dateInput.val(), $dateInput.data('format'));
    var dateDueFormat = $date_due_field.data('format');
    $date_due_field.val(formatDate(invoiceDate.add(daysToPay, 'days'), dateDueFormat));
  }

  function updateSaveAndRecurLink($form) {
    var url = Routes.new_recurring_transaction_path({
      from_template_form: "#new_sales_invoice",
      type: typeForSaveAndRecurLink($form),
      customer: $('#customer_select_single').val(),
      recurring_transaction: { for_group: isCustomerGroupInvoice($form) }
    });

    $('#save_and_recur_button').attr('href', url);
  }

  function reloadCustomerSelectField($form) {
    if (isCustomerGroupInvoice($form)) {
      $('#customer_select_multiple').selectpicker('refresh');
    } else {
      $('.customer_single_select select').trigger('chosen:updated');
    }
  }

  function typeForSaveAndRecurLink($form) {
    if (isCustomerGroupInvoice($form)) {
      return "CustomerGroupInvoice"
    } else {
      return "SalesInvoice"
    }
  }

  function saveAcceptsPaymentsPreference(){
    $('#accept_payments_sf').click(function(){
      UserPreferences.set('accept_payments_sf_value', $('#accept_payments_sf').prop('checked'));
    });
  }

  function addEventListenerForAddingLineItems() {
    errors.tryOn($('.non_batch_invoices_form'), 'nested:fieldAdded:line_items', function(event) {
      formatNewLineItemRow(event.field);
      var selected_ref = $('#invoice_ref_selector option:selected');
      _getInvoiceLineItemsAndPopulateOptions(selected_ref);
      $('.non_batch_invoices_form').change();
    });
  }

  function addEventListenerForRemovingLineItems() {
    errors.tryOn($('.non_batch_invoices_form'), 'nested:fieldRemoved', function() {
      addLineItemRowIfNeeded();
      updateCustomerDocumentAmounts();
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.nb_form.non_batch_invoices_form').length === 0) { return }
    initNonBatchInvFieldStateController();
    setJQueryReferences();

    var $typeaheadDescriptions = $('.typeahead_descriptions');
    typeAheadInput($('.typeahead_description'), $typeaheadDescriptions);
    typeAheadInput($('.typeahead_unit'), $('.typeahead_units'));

    initInvoiceForm();
    registerCustomerDocumentChangeListener();
  }));
})();
