/**
 * Normalizes an incomplete date string by prepending the current century to the year if necessary.
 * Dates expected to be in the format of either 'dd/mm/yyyy' or 'dd/mm/yy__', with the later
 * having its year completed based on the current century.
 *
 * @param {string} dateString - The date string to normalize.
 * @returns {string} - The normalized date string with a four-digit year or the original string
 *                     if it already has a four-digit year or doesn't match expected formats.
 *
 * @example
 * // returns '22/04/2022'
 * normalizeDateString('22/04/22__');
 *
 * @example
 * // returns '22/04/2022' (unchanged)
 * normalizeDateString('22/04/2022');
 */
export function normalizeDateString(dateString) {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{2})__$/

  // Check if the date is already in the correct format (dd/mm/yyyy)
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return dateString
  }

  // Check if the date is in the expected incomplete format (dd/mm/yy__)
  else if (dateRegex.test(dateString)) {
    return dateString.replace(dateRegex, (_match, day, month, year) => {
      const currentYear = new Date().getFullYear()
      const century = currentYear.toString().substr(0, 2) // Extract the century
      const fullYear = `${century}${year}`

      return `${day}/${month}/${fullYear}`
    })
  } else {
    // If the input doesn't match expected formats, return the input unchanged
    return dateString
  }
}
