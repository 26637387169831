window.initLoadCustGroupButton = function() {
  var elem = $('select.js-load-cust-group');

  if (elem.length > 0) {
    elem.on("chosen:ready", function(event, chosen) {
      var select = $(event.target);
      var chosen_container = select.siblings('div.chosen-container');
      addLoadCustGroupOption(chosen_container);
      setLinkToLoadCustGroup(select);
    });
    elem.on("loaded.bs.select", function(event) {
      var select = $(event.target);
      var bootstrap_select = select.siblings('div.bootstrap-select');
      addLoadCustGroupOption(bootstrap_select);
      setLinkToLoadCustGroup(select);
    });
  }
}

function addLoadCustGroupOption(chosen_container) {
  if (chosen_container.find('button.js-load-cust-group-button').length === 0) {
    if (chosen_container.find('div.customer-select-custom-options').length === 0) {
      createCustomOptionsDiv(chosen_container);
    }
    chosen_container.find('div.customer-select-custom-options').prepend("<button type='button' class='btn btn-primary btn-xs pull-right m-r-5 js-load-cust-group-button' tabindex='-1'><i class='zmdi zmdi-accounts-alt'></i> Select Group</button>");
    loadCustomerGroupClickListener(chosen_container);
  }
}

window.createCustomOptionsDiv = function(chosen_container) {
  chosen_container.find('ul').after("<div class='customer-select-custom-options text-center p-t-5 p-b-25'></div>");
}

function setLinkToLoadCustGroup(select) {
  select.siblings('a.load-cust-group-link').remove();
  select.after("<a href='/company/customer_group_selections/new' data-remote='true' class='load-cust-group-link' tabindex='-1'></a>");
}

function loadCustomerGroupClickListener(chosen_container) {
  chosen_container.find('button.js-load-cust-group-button').click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    loadCustGroupButtonClicked($(this));
  });
}

function loadCustGroupButtonClicked(button) {
  button.addClass('load-cust-group-button-clicked');
  showSelectCustGroupFormInModal(button);
}

function showSelectCustGroupFormInModal(button) {
  button.closest('div.customer_select_container').children('a.load-cust-group-link').click();
}
