import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class BudgetsController extends Controller {
  initialize() {
    window.initDatepickerComponent()
    // Initialize the ancient chosen JS library on the select element
    $('#budgets').chosen({ width: '200px' })
    // jQuery and Stimulus events don't play nice together so we have to listen to the chosen element here
    $('#budgets').on('change', this.updateFilters.bind(this))
  }

  connect() {
    this.useEnterprises = document.getElementById('use-enterprises').dataset.value
    this.parseBudgetsData()
  }

  updateFilters(event) {
    const budget = this.findBudget(event.target.value)
    document.getElementById('filterFormSubmitButton').disabled = false
    this.clearFilters()
    this.updateFilterType(budget)
    this.updateDates(budget)
  }

  parseBudgetsData() {
    if (!this.budgetsData) {
      const budgetsDataElement = document.getElementById('budgets-data')
      this.budgetsData = JSON.parse(budgetsDataElement.textContent)
    }
  }

  findBudget(budgetId) {
    return this.budgetsData.find(b => b.id.toString() === budgetId)
  }

  updateFilterType(budget) {
    if (this.useEnterprises === 'false') {
      return
    }

    let filterType = budget.scope || budget.entity_type || ''

    if (budget.entity) {
      filterType = filterType + ' - ' + budget.entity.name
    }

    this.setElementText('filter-type', filterType)
  }

  updateDates(budget) {
    const formattedStartDate = moment(budget.start_date).format('DD/MM/YYYY')
    const formattedEndDate = moment(budget.end_date).format('DD/MM/YYYY')
    this.setElementValue('start-date', formattedStartDate)
    this.setElementValue('end-date', formattedEndDate)
  }

  setElementText(elementId, text) {
    document.getElementById(elementId).innerHTML = text || ''
  }

  setElementValue(elementId, text) {
    document.getElementById(elementId).value = text || ''
  }

  clearFilters() {
    if (this.useEnterprises === 'false') {
      return
    }

    this.setElementText('filter-type', '')
  }
}
