(function() {
  'use strict';

  function getClosestRowToEvent(event) {
    return wrapBatchInvoiceRow($(event.currentTarget).closest('tr.credit_note_row'));
  }

  function initialiseCreditNoteRows() {
    $('.credit_note_row').each(function() {
      var $row = $(this);
      var $batchInvoiceModal = $row.find('.batch-invoice-modal');
      addCreditNoteRowChangeListeners($row);
      initBatchInvoiceModal($batchInvoiceModal);
      setExistingCreditedInvoices($batchInvoiceModal, $row);
    });
  }

  function addCreditNoteRowChangeListeners(section) {
    var $section = $(section);
    [ [ ".credit_note_datepicker_div", 'focusout', invDateOnChange ],
      [ ".nominal_account_selector", 'change', invNominalAccountChange ],
      [ ".bt_currency_field.show-disabled-select", 'change', currencyOnChange ],
      [ ".add_new_credit_note", 'click', addNewInvoiceButtonClick ],
      [ ".remove_credit_note", 'click', removeInvoiceButtonClick ],
      [ ".invoice_button", 'click', invoiceButtonClick ],
      [ ".invoice_button", 'change', enableDisableInvoiceButton ],
      [ '.tax_amount_tf', 'change', setNoVatSchemeWarning ],
      [ ".customer_supplier_select", 'change', customerSupplierSelectChange ],
      [ 'input.description_tf', 'change', updatePopoverText ],
      [ ".total_amount_tf", 'focus', highlightCell ],
      [ ".attachment_button", 'click', openBatchUploadsModal ]
    ].forEach(function(args) {
      errors.tryOn($section.find(args[ 0 ]), args[ 1 ], args[ 2 ]);
    });
  }

  function setNominalAccount(batchRow, nominalAccountSelectId) {
    var nominalAccountSelect = batchRow.getNominalAccountSelect();
    nominalAccountSelect.val(nominalAccountSelectId);
    nominalAccountSelect.trigger("chosen:updated").trigger('change');
  }

  function setCurrencySelectId(batchRow, currencySelectId) {
    var currencySelect = batchRow.getCurrencySelect();
    var hiddenCurrencyField = batchRow.getHiddenCurrencyField();
    currencySelect.val(currencySelectId).change();
    hiddenCurrencyField.val(currencySelectId).change();
    currencySelect.trigger("chosen:updated");
  }

  // EVENT HANDLERS ETC
  function invDateOnChange(event) {
    setConversionRateForRow(event);
    _invoiceDateWarnings(event);
  }

  function _invoiceDateWarnings(event) {
    var $elem = $(event.currentTarget);

    addDatePeriodWarning($elem);
    addDateInFutureWarning($elem);
  }

  function setCustomerSupplierData(batchRow) {
    var customerSupplierId = batchRow.getCustomerSupplierSelect().val();
    if (customerSupplierId) {
      _populateCustomerSupplierDefaults(batchRow, customerSupplierId);
    }
  }

  function enableDisableInvoiceButton(event) {
    var $invoiceButton = $(event.target);
    var batchRow = getClosestRowToEvent(event);
    var $customerSupplierSelect = batchRow.getCustomerSupplierSelect()
    if ($customerSupplierSelect.val().length ==  0) {
      $invoiceButton.attr('disabled', true);
    } else {
      $invoiceButton.attr('disabled', false);
    }
  }

  // calls get_customer.json or get_supplier.json, and populates their defaults
  function _populateCustomerSupplierDefaults(batchRow, customerSupplierId) {
    var url = getCustomerSupplierUrl(batchRow.getRow(), customerSupplierId);
    if ( typeof(url) === 'undefined' ) { return; }
    getJsonOrShowMaintenanceModal(url, function (customerSupplier) {
      setNominalAccount(batchRow, customerSupplier.nominal_account_id); // Changing the nominal will trigger updating the tax code according to the customer and nominal
      setCurrencySelectId(batchRow, customerSupplier.currency_id_json);
    });
  }

  function invNominalAccountChange(event) {
    var batchRow = getClosestRowToEvent(event);
    new InvoiceTaxCodeDefaulting(
      batchRow.getCustomerSupplierSelect(),
      batchRow.getNominalAccountSelect(),
      batchRow.getTaxCodeSelectsIncludingSplits(),
      batchRow.getStockItemSelect(),
      batchRow.getInvoiceTypeSelect().val()
    ).call();
  }

  function currencyOnChange(event) {
    setConversionRateForRow(event);
  }

  var addNewRowTimeout; // ID of previous call of setTimeout for addNewCreditNoteRow
  var ADD_NEW_ROW_INTERVAL = 200;

  function addNewInvoiceButtonClick(event) {
    event.preventDefault();
    clearTimeout(addNewRowTimeout);
    addNewRowTimeout = setTimeout(function() { addNewCreditNoteRow(); }, ADD_NEW_ROW_INTERVAL); 
    return false;
  }

  function removeInvoiceButtonClick(event) {
    var source = event.currentTarget;
    var tableRowCount = $(source).closest('tbody').find('.credit_note_row').length;
    if (tableRowCount == 1) { addNewCreditNoteRow(); }
    releaseAttachmentsForNonPersistedRecord(event);
    removeClosestRow(event);
    return false;
  }

  function invoiceButtonClick(event) {
    event.preventDefault();
    if($(event.target).closest('tr.batch_row').find('.customer_supplier_select option:selected').val() == "") {
      event.stopPropagation();
      alert('You must specify the account before choosing invoices.');
    }
  }

  function addNewCreditNoteRow() {
    checkNumberRows($('.credit_note_row').length);
    var $table = $('#credit_notes_table_body');
    var lastRow = $table.find("tr.credit_note_row:last");
    var newRow = _generateNewRowForSetup(lastRow);
    $table.append(newRow);
    $('.mousetrap_add_row').html('<i class="zmdi zmdi-plus zmdi-hc-fw" title=""></i>');

    _initInsertedNewRow(newRow);
    _incrementNextRowId();
  }

  function _generateNewRowForSetup(lastRow) {
    var newRow = $(lastRow).clone();
    newRow = removeErrorDivs(newRow);
    _resetRowData(newRow);
    _resetBatchInvoiceModal(newRow);

    var row_id = $('#next-row-id').val();
    _updateRowIdOnNewRow(newRow, row_id);
    _setupUploadModalForNewRow(newRow, row_id);
    return newRow;
  }

  function _resetRowData($row) {
    $row.find('.popover-container').attr('data-content', '');
    $row.find('.popover').remove();
    $row.find("div.chosen-container").remove();
    $row.find("input:not(#credit_notes__tax_code_id, #credit_notes__currency_id, .hidden_date_format_field, #credit_notes__customer_supplier_type)").each(function () {
      $(this).val('');
    });
  }

  function _resetBatchInvoiceModal($row) {
    var $modal = $row.find('.batch-invoice-modal');
    $modal.find('.invoice-line-item-container').remove();
  }

  function _updateRowIdOnNewRow(row, row_id) {
    $(row).attr('id', 'credit_note_row' + row_id).attr('data-id', row_id);
    findAndReplaceUniqueIdForRow(row, 'persisted', row_id);
    $(row).find(".date").attr('id', 'input_date' + row_id + '_container');
    $(row).find(".js_date_field").attr('id', 'input_date' + row_id);
    var new_href = $(row).find(".invoice_button").attr('href').replace(/row_id=\d+/, 'row_id=' + row_id);
    $(row).find(".invoice_button").attr('href', new_href)
    $(row).find(".batch-invoice-modal").attr('id', 'batch_invoice_modal' + row_id);
    $(row).find(".batch-invoice-modal").attr('data-row-id', row_id);
  }

  function _setupUploadModalForNewRow(newRow, row_id) {
    newRow.find('.new-uploads-list').html("");
    findAndReplaceUniqueIdForRow(newRow, 'upload_ids', row_id);
  }

  function _initInsertedNewRow(newRow) {
    $.each($(newRow).find('.typeahead'), function(index, desc_field) {
      typeAheadInput($(desc_field), $('.typeahead_descriptions'));
    })
    formatFormsIn(newRow);
    duplicateFieldValue(newRow, '.js_date_field');
    duplicateFieldValue(newRow, '.upload_company_field');
    addCreditNoteRowChangeListeners(newRow);
    initBatchInvoiceModal(newRow.find('.batch-invoice-modal'));
    $(newRow).find('.invoice_button').change();
    $(newRow).find('.js_date_field').focus();
  }

  function _incrementNextRowId() {
    var row_id = $('#next-row-id').val();
    $('#next-row-id').val(parseInt(row_id) + 1);
  }

  function setNoVatSchemeWarning(event) {
    var batchRow = getClosestRowToEvent(event);
    addNoVatSchemeWarning({
      tax_amount_field: batchRow.getTaxAmountTf(),
      tax_code_field: batchRow.getTaxCodeSelect(),
      datepicker: batchRow.getDatepicker()
    });
  }

  function customerSupplierSelectChange(event) {
    setCustomerSupplierData(getClosestRowToEvent(event));
    var $invoiceButton = $(event.target).closest('tr.batch_row').find('.invoice_button');
    $invoiceButton.change();
    var $row =  $(event.target).closest('tr.batch_row');
    $row.find(".net_amount_tf, .tax_amount_tf, .total_amount_tf").each(function () {
      $(this).val('');
    });
    _resetBatchInvoiceModal($row)
    CreditedInvoiceDataHandler.creditedInvoices.deleteData($row.data('id'));
  }

  function initAreYouSureInvoice() {
    var invoice_form_id = '#batch_credit_note_form';
    window.invoice_are_you_sure_controller = checkFormChangedOnPageUnload(invoice_form_id);
  }

  function initBatchInvoiceModal($modal) {
    var $invoiceCards = $modal.find('.invoice-line-item-container');
    $invoiceCards.each(function() {
      var $invoiceCard = $(this);
      InvoiceCardBehaviour.enableInvoiceCardBehaviourBatchForm($invoiceCard);
      InvoiceCardBehaviour.enableLineItemEventBatchForm($invoiceCard.find('.line_item_row'));
      InvoiceCardBehaviour.actionsAfterInitializeBatchForm($invoiceCard);
    })
    BatchInvoiceModalBehaviour.enableInvoiceModalEvent($modal)
    BatchInvoiceModalBehaviour.actionsAfterInitialize($modal)
  }

  function setExistingCreditedInvoices($modal, $row) {
    var $invoiceCards = $modal.find('.invoice-line-item-container');
    if ($invoiceCards.length !== 0) {
      var row_id = $row.data('id');
      CreditedInvoiceDataHandler.creditedInvoices.initHashOnExistingInvoiceCards($invoiceCards, function() { $row.find('.invoice_button').change(); }, row_id);
    } else {
      $row.find('.invoice_button').change();
    }
  }

  function viewInvoicesModal(rowId) {
    $(`#batch_invoice_modal${rowId}`).modal();
  }

  function initInvoiceModalButtonClickEvent() {
    $(".view-invoice-button").click(function (event) {
      event.stopPropagation();
      var row_id = $(event.target).parents('tr.read-only-row').data("id");
      viewInvoicesModal(row_id);
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#batch_credit_note_form').length === 0) { return }
    $.each($('.typeahead'), function(index, desc_field) {
      typeAheadInput($(desc_field), $('.typeahead_descriptions'));
    });
    initBatchInvFieldStateController();
    initialiseCreditNoteRows();
    initAreYouSureInvoice();
    initInvoiceModalButtonClickEvent();
  }));
})();
