(function() {
  'use strict';

  window.initNonBootgrid = function(pageId, url) {
    _initSearchBar(pageId, url);
    _initFilters(pageId, url);
    _sendNewQuery(pageId, url);
  }

  window.getResults = function(pageId, url, page) {
    if (typeof page === 'undefined') { page = 1; }

    $(pageId + '-loader').show();

    var filterOptions = JSON.stringify($(pageId).data('filters'));
    var searchPhrase = $(pageId).data('search-phrase');
    var queryUID = $(pageId).data('query-uid');
    $(pageId).data('last-page', page);

    $.ajax({
      method : 'get',
      url    : url,
      dataType : 'script',
      data : {
        filterOptions: filterOptions,
        searchPhrase: searchPhrase,
        queryUID: queryUID,
        page: page,
      }
    }).done(function() {
      if (searchPhrase) {
        $('#bulk-destroy-button').addClass('disabled');
        $('#uploads-index').attr('data-page', "1");
        $('#uploads-index').attr('data-upload-ids', "[]");
      }
    });
  }

  window.onResetValues = function(pageId, url) {
    resetDatepickersAndSelectors();
    $('#search-field').val('');
    $(pageId).data('search-phrase', '')
    $(pageId).data('filters', {});
    $('#uploads-index').attr('data-page', "1");
    $('#uploads-index').attr('data-upload-ids', "[]");
    _sendNewQuery(pageId, url);
  }

  function _initSearchBar(pageId, url) {
    var searchField = $('#search-field'),
      timer = null, // fast keyup detection
      currentValue = "";

    searchField.on('keyup', function(e) {
      e.stopPropagation();
      var newValue = $(this).val();

      if (currentValue !== newValue) {
        currentValue = newValue;

        window.clearTimeout(timer);
        timer = window.setTimeout(function() {
          $(pageId).data('search-phrase', newValue);
          _sendNewQuery(pageId, url);
        }, 250);
      }
    });
  }

  function _sendNewQuery(pageId, url) {
    var $page = $(pageId);
    evaluateResetButtonVisibilityFromFilters($page.data('filters'));
    $page.html('');
    $page.data('query-uid', Date.now());
    getResults(pageId, url);
  }

  function _initFilters(pageId, url) {
    $('.advanced-search-date').on('dp.change', function() {
      _onDateChange(pageId, url);
    });

    [ [$('#associated_record_type'), 'associated_record_type'],
      [$('#category_selector'), 'category_id'],
      [$('#bank_account_selector'), 'bank_account_id'],
      [$('#tax_code_selector'), 'tax_code_id'],
      [$('#project_selector'), 'project_id']
    ].forEach(function(args) {
      args[0].on('change', function() {
        _onSelectorChange(pageId, url, args[0], args[1]);
      });
    });

    $('#reset-button').click(function(e) {
      e.preventDefault();
      onResetValues(pageId, url);
    });

    $('#reset-button').addClass('disabled')
  }

  function _onDateChange(pageId, url) {
    var filters = $(pageId).data('filters');
    updateFilterForDateRange(filters);
    $(pageId).data('filters', filters);
    _sendNewQuery(pageId, url);
  }

  function _onSelectorChange(pageId, url, $selector, name) {
    var filters = $(pageId).data('filters');
    updateFilterForSelector(filters, $selector, name);
    $(pageId).data('filters', filters);
    _sendNewQuery(pageId, url);
  }
})();
