(function() {
  'use strict';

  // Use this when we want to trigger a Google conversion in a js.erb response instead of an html.erb response
  window.trackGoogleConversion = function(google_conversion_id, google_conversion_label, user_id) {
    var image = new Image(1, 1);
    image.src = '//www.googleadservices.com/pagead/conversion/' + google_conversion_id +
      '/?value=1.00&currency_code=GBP&label=' + google_conversion_label +
      '&guid=ON&oid=' + user_id;
  }

  // Use this when we want to trigger a LinkedIn conversion in a js.erb response instead of an html.erb response
  window.trackLinkedInConversion = function(linkedin_conversion_id) {
    var image = new Image(1, 1);
    image.src = 'https://dc.ads.linkedin.com/collect/?pid=55726&conversionId=' + linkedin_conversion_id + '&fmt=gif';
  }
})();
