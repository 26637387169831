(function() {
  'use strict';

  function initImportedInvoiceForm() {
    // From Invoice Batch
    typeAheadInput($('.typeahead'), $('.typeahead_descriptions'));
    initialiseImportedInvoiceRow();
    initAreYouSureImportedInvoice();
  }

  function initialiseImportedInvoiceRow() {
    var $row = $('.invoice_row');
    addInvoiceRowChangeListeners($row);
    $row.find('.js_date_field').last().focus();
  }

  function initAreYouSureImportedInvoice() {
    checkFormChangedOnPageUnload('#imported_invoice_form');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#imported_customer_invoices, #imported_supplier_invoices').length === 0) { return }
    if ($('#imported_invoice_form').length > 0) {
      initImportedInvoiceForm();
      initBatchInvFieldStateController();
      errors.tryClick($('#form-submit'), preprocessSplitModalsForSubmit);
    }
    initBootgridTable($('#imported_invoices_index_table'));
  }));
})();