import { Controller } from '@hotwired/stimulus'
import { parseHTMLDocument } from 'helpers/dom_parser'

export default class extends Controller {
  static targets = ['container']

  static values = {
    url: String,
    method: String,
    pollInterval: Number,
    contentClass: String
  }

  connect() {
    if (!this.hasUrlValue) {
      console.error('[stimulus-content-loader] You need to pass an url to fetch the remote content.')
    }
  }

  disconnect() {
    this.stopPolling()
  }

  poll() {
    this.pollTimer = setInterval(() => {
      this.load()
    }, this.pollIntervalValue)
  }

  stopPolling() {
    if (this.pollTimer) {
      clearInterval(this.pollTimer)
    }
  }

  load() {
    const controller = this
    fetch(controller.urlValue, { method: controller.methodValue || 'GET', credentials: 'include', headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content } })
      .then((response) => response.text())
      .then((html) => {
        const responseDocument = parseHTMLDocument(html)
        if (html.length > 0 & responseDocument.getElementsByClassName(controller.contentClassValue).length > 0) {
          this.element.innerHTML = html
          this.stopPolling()
        }
      })
  }

  urlValueChanged() {
    if (this.hasPollIntervalValue) {
      this.poll()
    } else {
      this.load()
    }
  }
}
