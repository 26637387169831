(function () {
  'use strict';

  window.displayErrorIfDateBeforeLockedDate = function(new_feed_import_form, locked_date, pull_from) {
    new_feed_import_form.find('button[type="submit"]').click(function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      var moment_date = moment(locked_date, 'DD/MM/YYYY');
      
      if (locked_date && pull_from.date() <= moment_date) {
        var DATE_BEFORE_LOCKED_DATE_END = "The date you have selected is in your company's locked period, which ends on " +
        formatDateForCompany(moment_date) + ".";
        confirmFormSubmission(DATE_BEFORE_LOCKED_DATE_END, new_feed_import_form);
      } else {
        new_feed_import_form.submit();
      }
    });
  }
})();
