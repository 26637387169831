(function() {
  'use strict';

  window.hideSubmitButtonForEmptyBtForm = function() {
    if (isRowContainerEmpty(findBtRowContainer())) {
      disableFields($('#form-submit'))
    }
  };

  function findBtRowContainer() {
    return $('#bank-transaction-rows')
  }

  function isRowContainerEmpty($rows) {
    return $rows.length > 0 && $rows.children().length === 0
  }

  function addImportedAmountChangedWarning(event) {
    var row_id = $(event.currentTarget).closest('tr').data('id');
    if ($('#net_amount_field' + row_id).val() < 0) {
      addHelpMessage($(this), HELP_WARNING, 'The net value on row ' + row_id + ' is negative!');
    }

    if ($('#tax_amount_field' + row_id).val() < 0) {
      addHelpMessage($(this), HELP_WARNING, 'The tax value on row ' + row_id + ' is negative!');
    }
  }

  window.addImportedBtFormEventListeners = function(row_id) {
    var row = row_id ? $('#bt__row' + row_id) : $('[id^="bt__row"]');

    row.find('.net_amount_field').on('change', addImportedAmountChangedWarning);
    row.find('.tax_amount_field').on('change', addImportedAmountChangedWarning);
    row.find('.bt__account select').on('change', showBankRuleBankTransferPopup);
  };

  window.fetchConversionRatesForImportedBankTransactions = function() {
    //Fetch conversion rates for rows where it's blank
    var blank_rows = $('#bank-transaction-rows').find('.conversion_rate_field:visible').filter(function() { return !this.value;}).closest('tr');
    $(blank_rows).find('.bt_currency_field.show-disabled-select').change();
  };

  function initAreYouSureImportedBankTransactions() {
    window.bt_are_you_sure_controller = checkFormChangedOnPageUnload('#imported_bank_transaction_form');
  }

  var PAGE_INFO_PATTERN = new RegExp(/(\d+) to (\d+) of (\d+)/);

  /**
   * Gets the Page information such as "Showing transaction_type {min_value} to {max_value} of {total}
   *
   * @returns {*}
   */
  window.getPageInfoValues = function () {
    return getPageInfo().html().match(PAGE_INFO_PATTERN)
  };

  window.decrementBatchFormPageTotalBy = function(number) {
    if ( getPageInfo().length === 0 ) { return; }
    var new_max = newMaxValueForPageInfo() - number;
    var new_min = newMinValueForPageInfo(new_max);
    var new_total = newTotalValueForPageInfo(number);
    let tabSelector = document.querySelector('#main .tab-nav');
    updatePageInfoText(new_min, new_max, new_total);
    if(tabSelector) {
      tabSelector.dispatchEvent(
        new CustomEvent('set-tab-counter-value', { detail: { new_value: new_total } })
      );
    }
  };

  window.incrementBatchFormPageTotalBy = function(number) {
    decrementBatchFormPageTotalBy(number * -1);
  }

  window.updatePageInfoText = function(new_min, new_max, new_total) {
    var $page_info = getPageInfo(),
        text;

    if (new_total === 0){
      text = "Showing 0 " + $page_info.data('import-type');
    } else {
      text = "Showing " + $page_info.data('import-type') + " " + new_min + " to " + new_max + " of " + new_total
    }

    $page_info.html(text);
  };

  function getPageInfo() {
    return $memoize('#page-info')
  }

  function newMaxValueForPageInfo() {
    return $('.batch_row').length;
  }

  function newMinValueForPageInfo(new_max) {
    if ( new_max === 0 ) {
      return 0;
    } else {
      return getPageInfoValues()[1];
    }
  }

  window.getTotalNumberOfTransactions = function() {
    var page_info = getPageInfoValues();
    if (page_info && page_info[3]) {
      return page_info[3]
    } else {
      return 0;
    }
  };

  function newTotalValueForPageInfo(number) {
    return getTotalNumberOfTransactions() - number;
  }

  var OFFSET_PATTERN = /offset=\d+/;
  var ROW_ID_PATTERN = /row_id=\d+/;

  window.updateBatchFormUrlValues = function(rowId) {
    var $show_more = getShowMoreButton();
    if ( $show_more.length === 0 ) { return; }
    $show_more.attr(
      'href',
      getNewBatchFormUrlValues($show_more.attr('href'), rowId)
    );
  };

  function getShowMoreButton() {
    return $memoize('#show-more')
  }

  function getNewBatchFormUrlValues(href, rowId) {
    var offset = newMaxValueForPageInfo();
    if ( offset > 0 ) {
      if ( typeof(rowId) !== 'undefined' ) {
        href = href.replace(ROW_ID_PATTERN, 'row_id=' + rowId);
      }
      return href.replace(OFFSET_PATTERN, 'offset=' + offset);
    } else {
      return href;
    }
  }

  function warnForAlreadyKnownPotentialDuplicates() {
    $.each($('[id*="potential_duplicate"]'), _showPotentialDuplicateWarning)
  }

  function _showPotentialDuplicateWarning(_, duplicate_field) {
    var row_id = duplicate_field.id.split('#')[1];
    var dup_values = _duplicateValuesHash(duplicate_field);
    var account_type = duplicate_field.dataset['accountType'];
    _warnForDuplicateTransferOrTransaction(account_type, dup_values, row_id);
  }

  function _duplicateValuesHash(duplicate_field) {
    return {
      total_amount: duplicate_field.dataset['totalAmount'],
      date: duplicate_field.dataset['date'],
      description: duplicate_field.dataset['description']
    }
  }

  function _warnForDuplicateTransferOrTransaction(account_type, dup_values, row_id) {
    if ( account_type === 'BankAccount' ) {
      addDuplicateBankTransferWarning(dup_values, row_id);
    } else {
      addDuplicateBankTransactionWarning(dup_values, row_id);
    }
  }

  function showBankRuleBankTransferPopup(event) {
    var $select = $(event.currentTarget);
    var row_id = $select.closest('tr').data('id');
    var bank_transaction_type = getBtType(row_id);
    if(btTypeIsInvoices(bank_transaction_type)) { return; }

    var category_code = $select[0].options[$select[0].selectedIndex].text.split(" | ")[0];
    var category_type = $select.val().split('#')[0];
    var ignoreBankAccount = getAccountFieldSelection(event).data('ignore-account');
    var description = $('#description_field'+row_id).val().toLowerCase();
    hideAllPopovers();
    if (category_type == 'BankAccount' && !ignoreBankAccount ) {
      var $popoverSpan = $(this).parents('.bt__account').children('.bank_transfer_rules_popover').children(".popover-container");
      showPopover($popoverSpan);
      $('#bank_transfer_rule_link'+row_id).off().on('click', getBankTransferRuleFormWithAutoConfirmParamsFromRow)
      $('#dismiss_bank_transfer_rule_'+row_id).off().on('click', setDismissPopupEntryAndUpdateFormFields)

    } else if (category_code == "400" && (description.includes("stripe") || description.includes("paypal"))) {
      var $popoverSpan = $(this).parents('.bt__account').children('.payment_provider_popover').children(".popover-container");
      showPopover($popoverSpan);
      $('#dismiss_payment_provider_'+row_id).off().on('click', hideAllPopovers)
    }
  }

  function getBankTransferRuleFormWithAutoConfirmParamsFromRow(event){
    event.stopPropagation();
    var row_id = $(event.currentTarget).closest('tr').data('id');
    var imported_bank_transaction_id = $('#imp_bt_id'+row_id).val();
    var global_id = getAccountFieldSelection(event).data('global-id')
    getNewBankTransferRule(imported_bank_transaction_id, global_id, false);
  }

  function getBankTransferRuleFormWithAutoIgnoreParamsFromError(event){
    event.stopPropagation();
    var that = $(this);
    var imported_bank_transaction_id = that.data('imported-bank-transaction-id')
    var global_category_id = that.data('global-category-id')
    getNewBankTransferRule(imported_bank_transaction_id, global_category_id, true);
  }

  function getNewBankTransferRule(imported_bank_transaction_id, global_category_id, ignore_form_checked){
    var new_bank_transfer_rule_path = Routes.new_bank_transfer_rule_path({
      imported_bank_transaction_id: imported_bank_transaction_id,
      global_category_id: global_category_id,
      ignore_form_checked: ignore_form_checked,
      format: 'js'
    });
    $.get(new_bank_transfer_rule_path);
  }

  function setDismissPopupEntryAndUpdateFormFields(event){
    event.stopPropagation();

    setIgnoredBankAccountEntries(event);
    updateAccountFieldDataAttr(event);
    hideAllPopovers();
  }

  function setIgnoredBankAccountEntries(e) {
    var import_bank_account_id = $('#bank_transaction_table_new').data('import-bank-account-id');
    var ignored_bank_account_id = _getNominalAccountIdFromSelectVal(e);
    var bank_transfer_rule_ignored_suggestions_path = Routes.bank_transfer_rule_ignored_suggestions_path();
    var ignored_suggestion = {
      ignored_suggestion: {
        import_bank_account_id: import_bank_account_id,
        ignored_bank_account_id: ignored_bank_account_id
      }
    };
    $.post(bank_transfer_rule_ignored_suggestions_path, ignored_suggestion);
  }

  function _getNominalAccountIdFromSelectVal(e){
    var selected_account = getAccountFieldSelection(e).val();
    return selected_account.split('#')[1];
  }

  function updateAccountFieldDataAttr(e){
    var bank_account_value = getAccountFieldSelection(e).val();

    $("select[id^=account_field] option[value='" + bank_account_value + "']").each( function(){
      $(this).attr('data-ignore-account', true);
    });
  }

  function getAccountFieldSelection(e){
    var row_id = $(e.currentTarget).closest('tr').data('id');
    return getBtAccountSelect(row_id).find('option:selected')
  }

  function hasClickedPopover(e) {
    return $(e.target).data("toggle") == "popover";
  }

  function hasClickedPopoverContainer(e) {
    return $(e.target).parents('.bt__account').length != 0;
  }

  function selectAllVisibleCheckboxes(){
    var isChecked = $('.select-box')[0].checked;

    // Use .click to add the ID to #id_field
    if (isChecked) {
      if ($('.checkboxes:unchecked').not(':disabled').length > 0) {
        // Select visible rows
        $('.checkboxes:unchecked').click();
      }
    } else {
      if ($('.checkboxes:checked').not(':disabled').length > 0) {
        // Unselect visible rows
        $('.checkboxes:checked').click();
      }
    }
    updateSelectedCount();
  };

  window.reloadBootgridTable = function(id) {
    var table = $(id);
    if ( table && table.hasClass('bootgrid-table') ){
      // Don't try to reload the Bootgrid table if it hasn't been initialized yet
      table.bootgrid('reload');
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#imported_bank_transaction_form').length === 0) { return }

    initBatchBankTransactionsForm();

    $(document).click(function(e) {
      if ( !hasClickedPopover(e) && !hasClickedPopoverContainer(e) ) {
        hideAllPopovers();
      }
    });

    addImportedBtFormEventListeners();
    fetchConversionRatesForImportedBankTransactions();
    warnForAlreadyKnownPotentialDuplicates();

    $('th.select-checkbox').on('click', selectAllVisibleCheckboxes);
    $('.ignore_bank_transfer_rule_error').each(function (){
      $(this).on('click', getBankTransferRuleFormWithAutoIgnoreParamsFromError);
    });
    initAreYouSureImportedBankTransactions();
  }));
})();
