(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.feedbacks-index').length === 0) { return }
    if ($('#feedbacks_table').length === 0) { return }
    initBootgridTable($('#feedbacks_table'));

    var tooltipContent = function(label, xval, yval, x, y, z) {
      var posCount = x.series.data[x.dataIndex][2]
      var neuCount = x.series.data[x.dataIndex][3]
      var negCount = x.series.data[x.dataIndex][4]
      if (yval.charAt(0) === '-') {
        return 'Positive: ' + posCount + ' Neutral: ' + neuCount + ' Negative: ' + negCount + ' Average: ' + yval.slice(0, 1) + parseFloat(yval.slice(1, -1)).toFixed(2)
      } else {
        return 'Positive: ' + posCount + ' Neutral: ' + neuCount + ' Negative: ' + negCount + ' Average: ' + parseFloat(yval).toFixed(2)
      }
    }

    initLineChartOrDisplayNoData($('#feedbacks-line-chart'), tooltipContent);
  }));
})();
