(function () {
  'use strict';

  var STOCK_TAB_MAP = {
    'tracked': {
      options_id: 'tracked',
      table_id: 'tracked_stock_items_table',
      multi_sort: false
    },
    'untracked': {
      options_id: 'untracked',
      table_id: 'untracked_stock_items_table',
      multi_sort: false
    }
  };

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#stock_tabs').length === 0) { return };
    initStockItemsIndexPage();
  }));

  function initStockItemsIndexPage() {
    initNavTabsWithTables({
      tabbable_id: '#stock_index',
      user_preference_key: 'stock_tab_id',
      tab_map: STOCK_TAB_MAP
    });
    initNavTabsChangeEventHandler();
  }

  function initNavTabsChangeEventHandler() {
    $('#stock_tabs a').on("click", function() {
      var active_tab_id = $(this).attr('id');
      setOptionsForCurrentTab(active_tab_id);
    });
    
    var active_tab_id = $('#stock_tabs li.active a').attr('id');
    setOptionsForCurrentTab(active_tab_id);
  }

  function setOptionsForCurrentTab(active_tab_id) {
    var $openingInventoryButton = $('#opening-inventory-button');
    var url = new URL($('#add-new-item-button').attr('href'));

    if (active_tab_id == 'tracked_tab') {
      $openingInventoryButton.show();
      url.searchParams.set('is_tracked', true);
    } else {
      $openingInventoryButton.hide();
      url.searchParams.delete('is_tracked');
    }

    $('#add-new-item-button').attr('href', url.toString());
  }
})();