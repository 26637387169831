(function() {
  'use_strict'

  window.initCreditNotesAllocationsModal = function() {
    var $modal = $('#select-invoice-modal');
    $modal.find('.total_amount_tf').on('change', totalAmountFieldChange);
    $modal.find('.pif a.pif_btn').on('click', payInFullButtonClick);
    $modal.find('#modal-close-btn_sales_credit_notes_allocations').on('click', doneButtonClick);
  }

  function totalAmountFieldChange(event) {
    var $invoiceRow = $(event.currentTarget).parents('tr');
    var $totalField = $invoiceRow.find('.total_amount_tf');
    var $outstandingField = $invoiceRow.find('.inv_total_amt_outstanding');

    var newTotal = parseFloat($totalField.val());
    var originalOutstanding = parseFloat($outstandingField.data('original-outstanding'));
    if (newTotal < 0 || isNaN(newTotal)) {
      newTotal = 0;
    } else if (newTotal > originalOutstanding) {
      newTotal = originalOutstanding;
    }
    var newOutstanding = originalOutstanding - newTotal;

    $totalField.attr('value', newTotal.toFixed(2));
    $totalField.val(newTotal.toFixed(2));
    $outstandingField.val(newOutstanding.toFixed(2));
  }

  function payInFullButtonClick(event) {
    event.preventDefault();
    var $invoiceRow = $(event.currentTarget).parents('tr');
    var $totalField = $invoiceRow.find('.total_amount_tf');
    var $outstandingField = $invoiceRow.find('.inv_total_amt_outstanding');

    var originalOutstanding = $outstandingField.data('original-outstanding');
    $totalField.val(originalOutstanding);
    $totalField.change();
  }

  function doneButtonClick(event) {
    event.preventDefault();
    $('#sales_credit_notes_allocations').submit();
    $('#select-invoice-modal').modal('hide');
  }
})();