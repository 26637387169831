(function() {
  'use strict';

  var page_expiry_warning;

  function startSessionExpiryTimer(){
    page_expiry_warning = setTimeout(warnOfSessionExpiry, 10800000);
  }

  function resetSessionExpiryTimer(){
    clearTimeout(page_expiry_warning);
    startSessionExpiryTimer();
  }

  function warnOfSessionExpiry(){
    errors.showModal(
      document.createTextNode('Warning'),
      '<div class="modal-body">' +
        '<p>This tab has been inactive for a while and your session may have expired due to timeout.' +
        '<p>Please try <a href="' + window.location.href + '">refreshing the page</a></p>' +
      '</div>'
    );
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    resetSessionExpiryTimer();
  }));

  $(document).ajaxStart(function(){
    resetSessionExpiryTimer();
  });

})();
