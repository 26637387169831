var RowSelected = {
  transactions: []
};

window.initBootgridTable = function(table, row_count, csv_export, filter_options, labels) {
  filter_options = useInjectedFilterOptions(filter_options, getFilterOptionsFromPage(table.attr('id')));

  return errors.try(function() {
    row_count = typeof row_count !== 'undefined' ? row_count : [ 10, 25, 50, 100 ]
    if ( table.length === 0 ) { return; }
    return table.bootgrid({
      css : {
        icon : 'zmdi icon',
        iconColumns : 'zmdi-view-module',
        iconDown : 'zmdi-chevron-down',
        iconRefresh : 'zmdi-refresh',
        iconUp : 'zmdi-chevron-up',
        iconExport : 'csv-export-icon',
        iconFilterOptions : 'zmdi-filter-list',
        iconLoading : 'zmdi-rotate-right zmdi-hc-spin'
      },
      ajaxSettings : {
        method : 'GET'
      },
      multiSort : false,
      rowCount : row_count,
      csvExport : csv_export,
      filterOptions : filter_options,
      labels: labels
    });
  });
}

function useInjectedFilterOptions(defaults, injected) {
  return injected ? injected : defaults;
}

function getFilterOptionsFromPage(table_id) {
  var settings = window.bootgrid_settings;
  return settings && settings.filter_options[table_id];
}

window.initBootgridTableWithCheckboxes = function(table, csv_export, filter_options) {
  filter_options = useInjectedFilterOptions(filter_options, getFilterOptionsFromPage(table.attr('id')));

  return errors.try(function() {
    table.bootgrid({
      css : {
        icon : 'zmdi icon',
        iconColumns : 'zmdi-view-module',
        iconDown : 'zmdi-chevron-down',
        iconRefresh : 'zmdi-refresh',
        iconUp : 'zmdi-chevron-up',
        iconExport : 'csv-export-icon',
        iconFilterOptions : 'zmdi-filter-list',
        iconLoading : 'zmdi-rotate-right zmdi-hc-spin'
      },
      ajaxSettings : {
        method : 'GET'
      },
      multiSort : false,
      selection : true,
      multiSelect : true,
      keepSelection : true,
      csvExport : csv_export,
      filterOptions : filter_options
    }).on('selected.rs.jquery.bootgrid', errors.tryFn(function(e, rows) {
      for (var i = 0; i < rows.length; i++) {
        RowSelected.transactions.push(rows[ i ].id);
        rowClick();
      }
    })).on('deselected.rs.jquery.bootgrid', errors.tryFn(function(e, rows) {
      for (var i = 0; i < rows.length; i++) {
        var id = rows[ i ].id;
        var index = $.inArray(id, RowSelected.transactions);
        RowSelected.transactions.splice(index, 1);
        rowClick();
      }
    }));
    disableButtonsWhenNoSelection();
  });
}

function toggleGroupOptionWhenApplicable() {
  if ($("tr.active").find(".converted").length > 0) {
    $('[data-convertable-quotes]').attr('disabled', true);
  } else {
    $('[data-convertable-quotes]').attr('disabled', false);
  }
}

function rowClick() {
  // Need to use .split(',') in controller to make an array of IDs
  $('#id_field').val(RowSelected.transactions);
  disableButtonsWhenNoSelection();
  toggleGroupOptionWhenApplicable();
}

window.initTransactionUpdates = function() {
  RowSelected.transactions = [];
}

function disableButtonsWhenNoSelection() {
  if ($('.require-selection').length === 0) { return }
  $('.require-selection').attr('disabled', RowSelected.transactions.length === 0);
}
