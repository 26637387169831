import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['linkId']

  connect() {
  }

  createNewQuickLink(event) {
    event.preventDefault()
    event.stopPropagation()
    const controller = this
    $.post(
      Routes.user_custom_quick_links_path({ format: 'js' }),
      {
        custom_quick_links: {
          path: controller.getPageUrl()
        }
      }
    )
  }

  showDeleteWarning(event) {
    const linkId = $(event.target).closest('li').data('link-id')
    return sweetAlert({
      title: 'Warning!',
      text: 'Are you sure you want to delete this link?',
      type: 'warning',
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: 'sweetalert-scary-warning'
    }, isConfirmed => this.deleteQuickLink(isConfirmed, linkId))
  }

  deleteQuickLink(isConfirmed, linkId) {
    if (!isConfirmed) { return }
    $.ajax({
      url: Routes.user_custom_quick_link_path({ format: 'js', id: linkId }),
      method: 'delete'
    })
  }

  getPageUrl() {
    const pathname = window.location.pathname
    let queryParams = window.location.search

    if (this.currentPageUsesTabs()) {
      const activeTab = this.getActiveTabElement().children[0]
      const tabName = activeTab.id.split('_')[0] // Similar to getTabbedFormTabId
      queryParams = '?tab=' + tabName
    }

    return pathname + queryParams
  }

  currentPageUsesTabs() {
    return Boolean(document.querySelector('#main .tab-nav') != null)
  }

  getActiveTabElement() {
    return document.querySelector('#main .tab-nav .active')
  }
}
