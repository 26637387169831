(function() {
  'use strict';

  window.showErrorsOnInvoicePaymentForm = function(errors) {
    var $form = $('#invoice_payment_form');
    showErrorsOnStripeForm(errors, $form)
  }

  window.addCardJsStyling = function(stripe_elt, flipped, focused_field_class, onchange) {
    stripe_elt.on('focus', function(){
      if ( flipped ) { $('.jp-card').addClass('jp-card-flipped'); }
      $(focused_field_class).addClass('jp-card-focused');
    })
    stripe_elt.on('blur', function(){
      $('.jp-card').removeClass('jp-card-flipped');
      $(focused_field_class).removeClass('jp-card-focused');
    })
    if (typeof(onchange) == 'function') { stripe_elt.on('change', onchange) }
  }

  window.checkCardType = function(event){
    switch (event.brand) {
      case 'visa':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-visa');
        break;
      case 'mastercard':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-mastercard');
        break;
      case 'amex':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-amex');
        break;
      case 'discover':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-discover');
        break;
      case 'diners':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-dinersclub');
        break;
      case 'jcb':
        markCardTypeNotIdentified();
        $('.jp-card').addClass('jp-card-identified jp-card-jcb');
        break;
      case 'unionpay':
        markCardTypeNotIdentified();
        break;
      case 'unknown':
        markCardTypeNotIdentified();
        break;
      default:
        markCardTypeNotIdentified();
    }
  }

  function markCardTypeNotIdentified(){
    $('.jp-card').removeClass('jp-card-identified jp-card-visa jp-card-mastercard jp-card-amex jp-card-discover jp-card-dinersclub jp-card-jcb');
  }
})();
