import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['singleCheckbox', 'selectAllCheckbox']

  toggleSingleCheckboxes() {
    const that = this
    this.singleCheckboxTargets.forEach(function (checkbox) {
      checkbox.checked = that.selectAllCheckboxTarget.checked
      checkbox.dispatchEvent(new Event('change'))
    })
    window.updateSelectedCount()
  }

  toggleSelectAllCheckbox(event) {
    if (!event.target.checked) {
      this.selectAllCheckboxTarget.checked = false
    } else {
      this.selectSelectAllCheckbox()
    }
  }

  selectSelectAllCheckbox() {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }
    const boolArray = []

    this.singleCheckboxTargets.forEach(function (checkbox) {
      boolArray.push(checkbox.checked)
    })

    const filteredArray = boolArray.filter(unique)

    if (filteredArray.length === 1 && filteredArray[0] === true) {
      this.selectAllCheckboxTarget.checked = true
    }
  }
}
