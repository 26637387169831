import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).on('dp.change', function (event, index) {
      this.dispatchEvent(new CustomEvent('datepicker-change'))
    })
  }

  disconnect() {
    $(this.element).off()
  }
}
