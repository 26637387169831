(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#nominal-set-form').length === 0) { return }

    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      var $row = $('#row-add-button-row')
      var field = $(content).insertBefore($row);
      formatForms();
      return field;
    }
  }));
})();
