import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.hasUnsavedChanges = false
    this.companiesWithDraftCombinedVatReturns = []
    this.sweetOptions = {
      title: 'Are you sure?',
      text: 'Are you sure you want to leave the screen without saving your changes?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Okay',
      cancelButtonText: 'Cancel',
      customClass: 'sweet-farmplan'
    }

    // Add change event to selects in the Bootgrid table
    $('#companies_index_table').on('loaded.rs.jquery.bootgrid', () => {
      this.element.querySelectorAll('[data-level-target="select"]').forEach((select) => {
        select.addEventListener('change', event => {
          const companyName = event.target.dataset.companyName
          this.hasUnsavedChanges = true
           if(Number(event.target.value) < 2 && JSON.parse(event.target.dataset.combinedVat)){
            this.companiesWithDraftCombinedVatReturns.push(companyName)
           } else {
            this.companiesWithDraftCombinedVatReturns = this.companiesWithDraftCombinedVatReturns.filter(company => company !== companyName)
           }
         })
      })
    })

    // Handle Turbo Links
    document.addEventListener('turbo:before-visit', this.handleLinks.bind(this))

    // Handle links with data-turbo="false"
    document.querySelectorAll('a[data-turbo="false"]').forEach(link => {
      link.addEventListener('click', this.handleLinks.bind(this))
    })
  }

  handleLinks(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault()
      sweetAlert(this.sweetOptions,
        (isConfirmed) => {
          if (isConfirmed) window.location.href = event.detail.url ? event.detail.url : event.target.closest('[href]').href
        })
    }
  }

  save(event) {
    event.preventDefault();
    if (this.companiesWithDraftCombinedVatReturns.length) {
      sweetAlert({
        ...this.sweetOptions,
        text: "The following companies currently have combined VAT returns that have not been submitted: " + this.companiesWithDraftCombinedVatReturns.toString()
      }, (isConfirmed) => {
        if (isConfirmed) {
          // Allow submission if confirmed
          event.target.closest("form").submit();
        }
      });
    } else {
      // No confirmation needed; submit directly
      event.target.closest("form").submit();
    }
  
    this.hasUnsavedChanges = false;
  }
}
