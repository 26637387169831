/**
 * @fileoverview
 * This decorates the modal jquery plugin, supporting an additional areYouSure
 * configuration and behaviour.
 */
(function() {
  'use strict';

  var WARNING_MESSAGE = 'You have entered data into the form which will be lost. Are you sure you want to continue?';
  var _modal = $.fn.modal;
  $.fn.modal = modalAreYouSureDecorated;

  /**
   * Extends behaviour in
   * @link{http://getbootstrap.com/javascript/#modals-usage} with an optional
   * areYouSure key in the options object.
   *
   * This option defaults to false. If set to true, then the modal's input
   * fields and datepickers will be checked whenever the {close} event is
   * triggered. If any fields or datepickers have been changed from their
   * initial values, the user will be presented with an 'Are you sure?'
   * dialogue message, permitting them to continue or prevent the modal from
   * closing.
   *
   * This option is set when ModalFieldsController is used or when options
   * are explicitly passed it. If no options are defined, it will be decorated.
   *
   * @param {Object=} options Optional options object
   * @returns {$} The JQuery object upon which modal() was called.
   */
  function modalAreYouSureDecorated(options){
    if(!(options && options.areYouSure === false)){
      _decorateModal(this);
    }
    return _modal.apply(this, arguments);
  }
  /**
   * Check if the modal has a form element first
   * @param $modal
   * @private
   */
  function _decorateModal($modal) {
    if(_hasFormElement($modal)){
      _addModalHideListener($modal, new FormChangeObserver($modal));
    }
  }
  /**
   *
   * @param $modal
   * @returns {boolean}
   * @private
   */
  function _hasFormElement($modal){
    return $modal.find('form').length > 0;
  }
  /**
   * @param $modal
   * @param formChangeObserver
   * @private
   */
  function _addModalHideListener($modal, formChangeObserver) {
    $modal.on('hide.bs.modal', _getModalHideHandler(formChangeObserver));
  }
  /**
   * If the modal has a stale form, show a confirmation modal and attach a
   * new event listener if the user tries to close the form again. Otherwise
   * permit it to close.
   * @param observer {FormChangeObserver} Object with an isStale() method
   * @returns {Function}
   * @private
   */
  function _getModalHideHandler(observer){
    return function(e){
      if(observer.isStale()) {
        _displayCloseConfirmationModal(e, observer);
      } else {
        observer.dispose();
      }
    };
  }
  /**
   * Remove the hide listener from the original modal.
   * Prevent the modal from closing and display a confirmation warning instead.
   *
   * @param e {jQuery.Event}
   * @param observer
   * @private
   */
  function _displayCloseConfirmationModal(e, observer) {
    var $originalModal = $(e.target);
    e.preventDefault();

    _removeModalHideListenerFn($originalModal);

    confirmModal({
      content: WARNING_MESSAGE,
      onConfirm: _disposeObserverFn(observer),
      afterConfirm: _hideOriginalModalFn($originalModal),
      afterCancel: _addModalHideListenerFn($originalModal, observer)
    });
  }
  /**
   * @param $modal
   * @private
   */
  function _removeModalHideListenerFn($modal) {
    $modal.off('hide.bs.modal');
  }
  /**
   * @param observer
   * @returns {Function}
   * @private
   */
  function _disposeObserverFn(observer){
    return function(){
      observer.dispose();
    };
  }
  /**
   * @param $originalModal
   * @returns {Function}
   * @private
   */
  function _hideOriginalModalFn($originalModal){
    return function(){
      $originalModal.modal('hide');
    };
  }
  /**
   * @param $originalModal
   * @param observer
   * @returns {Function}
   * @private
   */
  function _addModalHideListenerFn($originalModal, observer){
    return function(){
      _addModalHideListener($originalModal, observer);
    };
  }
})();
