(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#content.vat_returns.index').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#vat_index',
      user_preference_key: 'vat_index_tab_id',
      tab_map: {
        'vat-returns': {
          options_id: 'vat_returns',
          table_id: 'vat_returns_table',
          multi_sort: true
        },
        'hmrc-submissions': {
          options_id: 'hmrc_submissions',
          table_id: 'hmrc_submissions_table',
          multi_sort: true
        }
      }
    });
  }));
})();
