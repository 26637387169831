(function() {
  'use strict';

  function verifyEmailInputFor(id) {
    if ($(id).length === 0) { return }
    verifyEmailInput($(id));
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    verifyEmailInputFor('#invoice_email_creator_customer_email');
    verifyEmailInputFor('#invoice_email_creator_cc');
    verifyEmailInputFor('#invoice_email_creator_bcc');
  }));
})();
