(function() {
  'use strict';

  window.updateJournalEntryTotalAmounts = function(event) {
    setTotalAmount('debit');
    setTotalAmount('credit');
  }

  // calculates and sets the total credit/debit amounts
  function setTotalAmount(type) {
    var total = 0;
    var cr_currency = $('.je_currency_field').val();
    var conv_rate = $('.conversion_rate_field').val()
    $('input.'+type+'_amount:visible').each(function() {
      var amount = $(this).val();
      if ($.isNumeric(amount)) {
        var amount_currency = $(this).closest('tr').find('.amount_currency_id').val()
        if (amount_currency && cr_currency && conv_rate && (amount_currency == cr_currency)) {
          var converted = amount*conv_rate;
          total += parseFloat(roundToTwoDp(converted));
        } else {
          total += parseFloat(amount);
        }
      }
    });
    $('#' + type + 's_total').val(total.toFixed(2));
  }

  function ehCreditAmount(event) {
    var credit_field = event.currentTarget;
    var debit_amount_field = $(credit_field).closest('tr').find('input.debit_amount');
    $(debit_amount_field).val('');
  }

  function ehDebitAmount(event) {
    var debit_field = event.currentTarget;
    var credit_amount_field = $(debit_field).closest('tr').find('input.credit_amount');
    $(credit_amount_field).val('');
  }

  function initCustomNestedFormAddForAmounts() {
    // Custom behaviour of nested_form link_to_add in amounts mult-edit table
    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      // New ID part of nested_form doesn't seem to be working: fix here
      // name='journal_entry[journal_entry_transaction_attributes][amounts_attributes][2][amount]'
      // --> '...[123456789][amount]'
      var new_id = new Date().getTime();
      content = $.trim(content.replace(/\d+(?=\]\[[a-z_]+\])/g, new_id));

      // Insert new content after current table row
      var $new_row = $(content);
      var $last_row = $(link).closest('tr');
      $new_row.find('div.chosen-container').remove();
      $new_row.find('.amounts-category-container select').html($last_row.find('.amounts-category-container select').html());  // Copy category options from previous row
      $new_row.insertAfter($last_row);
      checkNeedForNewNominalOption();
      jQuery('.chosen-select').chosen();
      addAmountFormEventListeners();
      addJeCurrencyEventListeners($new_row);
      jQuery('.chosen-select').trigger('chosen:activate');
      return $new_row;
    }
  }

  function initCustomNestedFormRemoveForAmounts() {
    // Prevent the only row from being removed
    $(document).undelegate('form a.remove_nested_fields', 'click', window.nestedFormEvents.removeFields);
    var removeFieldsOriginal = window.nestedFormEvents.removeFields;
    window.nestedFormEvents.removeFields = function(e) {
      var nVisible = 0;
      var rows = $(e.currentTarget).closest('tbody').children()
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].style.display === '') {
          nVisible++;
        }
      }
      ;
      if (nVisible > 1) {
        return removeFieldsOriginal(e);
      } else {
        alert('Cannot remove the only row');
      }
    }
    $(document).delegate('form a.remove_nested_fields', 'click', window.nestedFormEvents.removeFields);
  }

  function initAmountsNestedFormDragAndDrop() {
    // Drag and drop re-ordering of rows in amounts table
    // http://www.foliotek.com/devblog/make-table-rows-sortable-using-jquery-ui-sortable/
    var fixHelper = function (e, ui) {
      ui.children().each(function () {
        $(this).width($(this).width());
      });
      return ui;
    };

    $('.amounts_table_body').sortable({
      helper: fixHelper
    });
  }

  function initAmountsNestedForm() {
    initCustomNestedFormAddForAmounts();
    $('#journal_entry_form').on('nested:fieldRemoved:amounts', function (event) {
      updateJournalEntryTotalAmounts();
    })

    initAmountsNestedFormDragAndDrop();
    initCustomNestedFormRemoveForAmounts();
  }

  function addAmountFormEventListeners() {
    $('input.debit_amount').on('keypress', ehDebitAmount);
    $('input.credit_amount').on('keypress', ehCreditAmount);
    $('input.credit_amount').on('change', updateJournalEntryTotalAmounts);
    $('input.debit_amount').on('change', updateJournalEntryTotalAmounts);
    $('input.amount_currency_id').on('change', updateJournalEntryTotalAmounts);
  }

  function initAreYouSureJournalEntry() {
    var form_id = '#journal_entry_form';
    var input_classes = '.debit_amount, .credit_amount, .account_selector, .journal_entry_description, .datepicker-component';
    checkFormChangedOnPageUnload(form_id,input_classes);
  }

  function onAutoReverseButtonClicked(event) {
    event.preventDefault();
    $("#auto_reverse_modal").modal('show');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#journal_entry_form').length === 0) { return }
    initAreYouSureJournalEntry();
    initAmountsNestedForm();
    addAmountFormEventListeners();
    addJeCurrencyEventListeners($('body'));
    updateJournalEntryTotalAmounts(); // For edit / reload with error

    errors.tryOn($('.attachment_button'), 'click', openNonBatchUploadsModal);
    errors.tryOn($('#auto_reverse_button'), 'click', onAutoReverseButtonClicked);
  }));
})();