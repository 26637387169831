(function() {
  'use strict';

  window.initPlaidFeedRefresh = function(bank_account_id){
    callPlaidRefreshCredentialsEndpoint(bank_account_id, plaid_supported_country_codes(), function(response) {
      var handler_config = refreshHandlerConfig(response, bank_account_id)
      var handler = plaid_feed_setup.createPlaidHandler(response, handler_config)
      handler.open();
    });
  }

  function refreshHandlerConfig(response, bank_account_id) {
    return {
      token: response.link_token,
      onSuccess: function(public_token, metadata) {
        plaid_feed_setup.logPlaidRequest(metadata, 'Refresh - Success');
        refreshBankAccountCredentials(bank_account_id);
      },
      onExit: onRefreshExit,
      onEvent: onRefreshEvent
    }
  }

  function onRefreshExit(err, metadata) {
    plaid_feed_setup.logPlaidRequest(metadata, "Refresh - Exit: "+metadata.status);
    if (err != null) { plaid_feed_setup.logRavenMessage(err, metadata); };
  }

  function onRefreshEvent(eventName, metadata) {
    plaid_feed_setup.onEventHandler(eventName, metadata);
    if (metadata.error_code == "item-no-error") {
      refreshBankAccountCredentials(bank_account_id)
    }
  }

  function refreshBankAccountCredentials(bank_account_id) {
    $.post(Routes.third_party_plaid_refreshes_path({format: 'js'}), {
      bank_account_id: bank_account_id
    });
  }
})();

