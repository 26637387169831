/* global MutationObserver */

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['tag', 'tagText', 'textInput', 'dataList', 'dataOption', 'customOption', 'descriptionField', 'itemField', 'tagEditor', 'tagEditorField']
  static values = { isSplit: Boolean }

  connect() {
    if (this.descriptionFieldTarget.value) {
      this.textInputTarget.value = this.descriptionFieldTarget.value
      if (this.isSplitValue === false || this.textInputTarget.classList.contains('split_description_tf')) {
        this.tag()
      }
    }

    this.observer = new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.dataset && node.dataset.target === 'stock-description.dataOption' && this.dataListTarget.classList.contains('clicked')) {
            this.dataListTarget.classList.remove('clicked')
            node.dispatchEvent(new Event('click'))
          }
        })
      })
    })

    this.observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }

  tag() {
    this.tagTextTarget.textContent = this.textInputTarget.value
    this.descriptionFieldTarget.value = this.textInputTarget.value
    this.textInputTarget.classList.add('hidden')
    this.tagTarget.classList.remove('hidden')
    this.dataListTarget.classList.add('hidden')
  }

  setValuesFromSelectedOption(event) {
    if (event.currentTarget.dataset.description) { this.textInputTarget.value = event.currentTarget.dataset.description }
    if (event.currentTarget.dataset.objectId) { this.itemFieldTarget.value = event.currentTarget.dataset.objectId }
    if (event.currentTarget.dataset.salesTaxCode) { this.itemFieldTarget.dataset.salesTaxCode = event.currentTarget.dataset.salesTaxCode }
    if (event.currentTarget.dataset.purchaseTaxCode) { this.itemFieldTarget.dataset.purchaseTaxCode = event.currentTarget.dataset.purchaseTaxCode }
    this.textInputTarget.dispatchEvent(new Event('change'))
    this.itemFieldTarget.dispatchEvent(new Event('change'))
  }

  untagUnlessIsSplit() {
    if (this.isSplitValue) return
    this.untag()
  }

  untag() {
    this.element.getElementsByClassName('popover-container')[0].dispatchEvent(new CustomEvent('destroy-popover'))
    this.tagTarget.classList.add('hidden')
    this.tagTextTarget.textContent = ''
    this.textInputTarget.value = ''
    this.itemFieldTarget.value = ''
    this.descriptionFieldTarget.value = ''
    this.textInputTarget.classList.remove('hidden')
    this.itemFieldTarget.dataset.salesTaxCode = ''
    this.itemFieldTarget.dataset.purchaseTaxCode = ''
    this.filterOptions()
  }

  showTagEditor() {
    this.tagTarget.classList.add('hidden')
    this.tagEditorFieldTarget.value = this.descriptionFieldTarget.value
    this.tagEditorTarget.focus()
    this.tagEditorTarget.classList.remove('hidden')
  }

  editTag() {
    this.textInputTarget.value = this.tagEditorFieldTarget.value
    this.tagEditorFieldTarget.value = ''
    this.tagEditorTarget.classList.add('hidden')
    this.tag()
  }

  filterOptions() {
    const filter = this.textInputTarget.value

    this.dataOptionTargets.forEach((option, index) => {
      const txtValue = option.dataset.description
      if (txtValue.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
        option.classList.remove('hidden')
      } else {
        option.classList.add('hidden')
      }
    })

    if (filter.length > 0) {
      this.customOptionTarget.textContent = filter
      this.customOptionTarget.dataset.description = filter
      this.customOptionTarget.classList.remove('hidden')
    } else {
      this.customOptionTarget.classList.add('hidden')
    }
  }

  showList() {
    if (!this.textInputTarget.readOnly) {
      this.dataListTarget.classList.remove('hidden')
    }
  }

  hideList(event) {
    if (this.data.has('closing_disallowed')) {
      event.preventDefault()
    } else {
      if (!this.textInputTarget.readOnly && this.textInputTarget.value.length > 0) {
        const availableOptions = this.dataOptionTargets.filter(target => !target.classList.contains('hidden'))
        if (availableOptions.length === 1) {
          availableOptions[0].dispatchEvent(new Event('click'))
        }
        this.tag()
      }
      this.filterOptions()
      this.dataListTarget.classList.add('hidden')
      this.data.delete('closing_disallowed')
    }
  }

  preventClosingList() {
    this.data.set('closing_disallowed', true)
  }

  allowClosingList() {
    this.data.delete('closing_disallowed')
  }

  markClicked() {
    const elements = document.getElementsByClassName('clicked')

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('clicked')
    }

    this.dataListTarget.classList.add('clicked')
  }
}
