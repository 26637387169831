// Stimulus actions cannot be setup for Multipicker directly.
// A custom event is needed to be setup to allow the function to be called
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).on('changed.bs.select', function (event, index) {
      const selectorValue = $(event.target).val()
      if (selectorValue && selectorValue[index] === '-1') {
        this.dispatchEvent(new CustomEvent('multi-change-all'))
      } else {
        this.dispatchEvent(new CustomEvent('multi-change'))
      }
    })
  }

  disconnect() {
    $(this.element).off()
  }
}
