/* Code that relates to adding supplier accounts anywhere */
window.checkNeedForNewSupplierOption = function(section) {
  if (section) {
    var elements = section.find('select.js-add-new-supplier');
  } else {
    var elements = $('select.js-add-new-supplier');
  }

  if (elements.length === 0) { return }
  elements.on('chosen:ready', function(event, chosen) {
    var select = $(event.target);
    var chosen_container = select.siblings('div.chosen-container');
    addNewSupplierOption(chosen_container);
    setLinkToAddNewSupplier(select);
  });
}

window.addNewSupplierOption = function(chosen_container) {
  if (chosen_container.find('button.js-new-supplier-button').length === 0) {
    chosen_container.find('ul.chosen-results').after("<button class='btn btn-primary btn-xs pull-right js-new-supplier-button' tabindex='-1'>+ Add new</button>")
    registerNewSupplierClickListener(chosen_container);
  }
}

window.setLinkToAddNewSupplier = function(select) {
  select.siblings('a.add-new-supplier-link').remove();
  select.after("<a href='/suppliers/new' data-remote='true' class='add-new-supplier-link' tabindex='-1'><i class='add-new-supplier-link'></i></a>")
}

function registerNewSupplierClickListener(chosen_container) {
  chosen_container.find("button.js-new-supplier-button").click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    newSupplierButtonClicked($(this));
  });
}

function newSupplierButtonClicked(button) {
  button.addClass('new-supplier-button-clicked');
  showNewSupplierFormInModal(button);
}

function showNewSupplierFormInModal(button) {
  button.closest('div.chosen-container').siblings('a.add-new-supplier-link').find('i').click();
}
