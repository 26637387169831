(function() {
  'use strict';

  window.truncateAmountToPennyPrecisionInElement = function (element) {
    if ($(element).length) {
      var val = element.val();
      if (val.length === 0) {
        return;
      }
      var amount = roundToTwoDp(val);
      element.val(amount.toFixed(2));
      return amount;
    }
  };

  window.getValAsFloat = function($field) {
    var value_str = (assertJQuery($field).val() || '');
    return parseFloat(value_str.replaceAll(',', '')) || 0;
  };

  /*
  @param {Object} arg
  @param {Float} arg.tax_rate
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setTaxAndTotalFromNetAndTaxRate = function(arg) {
    setTimeout(function() { // There is a race condition with Field State so this has to be a bit patient :(
      var net_amount_val = arg.net_amount_field.val();
      if ( net_amount_val.length === 0 ) { return; }
      var net_amount = parseFloat(net_amount_val);
      var tax_amount = roundToTwoDp(net_amount * (arg.tax_rate / 100));
      var total_amount = roundToTwoDp(accurateAddition(net_amount, tax_amount));

      arg.net_amount_field.val(net_amount.toFixed(2));
      if (arg.tax_amount_field) {
        arg.tax_amount_field.val(tax_amount.toFixed(2));
      }
      if (arg.total_amount_field) {
        arg.total_amount_field.val(total_amount.toFixed(2));
      }
      updateCustomerDocumentAmounts();
    }, 1)
  };

  /*
  @param {Object} arg
  @param {Float} arg.tax_rate
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setNetAndTaxFromTotalAndTaxRate = function(arg) {
    setTimeout(function() { // There is a race condition with Field State so this has to be a bit patient :(
      var total_amount_val = arg.total_amount_field.val();
      var total_amount = getValAsFloat(arg.total_amount_field);
      var tax_amount = roundToTwoDp(total_amount * arg.tax_rate / (100 + arg.tax_rate));
      var net_amount = accurateSubtraction(total_amount, tax_amount);
      arg.net_amount_field.val(net_amount.toFixed(2));
      arg.tax_amount_field.val(tax_amount.toFixed(2));
      updateCustomerDocumentAmounts();
    }, 1)
  };

  /*
  @param {Object} arg
  @param {Float} arg.net_amount
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setTotalToNetPlusTax = function(arg) {
    var tax_amount = getValAsFloat(arg.tax_amount_field);
    var total_amount = accurateAddition(arg.net_amount, tax_amount);
    setTotalField(arg, total_amount);
    setTaxField(arg, tax_amount);
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setTaxToTotalMinusNet = function(arg) {
    var net_amount = getValAsFloat(arg.net_amount_field);
    var total_amount = getValAsFloat(arg.total_amount_field);
    var tax_amount = accurateSubtraction(total_amount, net_amount);
    setNetField(arg, net_amount);
    setTaxField(arg, tax_amount);
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setNetToTotalMinusTax = function(arg) {
    var tax_amount = getValAsFloat(arg.tax_amount_field);
    var total_amount = getValAsFloat(arg.total_amount_field);
    var net_amount = accurateSubtraction(total_amount, tax_amount);
    setNetField(arg, net_amount);
    setTaxField(arg, tax_amount);
  };

  /*
  @param {Object} arg
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.stock_item_field
  @param {jQuery} arg.type_field
  @param {jQuery} arg.price_field

  */
  window.setPriceFromStockItemAndType = function(arg){
    if (!arg.stock_item_field.val() || !window.stockItemInfoExists()) { return; }
    var priceType = arg.type_field.val() == 'PurchaseInvoice' ? 'purchase_price' : 'sales_price'
    var prices = window.parseStockItemInfo();
    var selectedItemPrice = prices[arg.stock_item_field.val()][priceType];
    arg.price_field.val(selectedItemPrice)
  };

  window.disableTaxAmountIfTaxCodeIsZero = function($row, amount_selector, rate_selector) {
    var taxAmount = $row.find(amount_selector)
    var taxRate = getTaxRateAsFloat($row.find(rate_selector))
    if (taxRate == 0) {
      taxAmount.trigger('change');
    }
  }

  // TODO: FIX DELIMITER BREAKING FORM SUBMISSION BY NORMALISING SERVER SIDE AFTER SUBMISSION WITH sanitize_delimiter_values

  // function setNetField(arg, amount) {
  //   arg.net_amount_field.val(formatAsCurrency(amount));
  // }

  // function setTaxField(arg, amount) {
  //   arg.tax_amount_field.val(formatAsCurrency(amount));
  // }

  // function setTotalField(arg, amount) {
  //   arg.total_amount_field.val(formatAsCurrency(amount));
  // }

  function setNetField(arg, amount) {
    arg.net_amount_field.val(amount.toFixed(2));
  }

  function setTaxField(arg, amount) {
    arg.tax_amount_field.val(amount.toFixed(2));
  }

  function setTotalField(arg, amount) {
    arg.total_amount_field.val(amount.toFixed(2));
  }


  /*
  @param {Object} arg
  @param {jQuery} arg.net_amount_field
  @param {jQuery} arg.tax_amount_field
  @param {jQuery} arg.total_amount_field
  */
  window.setNetAndTaxFromTaxRateAndTotal = function(arg) {
    var total_amount = getValAsFloat(arg.total_amount_field);
    var tax_amount = roundToTwoDp(total_amount - (total_amount / (1 + (arg.tax_rate / 100))));
    var net_amount = accurateSubtraction(total_amount, tax_amount);
    setNetField(arg, net_amount);
    setTaxField(arg, tax_amount);
  };
})();
