/**
* @fileoverview
*/

(function() {
'use strict';

  /**
   * Attach click event listeners to one or more elements
   * so that when clicked, the selected color is saved on the server.
   *
   * @returns {Function}
   * @private
   */
  function _initSkinSelect() {
    _addEventListener('pandle-skin', $('.ss-skin'));
  }

  function _addEventListener(key, $elements) {
    if ($elements.length === 0) { return }
    $elements.on('click', _onElementClick(key));
  }

  function _onElementClick(key){
    return function($event){
      UserPreferences.set(key, $event.target.dataset['skin']);
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    _initSkinSelect();
  }));
})();
