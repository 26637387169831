document.addEventListener('turbo:load', errors.tryFn(function() {
  if ($('#industry_modal').length > 0) {
    var timer = null;
    $('#company_industry').keydown(function(){
      clearTimeout(timer);
      timer = setTimeout(setIndustryButton, 10)
    });
    $('#industry_modal').modal({backdrop: 'static', keyboard: false});
    $('#industry_modal').modal('show');
    $('.industry-select-input').on('change', checkAndShowOtherField);
    $('.industry-select-input').on('change', setIndustryButton);
    setIndustryButton();
  }
}));

window.checkAndShowOtherField = function() {
  var industry = $('.industry-select-input .chosen-select').val()
  if(industry == "Other"){
    $('#industry_other_field').slideDown();
    $('#company_industry').val("").attr('disabled', false);
  } else {
    $('#industry_other_field').slideUp();
    $('#company_industry').attr('disabled', true);
  }
}

function setIndustryButton(){
  var isValidSelection = $('.industry-select-input .chosen-select').val() != "" && $('.industry-select-input .chosen-select').val() != "Other";
  var isOther = $('.industry-select-input .chosen-select').val() == "Other" && $('#company_industry').val() != "";
  if(isValidSelection || isOther){
    $('#industry_update_button').prop('disabled', false);
  } else {
    $('#industry_update_button').prop('disabled', true);
  }
}

function setIndustrySelect(){
  var industry = $('#company_industry').val();
  $('#industry_selector_chosen > a > span').html(industry);
}
