import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.enableOrDisableFormSubmitField()
  }

  updateCountAndSubmitButton() {
    window.updateSelectedCount()
    this.enableOrDisableFormSubmitField()
  }

  selectOrDeselectAll(event) {
    if (event.target.checked) {
      if (this.uncheckedCount() > 0) {
        $('.checkboxes:unchecked').click() // Select visible rows
      }
    } else {
      if (this.checkedCount() > 0) {
        $('.checkboxes:checked').click() // Unselect visible rows
      }
    }
    window.updateSelectedCount()
  }

  // Private

  checkedCount() {
    return $('.checkboxes:checked').not(':disabled').length
  }

  uncheckedCount() {
    return $('.checkboxes:unchecked').not(':disabled').length
  }

  enableOrDisableFormSubmitField() {
    if (this.checkedCount() > 0) {
      window.enableFields($('#form-submit'))
    } else {
      window.disableFields($('#form-submit'))
    }
  }
}
