(function() {
  "use strict";

  function addHmrcHeadersToRequest(request){
    var hmrc_header_data = $('#hmrc_header_values').data('headers');
    $.extend(request, hmrc_header_data.values())
  }

  function getLiabilitiesAndPaymentInfoAsync() {
    $('#government_gateway_account_table').data('bootgrid-extra-request-data-function', addHmrcHeadersToRequest);
    $('#government_gateway_account_table').bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up',
        iconExport: 'csv-export-icon',
        iconLoading: 'zmdi-rotate-right zmdi-hc-spin'
      },
      ajaxSettings: { method: 'GET' },
      multiSort: false,
      csvExport: false,
      labels: { noResults: 'No results found' }
    });
  }

  function getObligationsDataAsync() {
    var url = $('#mtd_obligations').data('url');
    var hmrc_header = $('#hmrc_header_values').data('headers').values();

    $.ajax({
      method: "GET",
      url: url,
      data: hmrc_header
    }).done(function(result) {
      $('#mtd_obligations').text(result['data'])
    });
  }

  document.addEventListener("turbo:load", errors.tryFn(function() {
    if ($("#government_gateway_account_statement").length > 0) {
      // Start calculating the values
      $('#hmrc_header_values').data('headers', new HmrcHeaderDataFinder());

      getObligationsDataAsync();
      getLiabilitiesAndPaymentInfoAsync();
    }
  }));
})();
