(function() {
  'use strict';

  function allLevelSelectors() {
    return $('input[id$="_permission_type"]').next('select');
  }

  function onLevelChange() {
    allLevelSelectors().off('change', onLevelChange);
    updateFields();
    allLevelSelectors().change(onLevelChange);
  }

  function updateFields() {
    if (isTypeAtLevel('overview','create_edit_delete')) {
      setAllTypesToDefault('create_edit_delete');
    } else if (isTypeAtLevel('overview','read')) {
      setAllTypesToDefault('read');
    } else {
      setAllTypesToDefault('no_access');
      updatePurchaseLedgerAndSalesLedgerClerks();
    }

    if (window.permissions_form_updated) {
      $('#level_change_alert').toggle();
      window.permissions_form_updated = false;
    }
  }

  function updatePurchaseLedgerAndSalesLedgerClerks() {
    if (isTypeAtLevel('banking','read') ||
      isTypeAtLevel('banking','create_edit_delete') ||
      isTypeAtLevel('vat_returns','create_edit_delete') ||
      isTypeAtLevel('vat_returns','read')
    ) {
      setTypeToDefault('suppliers', 'read');
      setTypeToDefault('customers', 'read');
    } else {
      setTypeToDefault('suppliers', 'no_access');
      setTypeToDefault('customers', 'no_access');
    }
  }

  function isTypeAtLevel(type, level) {
    var $select = getSelectForType(type);
    if ($select.length == 0) { return }
    return level == $select.val();
  }

  function setAllTypesToDefault(level) {
    setTypeToDefault('payroll', level);
    setTypeToDefault('vat_returns', level);
    setTypeToDefault('banking', level);
    setTypeToDefault('suppliers', level);
    setTypeToDefault('customers', level);
  }

  function setTypeToDefault(type, level) {
    var $select = getSelectForType(type);
    if ($select.length === 0) { return }

    if (level === 'create_edit_delete') {
      enableLevel($select, 'create_edit_delete');
      disableLevel($select, 'read');
      disableLevel($select, 'no_access');

      if ($select.val() !== 'create_edit_delete') {
        setTypeToLevel($select, 'create_edit_delete');
      }
    } else if (level === 'read') {
      enableLevel($select, 'create_edit_delete');
      enableLevel($select, 'read');
      disableLevel($select, 'no_access');

      if ($select.val() !== 'create_edit_delete' && $select.val() !== 'read') {
        setTypeToLevel($select, 'read');
      }
    } else if (level === 'no_access') {
      enableLevel($select, 'create_edit_delete');
      enableLevel($select, 'read');
      enableLevel($select, 'no_access');
    }

    $select.trigger('chosen:updated').change();
  }

  function getSelectForType(type) {
    return $('input[value=' + type + ']').next('select');
  }

  function enableLevel($select, level) {
    getOptionForValue($select, level).prop('disabled', false);
  }

  function disableLevel($select, level) {
    getOptionForValue($select, level).prop('disabled', true);
  }

  function setTypeToLevel($select, level) {
    getOptionForValue($select, level).prop('selected', true);
    window.permissions_form_updated = true;
  }

  function getOptionForValue($select, value) {
    return $select.children('option[value=' + value + ']')
  }

  window.initUserPermissionForm = function() {
    formatFormsIn($('#user_permissions_tabbed_form'));
    updateFields();
    allLevelSelectors().change(onLevelChange);
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#user_permissions_tabbed_table').length === 0) { return }
    window.permissions_form_updated = false;
  }));
})();
