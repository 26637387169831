(function() {
  'use strict';

  function enableAccountantsTabPersistence () {
    errors.tryClick($('ul.tab-nav a'), onTabClickAccountant);
  }

  function onTabClickAccountant(e) {
    UserPreferences.set(
      'accountant_settings_active_tab',
      getTabbedFormTabId(e.target)
    );
  }

  function showSpinner() {
    openSpinnerConnection();
  }

  window.initPartnerAccountantEditForm = function(){
    focusFirstFieldOnTabChange();
    focusFirstField($('.tab-pane.active'));
    enableAccountantsTabPersistence();
    initBootgridTable($('#partner_accountant_users_table'), -1);
    initBootgridTable($('#partner_accountants_billing_plans_table'));
    initBootgridTable($('#partner_accountant_companies_index_table'), undefined, true);
    initBootgridTable($('#partner_accountant_pending_requests_index_table'), undefined, true);
    initListenerForTelephoneField($('#partner_accountant_telephone_number'));
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ( $('.partner_accountants.edit').length === 0 && $('.partner_accountants.update').length === 0 ) { return }
    if ( $('#spinner').length > 0 ){
      showSpinner();
    }else{
      initPartnerAccountantEditForm()
    }
  }));
})();
