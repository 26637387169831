(function() {
  'use strict';

  window.csNameChangeModal = function(cs, name) {
    if (name.length) {
      var name = name.substring(0, 5);
      $('#' + cs + '_' + cs + '_ref').val(name);
    }
  };

  window.setInvoiceRefVisibility = function() {
    var disabled = !$('.modal').find('#has_invoice_ref_prefix').is(':checked');

    if (disabled) {
      $('.modal').find('#invoice_ref_prefix_fields input').val('');
    }

    if ($('.modal').find('#invoice_ref_field').is(':visible') === disabled) {
      $('.modal').find('#invoice_ref_field').slideToggle();
    }
  };

  window.setEuVatCheckboxVisibility = function(visible) {
    if (visible == false) {
      $('.modal').find('#eu_vat_checkbox_input input').attr('checked', false);
      if ($('.modal').find('#eu_vat_checkbox_field').is(':visible')) {
        $('.modal').find('#eu_vat_checkbox_field').slideToggle();
      }
    } else {
      if ($('.modal').find('#eu_vat_checkbox_field').is(':hidden')) {
        $('.modal').find('#eu_vat_checkbox_field').slideToggle();
      }
    }
  }

  window.customerSupplierCountryChange = function(event) {
    initPostcodeInputMaskInModal();
    alterCountyInputType(event, $(event.target).closest('.modal').find('div.county_input_field'));
    checkForEuVatCompatability();
  };

  window.warnUnitedKingdomEuSelection = function() {
    var companyCountry = $('#company_country').val()
    var euChecked = $('.modal').find('[id*="_uses_eu_vat"]').is(':checked')

    if (euChecked && companyCountry == "United Kingdom") {
      sweetAlert({
        title: 'Warning',
        html: true,
        type: 'warning',
        text: "Due to the departure from the EU the ES \
        and EG VAT codes can only be used on transactions \
        dated on or before 31st December 2020"
      })
    }
  }

  function checkForEuVatCompatability() {
    function countryIsInTheEU(_country_code, _eu_codes) {
      return $.inArray(_country_code, _eu_codes) > -1;
    }
    if ($('[id*="_uses_eu_vat"]').length === 0) { return } // Don't do this if not EU VAT
    var country_code = $('.js-input_mask_country_for_postcode').chosen().val();
    var customer_does_not_use_eu_vat = !($('#customer_uses_eu_vat').is(':checked'));
    var company_does_not_use_reverse_charge = (window.companySettings.uses_reverse_charge == false);

    getJsonOrShowMaintenanceModal('/ec_sales_lists/eu_country_codes.json', function(eu_codes) {
      if (countryIsInTheEU(country_code, eu_codes) && customer_does_not_use_eu_vat && company_does_not_use_reverse_charge) {
        notify('You have selected an EU country but not selected EU VAT', 'warning', 'Warning! ');
      }
    });
  }

  window.initRecurringTransactionsAlertModal = function(cs) {
    var url = Routes.recurring_transactions_path({ format: 'html' }),
    message = "All recurring transactions for this "
              + cs
              + " will be paused. You can review the status of your recurring transactions on the "
              + "Recurring Transactions".link(url)
              + " page.",
    $checkbox = $('.modal').find('#cs_status_checkbox'),
    has_rt = $checkbox.data('has-recurring-transactions'),
    checked = $checkbox.is(':checked');

    if (checked && has_rt) {
      recurringTransactionsModal({
        content: message,
        onCancel: function() { _untickInactiveCheckbox($checkbox) },
        buttonsHtml: {
          confirm: "Pause All Recurring Transactions",
          cancel: "Keep Status Active"
        }
      });
    };
  };

  function _untickInactiveCheckbox(checkbox) {
    checkbox.removeAttr('checked');
  };

  window.initTabChangeHandler = function(formSelector) {
    focusFirstFieldOnTabChange($(formSelector));
    var mut = new MutationObserver(function () {
      onTabChange(formSelector);
    });
    mut.observe(document.querySelector(formSelector + ' .tab-pane'), { 'attributes': true });
    onTabChange(formSelector);
  }

  function onTabChange(formSelector) {
    var $form = $(formSelector);
    var $previousButton = $form.find('.previous');
    var $nextButton = $form.find('.next');
    var $submitButton = $form.find('#submit');

    if ($('#basic').hasClass('active')) {
      $previousButton.hide();
      $nextButton.show();
      $submitButton.hide();
    } else {
      $previousButton.show();
      $nextButton.hide();
      $submitButton.show();
    }
  }

  window.autofillAddressWithCompaniesHouseResponse = function($line1,$line2,$postcode,$locality, response){
    resetContactFields()  // Farmplan
    var response_data = response['address'];
    if (response_data === 'undefined') { return; };
    if (response_data['address_line_1'] != 'undefined'){ $line1.val(response_data['address_line_1']); };
    if (response_data['address_line_2'] != 'undefined'){ $line2.val(response_data['address_line_2']); };
    if (response_data['address_line_3'] != 'undefined'){ $line2.val(response_data['address_line_3']); }; // Farmplan
    if (response_data['postal_code'] != 'undefined'){ $postcode.val(response_data['postal_code']); };
    if (response_data['locality'] != 'undefined'){ $locality.val(response_data['locality']); };
  }

  window.autoFillContactNameWithFirstOfficer = function ($contactNameElement, activeOfficers) {
    if (activeOfficers.length > 0) {
      var contactName = activeOfficers[0];
      $contactNameElement.val(contactName);
    }
  }

  window.resetContactFields = function() {
    var fields = contactFieldsToEmpty();
    emptyContactDetailsFields(fields);
  }

  function contactFieldsToEmpty() {
    var contactFieldContext = $('#new_customer_form').length ? 'customer' : 'supplier';
    return {
      'contact_name': $('#' + contactFieldContext + '_contact_name'),
      'address_line_1': $('#' + contactFieldContext + '_address_attributes_address_line_1'),
      'address_line_2': $('#' + contactFieldContext + '_address_attributes_address_line_2'),
      'address_line_3': $('#' + contactFieldContext + '_address_attributes_address_line_3'), // Farmplan
      'postcode': $('#' + contactFieldContext + '_address_attributes_postcode'),
      'locality': $('#' + contactFieldContext + '_address_attributes_town_city')
    }
  }

  function emptyContactDetailsFields(fields) {
    Object.values(fields).forEach(function(value) {
      value.val('');
    });
  }
})();

