import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    condition: Boolean,
    text: String,
    type: String,
    title: String
  }

  displayNotice() {
    if (this.conditionValue) { notify(this.textValue, this.typeValue, this.titleValue) }
  }
}
