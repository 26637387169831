import consumer from './consumer'

window.openTrialBalanceBackgroundConnection = function () {
  consumer.subscriptions.create({ channel: 'TrialBalanceBackgroundChannel' }, {
    connected: function () {
      $('#date_end').closest('form').submit()
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      updateTrialBalanceTable()
    }
  })

  function updateTrialBalanceTable() {
    $.ajax({
      url: Routes.report_trial_balance_path({ format: 'js' }),
      method: 'put'
    })
  }
}
