(function() {
  'use strict';

  window.checkForEuVatCustomer = function() {
    var customer_supplier_ids = getCustomerSupplierIds();
    if (!customer_supplier_ids) { return }
    for (var i = 0; i < customer_supplier_ids.length; i++) {
      getJsonOrShowMaintenanceModal('/customers/' + customer_supplier_ids[i] + '/get_eu_vat_status.json', function(eu_vat_status) {
        if (eu_vat_status['uses_eu_vat'] && (window.companySettings.uses_reverse_charge == false)) {
          // This is an EU VAT customer
          addEuVatMissingInfoWarnings(eu_vat_status);
        }
      });
    }
  };

  window.custDocAddNoVatSchemeWarning = function(datepicker, line_item) {
    addNoVatSchemeWarning({
      tax_amount_field: line_item.tax,
      tax_code_field: line_item.tax_code,
      datepicker: datepicker
    });
  };

  window.formatNestedFormTable = function() {
    // Custom behaviour of nested_form link_to_add in line items multi-edit table
    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      var $tr = $(link).closest('tr');
      return $(content).insertBefore($tr);
    };
  };

  window.setNominalAccountAndUpdateCurrencyToCustomersCurrency = function() {
    var $form = $('form');
    if(!isCustomerGroupInvoice($form)) {
      var customerSupplierId = $('#customer_select_single').val();
    } else {
      var customerSupplierId = $('#customer_select_multiple').val()[0];
    }

    if (customerSupplierId.length > 0) {
      getJsonOrShowMaintenanceModal('/customers/' + customerSupplierId + '/get_customer.json', function(customer) {
        UpdateCurrencyToCustomersCurrency(customer);
        if (!isCustomerGroupInvoice($form)) {
          setNominalAccount(customer); // Changing the nominal account will then trigger setting the tax code from the customer or nominal
        }
      });
    }
  };

  window.custDocCurrencyChange = function() {
    var currency_id = $('#cd_currency_tf').val();
    updateCurrencySymbols(currency_id);
    var datepicker = getClosestDateTimePicker($('#date_tf'));
    if (typeof(datepicker) === 'undefined') { return; }
    updateConversionRate(currency_id, datepicker.date());
  };

  // date should be a moment date object
  window.updateConversionRate = function(currency_id, date) {
    $('#conversion_rate_tf').val('');
    toggleConversionRateField(currency_id, date);
  };

  window.formatNewLineItemRow = function(field) {
    formatFormsIn($(field));
    addLineItemEventListeners(field);
    registerCustomerDocumentChangeListener();
    allowEnterToTab();
    typeAheadInput($('.typeahead_description'), $('.typeahead_descriptions'));
    typeAheadInput($('.typeahead_unit'), $('.typeahead_units'));
    $(field).find('.description').focus();
    setTaxCodeOptionsForNewLineItemRow();
    setDefaultTaxCodeForNewLineItemRow($(field));
  };

  window.addLineItemEventListeners = function(line_items) {
    line_items.each(addEachLineItemEventListeners);
  };

  var non_batch_form_change_listener;

  window.registerCustomerDocumentChangeListener = function() {
    // register page unload listener to check if form content has been changed
    // when the user navigates away from the page
    if ( !non_batch_form_change_listener ) {
      non_batch_form_change_listener = checkFormChangedOnPageUnload('.nb_form');
    }
  };

  // The magic tabbing logic is really janky. In cases where it doesn't
  // work, we skip it and fall back to the browser's natural tab.
  // This is quick fix for deploy- we ought to rethink this behaviour
  function isJankyMagicTabGoingToWork(activeElement, inputsToTab) {
    if ($(activeElement).is('a')) { return true }
    const index = $.inArray(activeElement, inputsToTab)
    if (index == -1 || inputsToTab.length == index + 1) { return false }
    const next = inputsToTab[index + 1]
    if (String(next.offsetParent.getAttribute('class')).match(/chosen-search/)) {
      return false
    }
    return 'Maybe'
  }

  window.allowEnterToTab = function() {
    // TODO: Remove in favour of _enter_to_tab.js
    var inputsToTab = document.body.querySelectorAll(
      'input:enabled:not([readonly]):not([type=hidden]):not([tabindex="-1"]):not(.hidden),' +
      'textarea:enabled:not([readonly]):not([type=hidden]):not([tabindex="-1"]):not(.hidden),' +
      'a.mousetrap_add_row'
    ); //FARMPLAN: updated so that non-batch invoices tabbing issues are fixed
    var $nbForm = $('.nb_form');
    $nbForm.unbind('keydown');
    $nbForm.keydown(function(event) {
      var activeElement = document.activeElement;

      if ((event.keyCode === 13 || event.keyCode === 9) && !event.shiftKey && !$(activeElement).is('textarea') && isJankyMagicTabGoingToWork(activeElement, inputsToTab)) {
        event.preventDefault();
        setTimeout(function() {
          enterKeyPressNonBatch(activeElement, inputsToTab);
        }, 250);
      }
    });

    $('input.btn').keyup(function(event) {
      if ((event.keyCode === 13 || event.keyCode === 9) && !$(this).is('[disabled=disabled]')) {
        $(this).click();
      }
    });
  };

  window.addLineItemRowIfNeeded = function() {
    if ($('.line_item_row').filter(':visible').length === 0) {
      $('.btn.add_nested_fields').click();
    }
  };

  window.updateCustomerDocumentNetAmount = function() {
    var net = sumLineItemNetAmounts();
    var $cdNetAmountTf = $('#cd_net_amount_tf');
    $cdNetAmountTf.val(formatAsCurrency(net));
    $cdNetAmountTf.change();
  };

  window.updateCustomerDocumentTaxAmount = function() {
    var $cdTaxAmountTf = $('#cd_tax_amount_tf');
    if ($cdTaxAmountTf.length > 0) {
      var tax = sumLineItemTaxAmounts();
      $cdTaxAmountTf.val(formatAsCurrency(tax));
      $cdTaxAmountTf.change();
    }
  };

  window.updateCustDocTotal = function() {
    var $cdTotalAmountTf = $('#cd_total_amount_tf');
    if ($cdTotalAmountTf.length === 0) { return }
    var $cdNetAmountTf = $('#cd_net_amount_tf')
    var $cdTaxAmountTf = $('#cd_tax_amount_tf')
    var tax_amount = getValAsFloat($cdTaxAmountTf);
    var net_amount = getValAsFloat($cdNetAmountTf);
    var total_amount = accurateAddition(net_amount, tax_amount);
    $cdTaxAmountTf.val(formatAsCurrency(tax_amount));
    $cdTotalAmountTf.val(formatAsCurrency(total_amount));
  };

  window.updateCustomerDocumentAmounts = function() {
    updateCustomerDocumentNetAmount();
    updateCustomerDocumentTaxAmount();
  };

  window.createLineItem = function($row) {
    var line_item = {
      row: $row,
      description: $row.find('.description'),
      category: $row.find('.split_account'),
      discount: $row.find('.discount_percentage_tf'),
      quantity: $row.find('.split_stock_item_quantity_tf'),
      out_of_stock_popover: $row.find('#out-of-stock-popover'),
      quantity_popover: $row.find('#stock-quantity-popover'),
      stock_item: $row.find('.split_stock_item'),
      price: $row.find('.price_tf'),
      net: $row.find('.line_item_net_amount_tf'),
      tax: $row.find('.line_item_tax_amount_tf'),
      tax_code: $row.find('.split_tax_code_field'),
      total: $row.find('.line_item_total_amount_tf'),
      parent_li: $row.find('.invoice_line_items_select')
    };

    line_item.can_calculate_net_amount = canCalculateNetAmount(line_item);
    return line_item;
  }

  window.lineItemSetTaxAndTotalFromNetAndTaxRate = function(line_item) {
    setTaxAndTotalFromNetAndTaxRate({
      tax_rate: getTaxRateFromTaxCodeSelect(line_item.tax_code),
      net_amount_field: line_item.net,
      tax_amount_field: line_item.tax,
      total_amount_field: line_item.total
    })
  }

  function canCalculateNetAmount(line_item) {
    return line_item.price.length
  }

  function addEachLineItemEventListeners(i, line_item) {
    var line_item = createLineItem($(line_item));

    errors.tryOn(line_item.description, 'keydown', suppressTypeaheadTab);
    errors.tryChange(line_item.category, lineItemNominalAccountChange(line_item));
    errors.tryChange(line_item.net, lineItemNetAmountChange(line_item));
    errors.tryChange(line_item.tax_code, lineItemTaxCodeChange(line_item));
    errors.tryChange(line_item.tax, lineItemTaxAmountChange());
    errors.tryChange(line_item.total, lineItemOnTotalAmountChange());
    errors.tryChange(line_item.parent_li, lineItemParentLiChange(line_item));
    errors.tryOn($('#customer_document_date_div'), 'dp.change', customerDocumentDateChange(line_item));
    errors.tryChange(line_item.stock_item, lineItemStockItemChange(line_item));
    errors.tryChange(line_item.quantity, lineItemQuantityChange(line_item));
    // addNetAmountCalculationListeners(line_item); FARMPLAN: Removed method so that some line item calculations that we don't want don't execute
  }

  window.updateTaxCodeOptions = function($elem, $tax_code_fields, invoice_id, customer_supplier_id, customer_supplier_type){
    new TaxCodeFiltering($elem, $tax_code_fields, invoice_id, customer_supplier_id, customer_supplier_type).getTaxCodesForInvoice();
  }

  window.updateTaxCodeOptionsForNonBatch = function() {
    var selected_ref = $('#invoice_ref_selector').find('option:selected');
    var invoice_id = selected_ref.data('invoice-id');
    var $tax_code_fields = $('.nb_split__tax_code select');
    var customer_id = $('#customer_select_single').val();
    var $datePickerComponent = $('#customer_document_date_div')
    updateTaxCodeOptions($datePickerComponent, $tax_code_fields, invoice_id, customer_id);
  }

  function setTaxCodeOptionsForNewLineItemRow(){
    if (!$('.nb_form').length) { return };
    if ($('#new_sales_credit_note').length) { return };
    updateTaxCodeOptionsForNonBatch();
  }

  window.updateTaxCodesDropdownBT = function($category_field, date, $tax_code_fields, post_callback = (() => {})) {
    const $selected_category = $category_field.find(':selected');
    const url = Routes.get_tax_codes_for_account_companies_path({
      format: 'json',
      global_id: $selected_category.data('global-id'),
      date: date
    });

    getJsonOrShowMaintenanceModal(url, function(data) {
      if (data == null) { return; }
      $tax_code_fields.selectPopulator(data.tax_codes);
      setTaxCodeToDefaultValue($selected_category, $tax_code_fields)
      setToLastTaxCodeValueIfNoneSelected($tax_code_fields)
      if ($selected_category.data('is-bank-transfer')) {
        setTimeout(function() {
          $tax_code_fields.prop('disabled', true).trigger('chosen:updated').prop('disabled', false)
        }, 0);
      }
      post_callback();
    });
  }

  function setTaxCodeToDefaultValue($selected_category, $tax_code_fields) {
    const default_tax_code = $selected_category.data('default-tax-code-id');
    if ( typeof(default_tax_code) !== 'undefined' ) {
      $tax_code_fields
        .val(default_tax_code)
        .trigger('change')
        .trigger('chosen:updated');
    }
  }

  function setToLastTaxCodeValueIfNoneSelected($tax_code_fields) {
    var $dropdowns_with_no_value = $tax_code_fields.filter(function(i, obj) { return $(obj).val() === "" || $(obj).val() === null });
    $dropdowns_with_no_value.each(function(index, element) {
      $(element).find('option').not(':empty').first()
        .prop('selected', true)
        .trigger('chosen:updated')
        .trigger('change');
    });
  }

  function setDefaultTaxCodeForNewLineItemRow($line_item){
    var line_item = createLineItem($line_item);
    new InvoiceTaxCodeDefaulting(
      $('#customer_select_single:enabled'), // If it's a group invoice, we can't use the customer default
      line_item.category,
      line_item.tax_code,
      line_item.stock_item,
      $('#invoice_type').val()
    ).call();
  }

  function addEuVatWarning() {
    var msg = I18n.t('customer_documents.eu_vat_warning');
    addHelpMessageUnlessExists(null, 'warning', msg, 'not-an-eu-vat-customer');
  }

  function addReverseChargeWarning() {
    var msg = I18n.t('customer_documents.reverse_charge_warning');
    addHelpMessageUnlessExists(null, 'warning', msg, 'not-a-reverse-charge-customer');
  }

  function addEuVatMissingInfoWarnings(eu_vat_status) {
    if (!eu_vat_status['vat_number']) {
      addHelpMessageUnlessExists(this, 'warning', I18n.t('customer_documents.eu_vat_number_missing'),'eu-vat-no-number');
    }

    if (!eu_vat_status['address_present']) {
      addHelpMessageUnlessExists(this, 'warning', I18n.t('customer_documents.eu_address_missing'), 'eu-vat-no-address');
    }
  }

  function checkNeedForEuVatWarnings(tax_code_field) {
    if (!tax_code_field.find('option:selected').data('include-in-ec-sales-list')) {
      return;
    }

    const closestPicker = getClosestDateTimePicker($('#date_tf'));
    if (!!closestPicker){
      if (tax_code_field.find('option:selected').data('is-rc-tax-code') && (closestPicker.date() > moment('2021-01-01'))){
        return;
      }
    }

    var customer_supplier_ids = getCustomerSupplierIds();

    for(var i = 0; i < customer_supplier_ids.length; i++) {
      getJsonOrShowMaintenanceModal('/customers/' + customer_supplier_ids[i] + '/get_eu_vat_status.json', function(eu_vat_status) {
        addEuVatMissingInfoWarnings(eu_vat_status);
        if (!eu_vat_status['uses_eu_vat']) {
          addEuVatWarning();
        }
      });
    }
  }

  function checkNeedForReverseChargeWarnings(tax_code_field) {
    if (tax_code_field.find('option:selected').data('is-rc-tax-code') && window.companySettings.uses_reverse_charge == false) {
      addReverseChargeWarning();
    }
  }

  function showQuantityWarningPopover(line_item){
    if (line_item.stock_item.val() == ""  || !window.stockItemInfoExists()) { return; }
    destroyPopover(line_item.quantity_popover);
    var stockInfo = window.parseStockItemInfo();
    var selectedStockItemInfo = stockInfo[line_item.stock_item.val()];
    if (!selectedStockItemInfo) { return; }
    if (selectedStockItemInfo['quantity'] < line_item.quantity.val()){
      if (!selectedStockItemInfo["is_tracked"]) return;
      var warning = 'You do not have enough ' + selectedStockItemInfo['name_plural'] + ' in stock. You have ' + selectedStockItemInfo['quantity'] + ' available to sell.'
      hideAllPopovers();
      showPopoverWithContent(line_item.quantity_popover, warning);
    }
  }

  function showOutOfStockPopover(line_item){
    if (line_item.stock_item.val() == "" || !window.stockItemInfoExists()) { return; }
    destroyPopover(line_item.out_of_stock_popover);
    var stockInfo = window.parseStockItemInfo();
    var selectedStockItemInfo = stockInfo[line_item.stock_item.val()];

    if (selectedStockItemInfo['quantity'] == 0 && selectedStockItemInfo['is_tracked']){
      if (!selectedStockItemInfo["is_tracked"]) { return; }
      var warning = selectedStockItemInfo['name_plural'] + ' are currently out of stock.'
      hideAllPopovers();
      showPopoverWithContent(line_item.out_of_stock_popover, warning);
    }
  }

  function setPriceFromStockItem(line_item){
    if (line_item.stock_item.val() == "" || !window.stockItemInfoExists()) { return; }
    var stockInfo = window.parseStockItemInfo();
    line_item.price.val(stockInfo[line_item.stock_item.val()]['sales_price']);
  }

  function setCategoryFromStockItem(line_item){
    if (line_item.stock_item.val() == "" || !window.stockItemInfoExists()) { return; }
    var stockInfo = window.parseStockItemInfo();
    line_item.category.val(stockInfo[line_item.stock_item.val()]['sales_account']).trigger('chosen:updated');
  }

  function setProjectFromStockItem(line_item){
    if (line_item.stock_item.val() == "" || !window.stockItemInfoExists()) { return; }
    var stockInfo = window.parseStockItemInfo();
    var $projectField = $('#project_select_sf');
    $projectField.val(stockInfo[line_item.stock_item.val()]['project']).trigger('chosen:updated');
  }

  function setDescriptionFromStockItem(line_item){
    if (line_item.stock_item.val() == "" || !window.stockItemInfoExists()) { return; }
    var stockInfo = window.parseStockItemInfo();
    line_item.description.typeahead('val', stockInfo[line_item.stock_item.val()]['sales_description']);
  }

  window.parseStockItemInfo = function(){
    return $('#stock_item_values').data('prices');
  }

  window.stockItemInfoExists = function(){
    return $('#stock_item_values').length > 0;
  }

  function suppressTypeaheadTab(e) {
    if (e.keyCode === 9) {
      e.stopImmediatePropagation();
    }
  }

  function lineItemNominalAccountChange(line_item){
    return function(){
      new InvoiceTaxCodeDefaulting(
        $('#customer_select_single:enabled'), // If it's a group invoice, we can't check the customer
        line_item.category,
        line_item.tax_code,
        line_item.stock_item,
        $('#invoice_type').val()
      ).call();
    }
  }

  function lineItemNetAmountChange(line_item) {
    return function() {
      checkTaxCodeMatchesInvoiceType(line_item);
    }
  }

  function lineItemSetAmountsFromChangedNetAmount(line_item) {
    lineItemSetTaxAndTotalFromNetAndTaxRate(line_item);
    checkTaxCodeMatchesInvoiceType(line_item);
  }

  function lineItemTaxAmountChange() {
    return function() {
      updateCustomerDocumentTaxAmount();
    }
  }

  function lineItemTaxCodeChange(line_item) {
    return function() {
      updateCustomerDocumentAmounts();
      custDocAddNoVatSchemeWarning(getClosestDateTimePicker($('#date_tf')), line_item);
      checkNeedForEuVatWarnings(line_item.tax_code);
      checkNeedForReverseChargeWarnings(line_item.tax_code);
      checkTaxCodeMatchesInvoiceType(line_item);
    }
  }

  function lineItemOnTotalAmountChange() {
    return function() {
      updateCustomerDocumentAmounts();
    }
  }

  function lineItemParentLiChange(line_item) {
    return function() {
      setLineItemValuesFromParentLineItemForNbInvoice(line_item.row);
      updateCustomerDocumentAmounts();
    }
  }

  function lineItemQuantityChange(line_item){
    return function() {
      showQuantityWarningPopover(line_item);
    }
  }

  function lineItemStockItemChange(line_item) {
    return function() {
      showOutOfStockPopover(line_item)
      showQuantityWarningPopover(line_item)
      setPriceFromStockItem(line_item)
      setCategoryFromStockItem(line_item)
      setProjectFromStockItem(line_item)
      new InvoiceTaxCodeDefaulting(
        $('#customer_select_single:enabled'), // If it's a group invoice, we can't check the customer
        line_item.category,
        line_item.tax_code,
        line_item.stock_item,
        $('#invoice_type').val()
      ).call();
      setDescriptionFromStockItem(line_item)
      updateLineItemNetAmount(line_item)
    }
  }

  window.customerDocumentDateChange = function(line_item) {
    return function() {
      custDocAddNoVatSchemeWarning(getClosestDateTimePicker($('#date_tf')), line_item);
    }
  }

  function updateCurrencySymbols(currency_id) {
    if (currency_id) {
      getJsonOrShowMaintenanceModal('/financial/currencies/' + currency_id + '/get_symbol.json', function(currency) {
        var currency_symbol_spans = $('form').find('span.currency-symbol');
        currency_symbol_spans.text(currency['currency_symbol']);
      });
    }
  }

  function toggleConversionRateField(currency_id, date) {
    if (currency_id) {
      var $conversionRateTf = $('#conversion_rate_tf');
      var base_currency_id = companySettings.base_currency.id;
      var $conversionRateField = $('#conversion_rate_field');

      if (currency_id == base_currency_id) {
        $conversionRateTf.attr('disabled', 'disabled');
        $conversionRateField.hide();
      } else {
        $conversionRateTf.removeAttr('disabled');
        $conversionRateField.removeClass('hidden').show();
        setConversionRate(currency_id, date);
      }
    }
  }

  function setConversionRate(currency_id, date) {
    var currency_code = $('#cd_currency_tf option:selected').text().substring(0, 3);
    var conversion_rate_field = $('#conversion_rate_tf');

    if (currency_id && date) {
      getJsonOrShowMaintenanceModal('/financial/conversion_rates/get_rate.json?currency_id=' + currency_id + '&date=' + encodeURIComponent(formatDateGb(date)),
      function(conversion_rate) {
        if (conversion_rate['warn'] && !conversion_rate['rate']) {
          addHelpMessage(
            conversion_rate_field, HELP_WARNING,
            I18n.t('customer_documents.no_conversion_rate_found', { currency_code: currency_code })
          );
        } else if (conversion_rate['warn']) {
          var import_date = formatDateForCompany(moment(conversion_rate['import_date']));
          addHelpMessage(
            conversion_rate_field, HELP_INFO,
            I18n.t('customer_documents.no_conversion_rate_found_for_date',
              { currency_code: currency_code, date: formatDateForCompany(date),
                rate: conversion_rate['rate'], import_date: import_date })
          );
        } else {
          conversion_rate_field.val(conversion_rate['rate']);
        }
      });
    }
  }

  function sumLineItemNetAmounts() {
    var net = 0;

    $.each($('.line_item_row'), function(i, row) {
      if ($(row).find('.line_item_net_amount_tf').val() && $(row).find('.mousetrap_remove_row').siblings('input').val() == 'false') {
        net += parseFloat($(row).find('.line_item_net_amount_tf').val());
      }
    });
    return net;
  }

  function sumLineItemTaxAmounts() {
    var tax = 0;

    $.each($('.line_item_row'), function(i, row) {
      if ($(row).find('.line_item_tax_amount_tf').val() && $(row).find('.mousetrap_remove_row').siblings('input').val() == 'false') {
        tax += parseFloat($(row).find('.line_item_tax_amount_tf').val());
      }
    });
    return tax;
  }

  function enterKeyPressNonBatch(activeElement, inputsToTab) {
    var index = $.inArray(activeElement, inputsToTab);

    if ($(activeElement).is('a') && !$(activeElement).is('[disabled=disabled]')) {
      $(activeElement).click();
    } else if (index > -1 && index < inputsToTab.length) {
      $(inputsToTab[index + 1]).focus();
    }
  }

  function addNetAmountCalculationListeners(line_item) {
    if (line_item.can_calculate_net_amount) {
      [ line_item.discount,
        line_item.quantity,
        line_item.price
     ].forEach(addEachNetCalculationListener(line_item));
    }
  }

  function addEachNetCalculationListener(line_item) {
    return function($elem) {
      $elem.change(function(){updateLineItemNetAmount(line_item)});
    }
  }

  function updateLineItemNetAmount(line_item) {
    var net = calculateNetFromPriceAndQuantity(line_item);

    if (net) {
      if (line_item.discount.length && (line_item.discount.val())) {
        net *= (1 - parseFloat((line_item.discount.val()) / 100));
      }

      line_item.net.val(roundToTwoDp(net));
      lineItemSetAmountsFromChangedNetAmount(line_item);
    }
  }

  function calculateNetFromPriceAndQuantity(line_item) {
    var price = line_item.price.val();
    var qty = line_item.quantity.val();
    if (qty && price) {
      return parseFloat(qty) * parseFloat(price);
    } else if (!qty && price) {
      return parseFloat(price);
    }
  }

  function getCustomerSupplierIds() {
    var $form = $('form');

    if (isCustomerGroupInvoice($form)) {
      return customerFieldValueIfExists($('#customer_select_multiple').val());
    } else {
      return $.makeArray(customerFieldValueIfExists($('#customer_select_single').val()));
    }
  }

  function customerFieldValueIfExists(field_value) {
    if (!field_value || field_value.length == 0) { return }
    return field_value
  }

  function checkTaxCodeMatchesInvoiceType(line_item) {
    var tax_code = line_item.tax_code.find('option:selected').text();
    var goods_tax = /EG/.test(tax_code);
    var service_tax = /ES/.test(tax_code);
    if (!goods_tax && !service_tax) { return; }

    var goods_fields = line_item.quantity.is(':visible');
    var goods_fields_used = _fieldHasBeenUsed(line_item.quantity) || _fieldHasBeenUsed(line_item.price);
    var line_items_entered = _fieldHasBeenUsed(line_item.net);

    if (goods_tax && (!goods_fields || !goods_fields_used && line_items_entered)) {
      addHelpMessageUnlessExists(null, 'warning',
        'This looks like a service invoice, did you mean to select tax code ES?',
        'tcInvTypeEs'
      );
    } else if (service_tax && goods_fields_used) {
      addHelpMessageUnlessExists(null, 'warning',
        'This looks like a goods invoice, did you mean to select tax code EG?',
        'tcInvTypeEg'
      );
    }
  }

  function _fieldHasBeenUsed(field){
    field && field.val() && field.val().length > 0;
  }

  function setNominalAccount(customer) {
    var nominal_account_tf = $('.split_account');
    nominal_account_tf.val(customer['nominal_account_id']);
    nominal_account_tf.trigger('chosen:updated').change();
  }

  function UpdateCurrencyToCustomersCurrency(customer) {
    var currency_select = $('#cd_currency_tf');
    var hidden_currency_select = $('#hidden_currency');

    // Set values
    currency_select.val(customer['currency_id_json']);
    hidden_currency_select.val(customer['currency_id_json']);

    // Trigger updates
    currency_select.trigger('chosen:updated').change();
    hidden_currency_select.trigger('chosen:updated').change();
  }

  window.setRowDataAndTaxAmountId = function($new_tr, row_id) {
    $new_tr.attr('data-row-id', row_id)
    $new_tr.find('#line_item_tax_amount1:last').attr('id', ('line_item_tax_amount' + row_id))
    return $new_tr
  }
})();
