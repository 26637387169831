(function() {
  'use strict';

  window.getClosestInvoiceRowToEvent = function(event) {
    return $(event.currentTarget).closest('tr.invoice_row');
  }

  window.getAndWrapClosestInvoiceRowToEvent = function(event) {
    return wrapBatchInvoiceRow($(event.currentTarget).closest('tr.invoice_row'));
  }

  // Use this method if we're catching an event that's bubbled up the DOM, but want to know which row it originated in
  window.getClosestInvoiceRowToEventOriginalTarget = function(event) {
    var thereIsASplitModal = Boolean($(event.target).parents('.split_row ').length > 0)
    if (thereIsASplitModal) {
      return $(event.target).parents('.split_row ');
    } else {
      return $(event.target).parents('tr.invoice_row');
    }
  }

  window.initialiseInvoiceRow = function() {
    var $row = $('.invoice_row');
    addInvoiceRowChangeListeners($row);
    disableTaxAmountIfTaxCodeIsZero($row, '.tax_amount_tf', '.tax_code');
    $row.find('.js_date_field').last().focus();
  }

  window.addInvoiceRowChangeListeners = function(section) {
    var $section = $(section);
    [ [ ".inv_datepicker_div", 'focusout', invDateOnChange ],
      [ ".invoice_type", 'change', invoiceTypeSelectChange ],
      [ ".nominal_account_selector", 'change', invNominalAccountChange ],
      [ ".bt_currency_field.show-disabled-select", 'change', currencyOnChange ],
      [ ".add_new_inv", 'click', addNewInvoiceButtonClick ],
      [ ".remove_inv", 'click', removeInvoiceButtonClick ],
      [ '.stock_item_quantity_tf', 'change', invStockChange ],
      [ '.price_tf', 'change', invPriceChange ],
      [ '.stock_item_selector', 'change', invStockChange ],
      [ '.tax_amount_tf', 'change', setNoVatSchemeWarning ],
      [ ".customer_supplier_select", 'change', invCustomerSupplierSelectChange ],
      [ "#invoice_ref_selector", 'change', invoiceRefChange ],
      [ '.description_tf', 'change', updatePopoverText ],
      [ ".net_amount_tf", 'focus', highlightCell ],
      [ ".tax_amount_tf", 'focus', highlightCell ],
      [ ".total_amount_tf", 'focus', highlightCell ],
      [ ".split_button", 'click', invSplitButtonClick ],
      [ ".is_split_field", 'change', onSplitStatusChange ],
      [ ".attachment_button", 'click', openBatchUploadsModal ]
    ].forEach(function(args) {
      errors.tryOn($section.find(args[ 0 ]), args[ 1 ], args[ 2 ]);
    });
  }

  window.findAndReplaceUniqueIdForRow = function(row, baseId, id) {
    var el = row.find('[id*="' + baseId + '"]');

    if (el.length > 0) {
      el[0].id = baseId + id;
    }
  }

  // METHODS FOR SETTING VALUES

  function setCustomerSupplierId(batchRow, value) {
    var cs_select = batchRow.getCustomerSupplierSelect();
    cs_select.val(value);
    cs_select.trigger('change');
  }

  function setCustomerSupplierType(batchRow, value) {
    var cs_type_field = batchRow.getCustomerSupplierTypeField();
    cs_type_field.val(value);
    cs_type_field.trigger('change');
  }

  function setNominalAccount(batchRow, nominalAccountSelectId) {
    var nominalAccountSelect = batchRow.getNominalAccountSelect();
    nominalAccountSelect.val(nominalAccountSelectId);
    nominalAccountSelect.trigger("chosen:updated").trigger('change');
  }

  function setCurrencySelectId(batchRow, currencySelectId) {
    var currencySelect = batchRow.getCurrencySelect();
    var hiddenCurrencyField = batchRow.getHiddenCurrencyField();
    currencySelect.val(currencySelectId).change();
    hiddenCurrencyField.val(currencySelectId).change();
    currencySelect.trigger("chosen:updated");
  }

  function setInvoiceRefPrefix(batchRow, invoiceRefPrefix) {
    var invoiceRefField = batchRow.getInvoiceRefField();
    if (invoiceRefField === undefined){ return }
    if (invoiceRefField.data('autofilled') || (invoiceRefPrefix.length > 0)){
      invoiceRefField.data('autofilled', true);
      invoiceRefField.val(invoiceRefPrefix);
    }
  }

  // EVENT HANDLERS ETC
  function invDateOnChange(event) {
    setConversionRateForRow(event);
    _invoiceDateWarnings(event);
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    updateTaxCodeOptionsForBatch(batchRow);
  }

  function _invoiceDateWarnings(event) {
    var $elem = $(event.currentTarget);

    addDatePeriodWarning($elem);
    addDateInFutureWarning($elem);
  }

  function invoiceTypeSelectChange(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    _batchTypeChangeSalesOrPurchases(
      batchRow,
      batchRow.getInvoiceTypeSelect().val()
    );
    invStockChange(event)
  }

  function _batchTypeChangeSalesOrPurchases(batchRow, invoiceType) {
    switch (invoiceType) {
      case SALES_INVOICE:
      case SALES_CREDIT_NOTE:
        _invPopulateCustomers(batchRow);
        invSetNominalsFromStockAndType(batchRow);
        break;
      case PURCHASE_INVOICE:
      case PURCHASE_CREDIT_NOTE:
        _invPopulateSuppliers(batchRow);
        invSetNominalsFromStockAndType(batchRow);
        break;
    }
  }

  // Called when invoice type is changed to sales invoice / credit note
  function _invPopulateCustomers(batchRow) {
    getJsonOrShowMaintenanceModal("/customers.json", function(data) {
      var customerSupplierSelect = batchRow.getCustomerSupplierSelect();
      customerSupplierSelect.selectPopulator(data);
      setCustomerSupplierType(batchRow, 'Customer');
      if ( data.length == 1 ) { setCustomerSupplierId(batchRow, data[0].id) }
      setCustomerSupplierData(batchRow); // populate default values for the current customer
      initAddNewCustomerButton(customerSupplierSelect, batchRow);
    });
  }

  function setCustomerSupplierData(batchRow) {
    var customerSupplierId = batchRow.getCustomerSupplierSelect().val();
    if (customerSupplierId) {
      _populateCustomerSupplierDefaults(batchRow, customerSupplierId);
      _populateCustomerSupplierInvoiceRefs(batchRow);
    }
  }

  // calls get_customer.json or get_supplier.json, and populates their defaults
  function _populateCustomerSupplierDefaults(batchRow, customerSupplierId) {
    var url = getCustomerSupplierUrl(batchRow.getRow(), customerSupplierId);
    if ( typeof(url) === 'undefined' ) { return; }
    getJsonOrShowMaintenanceModal(url, function (customerSupplier) {
      setNominalAccount(batchRow, customerSupplier.nominal_account_id); // Changing the nominal will trigger updating the tax code according to the customer and nominal
      setInvoiceRefPrefix(batchRow, customerSupplier.full_inv_ref_prefix);
      setCurrencySelectId(batchRow, customerSupplier.currency_id_json);
    });
  }

  function _populateCustomerSupplierInvoiceRefs(batchRow) {
    var cs = batchRow.getCustomerSupplierType();
    if ( cs && cs.length ) {
      populateInvoiceRefs(batchRow.getRow(), cs.toLowerCase()+"s");
    }
  }

  function initAddNewCustomerButton(customerSupplierSelect, batchRow) {
    // Replace add new button with Customer
    customerSupplierSelect.removeClass('js-add-new-supplier').addClass('js-add-new-customer');
    var chosen_container = customerSupplierSelect.siblings("div.chosen-container");
    batchRow.getAddNewSupplierButton().remove();
    addNewCustomerOption(chosen_container);
    setLinkToAddNewCustomer(customerSupplierSelect);
  }

  function _invPopulateSuppliers(batchRow) {
    getJsonOrShowMaintenanceModal("/suppliers.json", function(data) {
      var customerSupplierSelect = batchRow.getCustomerSupplierSelect();
      customerSupplierSelect.selectPopulator(data);
      setCustomerSupplierType(batchRow, 'Supplier');
      if ( data.length == 1 ) { setCustomerSupplierId(batchRow, data[0].id) }
      setCustomerSupplierData(batchRow); // populate default values for the current supplier
      initAddNewSupplierButton(customerSupplierSelect, batchRow);
    });
  }

  function initAddNewSupplierButton(customerSupplierSelect, batchRow) {
    // Replace add new button with Supplier
    customerSupplierSelect.removeClass('js-add-new-customer').addClass('js-add-new-supplier');
    var chosen_container = customerSupplierSelect.siblings("div.chosen-container");
    batchRow.getAddNewCustomerButton().remove();
    addNewSupplierOption(chosen_container);
    setLinkToAddNewSupplier(customerSupplierSelect);
  }

  function invNominalAccountChange(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    new InvoiceTaxCodeDefaulting(
      batchRow.getCustomerSupplierSelect(),
      batchRow.getNominalAccountSelect(),
      batchRow.getTaxCodeSelect(),
      batchRow.getStockItemSelect(),
      batchRow.getInvoiceTypeSelect().val()
    ).call();
    updateTaxCodeOptionsForBatch(batchRow);
  }

  function updateTaxCodeOptionsForBatch(batchRow) {
    var customer_supplier_id = $(".customer_supplier_select").val();
    var customer_supplier_type = findInvoiceType(batchRow.getInvoiceTypeSelect().val());
    var selectedRef = batchRow.getInvoiceRefSelector().find('option:selected');
    var invoiceId = selectedRef.data('invoiceId')
    //FARMPLAN: Don't update tax code field if split
    var fields = (batchRow.find(".is_split_field").val() === "false") ? batchRow.getTaxCodeSelectsIncludingSplits() : batchRow.find('.split_tax_code_field');
    updateTaxCodeOptions(batchRow.find('.inv_datepicker_div').parent(), fields, invoiceId, customer_supplier_id, customer_supplier_type);
  }

  function findInvoiceType(string) {
    if (string.match(/Sales/)) {
      return "Customer";
    } else if (string.match(/Purchase/)) {
      return "Supplier";
    }
  }

  function currencyOnChange(event) {
    setConversionRateForRow(event);
  }

  var addNewRowTimeout; // ID of previous call of setTimeout for chInvAddNewRow
  var ADD_NEW_ROW_INTERVAL = 200;

  function addNewInvoiceButtonClick(event) {
    event.preventDefault(); // prevent page position being reset in OBW
    clearTimeout(addNewRowTimeout); // prevents the previous chInvAddNewRow happening if it has not already been executed
    addNewRowTimeout = setTimeout(function() {
      chInvAddNewRow();
      setMaxInvoiceDates();
    }, ADD_NEW_ROW_INTERVAL);

    return false; // Don't submit the form!
  }

  // Click handler for - row button
  function removeInvoiceButtonClick(event) {
    var source = event.currentTarget;
    var tableRowCount = $(source).closest('tbody').find('.invoice_row').length;
    // If this is the only row, add a new (blank) row before we clear the
    // unwanted one (which might have junk data in it)
    if (tableRowCount == 1) {
      chInvAddNewRow();
    }
    releaseAttachmentsForNonPersistedRecord(event);
    removeClosestRow(event);
    return false; // Don't submit the form!
  }

  // PANDLE OVERRIDE to integrate with line-item-row
  // Click handler for + row button
  function chInvAddNewRow() {
    checkNumberRows($('.invoice_row').length);
    var $table = $('#inv_table_body');
    var originalRow = $table.find("tr.invoice_row:last");
    var newRow = _chInvSetupNewRowToInsert(originalRow);
    newRow.addClass('hidden')
    // Add the new row to the table
    $table.append(newRow);

    setTimeout(function() {
      $(newRow).find(".close-button").trigger('click');
      newRow.removeClass('hidden');

      // FARMPLAN - trigger the stimulus event to init fields
      $(newRow).find('.is_split_field')[0].dispatchEvent(new Event('switch'))
    }, 0);

    $('.mousetrap_add_row').html('<i class="zmdi zmdi-plus zmdi-hc-fw" title=""></i>');
    _chInvInitialiseInsertedNewRow(newRow);
    _incrementNextRowId();
  }

  function _chInvSetupNewRowToInsert(originalRow) {
    var newRow = $(originalRow).clone();
    newRow = removeErrorDivs(newRow);
    $(newRow).find('.popover-container').attr('data-content', '');
    $(newRow).find('.popover').remove();

    // before we clone, remove any Chosen containers
    $(newRow).find("div.chosen-container").remove();

    var row_id = $('#next-row-id').val();
    $(newRow).attr('data-id', row_id);
    findAndReplaceUniqueIdForRow(newRow, 'persisted', row_id);
    setupUploadModalForNewRow(newRow, row_id);
    addInvoiceRowChangeListeners(newRow);

    $(newRow).find(".date").attr('id', 'input_date' + row_id + '_container');
    $(newRow).find(".js_date_field").attr('id', 'input_date' + row_id);

    $(newRow).find(".invoice_type").trigger('change'); //Populate customers/suppliers and nominals
    return newRow;
  }

  function setupUploadModalForNewRow(newRow, row_id) {
    newRow.find('.new-uploads-list').html("");
    findAndReplaceUniqueIdForRow(newRow, 'upload_ids', row_id);
  }

  function _chInvInitialiseInsertedNewRow(newRow) {
    // Clear values from each field in row
    $(newRow).find("input:not(.hidden_nominal_account_selector, #invoices__tax_code_id, .non_vat_line_item_tax_code_field, .hidden_date_format_field, .invoice_type)").each(function () {
      $(this).val('');
    });
    $.each($(newRow).find('.typeahead'), function(index, desc_field) {
      typeAheadInput($(desc_field), $('.typeahead_descriptions'));
    })
    // Format form elements
    formatFormsIn(newRow);

    //set the default datepicker value to the value of the row above
    duplicateFieldValue(newRow, '.js_date_field');
    $(newRow).find('.js_date_field').focus();
    // Duplicate the is_template flag
    duplicateFieldValue(newRow, '.is_template');
    duplicateFieldValue(newRow, '.obw_invoice');
    duplicateFieldValue(newRow, '.recurring_transaction_id');
    duplicateFieldValue(newRow, '.upload_company_field');

    $(newRow).find('.is_split_field').val(false);
  }

  function _incrementNextRowId() {
    var row_id = $('#next-row-id').val();
    $('#next-row-id').val(parseInt(row_id) + 1);
  }

  function invStockChange(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    invShowQuantityWarningPopover(batchRow);
    invShowOutOfStockPopover(batchRow)
    invSetNominalsFromStockAndType(batchRow);
    invSetPriceFromStockItemAndType(batchRow);
    invSetCategoryFromStock(batchRow);
    invSetNetFromQuantityAndPrice(batchRow);
    new InvoiceTaxCodeDefaulting(
      batchRow.getCustomerSupplierSelect(),
      batchRow.getNominalAccountSelect(),
      batchRow.getTaxCodeSelectsIncludingSplits(),
      batchRow.getStockItemSelect(),
      batchRow.getInvoiceTypeSelect().val()
    ).call();
    invSetDescriptionFromStock(batchRow);
    invSetProjectFromStock(batchRow);
    invPriceChange(event);
    var stockQuantity = batchRow.getStockQuantity()[0];
    if (stockQuantity)
      stockQuantity.dispatchEvent(new Event('stock-items-changed'));
  }

  function invPriceChange(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    invSetNetFromQuantityAndPrice(batchRow);
    var stockQuantity = batchRow.getStockQuantity()[0];
    if (stockQuantity)
      stockQuantity.dispatchEvent(new Event('stock-items-changed'));
  }

  function invSetNetFromQuantityAndPrice(batchRow){
      setNetFromQuantityAndPrice({
        net_field: batchRow.getNetAmountTf(),
        quantity_field: batchRow.getStockQuantity(),
        price_field: batchRow.getPriceTf()
      })

  }

  function invShowOutOfStockPopover(batchRow){
    showOutOfStockPopover({
      stock_item_field: batchRow.getStockItemSelect(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function invShowQuantityWarningPopover(batchRow){
    showQuantityWarningPopover({
      stock_item_field: batchRow.getStockItemSelect(),
      quantity_field: batchRow.getStockQuantity(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function invSetNominalsFromStockAndType(batchRow){
    setNominalsFromStockAndType({
      stock_item_field: batchRow.getStockItemSelect(),
      category_field: batchRow.getNominalAccountSelect(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function invSetDescriptionFromStock(batchRow){
    setDescriptionFromStock({
      description_field: batchRow.getDescriptionTf(),
      stock_item_field: batchRow.getStockItemSelect(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function invSetProjectFromStock(batchRow){
    setProjectFromStock({
      project_field: batchRow.getProjectSelect(),
      stock_item_field: batchRow.getStockItemSelect(),
    })
  }

  function invSetCategoryFromStock(batchRow){
    setCategoryFromStock({
      category_field: batchRow.getNominalAccountSelect(),
      stock_item_field: batchRow.getStockItemSelect(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function invSetPriceFromStockItemAndType(batchRow){
    setPriceFromStockItemAndType({
      net_amount_field: batchRow.getNetAmountTf(),
      stock_item_field: batchRow.getStockItemSelect(),
      type_field: batchRow.getInvoiceTypeSelect(),
      price_field: batchRow.getPriceTf()
    })
  }

  function setNoVatSchemeWarning(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    addNoVatSchemeWarning({
      tax_amount_field: batchRow.getTaxAmountTf(),
      tax_code_field: batchRow.getTaxCodeSelect(),
      datepicker: batchRow.getDatepicker()
    });
  }

  function invCustomerSupplierSelectChange(event) {
    setCustomerSupplierData(
      getAndWrapClosestInvoiceRowToEvent(event)
    );
  }

  // Credit note: invoice selected, display the invoice's nom acc and tax code
  function invoiceRefChange(event) {
    var $invoice_row = getAndWrapClosestInvoiceRowToEvent(event)
    var selectedRef = $invoice_row.getInvoiceRefSelector().find('option:selected');
    updateTaxCodeOptionsForBatch($invoice_row);
    _showInvoiceValuesForCreditNote($invoice_row, selectedRef);
  }

  function _showInvoiceValuesForCreditNote($batch_row, selectedRef) {
    if (selectedRef.data('isSplit')) {
      notify('This credit note is against a split invoice. Click the "split" button to enter the credit note details.', 'info');
      _getInvoiceLineItemsAndPopulateSplitsModal($batch_row, selectedRef);
    } else {
      // Async to prevent overwriting from other tax code select triggers.
      setTimeout(function() {
        $batch_row.getTaxCodeSelect().val(selectedRef.data('taxCodeId')).trigger('chosen:updated');
      }, 200);

      if (selectedRef.data('suspenseInvoice')) { return ; }
      $batch_row.getNominalAccountSelect().val(selectedRef.data('nominalAccountId')).trigger("chosen:updated");
    }
  }

  function _getInvoiceLineItemsAndPopulateSplitsModal(batchRow, selected_ref) {
    getJsonOrShowMaintenanceModal("/invoices/" + selected_ref.data('invoiceId') + "/line_items.json", _populateLineItemModalParentLineItemSelector(batchRow));
  }

  function _populateLineItemModalParentLineItemSelector(batchRow) {
    return function(data) {
      batchRow.getLineItemsModal().find('.invoice_line_items_select').selectPopulator(data);
    }
  }

  function invSplitButtonClick(event) {
    hideAllPopovers();
    var $row = getAndWrapClosestInvoiceRowToEvent(event)
    $row.getIsSplitField().val(true).trigger('change');
    $row.find('.split_stock_item').each(function(key, value){
      if ($(value).val().length == 0){
        $(value).trigger('change')
      }
    });
    return false;
  }

  function onSplitStatusChange(event) {
    if ( $(event.target).val() == 'true') {
      showLineItemsModal(event);
    } else {
      var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
      removeInvoiceSplits(batchRow, batchRow.getLineItemsModal());
    }
  }

  function initAreYouSureInvoice() {
    var invoice_form_id = '#invoice_form';
    window.invoice_are_you_sure_controller = checkFormChangedOnPageUnload(invoice_form_id);
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {

    if ($('#invoice_form').length === 0) { return }

    $.each($('.typeahead'), function(index, desc_field) {
      typeAheadInput($(desc_field), $('.typeahead_descriptions'));
    });

    initBatchInvFieldStateController();
    initialiseInvoiceRow();
    initAreYouSureInvoice();
    errors.tryClick($('.submit_button'), preprocessSplitModalsForSubmit);
    errors.tryClick($('#save_and_recur_button'), preprocessSplitModalsForSubmit);
    checkCreditLimitOnSubmit();
  }));
})();
