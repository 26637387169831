(function(window) {
  'use strict';
  $(document).on("turbo:load show.bs.modal nested:fieldAdded", function(e) {
    $("[data-deselect]").on("change", function(e) {
      var toggleTarget = $(this).data('deselect');
      if ($(this).prop('checked')) {
        $(toggleTarget).prop('checked', false);
      }
    });
  });
})(this);
