import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['confirmable']

  toggle(event) {
    if (event.target.checked) {
      this.confirmableTargets.forEach((confirmable) => { confirmable.disabled = false })
    } else {
      this.confirmableTargets.forEach((confirmable) => { confirmable.disabled = true })
    }
  }
}
