window.initNominalAccountsModal = function() {
  var $dropdown = $('#nominal_account_account_type');
  $dropdown.unbind();
  nominalCodeSuggestions($dropdown);
  setCategoryTypeListener($dropdown);
}

function nominalCodeSuggestions($dropdown) {
  $dropdown.change(function() {
    getNextUniqueNominalCode(this.value);
  });
}

window.setCategoryTypeListener = function($dropdown) {
  showOrHideExpenseCategoryOption($dropdown);

  $dropdown.change(function() {
    showOrHideExpenseCategoryOption($dropdown);
    setTaxCodesForAccountType($dropdown);
  });
}

function getNextUniqueNominalCode(type) {
  getJsonOrShowMaintenanceModal('/nominal_accounts/next_unique_nominal_code.json?account_type=' + type, function(data) {
    $('#nominal_code_field').val(data);
  });
}

function showOrHideExpenseCategoryOption($dropdown) {
  var category_selected = ($dropdown.val() === 'Expense');

  if (!$('#expense_category_option').is(':visible') == category_selected) {
    $('#expense_category_option').slideToggle();
  }
}

function setTaxCodesForAccountType($dropdown) {
  const url = Routes.get_tax_codes_for_account_type_companies_path({
    format: 'json',
    account_type: $dropdown.val()
  });
  
  getJsonOrShowMaintenanceModal(url, function(data) {
    updateTaxCodesDropdown($dropdown, data)
  });
}

function updateTaxCodesDropdown($dropdown, data) {
  if (data == null) { return; }
  const $tax_code_field = $("#nominal_account_default_tax_code_id");
  $tax_code_field.selectPopulator(data.tax_codes);

  const default_tax_code = $dropdown.find(':selected').data('default-tax-code-id');  
  if ( typeof(default_tax_code) === 'undefined' ) { return; }
  $tax_code_field
    .val(default_tax_code)
    .trigger('change')
    .trigger('chosen:updated');
}