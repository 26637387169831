(function() {
  'use strict';

  var $modal = null;
  var $modal_header = null;
  var $modal_body_and_footer = null;
  var $large_modal = null;

  window.modals = {
    getModal : function() {
      if ($modal === null) {
        $modal = $('#modal');
      }
      return $modal;
    },

    getLargeModal : function() {
      if ($large_modal === null) {
        $large_modal = $('#modal-lg');
      }
      return $large_modal;
    },

    getModalHeader : function() {
      if ($modal_header === null) {
        $modal_header = this.getModal().find('.modal-header h4');
      }
      return $modal_header;
    },

    getModalBodyAndFooter : function() {
      if ($modal_body_and_footer === null) {
        $modal_body_and_footer = this.getModal().find('.modal-body-and-footer');
      }
      return $modal_body_and_footer
    }
  };
})();
