(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.items-table').length === 0) { return }
    $('.quantity-field').change(function(){
      var $quantityField = $(this)
      var $row = $quantityField.closest('tr')
      var $valueField = $row.find('.value-field')
      $valueField.val($row.data('price')*$quantityField.val());
    })
  }));
})();
