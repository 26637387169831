(function() {
  'use strict';

  window.addJeCurrencyEventListeners = function(some_place) {
    $(some_place).find('.je_currency_field').on('change', journalEntryCurrencyChange);
    $(some_place).find('#je_datepicker').on('dp.change', setConversionRateForRow);
    $(some_place).find('.conversion_rate_field').on('change', updateJournalEntryTotalAmounts);
    $(some_place).find('.account_selector').on('change', jeAccountOnChange);
  };

  function journalEntryCurrencyChange(event) {
    setConversionRateForRow(event);
    restrictAccountsByCurrency(event);

    var base_currency_id = companySettings.base_currency.id;
    var currency_id = $(event.target).val();
    enableOrDisableConversionRateField({
      first_account_currency_id: base_currency_id,
      second_account_currency_id: currency_id,
      base_currency_id: base_currency_id,
      conversion_rate_field: $('#conversion_rate_field')
    });
  }

  function restrictAccountsByCurrency(event) {
    var currency_id = $(event.target).val();
    var base_currency_id = companySettings.base_currency.id;
    var selects = $('.account_selector');
    if(currency_id == base_currency_id) {
      $(selects).find('*[data-currency-id]').removeAttr('disabled');
    } else {
      $(selects).find('*[data-currency-id="'+currency_id+'"]').removeAttr('disabled');
      $(selects).find('*[data-currency-id="'+base_currency_id+'"]').removeAttr('disabled');
      var banned = $(selects).find('*[data-currency-id]').not('*[data-currency-id="'+currency_id+'"]').not('*[data-currency-id="'+base_currency_id+'"]');
      $(banned).attr("disabled","disabled");
      $(banned).removeAttr("selected");
    }

    $(".account_selector").trigger('chosen:updated');
  }

  function jeAccountOnChange(event) {
    var row = $(event.currentTarget).closest('tr')[0];
    var currency_id = $(row).find('.account_selector option:selected').data('currency-id');

    updateCurrencySymbolsForRow(currency_id, row);
    setCurrencyIfNecessary(currency_id);
    updateJournalEntryTotalAmounts();
  }

  function updateCurrencySymbolsForRow(currency_id, row) {
    if (currency_id) {
      getJsonOrShowMaintenanceModal('/financial/currencies/' + currency_id + '/get_symbol.json', function (currency) {
        var currency_symbol_spans = $(row).find('span.currency-symbol');
        currency_symbol_spans.text(currency['currency_symbol']);
      });
      $(row).find('.amount_currency_id').val(currency_id);
    }
  }

  function setCurrencyIfNecessary(currency_id) {
    var je_currency_field = $('.je_currency_field');
    var base_currency_id = companySettings.base_currency.id;
    if( currency_id != base_currency_id) {
      je_currency_field.val(currency_id).change().trigger('chosen:updated');
    } else if ( je_currency_field.val() != base_currency_id ) {
      var selected_currencies = $('.account_selector option:selected');
      var currencies = $.map(selected_currencies, function(elt, i) { return $(elt).data('currency-id') });
      currencies = currencies.filter(function(el, index, arr) {
          return index === arr.indexOf(el); // Is this the first occurrence of this currency id?
      });
      if ( currencies.length == 1 ) { // No nonbase rows remain
        je_currency_field.val(currencies[0]).change().trigger('chosen:updated');
      }
    }
  }
})();
