(function() {
  'use strict';

  window.initModalScrolling = function($modal) {
    var $container = $modal.find('#select-files-tab-edit');
    $container.on('scroll', function() {
      var maxValue = this.scrollHeight - this.offsetHeight;

      if (this.scrollTop > (maxValue - 60)) {
        $container.off('scroll');
        getExistingFilesResult($modal);
      }
    });

    $modal.find('.back-to-top-button').on('click', function(e) {
      e.preventDefault();
      $container.animate({ scrollTop: 0 }, 'slow');
      return false;
    });
  }
})();
