(function() {
  'use strict';

  window.initRunningBalances = function() {
    calculateRunningBalances();
  };

  window.calculateRunningBalances = function() {
    updateTable(calculateNewBalances(getInitialBalance()));
  };

  window.updateRunningBalancesForRow = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    updateRunningBalances(row_id);
  };

  // Updates running balances, if the current row is complete.
  // Skip update if user is midway through row
  window.updateRunningBalances = function(row_id) {
    setTimeout(function() {
      if (!$('#bank_account_field' + row_id).val()) {
        return;
      }
      if (!$('#total_amount_field' + row_id).val()) {
        return;
      }
      var type = $('#type_field' + row_id).val();
      if (!type) {
        return;
      }
      if (!getBtAccountSelect(row_id).val() && !isSplitBt(row_id)) {
        return;
      }
      calculateRunningBalances();
    }, 1);
  };

  function isSplitBt(row_id) {
    return $('#is_split_field' + row_id).val() == 'true'
  }

  function getInitialBalance() {
    var ob = {};
    ob['bank_account'] = Number($('#running-balance-row').data('original-balance'));
    return ob;
  }

  function calculateNewBalances(balances) {
    $('#bank-transaction-rows').find('tr.batch_row').each(calculateRow(balances));
    return balances;
  }

  function updateTable(balances) {
    updateRow(balances)
    return balances;
  }

  function updateRow(balance) {
    $('#running-balance-row span.bank_account_balance').text(formatAsCurrency(balance['bank_account']));
  }

  function calculateRow(balance) {
    return function() {
      var row_id = $(this).data('id');
      var amount = Number($('#total_amount_field' + row_id).val());
      // Skip to next row if any required fields are blank
      if (!$.isNumeric(amount)) {
        return true;
      } // continue if field blank
      var bank_account = $('#bank_account_field' + row_id).val();
      if (!bank_account) {
        return true;
      }
      var bank_account_currency_id = $('#bank_account_field' + row_id).data('currency-id');
      var type = $('#type_field' + row_id).val();
      if (!type) {
        return true;
      }

      // Currency IDs
      var currency_field = $('#currency_field' + row_id);
      var currency_id = currency_field.val();
      var base_currency_id = companySettings.base_currency.id;
      var conversion_rate = Number($('#conversion_rate_field' + row_id).val());

      if (type.indexOf('BankReceipt') > -1) { // Money In, in bank_account_currency
        // BT currency is BankAcc currency, so no conversion needed
        balance['bank_account'] += amount;
      } else if (type.indexOf('BankPayment') > -1) { // Money Out, in bank_account_currency
        // BT currency is BankAcc currency, so no conversion needed
        balance['bank_account'] -= amount;
      } else if (type.indexOf('SalesReceipt') > -1) { // Money In, in account_currency
        if (bank_account_currency_id == base_currency_id) {
          if (currency_id == base_currency_id) { //Base Base
            balance['bank_account'] += amount;
          } else { //Base NonBase
            balance['bank_account'] += amount * conversion_rate;
          }
        } else {
          if (currency_id == base_currency_id) { //NonBase Base
            balance['bank_account'] += amount / conversion_rate;
          } else { //NonBase NonBase
            balance['bank_account'] += amount;
          }
        }
      } else if (type.indexOf('PurchasePayment') > -1) { // Money Out, in account_currency
        if (bank_account_currency_id == base_currency_id) {
          if (currency_id == base_currency_id) { //Base Base
            balance['bank_account'] -= amount;
          } else { //Base NonBase
            balance['bank_account'] -= amount * conversion_rate;
          }
        } else {
          if (currency_id == base_currency_id) { //NonBase Base
            balance['bank_account'] -= amount / conversion_rate;
          } else { //NonBase NonBase
            balance['bank_account'] -= amount;
          }
        }
      }
    }
  }
})();
