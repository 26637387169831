// Stimulus actions cannot be setup for ChosenJS directly.
// A custom event is needed to be setup to allow the function to be called
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).on('change', function (event, index) {
      this.dispatchEvent(new CustomEvent('chosen-change'))
    })
  }

  disconnect() {
    $(this.element).off()
  }
}
