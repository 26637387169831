import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reg_no']

  connect() {
    this.prohibitedCountries = ['GB', 'XI']
    this.numbersRegex = /\D/g
  }

  restrictVatNumberToNumbersOnly(event) {
    if (!window.isFeatureToggleActive('vat_number_uk_restriction')) { return }
    const companyUnLocode = window.companySettings.country.un_locode
    const isUK = this.prohibitedCountries.includes(companyUnLocode)

    if (isUK && this.valueContainsNonDigits()) {
      const newValue = this.reg_noTarget.value.replace(this.numbersRegex, '')
      this.reg_noTarget.value = newValue
    }
  }

  valueContainsNonDigits() {
    const regNoValue = this.reg_noTarget.value
    return Boolean(regNoValue.match(this.numbersRegex))
  }
}
