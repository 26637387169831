(function() {
  'use strict';

  var ecsl_form_are_you_sure_controller;

  window.initEcSalesListForm = function() {
    ecsl_form_are_you_sure_controller = checkFormChangedOnPageUnload('#ec_sales_list_form');
    addCheckboxAreYouSure(
      $('#ec_sales_list_final_return'),
      'Are you sure you are stopping using EU VAT and this is your final EC Sales List?'
    );
    $('#unlock_submitted_ec_sales_list').click(showUnlockSubmittedWarning)
  }

  window.disposeEcSalesListAreYouSureController = function() {
    //ecsl_form_are_you_sure_controller.dispose();
  }

  function showUnlockSubmittedWarning(event) {
    event.preventDefault()
    confirmFormSubmission(
      "<p class='m-b-15'>This EC Sales List has already been submitted to HMRC. " +
      "If you unlock it, the values may change. Are you really sure you want to unlock it?</p>" +
      "<p class='m-b-15'>Changes made to an EC Sales List which had been submitted to HMRC should be reported by " +
      "<a href='https://www.gov.uk/guidance/vat-how-to-report-your-eu-sales#correcting-errors-on-your-esl' target='_blank'>" +
      "informing HMRC</a>.</p>" +
      "<p>Are you sure you want to continue?</p>",
      $('#ec_sales_list_form')
    )
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#ec_sales_list_form').length === 0) { return }
    initEcSalesListForm();
  }));
})();
