FancyEllipsisTooltipComponentState = {
  "isClosed": function(element){
    return FancyEllipsisTooltipComponentGetter.dataOpenAttribute(element) === 'false'; 
  },
  "isOpen": function(element){
    return FancyEllipsisTooltipComponentGetter.dataOpenAttribute(element) === 'true';
  },
  "makeClosed": function(element){
    FancyEllipsisTooltipComponentStyling.closed(element);
    FancyEllipsisTooltipComponentSetter.dataOpenAttribute(element, 'false');
  },
  "makeOpen": function(element){
    FancyEllipsisTooltipComponentStyling.open(element);
    FancyEllipsisTooltipComponentSetter.dataOpenAttribute(element, 'true');
  }
}