(function() {
  'use strict';

  function getCsvTableId() {
    [ 'imported_customer_index_table',
      'imported_supplier_index_table',
      'imported_invoice_index_table',
    ].forEach(function(table_id) {
      if ($('#' + table_id).length > 0) {
        return table_id
      }
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#imported_records.tabbable').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#imported_records',
      tab_map: {
        'check': { options_id: 'check' },
        'confirmed': {
          options_id: 'confirmed',
          table_id: getCsvTableId(),
          multi_sort: true
        }
      }
    });
  }));
})();
