/**
 * @fileoverview We use mousetrap to handle keyboard shortcuts
 *               Note: They only work on fields with the class 'mousetrap'
 */

(function() {
    'use strict';

    window.initLedgerKeyboardCodes = function() {
        Mousetrap.bind('mod+k', function(e, combo) {
            $('#salesledger_tab').click();
            return false;
        });

        Mousetrap.bind('mod+o', function(e, combo) {
            $('#cashbook_tab').click();
            return false;
        });

        Mousetrap.bind('mod+l', function(e, combo) {
            $('#purchaseledger_tab').click();
            return false;
        });
    };

    window.initKeyboardShortcuts = function() {
        // Enters today's date in the current text field
        Mousetrap.bind(['mod+d', 'mod+7'], getMousetrapEventHandler(_setCurrentDate));
        // redirect mod+- to click the remove button
        Mousetrap.bind(['mod+-','mod+8'], getMousetrapEventHandler(_clickRemoveButton));
        // redirect + to insert a new blank row
        Mousetrap.bind(['+','mod+2'], getMousetrapEventHandler(_clickAddButton));
        // redirect mod+/ to duplicate the input directly above the focused one (doesn't work with selects)
        Mousetrap.bind(['mod+6'], getMousetrapEventHandler(_copyInputAbove));
        // redirect (ctrl/cmd)+s to save a form
        Mousetrap.bind(['mod+s','mod+9'], getMousetrapEventHandler(_submitForm));
    };

    function _setCurrentDate($elem) {
        var format = $elem.data('format');

        if (format) {
            $elem.val(formatDate(moment(), format)).change();
        } else {
            $elem.val(formatDateForCompany(moment())).change();
        }
    }

    function _clickRemoveButton($elem) {
        var $currentRow = $elem.closest('tr');
        var $button = $currentRow.find('.mousetrap_remove_row');
        $button.click();
    }

    function _clickAddButton($elem) {
        var $currentRow = $elem.closest('tr');
        var $button =  _findAddNewButton($currentRow);
        $button.click();
    }

    function _findAddNewButton($row) {
        if ($row.find('.mousetrap_add_row').length > 0) {
            return $row.find('.mousetrap_add_row');
        } else {
            return $('.mousetrap_add_row');
        }
    }

    function _copyInputAbove($elem) {
        var closest_row = $elem.closest('tr');
        var selector = "."+ $elem.attr("class").split(' ').join('.');
        duplicateFieldValue(closest_row, selector);
        $elem.next().focus();
    }

    function _submitForm($elem) {
        $elem.closest('form').first().submit();
    }


    function getMousetrapEventHandler(handler_function) {
        return function(e, combo) {
            handleMousetrapEvent(e, handler_function);
        };
    }

    // PANDLE OVERRIDE: Prevent shortcuts to fire when an input element is focused
    function handleMousetrapEvent(e, handler_function) {
        var $elem = getMousetrapEventElement(e);

        if ($elem) {
            // Don't handle the event if it's called from inside an input element
            if ($elem.is('input')) return;

            e.preventDefault();
            handler_function($elem);
        }

        return false;
    }
    // PANDLE OVERRIDE

    function getMousetrapEventElement(e) {
        if (e.srcElement) {
            return $(e.srcElement);
        } else if (e.target) {
            return $(e.target);
        }
    }
})();
