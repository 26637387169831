/* Code that relates to adding taxcode accounts anywhere */
window.checkNeedForNewTaxCodeOption = function(section) {
  if (!window.activeFeatures.FEATURES.includes("custom_taxcodes")){
    return;
  }

  elements = (section ? section.find('select.js-add-new-taxcode') : $('select.js-add-new-taxcode'))

  if (elements.length === 0) { return }
  elements.on('chosen:ready', function(event, chosen) {
    var select = $(event.target);
    var chosen_container = select.siblings('div.chosen-container');
    addNewTaxCodeOption(chosen_container);
    setLinkToAddNewTaxCode(select);
  });
}

function addNewTaxCodeOption(chosen_container) {
  if (chosen_container.find('button.js-new-taxcode-button').length === 0) {
    chosen_container.find('ul.chosen-results').after("<button class='btn btn-primary btn-xs pull-right js-new-taxcode-button' tabindex='-1'>+ Add new</button>")
    registerNewTaxCodeClickListener(chosen_container);
  }
}

function setLinkToAddNewTaxCode(select) {
  select.siblings('a.add-new-taxcode-link').remove();
  select.after("<a href='/tax_codes/new' data-remote='true' class='add-new-taxcode-link' tabindex='-1'><i class='add-new-taxcode-link'></i></a>")
}

function registerNewTaxCodeClickListener(chosen_container) {
  chosen_container.find('button.js-new-taxcode-button').click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    newTaxCodeButtonClicked($(this));
  });
}

function newTaxCodeButtonClicked(button) {
  button.addClass('new-taxcode-button-clicked');
  showNewTaxCodeFormInModal(button);
}

function showNewTaxCodeFormInModal(button) {
  button.closest('div.chosen-container').siblings('a.add-new-taxcode-link').find('i').click();
}
