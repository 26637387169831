(function() {
  'use strict';

  var MAXIMUM_SUBMITTABLE_TRANSACTIONS = 50;

  window.batch_form_paging = {
    updateShowMoreHref : function(rowId) {
      updateBatchFormUrlValues(rowId);
    },

    disableShowMoreButton : function() {
      findShowMoreButton().attr('disabled', true).addClass('disabled');
    },

    enableShowMoreButton : function() {
      findShowMoreButton().attr('disabled', false).removeClass('disabled');
    },

    preventShowingTooManyTransactions : function() {
      var rowCount = checkboxCount();
      if(rowCount > MAXIMUM_SUBMITTABLE_TRANSACTIONS - 10 || rowCount >= getTotalNumberOfTransactions()){
        batch_form_paging.disableShowMoreButton();
        showMoreButtonMessage("Please confirm or delete on-screen transactions before showing more");
      } else {
        batch_form_paging.enableShowMoreButton();
        showMoreButtonMessage("");
      }
    },

    updatePageInfo : function(limit, total) {
      updatePageInfoText(1, limit, total)
      updateSelectedCount();
    }
  };

  function findShowMoreButton() {
    return $memoize('#show-more')
  }

  function checkboxCount() {
    return $('.batch_row').length
  }

  function showMoreButtonMessage(message){
    findShowMoreButton().parent().attr({"title":message})
  }
})();
