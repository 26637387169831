(function() {
  'use strict';

  /*
       * Bootstrap Growl - Notifications popups

  Type: should be info, success, danger, warning or inverse
  Message: the message text
  Title: title text (optional)
  Icon: zmdi icon (optional)
  Url: makes the whole popup a link (optional)

  */
  window.notify = function(message, type, title, icon, url, delay) {
    $.growl(
      {
        message: message,
        icon: icon,
        title: title,
        url: url
      }, {
        type: type,
        allow_dismiss: false,
        label: 'Cancel',
        className: 'btn-xs btn-inverse',
        placement: {
          from: 'top',
          align: 'right'
        },
        delay: delay || 7500,
        animate: {
          enter: 'animated fadeInUp',
          exit: 'animated fadeOut'
        },
        offset: {
          x: 20,
          y: ($(this).scrollTop() > 163) ? 15 : 163
          // 163 = Menu size + 15px
        }
      }
    );
  };

  /*

  Displays a sweetalert confirmation, and only submits the form if the user confirms

  @param alert_text {String} Text (or HTML) to display
  @param $form {jQuery} Form element to submit

  */
  window.confirmFormSubmission = function(alert_text, $form, custom_class) {
    sweetAlert({
      title: 'Warning!',
      text: alert_text,
      html: true,
      type: 'warning',
      allowEscapeKey: false,
      showCancelButton: true,
      customClass: custom_class || ''
    }, function(isConfirmed) {
      if (isConfirmed) {
        $form.submit();
      }
    });
  }

  /*

  Displays a sweetalert error message

  @param alert_text {String} Text (or HTML) to display

  */
  window.showError = function(error_text) {
    sweetAlert({
      title: 'Error',
      text: error_text,
      html: true,
      type: 'warning',
      allowEscapeKey: true,
      showCancelButton: false
    });
  }

  window.sweetDeleteConfirmation = function(button) {
    sweetAlert({
      title: 'Are you sure?',
      text: button.dataset.confirmationMessage,
      html: true,
      type: 'warning',
      allowEscapeKey: true,
      showCancelButton: true,
      customClass: 'sweetalert-pandle-theme',
    }, function(isConfirmed) {
      if (isConfirmed) {
        $.ajax({
          dataType: 'script',
          url: button.href,
          method: button.dataset['method'] || "delete"
        });
      }
    });
  }

  window.loadSweetDeleteActions = function() {
    var $deleteButtons = $("[data-confirmation-style='sweet']");
    if ($deleteButtons.length === 0) { return }
    $deleteButtons.off("click").on("click", function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      sweetDeleteConfirmation(this);
    });
  }
})();
