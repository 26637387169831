(function() {
  'use strict';

  function swapBootgridActionsOnTabSwap(tab) {
    tab.click(function() {
      $('#review_transactions_actions__review_transactions').toggleClass('hidden');
      $('#review_transactions_actions__all_review_transactions').toggleClass('hidden');
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#review_transactions_table').length === 0) { return }
    initBootgridTable($('#review_transactions_table'));
    initBootgridTable($('#all_review_transactions_table'));
    swapBootgridActionsOnTabSwap($('#all_tab'))
    swapBootgridActionsOnTabSwap($('#reviews_tab'))
  }));
})();
