// https://yozudev.atlassian.net/browse/FP-1677
// fixes amountOnly accepting spaces eg spaces being saved to
// redis user setting cache and producing Json::ParseErrors

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  inputMaskAmount(event) {
    this.amountOnly(event)
    this.limitDecimal(event)
  }

  amountOnly(event) {
    const allowedInputValues = /[0-9|.]/
    const notAlphabetKeycode = event.keyCode <= 48
    const isSpace = /\s/ // Farmplan https://yozudev.atlassian.net/browse/FP-1677

    if ((!allowedInputValues.test(event.key) && !notAlphabetKeycode) || isSpace.test(event.key)) {
      event.preventDefault()
    } // Farmplan https://yozudev.atlassian.net/browse/FP-1677
  }

  limitDecimal(event) {
    if (event.keyCode <= 48) { return }
    const inputValue = this.getNewInput(event)
    const decimalIndex = inputValue.indexOf('.')
    if (decimalIndex > 0 && inputValue.substr(decimalIndex).length > 3) {
      event.preventDefault()
    }
  }

  getNewInput(event) {
    const currentValue = event.srcElement.value
    const eventKey = String.fromCharCode(event.charCode || event.keyCode)
    const currentValueArray = currentValue.split('')
    currentValueArray.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), eventKey)
    return currentValueArray.join('')
  }
}
