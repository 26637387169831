import moment from 'moment'

export class DateHelper {
  constructor(date, format = 'DD/MM/YYYY') {
    this.date = localizeFromString(date, format)
  };

  addYear() {
    this.date = moment(this.date).add(11, 'months')
    return this
  };

  subtractYear() {
    this.date = moment(this.date).subtract(11, 'months')
    return this
  };

  toDate() {
    return this.date
  };

  toISOFormat() {
    return moment(this.date).format('YYYY-MM-DD')
  };

  toMonthFormat() {
    return moment(this.date).format('MM/YYYY')
  }
};

function localizeFromString(date, format) {
  const parts = date.split('/')

  if (format === 'MM/DD/YYYY') { return new Date(date) };

  if (format === 'MM/YYYY') {
    const year = parts[1]
    const month = parts[0] - 1
    const day = '01'

    return new Date(year, month, day)
  };

  const year = parts[2]
  const month = parts[1] - 1
  const day = parts[0]

  return new Date(year, month, day)
};

export function splitDateToFormatArray(date, separator, splitFormat) {
  const newDateArray = []
  date.split(separator).forEach(function (dateValue, index) {
    const newDateVale = getDateValueForArray(dateValue, index, splitFormat)
    if (!isNaN(newDateVale)) { newDateArray.push(newDateVale) }
  })
  return newDateArray
}

function dateIsInRangeAndUnitIsNotYear(date, unit) {
  return (unit !== 'yyyy' && date > 0 && date <= 9)
}

function getDateValueForArray(dateValue, index, splitFormat) {
  const formatUnit = splitFormat[index].toLowerCase()
  let dateAsInt = parseInt(dateValue)
  if (!isNaN(dateAsInt) && dateIsInRangeAndUnitIsNotYear(dateAsInt, formatUnit)) {
    dateAsInt = '0' + dateAsInt
  }
  return dateAsInt
}

export function getSeparatorFromDateFormat(dateFormat) {
  return dateFormat.replaceAll(/[^/|-]/g, '')[0]
}
