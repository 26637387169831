(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#csv_imports_index_table').length === 0) { return }
    initBootgridTable($('#csv_imports_index_table'), -1, true, [
      { id : 'show_customer_import', name : 'Customer Import', visible : true },
      { id : 'show_supplier_import', name : 'Supplier Import', visible : true },
      { id : 'show_customer_invoice_import', name : 'Customer Invoice Import', visible : true },
      { id : 'show_supplier_invoice_import', name : 'Supplier Invoice Import', visible : true }
    ]);
  }));
})();
