/**
 * Add a change listener to the given element.
 *
 * When the event is triggered, check whether the callback
 * evaluates to true, i.e. the error message should be displayed,
 * and if so display the error message in the help pane.
 *
 * @param element   Element that the error will be checked on
 * @param hasError  Callback that evaluates to true if the error should be displayed
 * @param errorMessage  Error message to display if the callback evaluates to true
 */
window.addErrorListener = function(element, hasError, errorLevel, errorMessage) {
  $(element).change(function(event) {
    if (hasError(event)) {
      addHelpMessage(element, errorLevel, errorMessage);
    }
  });
}

window.addErrorListenerWithTitle = function(element, hasError, errorLevel, errorTitle, errorMessage) {
  $(element).change(function(event) {
    if (hasError(event)) {
      addHelpMessageWithTitle(errorTitle, errorLevel, errorMessage);
    }
  });
}
