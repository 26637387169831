(function() {
  'use strict';

  // Methods to do useful things involving checkboxes

  var SELECTED_PATTERN = /\d+/;

  window.updateSelectedCount = function() {
    var $elem = $('#selected_count');
    updateSelectedCountContentAndVisibility($elem, $elem.text(), getSelectedCount());
  }

  function getSelectedCount() {
    return $('.checkboxes:checked').not(':disabled').length
  }

  function updateSelectedCountContentAndVisibility($elem, text, count) {
    if (count > 0) {
      $elem.text(text.replace(SELECTED_PATTERN, count));
      bootstrap.show($elem);
    } else {
      bootstrap.hide($elem);
    }
  }

  /**
    * Global helper to show a section of a page when a checkbox is checked,
    * and hide it when unchecked
    *
    * @param parent_selector {string} jQuery selector for the parent containing the checkbox and section
    * @param checkbox_selector {string} jQuery selector for the checkbox
    * @param section_selector {string} jQuery selector for the section to show/hide
   * @param fields_selector {string} (Optional) jQuery selector for the form fields to enable/disable (if not inside
   *   section_selector)
  */
  window.showOrHideSectionWithCheckbox = function(parent_selector, checkbox_selector, section_selector, fields_selector) {
    var $parent = $(parent_selector);
    var disabled = !$parent.find(checkbox_selector).is(':checked');
    var $section = $parent.find(section_selector);
    var $fields_container = typeof(fields_selector) === 'undefined' ? $section : $(fields_selector);

    if ($section.is(':visible') == disabled) {
      $section.slideToggle();
    }

    $fields_container.find('input:not(.always-disabled, .hidden_date_format_field)').prop('disabled', disabled);
    $fields_container.find('textarea').prop('disabled', disabled);
    $fields_container.find('select').prop('disabled', disabled).trigger('chosen:updated');
  }

  window.showOrHideAndNullSectionWithCheckbox = function(parent_selector, checkbox_selector, section_selector, fields_selector) {
    var $parent = $(parent_selector);
    var disabled = !$parent.find(checkbox_selector).is(':checked');
    var $section = $parent.find(section_selector);
    var $fields_container = typeof(fields_selector) === 'undefined' ? $section : $(fields_selector);

    if ($section.is(':visible') == disabled) {
      $section.slideToggle();
    }

    if (disabled) {
      $fields_container.find('input:not(.always-disabled, .hidden_date_format_field)').val(null);
      $fields_container.find('textarea').val(null);
      $fields_container.find('select').val(null).trigger('chosen:updated');
    }

    $fields_container.find('input:not(.always-disabled, .hidden_date_format_field)').prop('disabled', disabled);
    $fields_container.find('textarea').prop('disabled', disabled);
    $fields_container.find('select').prop('disabled', disabled).trigger('chosen:updated');
  }

  window.checkboxReadOnly = function($checkbox, readOnly){
    var $hiddenHelper = $checkbox.parent().find(':hidden');
    if (readOnly) {
      $hiddenHelper.val($checkbox.val());
      $checkbox.prop('disabled','true');
    }else{
      $hiddenHelper.val("0");
      $checkbox.removeProp('disabled');
    }
  }

  window.addCheckboxAreYouSure = function($checkbox, message){
    $checkbox.click(areYouSureThisIsFinal(message))
  }

  /* FARMPLAN OVERRIDE */
  window.addCheckboxAreYouDoubleSure = function($checkbox, message, second_message) {
    $checkbox.click(areYouDoubleSureThisIsFinal(message, second_message))
  }

  function areYouSureThisIsFinal(message){
    return function(event){
      if ( $(event.target).is(':checked') ){
        return confirmCheckboxSubmission(message, $(event.target));
      } else {
        // It was checked, and we're unchecking - no confirmation needed
        return true;
      }
    }
  }

  /* FARMPLAN OVERRIDE */
  function areYouDoubleSureThisIsFinal(message, second_message) {
    return function(event){
      if ($(event.target).is(':checked')) {
        return doubleConfirmCheckboxSubmission(message, $(event.target), second_message)
      } else {
        // It was checked, and we're unchecking - no confirmation needed
        return true
      }
    }
  }

  /*

  Displays a sweetalert confirmation, and only leaves the checkbox checked if the user confirms

  @param alert_text {String} Text (or HTML) to display
  @param $checkbox {jQuery} Checkbox to check they meant to check

  */
  window.confirmCheckboxSubmission = function(alert_text, $checkbox, css) {
    sweetAlert({
      title: 'Warning!',
      text: alert_text,
      html: true,
      type: 'warning',
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: 'sweetalert-scary-warning ' + css
    }, function(isConfirmed) {
      if ( isConfirmed ){
        return true;
      } else {
        $checkbox.removeAttr('checked');
      }
    });
  }

  /* FARMPLAN OVERRIDE*/
  window.doubleConfirmCheckboxSubmission = function(alert_text, $checkbox, second_alert_text) {
    sweetAlert({
      title: 'Warning!',
      text: alert_text,
      html: true,
      type: 'warning',
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: 'sweetalert-scary-warning'
    }, function(isConfirmed) {
      if ( isConfirmed ){
        setTimeout(
          () => window.confirmCheckboxSubmission(
            second_alert_text, $checkbox, 'sweetalert-extra-scary-warning'
          ), 500
        )
      } else {
        $checkbox.removeAttr('checked');
      }
    });
  }

})();
