(function() {
  'use strict';

  window.initCustomerModal = function() {
    verifyAllEmailInputs();
    $('#has_invoice_ref_prefix').on('click', setInvoiceRefVisibility);
    $('#customer_uses_eu_vat').on('click', onEuVatChecked);
    $('#default_reminder_check').on('click', reminderAlertVisible);
    $('#cs_status_checkbox').on('click', function() { initRecurringTransactionsAlertModal("customer") });
    $('.modal #customer_name').on('change', customerNameChangeModal);
    $('.modal #company_selection_field').on('change', customerNameChangeModal);
    $('.js-input_mask_country_for_postcode').on('change', customerSupplierCountryChange);
    $('#customer_country_code').on('change', setAddressCountryToMatchEuVatCountry);
    $('#customer_currency_id').chosen().change(changeCreditLimitLabel)
    initCompaniesHouseSearch($('#customer_name'), autoFillFields, resetFields)
    submitOnEnterPress('#new_customer_form'); // Submit the customer form, not the hidden new nominal account form
    initCollapse($('#new_customer_form'));
    initBootstrapWizard();
    initTabChangeHandler('#new_customer_form');
    checkCustomerInCompanyHouseAndHideVatField();
    initChosenDropdown($('#customer_name'), $('#companies_house_search'));
    initListenerForTelephoneField($('#customer_telephone_number'));
  };

  function autoFillFields(){
    var reg_val = $('#companies_house_search_select_field').find('option:selected').data('company-number')
    callCompanyInfoEndpoint(reg_val, setAddressFields, resetContactFields);
  }

  function changeCreditLimitLabel(event) {
    var currencyId = $(event.target).val()

    if (!currencyId) return "Credit Limit"

    var url = "/financial/currencies/" + currencyId + "/get_symbol.json"
    getJsonOrShowMaintenanceModal(url, function(response) {
      var creditLimitCurrency = "Credit Limit (" + response.currency_symbol + ")";
      $('#credit_limit_label').text(creditLimitCurrency)
    })
  }

  function onEuVatChecked() {
    setVatNumberVisibilityCustomer()
    warnUnitedKingdomEuSelection()
  }

  function setAddressFields(response) {
    var $line1 = $('#customer_address_attributes_address_line_1');
    var $line2 = $('#customer_address_attributes_address_line_2');
    var $postcode = $('#customer_address_attributes_postcode');
    var $locality = $('#customer_address_attributes_town_city');
    autofillAddressWithCompaniesHouseResponse($line1, $line2, $postcode, $locality, response);
    autoFillContactNameWithFirstOfficer($('#customer_contact_name'), response['officers']['active_officers']);
    setEuVatCheckboxVisibility(false);
    setVatNumberVisibilityCustomer();
  }

  function resetFields() {
    setEuVatCheckboxVisibility(true);
  }

  function customerNameChangeModal() {
    if ($(this).val() != 'nil'){
      csNameChangeModal('customer', $(this).val());
    }
  }

  function setVatNumberVisibilityCustomer() {
    var disabled = !$('.modal').find('#customer_uses_eu_vat').is(':checked')

    if (disabled) {
      $('.modal').find('#customer_vat_number').val('');
      $('#customer_country_code')
        .find('option:selected')
        .removeAttr('selected')
        .trigger('chosen:updated');
    }

    $('.modal').find('.eu_vat_fields input').prop('disabled', disabled);
    if ($('.modal').find('#eu_vat_field').is(':visible') === disabled) {
      $('.modal').find('#eu_vat_field').slideToggle();
    }
  }

  function setAddressCountryToMatchEuVatCountry(event) {
    var country_code = $(event.target).val();
    $('#customer_address_attributes_country')
      .val(country_code)
      .trigger('chosen:updated')
      .trigger('change');
  }

  function reminderAlertVisible() {
    var $reminder_checkbox = $('.modal').find('#default_reminder_check'),
    $reminder_alert = $('.modal').find('#default_reminder_check_alert'),
    checked = $reminder_checkbox.is(':checked');
    if ( $reminder_alert.length == 0 ) { return; }

    if (checked) {
      $reminder_alert.removeClass("hidden");
    } else {
      $reminder_alert.addClass("hidden");
    }
  }

  function checkCustomerInCompanyHouseAndHideVatField() {
    var $customerNameField = _getCustomerNameField();
    if ($customerNameField.length == 0) { return; }
    if ($customerNameField.val().length > 0) { callCompaniesSearchEndpoint($customerNameField.val(), hideCheckboxIfCompanyInResponse, null); }
  }

  function hideCheckboxIfCompanyInResponse(response) {
    var companies = response['companies'];
    var $customerNameField = _getCustomerNameField();
    companies.forEach(function (s) {
      if (s.company_name.toLowerCase() == $customerNameField.val().toLowerCase()) {
        $('.modal').find('#eu_vat_checkbox_field').hide();
      }
    });
  }

  function _getCustomerNameField() {
    return $('.modal #customer_name');
  }
})();
