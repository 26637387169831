CreditableInvoiceModalComponent = {
  "renderInvoiceStatuses": function(statuses, elementId) {
    return InvoiceStatusIndicatorComponent.render(statuses, elementId)
  },
  'renderDescriptionCell': function(invoiceDescription, lineItemDescriptions) {
    var invoiceDescriptionTooltip = '';
    if (StringHelper.isBlank(invoiceDescription) == false) {
      invoiceDescriptionTooltip = FancyEllipsisComponent.renderAsPlaintextTooltipSection('Invoice Description', invoiceDescription);
    }

    var lineItemDescriptionsTooltip = '';
    if (lineItemDescriptions.length >= 2) {
      var lineItemDescriptionDictionary = lineItemDescriptions.map(function(lineItemDescription, index) {
        var dictionaryKey = 'Line Item ' + (index + 1) ;
        return [dictionaryKey, lineItemDescription];
      });

      lineItemDescriptionsTooltip = FancyEllipsisComponent.renderAsDictionaryTooltipSection('Line Item Descriptions', lineItemDescriptionDictionary);
    }

    var fancyEllipsisTooltipContent = invoiceDescriptionTooltip + lineItemDescriptionsTooltip;
    return FancyEllipsisComponent.render(invoiceDescription, fancyEllipsisTooltipContent);
  },
  "removeRedundantDescriptionEllipsisIcons": function(tableId) {
    var rawRowData = $("#" + tableId).bootgrid("getCurrentRows");

    if (rawRowData && rawRowData.length > 0) {
      TableHelper
        .getTableRows(tableId)
        .each(function(index, tableRow) {
          var descriptionElement = CreditableInvoiceModalTableComponent.getDescriptionElementFromRow(tableRow)
          var visibleDescriptionTextElement = FancyEllipsisComponent.getVisibleText(descriptionElement);

          var invoiceHasNoLineItemDescriptions = rawRowData[index]['line_item_descriptions'].length === 0;
          var invoiceDescriptionWillFitInCellWidth = TableHelper.doesCellContentOverflowCell(visibleDescriptionTextElement);

          if(invoiceHasNoLineItemDescriptions && invoiceDescriptionWillFitInCellWidth){
            FancyEllipsisComponent.removeIconAndTooltip(descriptionElement);
          }
        })
      ;
    }
  },
  "setupCreditedInvoiceClientSideTracking": function(tableId){
    var tableBodyElement = TableHelper.getBody(tableId);
    EventDelegation.create(tableBodyElement, 'click', CheckboxIndicator.jQuerySelector, function(event){
      var currentRow = TableHelper.getRowThatElementIsContainedIn(event.target)
      var currentRowIndex = $(currentRow).index();
      var rawRowDataFromServer = $('#' + tableId).bootgrid("getCurrentRows")[currentRowIndex];
      var rawCreditTotal = CreditableInvoiceModalTableComponent.getCreditTotalElementFromRow(currentRow).value
      var creditTotalAsFloat = StringHelper.isBlank(rawCreditTotal) ? 0 : parseFloat(rawCreditTotal);
      var currentInvoiceCreditData = CreditedInvoiceDataHandler.tempInvoices.getData(rawRowDataFromServer.id);
      if(currentInvoiceCreditData){
        var currentCreditForInvoice = currentInvoiceCreditData['credit_total'];
        if(currentCreditForInvoice){
          CreditedInvoiceDataHandler.creditTotal.subtractAmount(currentCreditForInvoice);
        }
      }

      CreditedInvoiceDataHandler.creditTotal.addAmount(creditTotalAsFloat);
      rawRowDataFromServer['credit_total'] = creditTotalAsFloat

      CreditedInvoiceDataHandler.tempInvoices.setData(rawRowDataFromServer)
      var count = Object.keys(CreditedInvoiceDataHandler.tempInvoices.getHash()).length
      CreditableInvoiceModalComponentInvoiceSelectedCount.updateInvoiceSelectedCount($('.modal-items-selected'), count, " Items Selected")
      CreditableInvoiceModalComponentInvoiceSelectedCount.enableDisableConfirmButton(count);
    });
  },
  "checkInvoicesPreviouslySelected": function(tableId){
    $('#' + tableId).bootgrid("getCurrentRows").forEach(function(element, index) {
      var previousInvoiceCreditData = CreditedInvoiceDataHandler.tempInvoices.getData(element.id);
      if(previousInvoiceCreditData){
        var tableRow = TableHelper.getRow(tableId, index) // Header is a row, so add 1 here :)
        CreditableInvoiceModalTableComponent.setSelectedCheckboxForRow(tableRow, true);
      }
    })
  }
}
