import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['groups', 'tags', 'disableIfBlankGroup']
  static values = {
    tags: String,
    selectedId: String,
    blankTagsName: String
  }

  initialize() {
    $(this.groupsTarget).chosen({ width: '100%', search_contains: true })
    $(this.groupsTarget).on('change', (event) => this.update(event))
    $(this.tagsTarget).chosen({ width: '100%', search_contains: true })
    $(this.tagsTarget).on('change', (event) => this.updateSelectedId(event))

    this.tagsValue = this.tagsTarget.innerHTML

    if (this.groupsTarget.value === '') {
      this.disableTagSelect()
    } else {
      this.groupsTarget.dispatchEvent(new Event('change'))
    }
  }

  update(event) {
    const group = event.target.selectedOptions[0]
    if (group.value === '') {
      this.tagsTarget.innerHTML = this.blankTagsOption()
      this.disableTagSelect()

      this.disableThingsWhichShouldBeDisabledWhenGroupIsBlank()
    } else {
      const temp = document.createElement('div')
      temp.innerHTML = this.tagsValue
      const tagOptions = Array.from(temp.children)
      const filteredTags = tagOptions.filter(element => element.label === group.text)

      if (!filteredTags.length) return

      filteredTags[0].prepend(this.blankTagsOption())

      this.selectedIdValue = filteredTags[0].firstElementChild.value
      this.tagsTarget.innerHTML = filteredTags[0].innerHTML
      this.tagsTarget.disabled = false
      $(this.tagsTarget).trigger('chosen:updated')

      this.enableThingsWhichShouldBeEnabledWhenGroupIsNotBlank()
    }
  }

  updateSelectedId(event) {
    if (event.target.value) {
      this.selectedIdValue = event.target.value
      $(this.tagsTarget).trigger('chosen:updated')
    }
  }

  blankTagsOption() {
    const blank = document.createElement('option')
    blank.textContent = this.blankTagsNameValue
    blank.value = ''
    return blank
  }

  disableTagSelect() {
    this.tagsTarget.disabled = true
    $(this.tagsTarget).trigger('chosen:updated')
  }

  disableThingsWhichShouldBeDisabledWhenGroupIsBlank() {
    this.disableIfBlankGroupTargets.forEach(function (target) {
      target.disabled = true
    })
  }

  enableThingsWhichShouldBeEnabledWhenGroupIsNotBlank() {
    this.disableIfBlankGroupTargets.forEach(function (target) {
      target.disabled = false
    })
  }
}
