export function initBankAccountWidgetClickEvent() {
  if (bankAccountChart().length === 0) { return }
  initBarClickListener()
  initLabelClickListener()
  $('#bank_account_chart_loader').show()
}

function initBarClickListener() {
  bankAccountChart().bind('plotclick', function (_event, _, item) {
    if (item) {
      const bankAccountId = bankAccountIds()[_getItemIndex(item)][1]
      openBankAccountModal(bankAccountId)
    }
  })
}

function _getItemIndex(item) {
  const MAX_ITEMS_BEFORE_ZERO_INDEX = 4
  if (bankAccountIds().length > MAX_ITEMS_BEFORE_ZERO_INDEX) {
    return item.dataIndex
  } else {
    return item.dataIndex - 1
  }
}

function initLabelClickListener() {
  bankAccountChart().find('.xAxis > div').each(function (_, element) {
    $(element).on('click', function (event) {
      const selectedBankAccountName = $(event.target).text()
      bankAccountIds().forEach(function (bankAccountArray) {
        if (bankAccountArray[0] === selectedBankAccountName) {
          openBankAccountModal(bankAccountArray[1])
          return true
        }
      })
    })
  })
}

function bankAccountChart() {
  return $('#bank_account_chart')
}

function bankAccountIds() {
  return bankAccountChart().data('value')[0].bank_account_ids
}

function openBankAccountModal(bankAccountId) {
  $.ajax({ url: 'bank_accounts/' + bankAccountId + '/transactions', method: 'GET', dataType: 'script' })
}
