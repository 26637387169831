(function() {
  'use strict';

  // Function to initialize a Bootstrap Wizard
  // Optionally pass in a function that should be executed when a tab is shown
  window.initBootstrapWizard = function(on_tab_show_function) {
    $('.form-wizard-basic').bootstrapWizard({
      onTabShow: function(tab, navigation, index) {
        _wizardProgressBar(navigation, index);

        if ( typeof on_tab_show_function !== 'undefined' ){
          on_tab_show_function(tab, navigation, index);
        }
      },
      tabClass: 'fw-nav',
      'nextSelector': '.next',
      'previousSelector': '.previous',
      'finishSelector': '.finish'
    });
    _focusFirstTabWithError();
  };

  function _wizardProgressBar(navigation, index) {
    var total = navigation.find('li').length;
    var current = index + 1;
    var percent = (current / total) * 100;
    $('.form-wizard-basic').find('.bar').css({ width: percent + '%' });
  };

  function _focusFirstTabWithError() {
    // This method isn't very nice :( but the plugin doesn't currently expose any nicer way

    var first_problem_field;

    if ($('#error_explanation').length > 0) {
      first_problem_field = $('#error_explanation');
    } else {
      return;
    }

    var first_problem_step = $(first_problem_field).closest('.tab-pane');
    var step_id = $(first_problem_step).attr('id');

    $("a[href*='"+step_id+"']").click();
  };
})();
