(function() {
  'use strict';

  /*
  * FieldStatesBatchInvoiceRow takes a row from the batch invoice form, checks various values and uses them to set flags
  * It is used by the FieldStateController code
  */
  window.FieldStatesBatchInvoiceRow = function($row) {
    this._rules = window.invoice_field_enabling_rules;
    this._row = $row;
    this._rowHasAlreadyBeenInitialized = true;
    this._isSplit = null;
    this._isImported = null;
    this._isCreditNote = null;
    this._isMultiCurrency = null;
    this._isObw = null;
    this._canBeSplit = null;
    this._mustBeSplit = null;
    this._$fields = {};
    this._taxRateIsZero = null;
  };

  /*
  * Call this method when a change has occurred in the form, to update fields according to the rules
  *
  * @param {jQuery} event_target
  */
  FieldStatesBatchInvoiceRow.prototype.update = function(event_target) {
    if ( this._row ) {
      this._setFlagsFromInvoiceRow(event_target);
    }
  };

  FieldStatesBatchInvoiceRow.prototype.after_update = function(target) {
    // Nothing needed here
  }

  FieldStatesBatchInvoiceRow.prototype.getFlagValue = function(flag) {
    return this['_'+flag];
  }

  FieldStatesBatchInvoiceRow.prototype.getField = function(selector) {
    if(!this._$fields[selector]) {
      this._$fields[selector] = this._row.find(selector);
    }
    return this._$fields[selector];
  }

  FieldStatesBatchInvoiceRow.prototype.getSplitField = function() {
    return this.getField('#is_split_field');
  }

  FieldStatesBatchInvoiceRow.prototype.getRules = function() {
    return this._rules;
  }

  /*
  * Sets flags from the invoice row
  * @see {http://goo.gl/GFf3Av} for the equivalent implementation in Ruby
  */
  FieldStatesBatchInvoiceRow.prototype._setFlagsFromInvoiceRow = function(event_target) {
    this._isSplit = this._checkIsSplit(event_target);
    this._isImported = this._checkIsImported();
    this._isCreditNote = this._checkIsCreditNote();
    this._isMultiCurrency = this._checkIsMultiCurrency();
    this._isObw = this._checkIsObw();
    this._canBeSplit = this._checkCanBeSplit();
    this._mustBeSplit = this._checkMustBeSplit();
    this._taxRateIsZero = this._toggleTaxAmountReadOnlyBasedOnTaxRate();
  }

  FieldStatesBatchInvoiceRow.prototype._checkIsSplit = function(event_target) {
    if ($(event_target).closest('.split_row').length) {
      var split_field = $(event_target)
        .closest('.batch_row')
        .find('#is_split_field')
    } else {
      var split_field = this.getSplitField()
    }
    return split_field.val() === 'true';
  }

  FieldStatesBatchInvoiceRow.prototype._checkIsImported = function() {
    var importedIvoiceId = $('.imported_invoice_id')
    if ( importedIvoiceId.val() != undefined ) {
      return importedIvoiceId.length > 0
    }
  }

  FieldStatesBatchInvoiceRow.prototype._checkIsMultiCurrency = function() {
    var $currency_field = this.getField('.bt_currency_field');
    if ( !$currency_field.length ) {
      return false
    } else {
      var base_currency_id = companySettings.base_currency.id;
      return !conversionRateFieldShouldBeDisabled(base_currency_id, $currency_field.val(), base_currency_id)
    }
  }

  FieldStatesBatchInvoiceRow.prototype._checkIsCreditNote = function() {
    return [SALES_CREDIT_NOTE, PURCHASE_CREDIT_NOTE].includes(this.getField('.invoice_type').val());
  }

  FieldStatesBatchInvoiceRow.prototype._checkIsObw = function() {
    if (this._isCreditNote) { return false }
    return this.getField('.obw_invoice').val() === 'true';
  }

  FieldStatesBatchInvoiceRow.prototype._checkCanBeSplit = function() {
    return !this._isCreditNote // is invoice
      || this._getSelectedInvoiceRef().data('suspenseInvoice') === true // is credit note against OBW invoice
      || this._getSelectedInvoiceRef().data('isSplit') === true; // is credit note against split invoice
  }

  FieldStatesBatchInvoiceRow.prototype._checkMustBeSplit = function() {
    return this._isSplitModalRow() || (this._isCreditNote && this._getSelectedInvoiceRef().data('isSplit') === true);
  }

  FieldStatesBatchInvoiceRow.prototype._isSplitModalRow = function() {
    return this._row.hasClass("split_row");
  }

  FieldStatesBatchInvoiceRow.prototype._getSelectedInvoiceRef = function() {
    return this.getField('#invoice_ref_selector').find('option:selected');
  }

  FieldStatesBatchInvoiceRow.prototype._toggleTaxAmountReadOnlyBasedOnTaxRate = function() {
    if (this._isSplit) {
      var taxRateField = this.getField('.split_tax_code_field');
    } else {
      var taxRateField = this.getField('#invoices__tax_code_id');
    }
    var taxRate = getTaxRateAsFloat(taxRateField);
    return (taxRate == 0);
  }
})();
