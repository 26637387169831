/* Code that relates to adding project accounts anywhere */
window.checkNeedForNewProjectOption = function(section) {
  if (section) {
    var elements = section.find('select.js-add-new-project');
  } else {
    var elements = $('select.js-add-new-project');
  }

  if (elements.length === 0) { return }
  elements.on('chosen:ready', function(event, chosen) {
    var select = $(event.target);
    var chosen_container = select.siblings('div.chosen-container');
    addNewProjectOption(chosen_container);
    setLinkToAddNewProject(select);
  });
}

function addNewProjectOption(chosen_container) {
  if (chosen_container.find('button.js-new-project-button').length === 0) {
    chosen_container.find('ul.chosen-results').after("<button class='btn btn-primary btn-xs pull-right js-new-project-button' tabindex='-1'>+ Add new</button>")
    registerNewProjectClickListener(chosen_container);
  }
}

function setLinkToAddNewProject(select) {
  select.siblings('a.add-new-project-link').remove();
  select.after("<a href='/projects/new' data-remote='true' class='add-new-project-link' tabindex='-1'><i class='add-new-project-link'></i></a>")
}

function registerNewProjectClickListener(chosen_container) {
  chosen_container.find('button.js-new-project-button').click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    newProjectButtonClicked($(this));
  });
}

function newProjectButtonClicked(button) {
  button.addClass('new-project-button-clicked');
  showNewProjectFormInModal(button);
}

function showNewProjectFormInModal(button) {
  button.closest('div.chosen-container').siblings('a.add-new-project-link').find('i').click();
}
