(function () {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function () {
    if ($('#new_paypal_feed_import').length === 0) {
      return;
    } else {
      onceAfterDatepickersComponentHaveBeenInitialised(function(){
        var $new_paypal_feed_import = $('#new_paypal_feed_import'),
            $pull_from = getDateTimePicker($new_paypal_feed_import.find('#paypal_feed_import_date_start')),
            $locked_date = $('#company-locked-date').text();

        displayErrorIfDateBeforeLockedDate($new_paypal_feed_import, $locked_date, $pull_from);
      });
    }
  }));
})();
