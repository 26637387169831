/**
 * Creates an object for detecting form changes. Written as an ES5 class here
 * so we can clean up the event listeners after usage.
 *
 * We may also want to extend this behaviour, e.g. by supporting different
 * types of components, or choosing which fields to observe.
 *
 * @param formParent {jQuery} Jquery instance of the modal
 * @constructor
 */
window.FormChangeObserver = function(formParent) {
  this.formParent = this._validateFormParent(formParent);
  this._isChanged = false;
  this._isSubmitted = false;
  this._listeners = [];
  this._isDisposed = false;
  this.addEventListeners();
}
/**
 * Does the user have unsaved data in the form?
 * @returns {boolean}
 */
FormChangeObserver.prototype.isStale = function() {
  return (this._isChanged && !this._isSubmitted);
};
/**
 * Currently we handle all change events that bubble from input elements and
 * datetimepickers.
 * @protected
 */
FormChangeObserver.prototype.addEventListeners = function() {
  this._addEventListener('submit', this._onSubmit);
  this._addEventListener('change', this._onChange);
  this._addEventListener('dp.change', this._datepickerOnChange);
};
/**
 * Removes all event listeners and deletes the object's keys for garbage
 * collection.
 *
 * N.B. This should always be called after the observer has been used.
 */
FormChangeObserver.prototype.dispose = function() {
  if(!this._isDisposed) {
    this._removeListeners();
    this._deleteKeys();
    this._isDisposed = true;
  }
};
/**
 * Ensures that only one JQuery element is given
 * @param formParent {jQuery}
 * @returns {jQuery}
 * @private
 */
FormChangeObserver.prototype._validateFormParent = function(formParent) {
  if(!(formParent instanceof jQuery)) {
    throw new Error('formParent ' + formParent + 'is not a JQuery object!');
  } else if(formParent.length !== 1) {
    throw new Error('Exactly one form element should be given. Received formParent  ' + formParent + ' with length ' + formParent.length);
  }

  return formParent;
}
/**
 * @param eventType
 * @param fn
 * @private
 */
FormChangeObserver.prototype._addEventListener = function(eventType, fn) {
  fn = fn.bind(this);
  this._listeners.push({ eventType: eventType, fn: fn });
  this.formParent.on(eventType, fn);
};
/**
 * @returns {function(this:FormChangeObserver)}
 * @private
 */
FormChangeObserver.prototype._onSubmit = function() {
  this._isSubmitted = true;
};
/**
 * @returns {function(this:FormChangeObserver)}
 * @private
 */
FormChangeObserver.prototype._onChange = function() {
  this._isChanged = true;
};
/**
 * @returns {function(this:FormChangeObserver)}
 * @private
 */
FormChangeObserver.prototype._datepickerOnChange = function(e) {
  if ( !this._datepickerIsBeingInitialised(e) ) {
    this._onChange();
  }
};
/**
 * See https://github.com/Eonasdan/bootstrap-datetimepicker/issues/1118
 * Ignores the 'change' event if this is just initialising a datepicker
 * field that already has a value
 * @returns {function(this:FormChangeObserver)}
 * @private
 */
FormChangeObserver.prototype._datepickerIsBeingInitialised = function(e) {
  var old_date_is_null = e.oldDate === null;
  var datepicker_tf = $(e.target).find('.form-control:first');
  var dp_val_is_orig_val = datepicker_tf && datepicker_tf.val() === datepicker_tf.prop("defaultValue");
  return old_date_is_null && dp_val_is_orig_val;
};
/**
 * @private
 */
FormChangeObserver.prototype._removeListeners = function() {
  this._listeners.forEach(this._removeEventListener, this);
  this._listeners = [];
};
/**
 * @param binding {Object}
 */
FormChangeObserver.prototype._removeEventListener = function(binding) {
  this.formParent.off(binding.eventType, binding.fn);
};
/**
 *
 * @private
 */
FormChangeObserver.prototype._deleteKeys = function() {
  Object.keys(this).forEach(function(k) {
    delete this[k];
  }, this);
};