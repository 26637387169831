/* Code that relates to adding customer accounts anywhere */
window.checkNeedForNewCustomerOption = function(section) {
  if (section) {
    var elements = section.find('select.js-add-new-customer');
  } else {
    var elements = $('select.js-add-new-customer');
  }

  if (elements.length > 0) {
    elements.on("chosen:ready", function(event, chosen) {
      var select = $(event.target);
      var chosen_container = select.siblings("div.chosen-container");
      addNewCustomerOption(chosen_container);
      setLinkToAddNewCustomer(select);
    });
  }
}

window.addNewCustomerOption = function(chosen_container) {
  if (chosen_container.find('button.js-new-customer-button').length === 0) {
    if (chosen_container.find('div.customer-select-custom-options').length === 0) {
      createCustomOptionsDiv(chosen_container);
    }
    chosen_container.find('div.customer-select-custom-options').append("<button class='btn btn-primary btn-xs pull-right m-r-5 js-new-customer-button' tabindex='-1'>+ Add new</button>")
    registerNewCustomerClickListener(chosen_container);
  }
}

window.setLinkToAddNewCustomer = function(select) {
  select.siblings("a.add-new-customer-link").remove();
  select.after("<a href='/customers/new' data-remote='true' class='add-new-customer-link' tabindex='-1'><i class='add-new-customer-link'></i></a>")
}

function registerNewCustomerClickListener(chosen_container) {
  chosen_container.find("button.js-new-customer-button").click(function() {
    $(".chosen-with-drop").removeClass('chosen-with-drop');
    newCustomerButtonClicked($(this));
  });
}

function newCustomerButtonClicked(button) {
  button.addClass("new-customer-button-clicked");
  showNewCustomerFormInModal(button);
}

function showNewCustomerFormInModal(button) {
  button.closest("div.chosen-container").siblings("a.add-new-customer-link").find('i').click();
}
