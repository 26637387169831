import CONVERTER from './_bootgrid_converters.js'

window.initTrialBalanceDataTable = function() {
  $('#trial_balance_report_table').bootgrid({
    css: {
      icon: 'zmdi icon',
      iconColumns: 'zmdi-view-module',
      iconDown: 'zmdi-caret-down',
      iconRefresh: 'zmdi-refresh',
      iconUp: 'zmdi-caret-up'
    },
    columnSelection: false,
    navigation: 0,
    rowCount: -1,
    converters: {
      numeric_ignore_zero: CONVERTER.numeric_ignore_zero,
      order_by_link_text: CONVERTER.order_by_link_text
    },
    labels: { noResults: ' ' }
  });
}