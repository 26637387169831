import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  check(event) {
    const url = this.data.get('url')
    const id = this.data.get('id')

    if (event.target.checked) {
      fetch(url)
        .then(response => response.json())
        .then(json => {
          json.warnings.forEach(warning => {
            this.warningsContainer().appendChild(this.warningDiv(warning, id))
          })
        })
    } else {
      this.removeWarningsWithActivityId(id)
      this.checkboxTargets.forEach((checkbox) => {
        this.removeWarningsWithActivityId(checkbox.id)
      })
    }
  }

  warningDiv(warning, id) {
    const div = document.createElement('div')
    div.setAttribute('data-activity-id', this.farmingActivityId(id))
    div.setAttribute('class', 'bg-yellow text-center text-white p-2 mb-2')
    div.innerHTML = warning
    return div
  }

  warningsContainer() {
    return document.getElementById('farming-activity-warnings')
  }

  farmingActivityId(id) {
    return 'farming_activity_id_' + id
  }

  removeWarningsWithActivityId(id) {
    const toRemove = this.warningsContainer().querySelectorAll("div[data-activity-id='" + this.farmingActivityId(id) + "']")
    toRemove.forEach(e => e.parentNode.removeChild(e))
  }
}
