var personal = new RegExp(/.*personal.*/i);
var is_limited_company;

function checkForPersonalBankAccount() {
  if (!is_limited_company) { return }
  var bank_account_name = $(this).val();

  if (personal.test(bank_account_name)) {
    addHelpMessage(
      $(this), HELP_WARNING,
      "Personal bank accounts should not be included in your business' bookkeeping, please contact us for guidance"
    );
  }
}

window.checkCompanyTypeForBankAccount = function() {
  var $bankAccountName = $('#bank_account_name');

  if ($bankAccountName.length === 0) { return }
  // bank account modal
  var companyTypeName = $bankAccountName.data('companyType');
  is_limited_company = ["Limited Company", "Limited Liability Partnership (LLP)"].includes(companyTypeName);
  $bankAccountName.on('change', checkForPersonalBankAccount);
}

function addCompanyWizardBankAccountNameEventListener(section) {
  $(section).find('input[id^=company_bank_accounts_attributes][id$=name]').on('change', checkForPersonalBankAccount);
}

window.initCheckForPersonalBankAccount = function() {
  var companyTypeName = $('#company_data').data('typer');
  is_limited_company = ["Limited Company", "Limited Liability Partnership (LLP)"].includes(companyTypeName);
  addCompanyWizardBankAccountNameEventListener($('#step5'));
}

window.addEntryMethodListener = function() {
  $('#bank_account_entry_method').on('change', toggleBankFeedOptions);
  $('#bank_account_account_id_plaid').on('change', plaidAccountIdChanged);
  $('#bank_account_stripe_feed_credential_id').on('change', toggleBankFeedOptions);
  if($('#unconfirmed_transaction_warning').length){
    $('#bank_account_entry_method').on('change', entryMethodChangeAlert);
  }
}

window.plaidAccountIdChanged = function(){
  var plaid_feed_credential_id = $('#bank_account_account_id_plaid').find('option:selected').data('plaid-feed-credential-id');
  $('#plaid_feed_credential_id').val(plaid_feed_credential_id);

  var plaid_feed_account_name = $('#bank_account_account_id_plaid').find('option:selected').data('account-name');
  $('#plaid_feed_account_name').val(plaid_feed_account_name);

  var plaid_feed_account_mask = $('#bank_account_account_id_plaid').find('option:selected').data('account-mask');
  $('#plaid_feed_account_mask').val(plaid_feed_account_mask);

  toggleBankFeedOptions();
  changeCurrencyToMatchPlaidAccount();
}

window.toggleBankFeedOptions = function() {
  var entry_method = $('#bank_account_entry_method').val();
  bankFeedOptionsDisplay(entry_method);
  saveButtonEnabled(entry_method);
  togglePaypalButton(entry_method);
  enableOrDisableCurrencyField(entry_method)
}

function togglePaypalButton(entry_method) {
  var containing_form = $('#bank_account_entry_method').closest('form');
  var has_paypal_feed_credential = $(containing_form).data('already-has-paypal-feed-credential');

  if (entry_method === 'PayPal Feed' && !has_paypal_feed_credential) {
    $('#paypal_feed_button').show();
    $('#save_bank_account').hide();
  } else {
    $('#paypal_feed_button').hide();
    $('#save_bank_account').show();
  }
}

function enableOrDisableCurrencyField(entry_method) {
  if (entry_method === 'PayPal Feed') {
    $('#bank_account_currency_id').attr('disabled', true).trigger('chosen:updated');
  } else if (isFeatureToggleActive('plaid_currency_improvements') && entry_method === 'Plaid Feed') {
    $('#bank_account_currency_id').prop('disabled', true).trigger('chosen:updated').prop('disabled', false)
  } else {
    $('#bank_account_currency_id').attr('disabled', false).trigger('chosen:updated');
  }
}

function bankFeedOptionsDisplay(entry_method) {
  var options = {
    'Stripe Feed': '#stripe_feed_account',
    'PayPal Feed': '#paypal_feed_account',
    'Plaid Feed': '#plaid_feed_account'
  };

  $.each(options, function(key, value) {
    if (key === entry_method) {
      $(value).show();
    } else {
      $(value).hide();
    }
  });
}

window.saveFormInformationInit = function() {
  $('#bank_account_name').change(nameFieldActions);
  $('#bank_account_currency_id').change(bankAccountCurrencyChanged);
}

function nameFieldActions() {
  if (bankAccountNameIsStripe()) {
    setBankAccountEntryMethod('Stripe Feed');
    saveToUserPreference(this);
  }
  if (bankAccountNameIsPayPal()) {
    setBankAccountEntryMethod('PayPal Feed');
  }
}

function bankAccountCurrencyChanged(e) {
  saveToUserPreference(e.currentTarget)
}

function bankAccountEntryMethodsInclude(entryMethod) {
  var selector = "#bank_account_entry_method option[value='" + entryMethod + "']";
  if ($(selector).length === 0) { return false; }
  return true;
}

function bankAccountNameIsStripe() {
  if (!bankAccountEntryMethodsInclude('Stripe Feed')) { return false; }
  if ($("#bank_account_name").val().toLowerCase() != "stripe" ) { return false; }
  return true;
}

function saveToUserPreference(elem) {
  UserPreferences.set(
    'stripe_' + $(elem).attr('id') + '_' + $('#stripe_feed_button').attr('data-user-preference-id'),
    $(elem).val()
  );
}

function bankAccountNameIsPayPal() {
  if (!bankAccountEntryMethodsInclude('PayPal Feed')) { return false; }
  var bankAccountName = $('#bank_account_name').val().toLowerCase().replace(/\s/g, '');
  var nameContainsPayPal = bankAccountName.indexOf('paypal') != -1;
  if (nameContainsPayPal) { return true; }
  return false;
}

function setBankAccountEntryMethod(entryMethod) {
  $('#bank_account_entry_method')
    .val(entryMethod)
    .trigger('chosen:updated')
    .trigger('change')
}

function saveButtonEnabled(entry_method) {
  var saveButton = $('#save_bank_account');
  if (entry_method == '') {
    saveButton.attr('disabled', 'disabled');
  } else if (entry_method == 'Plaid Feed') {
    set_button_status(saveButton, '#bank_account_account_id_plaid')
  } else {
    saveButton.removeAttr('disabled');
  }
}

function set_button_status(button, dropdownID) {
  if (isAccountSelected(dropdownID)) {
    button.removeAttr('disabled');
  } else {
    button.attr('disabled', 'disabled');
  }
}

function isAccountSelected(selector) {
  var $feedAccountId = $(selector);
  return ($feedAccountId.length && $feedAccountId.val() != '')
}

function entryMethodChangeAlert(){
  var currentEntryMethod = $('#unconfirmed_transaction_warning').data("current-entry");
  var newEntryMethod = $('#bank_account_entry_method').val();
  if(currentEntryMethod != newEntryMethod){
    $('#unconfirmed_transaction_warning').show();
  } else {
    $('#unconfirmed_transaction_warning').hide();
  }
}

function changeCurrencyToMatchPlaidAccount() {
  if (!isFeatureToggleActive('plaid_currency_improvements')) { return; }
  var plaid_account_currency_id = $('#bank_account_account_id_plaid').find('option:selected').data('account-currency-id'),
    $currencyField = $('#bank_account_currency_id');

  if (plaid_account_currency_id != undefined) {
    $currencyField.val(plaid_account_currency_id)
    $currencyField.prop('disabled', true).trigger('chosen:updated').prop('disabled', false)
  } else {
    $currencyField.attr('disabled', false).trigger('chosen:updated');
  }
}
