FancyEllipsisTooltipComponent = {
  'render': function(content){
    return '<div class="fancy-ellipsis-tooltip" data-open="false"><button type="button" class="close">×</button>' + content + '</div>';
  },
  'jQuerySelector': function(){
    return 'div.fancy-ellipsis-tooltip';
  },
  "setupOn": function (elementId, afterCloseButtonClickedFunction){
    $("#" + elementId).on('click', 'div.fancy-ellipsis-tooltip button.close', function(event){
      var tooltipToClose = $(event.target).parents('div.fancy-ellipsis-tooltip');
      FancyEllipsisTooltipComponentState.makeClosed(tooltipToClose);
      if(afterCloseButtonClickedFunction) afterCloseButtonClickedFunction(tooltipToClose);
    });
  },
  'close': function (ellipsisTooltipElement){ 
    FancyEllipsisTooltipComponentState.makeClosed(ellipsisTooltipElement);
   },
  'open': function (ellipsisTooltipElement){ 
    FancyEllipsisTooltipComponentState.makeOpen(ellipsisTooltipElement);
   },
  'isOpen': function (ellipsisTooltipElement){ 
    return FancyEllipsisTooltipComponentState.isOpen(ellipsisTooltipElement);
  },
  "isClosed": function(ellipsisTooltipElement){ 
    return FancyEllipsisTooltipComponentState.isClosed(ellipsisTooltipElement);
  },
  'renderAsPlaintextTooltipSection': function(sectionHeading, sectionContent){
    return '<h5>' + sectionHeading + '</h5><p title="' + sectionContent + '" class="overflow-span">' + sectionContent + '</p>';
  },
  'renderAsDictionaryTooltipSection': function(sectionHeading, dictionaryKeyValueArray){
    var dictionaryHtml = dictionaryKeyValueArray.map(function(keyAndValue) {
      return '<tr title="' + keyAndValue[1] + '"><td>' + keyAndValue[0] + '</td><td>' + keyAndValue[1] + '</td></tr>'
    }).join('');
    return '<h5>' + sectionHeading +'</h5><table>' + dictionaryHtml + '</table>';
  },
}
