(function() {
  'use strict';

  /*
  * FieldStatesNonBatchInvoice takes the non batch invoice form, checks various values and uses them to set flags
  * It is used by the FieldStateController code
  */
  window.FieldStatesNonBatchInvoice = function($form, $row_id, $section) {
    this._rules = window.nb_invoice_field_enabling_rules;
    this._form = $form;
    this._rowId = $row_id;
    this._section = $section;
    this._rowHasAlreadyBeenInitialized = true;
    this._isCreditNote = null;
    this._isBaseCurrency = null;
    this._isSplit = null;
    this._mustBeSplit = null;
    this._$fields = {};
    this._taxRateIsZero = null;
  };

  /*
  * Call this method when a change has occurred in the form, to update fields according to the rules
  *
  * @param {jQuery} event_target
  */
  FieldStatesNonBatchInvoice.prototype.update = function(event_target) {
    if ( this._form ) {
      this._setFlagsFromInvoice();
    }
  };

  FieldStatesNonBatchInvoice.prototype.after_update = function(target) {
    // Nothing needed here
  }

  FieldStatesNonBatchInvoice.prototype.getFlagValue = function(flag) {
    return this['_'+flag];
  }

  FieldStatesNonBatchInvoice.prototype.getField = function(selector) {
    var rowIdIsPresent = Boolean(this._rowId);
    if (rowIdIsPresent) {
      this._$fields[selector] = $(selector.replace('ROW_ID', this._rowId));
    }
    if (!this._$fields[selector]) {
      var found_fields = this._form.find(selector);
      if (this._section) { // Blue Print when adding a new row, hasn't been added to form at this stage
        found_fields = found_fields.add(this._section.find(selector));
      }
      this._$fields[selector] = found_fields;
    }
    return this._$fields[selector];
  }

  FieldStatesNonBatchInvoice.prototype.getRules = function() {
    return this._rules;
  }

  FieldStatesNonBatchInvoice.prototype._setFlagsFromInvoice = function() {
    this._isCreditNote = this._checkIsCreditNote();
    this._isBaseCurrency = this._checkIsBaseCurrency();
    this._isSplit = this._checkIsSplit();
    this._mustBeSplit = this._checkMustBeSplit();
    this._isCustomerGroupInvoice = this._checkIsCustomerGroupInvoice();
    this._taxRateIsZero = this._toggleTaxAmountReadOnlyBasedOnTaxRate();
  }

  FieldStatesNonBatchInvoice.prototype._checkIsCreditNote = function() {
    return this.getField('#invoice_type').val() === 'SalesCreditNote';
  }

  FieldStatesNonBatchInvoice.prototype._checkIsBaseCurrency = function() {
    var $currency_field = this.getField('.currency_select.show-disabled-select');
    var currency_val = parseInt($currency_field.val());
    var base_currency_val = parseInt(companySettings.base_currency.id);
    return currency_val === base_currency_val;
  }

  FieldStatesNonBatchInvoice.prototype._checkIsSplit = function() {
    return this.getField('#is_split_nb_checkbox').is(':checked');
  }

  FieldStatesNonBatchInvoice.prototype._checkMustBeSplit = function() {
    return this._isCreditNote && this._getSelectedInvoiceRef().data('isSplit') === true;
  }

  FieldStatesNonBatchInvoice.prototype._getSelectedInvoiceRef = function() {
    return this.getField('#invoice_ref_selector').find('option:selected');
  }

  FieldStatesNonBatchInvoice.prototype._checkIsCustomerGroupInvoice = function() {
    if (this.getField('.customer_single_select.hidden').length === 0) {
      return false;
    } else {
      return true;
    }
  }

  FieldStatesNonBatchInvoice.prototype._toggleTaxAmountReadOnlyBasedOnTaxRate = function() {
    var rowIdIsPresent = Boolean(this._rowId);
    if (rowIdIsPresent) {
      var row = this._form.find('tr[data-row-id="' + this._rowId + '"].line_item_row.fields.batch_row.line_item_description')
      var taxRateField = row.find('.nb_split__tax_code.nb_split_cell');
      var taxRate = getTaxRateAsFloat(taxRateField);
      return (taxRate == 0 && this.getField('#line_item_tax_amountROW_ID').length > 0);
    }
  }
})();
