(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#opening-inventories_table').length === 0) { return }
    initBootgridTable($('#opening-inventories_table'));

    $('.opening-inventory-edit').click(function(e){
      e.preventDefault();
      sweetAlert({
        title: "Are you sure you want to edit your opening stock?",
        text: 'Your opening stock should match your stock values from when you began using stock control',
        html: false,
        allowEscapeKey: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Close',
        showCancelButton: true,
        customClass: 'sweetalert-pandle-theme'
      }, function(isConfirmed) {
        if (isConfirmed) {
          window.location = e.target.href;
        }
      });
    })
  }));

})();
