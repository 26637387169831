import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  initialize() {
    $(this.inputTarget).on('focusout', function (event) {
      const date = $(event.target).val()
      window.dispatchEvent(new CustomEvent('enddate:changed', { detail: { date } }))
    })
  }
}
