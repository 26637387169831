(function() {
  'use strict';
  window.initObBalancesForm = function() {
    formatForms($('#ob_form')); // Initialize inputmasks (and anything else Bootgrid broke)
    addObwEventListeners();
    obwPopulateControlAccountBalances();
    obwDisableStockFields();
    setMaxInvoiceDates();
  }

  function obwDisableStockFields(){
    getStockData(function(data){
      var $account_row = getStockRow(data.stock_account);
      var $debit = $account_row.find('.debit-cell').find('input');
      var $credit = $account_row.find('.credit-cell').find('input');
      $debit.attr('readonly',true);
      $credit.attr('readonly',true);
    });
  }

  window.getStockData = function(callback){
    if (isFeatureToggleActive('stock_control')){
      $.getJSON('/stock_item/opening_inventory/total_data', function(data){
        if(data.stock_account){
          callback(data);
        }
      });
    }
  }

  window.getStockRow = function(id){
    return $('#ob_nominal_table tr[data-row-id='+id+']');
  }

  window.obwPopulateControlAccountBalances = function() {
    getJsonOrShowMaintenanceModal('/companies/current_company_suspense_balances.json', function(data) {
      function decimalOrNothing(balance) {
        return balance == 0 ? '' : parseFloat(balance).toFixed(2);
      }

      function disableFields($debit, $credit) {
        $debit.attr('disabled','disabled');
        $credit.attr('disabled','disabled');
      }

      function populateControlAccountRow(row_id, balance, is_credit) {
        var $account_row = $('#ob_nominal_table tr[data-row-id='+row_id+']');
        if( $account_row.length ) {
          var $debit = $account_row.find('.debit-cell').find('input');
          var $credit = $account_row.find('.credit-cell').find('input');
          disableFields($debit,$credit);
          balance = decimalOrNothing(balance);
          is_credit ? $credit.val(balance) : $debit.val(balance);
        }
      }

      // Refresh the Sales Tax Control Account value
      var stca_balance = data.stca_balance;
      var stca_id = data.stca_account_id;
      populateControlAccountRow(stca_id, stca_balance, true);

      // Refresh the Purchase Tax Control Account value
      var ptca_balance = data.ptca_balance;
      var ptca_id = data.ptca_account_id;
      populateControlAccountRow(ptca_id, ptca_balance, false);

      // Refresh the debtors/creditors balances
      var dca_balance = data.dca_balance;
      populateControlAccountRow('DCA', dca_balance, false);

      var cca_balance = data.cca_balance;
      populateControlAccountRow('CCA', cca_balance, true);

      obwCalcDebitsCredits();
    });
  }

  function addObwErrorDebitCreditListeners() {
    function fieldHasValue(event) {
      return $(event.currentTarget).val().length > 0;
    }

    function addNominalHelpWarning(url, tdClass, message, with_title) {
      getJsonOrShowMaintenanceModal(url, function(nominals) {
        $.each(nominals, function(index,nominal) {
          var $field = $('tr[data-row-id=' + nominal.account_id + ']').find(tdClass + ' input');
          if ( with_title === true ) {
            var nominal_title = nominal.nominal_code + ' ' + nominal.name_for_notifications;
            addErrorListenerWithTitle($field, fieldHasValue, HELP_WARNING, nominal_title, message);
          } else {
            addErrorListener($field, fieldHasValue, HELP_WARNING, message);
          }
        });
      });
    }

    addNominalHelpWarning('/companies/depreciation_nominals','.credit-cell','Credit balances for depreciation should be entered into the corresponding accumulated depreciation field');
    addNominalHelpWarning('/companies/accumulated_depreciation_nominals','.debit-cell','Debit balances for accumulated depreciation should be entered into the corresponding depreciation field');
    addNominalHelpWarning('/companies/expense_nominals','.credit-cell','Credit balance may be incorrect: expense accounts have normal debit balances',true);
    addNominalHelpWarning('/companies/sales_nominals','.debit-cell','Debit balance may be incorrect: revenue accounts have normal credit balances',true);
  }

  function addObwEventListeners() {
    $('#ob_nominal_table').on('change', '.debit-cell input', obwCalcDebits);
    $('#ob_nominal_table').on('change', '.credit-cell input', obwCalcCredits);
    $('#ob_nominal_table').on('change', '.obw_conversion_rate_field', obwCalcDebitsCredits);
    $('#obw_invoice_table_container').on('change', '.obw_conversion_rate_field', obwCalcDebitsCredits);
    $('#ob_post_date').on('dp.change', onObDateChange);
    addObwErrorDebitCreditListeners();
    disableObwFinishButtonUntilInvoicesPosted();
  }

  function onObDateChange() {
    fetchObwConversionRates();
    setHiddenFieldDate();
    setMaxInvoiceDates();
  }

   window.setMaxInvoiceDates = function(){
    if ($('#ob_post_date').length > 0){
      $('.invoice_row').each(function(i, row){
        var datePicker = getDateTimePicker($(row).find('.datepicker-component'));
        var openingBalanceDate = getClosestDateTimePicker($('#date-field')).date();
        if (openingBalanceDate != null){
          datePicker.maxDate(openingBalanceDate);
          adjustDatesToFitMinMax(datePicker);
        }
      })
    }
  }

  function setHiddenFieldDate(){
    $('#date-hidden-field').val($('#date-field').val());
  }

  window.disableObwFinishButtonUntilInvoicesPosted = function() {
    $('#invoice_form input').on('change', function InvoiceFormChange() {
      $('#submit_obw').attr('disabled','disabled');
    });
  }

  function obwGetBaseCurrencyValue($field) {
    var amount = $field.val();
    if (amount) {
      var conv_rate = $field.closest('tr').find('.obw_conversion_rate_field').val();
      if( conv_rate ) {
        var converted = amount * conv_rate;
        return parseFloat(converted.toFixed(2));
      } else {
        return parseFloat($field.val());
      }
    }
  }

  function obwCalcTotal($fields) {
    var total = 0.0;
    $fields.each(function() {
      var val = obwGetBaseCurrencyValue($(this));
      if ( typeof(val) !== 'undefined' ) {
        total += obwGetBaseCurrencyValue($(this));
      }
    });
    return total;
  }

  function obwCalcColumnTotal(cellClass,totalId) {
    var total = obwCalcTotal($(cellClass+' input')).toFixed(2);
    $(totalId).html(companySettings.base_currency.currency_symbol + total);
    return total;
  }

  function obwCalcDebits (event) {
    return obwCalcColumnTotal('.debit-cell','#obw_debit_total');
  }

  function obwCalcCredits (event) {
    return obwCalcColumnTotal('.credit-cell','#obw_credit_total');
  }

  window.obwCalcDebitsCredits = function() {
    obwCalcDebits();
    obwCalcCredits();
  }

  function obwCheckDebitsCreditsBalance() {
    if (obwCalcDebits() != obwCalcCredits()) {
      var title = 'Could not post opening balances! ';
      var text = 'Total credits must equal total debits';
      var type = 'danger';
      notify(text, type, title);
      return false;
    } else {
      return true;
    }
  }

  function obwCheckDateFilledIn() {
    return true // FARMPLAN - this validation does not work and has been disabled
    if ( !getDateFromDateTimePicker($('#ob_post_date')) ) {
      var title = 'Could not post opening balances! ';
      var text = 'Please fill in the posting date';
      var type = 'danger';
      notify(text, type, title);
      return false;
    } else {
      return true;
    }
  }

  function saveDraftOnButtonClick() {
    $('.form-wizard-basic #save_draft').on('click', function(e) {
      e.preventDefault();
      submitDraftOBW();
    });

    $('.form-wizard-basic .save_draft_for_import').on('click', function(e) {
      e.preventDefault();
      $('#import-hidden-field').val($(this).data('import-url'));
      submitDraftOBW();
    });
  }

  function submitDraftOBW(){
    if ($('#save_draft').prop('disabled') == true || $('.save_draft_for_import').prop('disabled') == true) {
      return
    } // They double-clicked and the previous request hasn't finished yet

    $('.finish').prop('disabled', 'disabled');
    $('.finish').text("Saving...");
    $('#ob_form').attr('action','/opening_balances/save_draft.js').data('remote', true);
    $('#ob_form').trigger('submit.rails');

  }

  function submitFormOnWizardFinish() {
    $('.form-wizard-basic #submit_obw').off('click', submitObwForm).on('click', submitObwForm)
  }

  function submitObwForm(e){
    e.preventDefault();
    if ($('#submit_obw').prop('disabled') == true) {
      return
    } // They double-clicked and the previous request hasn't finished yet
    if (!obwCheckDebitsCreditsBalance()) { return }
    if (!obwCheckDateFilledIn()) { return }
    //if ( typeof(invoice_are_you_sure_controller) === 'object' ) { invoice_are_you_sure_controller.dispose(); }
    $('.finish').prop('disabled', true);
    $('.finish').text("Saving...");
    $('.page-loader').fadeIn();
    $('#ob_form').submit();
  }

  function obwOnTabShow(tab, navigation, index) {
    var tab_target = tab.data('target');

    if (tab_target === '#enter_opening_balances' ) {
      if ($('#submit_obw').attr('disabled') === 'disabled') {
        preprocessSplitModalsForSubmit();
        $('#invoice_form').submit(); // NB Finish button will be enabled once this is completed.
      }
      obwPopulateControlAccountBalances();
      fetchObwConversionRates(false);
    }
    UserPreferences.set('obw_last_tab', tab.data('key'));
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#opening_balance_wizard').length === 0) { return }

    onceAfterDatepickersComponentHaveBeenInitialised(function() {
      initBootstrapWizard(errors.tryFn(obwOnTabShow));
      initObTables(); // NB: any change listeners on form fields in a bootgrid table have to be added after it has loaded
      saveDraftOnButtonClick();
      submitFormOnWizardFinish();

      setDateWarningEnabled(false);
      // NB see also invoices__batch_or_edit.js
    });
  }));
})();
