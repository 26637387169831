NumberComparisonService = {
"sourceNumberGreaterThanTargetNumber": function(numberComparisonResultJson){
    return numberComparisonResultJson.comparisonResult == 1;
  },
  "sourceNumberLessThanTargetNumber": function(numberComparisonResultJson){
    return numberComparisonResultJson.comparisonResult == -1;
  },
  "sourceNumberEqualToTargetNumber": function(numberComparisonResultJson){
    return numberComparisonResultJson.comparisonResult == 0;
  }
}