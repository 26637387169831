batchModalState = {}
BatchInvoiceModalState = {
  "saveState": function($modal) {
    var $invoiceCards = $modal.find('.invoice-line-item-container');
    $invoiceCards.each(function() {
      var $invoiceCard = $(this);
      var invoice_id = $invoiceCard.data('invoice-id');
      var $rows = $invoiceCard.find('.line_item_row');
      batchModalState[invoice_id] = {
        element: $invoiceCard,
        rows: generateHashforInvoiceCardRows($rows)
      }
    })
  },
  "resetModalToState": function ($modal) {
    var $invoiceCards = $modal.find('.invoice-line-item-container')
    $invoiceCards.each(function() {
      var $invoiceCard = $(this);
      var invoice_card_state = batchModalState[$invoiceCard.data('invoice-id')];
      if (invoice_card_state != undefined) {
        $invoiceCard.show(); 
        showAllRows($invoiceCard.find('.line_item_row'))
        resetRowsToOriginalState(invoice_card_state.rows);
      } else {
        $invoiceCard.remove();
      }
    })
  }
}

function generateHashforInvoiceCardRows($rows) {
  var row_hash = []
  $rows.each(function() {
    var line_item_row = $(this);
    var amount_element = line_item_row.find('.line_item_total_amount_tf');
    var destroy_element = line_item_row.find('.line_item_destroy');

    var hash = {
      row: line_item_row,
      amount: {
        element: amount_element,
        value: amount_element.val()
      },
      destroy: {
        element: destroy_element,
        value: destroy_element.val()
      }
    }
    row_hash.push(hash);
  })
  return row_hash;
}

function showAllRows($invoiceCardRows) {
  $invoiceCardRows.each(function() {
    $(this).show();
    $(this).find('.line_item_destroy')
      .removeClass('flagged_for_deletion')
      .val(false);
  });
} 

function resetRowsToOriginalState(row_states) {
  row_states.forEach(function(row_state) {
    setAmountValue(row_state.amount);
    setRowVisibility(row_state);
  })
}

function setAmountValue(amount_state) {
  amount_state.element.val(amount_state.value);
  amount_state.element.change();
}

function setRowVisibility(state) {
  if (state.destroy.value == "true") {
    state.row.find('.line_item_remove_button').click();
  }
}
