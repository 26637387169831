InvoiceCardBehaviour = {
  "actionsAfterInitialize": function($invoice_cards) {
    checkAndDisableElementsOnInvoiceCardCount();
    $invoice_cards.each(function() {
      var invoice_card = createInvoiceCard($(this));
      setCreditInFullState(invoice_card)
    })
  },
  "actionsAfterInitializeBatchForm": function($invoice_card) {
    checkAndDisableElementsOnInvoiceCardCount();
    var invoice_card = createInvoiceCard($invoice_card);
    accordianBehaviorOnLineItemTable(invoice_card)();
    validateAndUpdateInvoiceCardCheck(invoice_card)();
    setCreditInFullState(invoice_card)
  },
  "enableLineItemEvent": function(line_items) {
    line_items.each(function() {
      var line_item = createCreditNoteLineItem($(this));
      addSharedLineItemEventListeners(line_item)
    })
  },
  "enableLineItemEventBatchForm": function(line_items) {
    line_items.each(function() {
      var line_item = createCreditNoteLineItem($(this));
      addSharedLineItemEventListeners(line_item)
      errors.tryOn(line_item.total, 'totalChange', function() { line_item.invoice_card.validation.change() }); //FARMPLAN: Use totalChange event instead of change event so it fires at the appropriate time
    })
  },
  "enableInvoiceCardBehaviour": function(invoice_cards) {
    invoice_cards.each(function() {
      var invoice_card = createInvoiceCard($(this));
      addInvoiceCardEventListener(invoice_card)
      initClickEvent(invoice_card.collapse_button, checkLineItemTableVisibility(invoice_card));
      initClickEvent(invoice_card.delete_button, deleteButtonBehaviorForNonBatchForm(invoice_card));
    })
  },
  "enableInvoiceCardBehaviourBatchForm": function(invoice_cards) {
    invoice_cards.each(function() {
      var invoice_card = createInvoiceCard($(this));
      addInvoiceCardEventListener(invoice_card)
      initClickEvent(invoice_card.collapse_button, accordianBehaviorOnLineItemTable(invoice_card));
      errors.tryOn(invoice_card.validation, 'change', validateAndUpdateInvoiceCardCheck(invoice_card));
      initClickEvent(invoice_card.delete_button, deleteButtonBehaviorForBatchForm(invoice_card));
    })
  }
}

function addSharedLineItemEventListeners(line_item) {
  errors.tryOn(line_item.total, 'totalChange', lineItemOnTotalAmountChange(line_item)); //FARMPLAN: Changed event to customevent totalChange
  errors.tryClick(line_item.remove_button, removeLineItem(line_item));
  // errors.tryClick(line_item.credit_in_full, creditTotalAmountInFull(line_item)); //FARMPLAN: Credit_note_line_item_row_controller.js already does this
  errors.tryOn($('#customer_document_date_div'), 'dp.change', customerDocumentDateChange(line_item));
  errors.tryOn(line_item.total, 'keydown', restrictIllegalCharacters);
}

function addInvoiceCardEventListener(invoice_card) {
  if (invoice_card.line_item_rows.length >= 2) {
    initClickEvent(invoice_card.credit_all_in_full, creditAllLineItemsInFull(invoice_card));
  }
}

function initClickEvent($element, eventFunction) {
  $element.off()
  errors.tryClick($element, eventFunction);
}

function createCreditNoteLineItem($row) {
  var line_item = {
    row: $row,
    net: $row.find('.line_item_net_amount_tf'),
    tax: $row.find('.line_item_tax_amount_tf'),
    tax_rate: parseFloat($row.find('.line_item_tax_code').data('tax-rate')),
    total: $row.find('.line_item_total_amount_tf'),
    remove_button: $row.find('.line_item_remove_button'),
    destroy: $row.find('.line_item_destroy'),
    total_creditable_amount: parseFloat($row.find('.line_item_total_amount_tf').data('original-creditable')).toFixed(2),
    credit_in_full: $row.find('.credit-in-full-checkbox'),
    invoice_card: createInvoiceCard($row.parents('.invoice-line-item-container')),
    record_type: $row.data('record-type'),
    record_id: $row.data('record-id')
  };
  return line_item;
}

function createInvoiceCard($invoice_card) {
  var invoice_card = {
    base: $invoice_card,
    delete_button: $invoice_card.find('.invoice-card-delete'),
    credit_all_in_full: $invoice_card.find('.credit-all-in-full-checkbox'),
    collapse_button: $invoice_card.find('.invoice-card-collapse'),
    line_item_table: $invoice_card.find('.invoice-line-item-table'),
    line_item_rows: $invoice_card.find('.line_item_row'),
    total_credit_amount: $invoice_card.find('.invoice-total-credit-amount'),
    total_amount_elements: $invoice_card.find('td .line_item_total_amount_tf'),
    validation: $invoice_card.find('.invoice-validation'),
    batch_modal: $invoice_card.closest('.batch-invoice-modal'),
    invoice_id: $invoice_card.data('invoice-id')
  }
  return invoice_card;
}

function lineItemOnTotalAmountChange(line_item) {
  return function() {
    // updateAndRestrictTotalAmount(line_item); // Restriction on total amount as well as does some checking for the checkboxes.
    //FARMPLAN: Remove method that sets the net and tax from total. Existing stimulus.js files already do this.
    // setNetAndTaxFromTotalAndTaxRate({
    //   tax_rate: line_item.tax_rate,
    //   net_amount_field: line_item.net,
    //   tax_amount_field: line_item.tax,
    //   total_amount_field: line_item.total
    // });
    checkCreditInputAndSetDestroyValue(line_item); //sets destroy value based on if the line item has been credited
    checkAllInputsFullyCredited(line_item); //Checks if all other checkboxes are checked and so checks the top fully credited one
    updateInvoiceCardTotalAmount(line_item); //updates invoice card and provides warning if total is -ve
  }
}

function updateAndRestrictTotalAmount(line_item) {
  var total_value = parseFloat(line_item.total.val());
  var field_value = roundToTwoDp(total_value).toFixed(2);

  var other_credits = CreditedInvoiceAmountDataHandler.getOtherCredits(line_item);
  var creditable_limit = line_item.total_creditable_amount - other_credits;
  line_item.credit_in_full.prop('checked', false);

  if (creditable_limit * total_value < 0) { // FARMPLAN : If the user enters the wrong sign for the line, correct the sign entered.
    field_value = -1 * field_value
  }

  if ($.isNumeric(total_value) == false) { // FARMPLAN : Allow negative values to be entered.
    field_value = '';
  } else if (Math.abs(field_value) >= Math.abs(creditable_limit)) { // FARMPLAN : Creditable limit logic for negative values aswell as positive
    line_item.credit_in_full.prop('checked', true);
    field_value = roundToTwoDp(creditable_limit).toFixed(2)
  }
  line_item.total.val(field_value);
}

function checkCreditInputAndSetDestroyValue(line_item) {
  var get_boolean_value_for_destroy_attribute = !Boolean(parseFloat(Math.abs(line_item.total.val())) > 0); // FARMPLAN : Don't destroy contra lines
  line_item.destroy.val(get_boolean_value_for_destroy_attribute);
}

function checkAllInputsFullyCredited(line_item) {
  var invoice_card_checkboxes = line_item.invoice_card.base.find('.credit-in-full-checkbox:not(:disabled)')
  var checked_checkboxes = invoice_card_checkboxes.filter(":checked")
  var set_checkbox_state = Boolean(invoice_card_checkboxes.length == checked_checkboxes.length)
  line_item.invoice_card.credit_all_in_full.prop('checked', set_checkbox_state);
}

function removeLineItem(line_item) {
  return function() {
    line_item.row.slideUp(callback=function() {
      line_item.row.addClass('flagged_for_deletion')
      line_item.destroy.val(true);
      line_item.total.val(0);
      line_item.total[0].dispatchEvent(new Event("change")); //FARMPLAN: Changed it to dispatchevent so that stimulus.js will pick it up
      _removeInvoiceCardIfNoLineItemRemains(line_item);
    });
  }
}

function _removeInvoiceCardIfNoLineItemRemains(line_item) {
  var invoice_card = line_item.invoice_card;
  if (invoice_card.base.is(':visible') && invoice_card.base.find('.line_item_row:visible').length == 0) {
    if (invoice_card.batch_modal.length > 0) {
      deleteButtonBehaviorForBatchForm(invoice_card)();
    } else {
      deleteButtonBehaviorForNonBatchForm(invoice_card)();
    }
  }
}

function accordianBehaviorOnLineItemTable(invoice_card) {
  return function() {
    var all_invoice_cards = invoice_card.batch_modal.find('.invoice-line-item-container');
    all_invoice_cards.each(function() {
      var inv_card = createInvoiceCard($(this));
      if(inv_card.base.is(invoice_card.base)) {
        checkLineItemTableVisibility(invoice_card)();
      } else {
        _toggleLineItemTableVisibility(inv_card, true)
      }
    })
  }
}

function checkLineItemTableVisibility(invoice_card) {
  return function() {
    _toggleLineItemTableVisibility(invoice_card, invoice_card.line_item_table.is(':visible'))
  }
}

function _toggleLineItemTableVisibility(invoice_card, condition) {
  if (condition) {
    invoice_card.line_item_table.slideUp()
    invoice_card.collapse_button.removeClass('zmdi-caret-down')
    invoice_card.collapse_button.addClass('zmdi-caret-up')
  } else {
    invoice_card.line_item_table.slideDown()
    invoice_card.collapse_button.removeClass('zmdi-caret-up')
    invoice_card.collapse_button.addClass('zmdi-caret-down')
  }
}

function deleteButtonBehaviorForNonBatchForm(invoice_card) {
  return function() {
    var invoice_id = invoice_card.base.data('invoice-id');
    CreditedInvoiceDataHandler.creditedInvoices.deleteData(invoice_id);
    hideInvoiceCard(invoice_card)
    updateCustomerDocumentAmounts();
  }
}

function deleteButtonBehaviorForBatchForm(invoice_card) {
  return function() {
    var invoice_id = invoice_card.base.data('invoice-id');
    CreditedInvoiceDataHandler.tempInvoices.deleteData(invoice_id);
    hideInvoiceCard(invoice_card)
    _redirectUserBackToSelectInvoiceModal(invoice_card);
  }
}

function hideInvoiceCard(invoice_card) {
  invoice_card.base.slideUp(callback=function() {
    invoice_card.line_item_rows.each(function() {
      var line_item = createCreditNoteLineItem($(this));
      removeLineItem(line_item)();
    })
    invoice_card.base.addClass('flagged_for_deletion')
    $('#credit-in-full-' + invoice_card.invoice_id).prop('checked', false);
  });
}

function _redirectUserBackToSelectInvoiceModal(invoice_card) {
  var all_invoice_cards_not_deleted = invoice_card.batch_modal.find('.invoice-line-item-container:not(.flagged_for_deletion)');
  if (all_invoice_cards_not_deleted.length == 0) {
    invoice_card.batch_modal.find('.modal-back-btn').click();
  }
}

function checkAndDisableElementsOnInvoiceCardCount() {
  var $customer_selection_field = $('#customer_select_single');
  var $save_dropdown = $('.credit-note-save-dropdown');
  if ($('.invoice-line-item-container').length == 0) {
    $customer_selection_field.attr('disabled', false).trigger('chosen:updated');
    $save_dropdown.attr('disabled', true);
    $('#no-invoices-selected').show();
    $('.credit-note-totals').hide();
  } else {
    $customer_selection_field.attr('disabled', true).trigger('chosen:updated');
    $save_dropdown.attr('disabled', false);
    $('#no-invoices-selected').hide();
    $('.credit-note-totals').show();
  }
}

function creditTotalAmountInFull(line_item) {
  return function() {
    if (line_item.credit_in_full.prop('checked')) {
      line_item.total.val(line_item.total_creditable_amount);
    } else {
      line_item.total.val(null);
    }
    line_item.total[0].dispatchEvent(new Event("change")); //FARMPLAN: Changed the jquery change() event to javascript dispatchEvent()
  }
}

function creditAllLineItemsInFull(invoice_card) {
  return function() {
    var invoice_card_checkboxes = invoice_card.base.find('.credit-in-full-checkbox');
    var check_all_box_state = invoice_card.credit_all_in_full.prop('checked');
    var checkboxes_to_change = invoice_card_checkboxes.filter(function(index, checkbox) {
      return $(checkbox).prop('checked') != check_all_box_state;
    })
    checkboxes_to_change.each(function() { $(this).click(); })
  }
}

function restrictIllegalCharacters(event) {
  var ALPHABET_REGEX = new RegExp(/[a-z!"£$%^&=;:'@,?]/); // FARMPLAN: Allow - sign to be entered.
  var not_alphabet_keycode = event.keyCode <= 48;
  var regex_check = !ALPHABET_REGEX.test(event.key);
  return regex_check || not_alphabet_keycode;
}

function updateInvoiceCardTotalAmount(line_item) {
  var $total_amount_cells = line_item.invoice_card.total_amount_elements;
  var total = 0;
  $total_amount_cells.each(function() {
    var total_amount = parseFloat($(this).val())
    if (!isNaN(total_amount)) { total += total_amount; }
  })
  // FARMPLAN warn user for non-positive total
  var warning = $('#invalid-credit-warning')
  total >= 0 ? warning.addClass("hidden") : warning.removeClass("hidden")

  line_item.invoice_card.total_credit_amount.text(formatAsCurrency(total));
}

function validateAndUpdateInvoiceCardCheck(invoice_card) {
  return function () {
    var $line_item_destroy_states = invoice_card.base.find('.line_item_destroy');
    var is_invoice_card_valid = invoice_card.base.hasClass('flagged_for_deletion') ? true : (_validateLineItems($line_item_destroy_states) && _validateCreditAmountTotal()) // FARMPLAN: Added _validateCreditAmountTotal() check
    _updateInvoiceCardCheck(invoice_card, is_invoice_card_valid)
  }
}

function _validateLineItems($destroy_elements) {
  var invoice_valid = false;
  $destroy_elements.each(function() {
    var $destroy_element = $(this)
    if ($destroy_element.val() === 'false') {
      invoice_valid = true;
      return;
    }
  })
  return invoice_valid;
}

// FARMPLAN: additional validation to ensure credit note total is positive
// In some cases there can be more than one element on the page, we only
// want the one in the currently active modal. This is janky AF and needs
// a rewrite soon.
function _validateCreditAmountTotal() {
  return parseFloat($(
    ".batch-invoice-modal .invoice-line-item-container:not(.flagged_for_deletion) .invoice-total-credit-amount"
  ).text()) > 0
}
// /FARMPLAN

function _updateInvoiceCardCheck(invoice_card, invoice_card_is_valid) {
  if(invoice_card_is_valid) {
    invoice_card.validation.addClass('confirmed');
    invoice_card.validation.data('valid', true);
  } else {
    invoice_card.validation.removeClass('confirmed');
    invoice_card.validation.data('valid', false);
  }
  invoice_card.batch_modal.find('.modal-save-btn').change();
}

function setCreditInFullState(invoice_card) {
  invoice_card.line_item_rows.each(function() {
    var line_item = createCreditNoteLineItem($(this));
    lineItemOnTotalAmountChange(line_item)()
  })
}
