(function(){
  'use strict';

  window.vatSchemeChange = function(event) {
    var $element = $(event.currentTarget);
    var current_row = $element.closest('tr')[0];
    var $selected_option = $element.find('option:selected');
    var is_flat_rate_scheme = $selected_option.data('isFlatRate');

    if ( is_flat_rate_scheme ) {
      setRatePayableReadOnly(current_row, false);
      addFlatRateVatAlert();
    } else {
      setRatePayableReadOnly(current_row, true);
      setRatePayable(current_row, $selected_option.data('mainTaxRate'));
    }
  };

  function setRatePayable(row, ratePayable) {
    $(row).find('.rate_payable_tf').val(ratePayable);
  }

  function setRatePayableReadOnly(row, readOnly) {
    if (readOnly) {
      $(row).find('.rate_payable_tf').prop('disabled', true);
    } else {
      $(row).find('.rate_payable_tf').removeAttr('disabled');
    }
  }

  function addFlatRateVatAlert() {
    addHelpMessageWithTitle(
      'Flat Rate VAT Scheme', HELP_INFO,
      'The software will calculate your first year’s 1% discount. Please ensure your VAT registration date is correct to ensure an accurate calculation'
    );
  }
})();
