(function () {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function () {
    if ($('#trial_balance_report').length === 0) { return }
    openTrialBalanceBackgroundConnection();
    initTrialBalanceDataTable();
    submitFormOnDateChange($('#date_end'));
  }));
})();
