(function() {
  'use strict';

  window.setConversionRateForRow = function(event) { //Set conversion rate for account id
    try {
      var row = $(event.currentTarget).closest('tr')[ 0 ];
      var currency_field = $(row).find('.bt_currency_field');
      var currency_id = currency_field.val();
      var base_currency_id = companySettings.base_currency.id;
      var conversion_rate_field = $(row).find('.conversion_rate_field');
      var currency_code = ($(row).find('.bt_currency_field.show-disabled-select option:selected').text() || $(row).find('.je_currency_field option:selected').text());

      if ( !conversion_rate_field.length ) {
        // Probably means we are making a template for a recurring transaction
        return
      } else {
        onceAfterDatepickersComponentHaveBeenInitialised(function() {
          setConversionRateIfNecessary(
            currency_id,
            getDateForRow(row),
            base_currency_id,
            conversion_rate_field,
            currency_code);
        });
      }

    } catch(e) {
      if (e instanceof DatePickerError) {
        console.warn('setConversionRateForRow called before date picker initialised');
      } else {
        throw e
      }
    }
  };

  window.fetchObwConversionRates = function(overwrite_existing) {
    overwrite_existing = typeof overwrite_existing == 'undefined' ? true : overwrite_existing;

    var date = getDateFromDateTimePicker($('#ob_post_date'));

    // Do nothing if the date is blank or in the future - they'll only have to change it again
    if (!date || dateInFuture(date)) { return }

    $.each($('#ob_form').find('.obw_conversion_rate_field:enabled'), function() {
      var $conversion_rate_field = $(this);

      if ($conversion_rate_field.val().length && !overwrite_existing) {
        return true
      } //Go to the next

      var $currency_span = $(this).closest('tr').find('.obw_currency');
      var currency_id = $currency_span.data('currency-id');
      var base_currency_id = companySettings.base_currency.id;
      var has_paypal_conversion = $conversion_rate_field.data('has-paypal-conversion');

      $conversion_rate_field.val('').change();
      if (conversionRateShouldBeSet(currency_id, date, base_currency_id, has_paypal_conversion)) {
        getJsonOrShowMaintenanceModal('/financial/conversion_rates/get_rate.json?currency_id=' + currency_id + '&date=' +
                  encodeURIComponent(formatDateGb(date)), function(conversion_rate) {
          var currency_code = $currency_span.text();
          setConversionRateOrShowMessage(conversion_rate, $conversion_rate_field, currency_code, date);
        });
      }
    });
  };

  /*
  * A bank transaction involves two accounts. We need a conversion rate if either of them is non-base
  */
  window.conversionRateFieldShouldBeDisabled = function(bank_account_currency_id, account_currency_id, base_currency_id) {
    // NB: account_currency_id is a string, base_currency_id is a number - don't use ===
    return bank_account_currency_id === base_currency_id && account_currency_id == base_currency_id
  }

  /**
  * @param {Object} arg
  * @param {number} arg.first_account_currency_id
  * @param {string or number} arg.second_account_currency_id
  * @param {number} arg.base_currency_id
  * @param {jQuery} arg.conversion_rate_field
  */
  window.enableOrDisableConversionRateField = function(arg) {
    // NB: account_currency_id is a string, base_currency_id is a number - don't use ===
    if (conversionRateFieldShouldBeDisabled(arg.first_account_currency_id, arg.second_account_currency_id, arg.base_currency_id)) {
      arg.conversion_rate_field.val('');
      arg.conversion_rate_field.prop('disabled', true);
      arg.conversion_rate_field.closest('tr').find('.calculator-button').hide();
    } else {
      arg.conversion_rate_field.prop('disabled', false);
      arg.conversion_rate_field.closest('tr').find('.calculator-button').show();
    }
  };

  /**
   * A bank transaction involves two accounts. One of them determines the BT currency, but we might need a conversion
   * rate for a base-currency BT if the other is international
   *
   * @param arg, an object with the following keys:
   * @param bt_currency_id
   * @param other_currency_id
   * @param other_currency_code
   * @param base_currency_id
   * @param conversion_rate_field
   * @param {moment} date
   */
  window.checkCurrencyForSecondAccount = function(arg) {
    warnIfBothInternational(arg.other_currency_id, arg.bt_currency_id, arg.base_currency_id);
    // Don't use === to compare values from selects (strings) with values from data attributes (integers)
    if (arg.other_currency_id != arg.base_currency_id && arg.bt_currency_id === arg.base_currency_id) {
      setConversionRateIfNecessary(
        arg.other_currency_id, arg.date, arg.base_currency_id, arg.conversion_rate_field, arg.other_currency_code
      );
    }
  };

  function getDateForRow($elem) {
    $elem = $($elem).find('.js_date_field');
    return dateFromElement($elem);
  }

  function setConversionRateIfNecessary(
    currency_id, date, base_currency_id, conversion_rate_field, currency_code
  ) {
    var has_paypal_conversion = $(conversion_rate_field).data('has-paypal-conversion');
    if (conversionRateShouldBeSet(currency_id, date, base_currency_id, has_paypal_conversion)) {
      fetchConversionRateAndDisplay(currency_id, date, conversion_rate_field, currency_code);
    }
  }

  // @param {moment} date
  function fetchConversionRateAndDisplay(currency_id, date, conversion_rate_field, currency_code) {
    getJsonOrShowMaintenanceModal(
      '/financial/conversion_rates/get_rate.json?currency_id=' + currency_id + '&date=' + encodeURIComponent(formatDateGb(date)),
    function(conversion_rate) {
      setConversionRateOrShowMessage(conversion_rate, conversion_rate_field, currency_code, date);
    });
  }

  // @param {moment} date
  function setConversionRateOrShowMessage(conversion_rate, conversion_rate_field, currency_code, date) {
    if (conversion_rate['warn']) {
      addHelpMessageForConversionRate(conversion_rate, conversion_rate_field, currency_code, date);
    } else {
      conversion_rate_field.val(conversion_rate['rate']).change();
    }
  }

  // @param {moment} date
  function addHelpMessageForConversionRate(conversion_rate, conversion_rate_field, currency_code, date) {
    var rate = conversion_rate['rate'];
    addHelpMessage(
      conversion_rate_field,
      rate ? HELP_INFO : HELP_WARNING,
      formatWarningMessageForConversionRate(
        rate,
        conversion_rate[ 'import_date' ],
        conversion_rate_field,
        currency_code,
        date
      )
    );
  }

  function formatWarningMessageForConversionRate(rate, import_date, conversion_rate_field, currency_code, date) {
    var message = 'No conversion rate found for ' + currency_code;

    if (rate) {
      message += formatConversionRateForWarningMessage(date, conversion_rate_field, rate, import_date);
    } else {
      message += '. Please contact support';
    }

    return message + '.';
  }

  // @param {moment} date
  // @param {string} import_date (in YYYY-MM-DD format)
  function formatConversionRateForWarningMessage(date, conversion_rate_field, rate, import_date) {
    return formatDateForConversionRateWarning(date) +
      formatRowForConversionRateWarning($(conversion_rate_field).closest('tr').data('id')) +
      formatClosestRateFound(rate) +
      formatImportDateForConversionRateWarning(import_date)
  }

  function formatDateForConversionRateWarning(date) {
    return ' on ' + formatDateForCompany(date)
  }

  function formatRowForConversionRateWarning(row_id) {
    if (row_id) {
      return ' (Row ' + row_id + ')'
    } else {
      return '';
    }
  }

  function formatClosestRateFound(rate) {
    return '. Closest rate found: ' + rate
  }

  function formatImportDateForConversionRateWarning(import_date) {
    return ' on ' + formatDateForCompany(moment(import_date))
  }

  /**
   *
   * @param {String} currency_id
   * @param {moment}  date
   * @param {Number} base_currency_id
   * @returns {*|boolean}
   */
  function conversionRateShouldBeSet(currency_id, date, base_currency_id, has_paypal_conversion) {
    return currency_id && date && (currency_id != base_currency_id) && !dateInFuture(date) && !has_paypal_conversion;
  }

  function areCurrenciesBothInternational(currency_id, bank_account_currency_id, base_currency_id) {
    // Don't use !== to compare values from selects (strings) with values from data attributes (integers)
    return currency_id &&
      bank_account_currency_id &&
      currency_id != bank_account_currency_id &&
      currency_id != base_currency_id &&
      bank_account_currency_id != base_currency_id;
  }

  function warnIfBothInternational(currency_id, bank_account_currency_id, base_currency_id) {
    if (areCurrenciesBothInternational(currency_id, bank_account_currency_id, base_currency_id)) {
      addHelpMessage(
        null, HELP_ERROR,
        'Bank account currency and Category currency must match if they are both ' +
        'different from your company\'s base currency'
      );
    }
  }
})();
