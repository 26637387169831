(function() {
  'use strict';

  window.resetDatepickersAndSelectors = function() {
    $("#advanced-search .advanced-search-date, .amount-filter-field").each(function() {
      $(this).val('');
    });

    $("#advanced-search .advanced-search-selector").each(function() {
      $(this).val("-1").trigger('change');
      $(this).selectpicker('refresh');
      if (this.id) {
        var firstOption = "button[data-id=" + this.id + "]";
        $(firstOption).text("All");
      }
    });

      //FARMPLAN: Reset enterprise selector so that enterprises defaults to all after reset
      $("#advanced-search #enterprise_selector").each(function() {
        $(this).val(null).trigger('chosen:updated');
      });
      //FARMPLAN
  }

  window.evaluateResetButtonVisibilityFromFilters = function(filters) {
    if (Object.keys(filters).length == 0) {
      $("#reset-button").addClass('disabled');
    } else {
      $("#reset-button").removeClass('disabled');
    }
  }

  window.updateFilterForSelector = function(filters, $selector, name) {
    if ($selector.val() == -1) {
      delete filters[name];
    } else {
      filters[name] = $selector.val();
    }
  }

  window.updateFilterForDateRange = function(filters) {
    var dateRange = {
      date_start: getGbDateStringFromClosestDateTimePicker($('#start-date')),
      date_end: getGbDateStringFromClosestDateTimePicker($('#end-date'))
    };
    if (dateRange['date_start'] == undefined && dateRange['date_end'] == undefined) {
      delete filters['date_range']
    } else {
      filters['date_range'] = JSON.stringify(dateRange)
    }
  }

  window.updateFilterForInput = function(filters, value, name) {
    if (isNaN(value)) {
      delete filters[name];
    } else {
      filters[name] = value;
    }
  }
})();
