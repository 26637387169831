(function() {
  'use strict';

  function _getBankRulesIndexTable() {
    return $('#bank_rules_index_table');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if (_getBankRulesIndexTable().length === 0) { return }
    initBootgridTable(_getBankRulesIndexTable(), undefined, false);
  }));
})();
