(function() {
  /**
    * Called when removing a row.
  */
  window.releaseAttachmentsForNonPersistedRecord = function(event) {
    var $row = $(event.currentTarget).closest('tr');
    releaseAttachmentsForRow($row);
  }

  function releaseAttachmentsForRow($row) {
    var upload_ids = parseCsvToArray(
      $row.find('.upload_ids').val()
    );

    upload_ids.forEach(function(id) {
      removeFromAllUploads(id);
    });
  }

  /**
    * Called when closing the modal of an existing record.
  */
  window.releaseAttachmentsForPersistedRecord = function($row) {
    releaseAttachmentsForRow($row);
  }

  /**
    * Called when deleting or detaching files.
  */
  window.removeUploadIdFromAttachments = function(upload_id, attachments_count) {
    $('.upload_ids').each(function() {
      var that = $(this);
      var attached_ids = parseCsvToArray(that.val());

      if (attached_ids.indexOf(upload_id) > -1) {
        attached_ids.splice(attached_ids.indexOf(upload_id), 1);
        updateUploadIds(that, attached_ids, false);

        $('.attachments-nb').html(attachments_count);
      }
    });
  }

  window.parseCsvToArray = function(input) {
    if (input && input.length > 0 && input[0] !== "") {
      // Don't push a blank string: ""
      return input.split(',');
    } else {
      return [];
    }
  }

  window.updateUploadIds = function($upload_ids, upload_ids, isNewFile) {
    $upload_ids.val(upload_ids);
    $upload_ids.trigger('change', isNewFile);
  }
})();
