window.BankFeedsRowController = function(cellMap) {
  this._cell_map = cellMap;
  this._data     = null;
};

BankFeedsRowController.prototype.update = function(row) {
  this._data = row;
  Object.keys(row).forEach(this._updateColumn, this);
};

BankFeedsRowController.prototype._updateColumn = function(col_id) {
  var cell = this._cell_map[ col_id ];

  if (cell) {
    cell.html(this._data[ col_id ]);
  }
};
