CreditableInvoiceModalTableComponent = {
  "getDescriptionElementFromRow": function(row){
    return TableHelper.getRowCell(row, 3);
  },
  "getCreditTotalElementFromRow": function(row){
    return TableHelper.getRowCell(row, 6).find('input')[0];
  },
  "getCreditInFullElementFromRow": function(row){
    return TableHelper.getRowCell(row, 7).find('input')[0];
  },
  "getTotalElementFromRow": function(row){
    return TableHelper.getRowCell(row, 4).find('div')[0];
  },
  "setCreditTotalValueForRow": function(row, value){
    TableHelper.getRowCell(row, 6).find('input')[0].value = value;
  },
  "setSelectedCheckboxForRow": function(row, value){
    TableHelper.getRowCell(row, 6).find('input')[0].checked = value;
  }
}
