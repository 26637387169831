(function() {
  'use strict';

  function initCustomerGroupInvoiceTable() {
   initBootgridTableWithCheckboxes($('#customer_group_invoice_table'), true);
   $("#customer_group_invoice_table").on('loaded.rs.jquery.bootgrid', hideBulkActionButtonListener);
   bulkPrintOnClick();

  }

  var selectBoxState = {
    _childInvoiceCount : 0,

    update: function() {
      var $selectBoxes = $('td .select-box').filter(':checked');
      this._childInvoiceCount = $selectBoxes.length;
      return this;
    },

    nothingChecked: function() {
      return this._childInvoiceCount == 0;
    },

    somethingChecked: function() {
      return this._childInvoiceCount != 0;
    },
  };

  function hideBulkActionButtons() {
    $('.bulk-print').addClass('hidden');
    $('.bulk-delete').addClass('hidden');
  }

  function showBulkActionButtons() {
    $('.bulk-print').removeClass('hidden');
    $('.bulk-delete').removeClass('hidden');
  }

  function hideCustomerGroupActionButtons() {
    $('.cg-inv-action-button').addClass('hidden');
  }

  function showCustomerGroupActionButtons() {
    $('.cg-inv-action-button').removeClass('hidden');
  }

  function hideBulkActionButtonListener() {
    hideBulkActionButtons();

    $('.select-box').change(function() {
      updateBulkActionButtonState(selectBoxState.update())
    });
  }

  function updateBulkActionButtonState(state) {
    if (state.nothingChecked()) {
      hideBulkActionButtons();
      showCustomerGroupActionButtons();
    } else if (state.somethingChecked()) {
      showBulkActionButtons();
      hideCustomerGroupActionButtons();
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#customer_group_invoice_table').length === 0) { return }
    initCustomerGroupInvoiceTable();
  }));
})();
