(function() {
  'use strict';

  window.callPlaidConnectCredentialsEndpoint = function(response_function, country_codes) {
    var url = Routes.third_party_plaid_credentials_connect_credentials_path({ format: "json", country_codes: country_codes })
    getDataFromPlaidEndpointAndCallFunction(url, response_function)
  }

  window.callPlaidRefreshCredentialsEndpoint = function(bank_account_id, country_codes, response_function) {
    var url = Routes.third_party_plaid_credentials_refresh_credentials_path({
      format: "json",
      bank_account_id: bank_account_id,
      country_codes: country_codes,
      update: { "account_selection_enabled": true }
    })
    getDataFromPlaidEndpointAndCallFunction(url, response_function)
  }

  function getDataFromPlaidEndpointAndCallFunction(url, response_function){
    getJsonOrShowMaintenanceModal(url, function(response) {
      if (response && !$.isEmptyObject(response)) {
        response_function(response);
      }
    });
  }

})();
