window.liquidInserts = function() {
  $('.js-btnbox-btn').on("click", function() {
    var target_id = this.dataset.liquidTarget;
    var text_area = $(target_id)[0];
    var text = '{{' + this.dataset.actualText + '}}';
    var v = text_area.value;
    var i = text_area.selectionStart;

    // Add the insert at cursor position
    text_area.value = v.slice(0, i) + text + v.slice(i);

    // Re-add cursor at new position (takes a range to highlight)
    text_area.setSelectionRange(i + text.length, i + text.length);
  });
};

(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('[data-enable-liquid=true]').length === 0 ) { return }
    liquidInserts();
  }));
})();
