(function() {
  'use strict';

  window.wrapBatchInvoiceRow = function(elem) {
    return Object.assign(Object.create(BatchInvoiceRow), {
      $row: $(elem)
    })
  }

  var BatchInvoiceRow = {
    $row: null,

    getDatepicker: function() {
      return getDateTimePicker(this.find('.datepicker-component'));
    },

    getInvoiceTypeSelect: function() {
      return this.find('.invoice_type');
    },

    getCustomerSupplierSelect: function() {
      return this.find('.customer_supplier_select');
    },

    getCustomerSupplierTypeField: function() {
      return this.find('.customer_supplier_type');
    },

    getCustomerSupplierType: function() {
      return this.getCustomerSupplierTypeField().val();
    },

    getAddNewCustomerButton: function() {
      return this.find('button.js-new-customer-button');
    },

    getAddNewSupplierButton: function() {
      return this.find('button.js-new-supplier-button');
    },

    getNominalAccountSelect: function() {
      return this.find(".nominal_account_selector");
    },

    getNominalAccountSelectsIncludingSplits: function() {
      return this.find(".nominal_account_selector, .split_account");
    },

    getInvoiceRefField: function() {
      return this.find('.invoice_ref_tf');
    },

    getInvoiceRefSelector: function() {
      return this.find('#invoice_ref_selector');
    },

    getDescriptionTf: function() {
      return this.find('.description_tf');
    },

    getCurrencySelect: function() {
      return this.find(".bt_currency_field.show-disabled-select");
    },

    getHiddenCurrencyField: function() {
      return this.find(".bt_currency_field.hidden");
    },

    getNetAmountTf: function() {
      return this.find(".net_amount_tf");
    },

    getPriceTf: function() {
      return this.find(".price_tf");
    },

    getStockItemSelect: function() {
      return this.find(".stock_item_selector");
    },

    getProjectSelect: function() {
      return this.find(".project_selector");
    },

    getStockQuantity: function() {
      return this.find(".stock_item_quantity_tf");
    },

    getNetAmount: function() {
      return truncateAmountToPennyPrecisionInElement(this.getNetAmountTf());
    },

    getTaxCodeSelect: function() {
      return this.find('.tax_code_field');
    },

    getTaxCodeSelectsIncludingSplits: function() {
      return this.find('.tax_code_field, .split_tax_code_field');
    },

    getRowTaxRate: function() {
      return getTaxRateFromTaxCodeSelect(this.getTaxCodeSelect());
    },

    getTaxAmountTf: function() {
      return this.find(".tax_amount_tf");
    },

    getTotalAmountTf: function() {
      return this.find('.total_amount_transaction_tf');
    },

    isReadOnlyTotal: function() {
      return this.getTotalAmountTf().is('[readonly]');
    },

    getIsSplitField: function() {
      return this.find('.is_split_field');
    },

    getLineItemsModal: function() {
      return this.find('.splits-modal');
    },

    find: function(selector) {
      return this.getRow().find(selector);
    },

    getRow: function() {
      if(this.$row) {
        return this.$row;
      } else {
        throw new Error('No element provided for batchInvoiceRow');
      }
    }
  };
})();
