import { Controller } from '@hotwired/stimulus'
import { normalizeDateString } from 'helpers/normalizeDateString'

export default class extends Controller {
  static classes = ['hidden']
  static targets = ['field', 'add']
  static values = {
    date: String,
    updateUrl: String,
    modalUrl: String,
    startDate: String,
    endDate: String,
    selectNew: Boolean
  }

  initialize() {
    this.unassignedEnterpriseOptionValue = -1

    $(this.fieldTarget).chosen({ width: '100%', search_contains: true })
    if (this.hasAddTarget) {
      const button = this.addTarget.cloneNode(true)
      this.element.querySelector('.chosen-drop').appendChild(button)
      button.classList.remove(this.hiddenClass)
    }
    this.updateRelevantJavascriptFunctions()
  }

  displayModal() {
    // This is a hack to close the chosen options when displaying the modal
    // and should be replaced with something better when possible.
    // We should be able to trigger the chosen event:
    // $(this.fieldTarget).trigger("chosen:close")
    // however this doesn't seem to work
    $('body').click()

    this.selectNewValue = true
    window.dispatchEvent(new CustomEvent('modal:show', { detail: this.modalUrlValue }))
  }

  connect() {
    const dateField = document.querySelector("div[data-controller='input-date non-batch-date'] input")
    if (dateField) {
      this.dateValue = dateField.value
    }
  }

  updateDateIfRequired(event) {
    const eventId = event.detail.id
    const eventRowId = eventId.replace('input_date', '').replace('date_field', '')
    const rowId = this.fieldTarget.closest('tr.batch_row').dataset.id

    if (eventRowId === rowId) {
      this.dateValue = event.detail.date
    }
  }

  updateDate(event) {
    this.dateValue = event.detail.date
  }

  updateStartDate(event) {
    this.startDateValue = event.detail.date
  }

  updateEndDate(event) {
    this.endDateValue = event.detail.date
  }

  updateSelectedId(event) {
    this.selectedIdValue = event.target.value
  }

  dateValueChanged() {
    if (this.hasDateValue) {
      this.update()
    }
  }

  startDateValueChanged() {
    if (this.hasStartDateValue) {
      this.update()
    }
  }

  endDateValueChanged() {
    if (this.hasEndDateValue) {
      this.update()
    }
  }

  update() {
    if (this.hasUpdateUrlValue) {
      $.ajax({
        method: 'get',
        url: this.updateUrlValue,
        data: {
          date: normalizeDateString(this.dateValue),
          selected_id: this.fieldTarget.value,
          start_date: normalizeDateString(this.startDateValue),
          end_date: normalizeDateString(this.endDateValue),
          select_new: this.selectNewValue
        },
        dataType: 'html'
      }).done((data) => {
        // eslint-disable-next-line no-useless-call
        this.refresh.call(this, data)
        this.dispatchEventOnUpdate()
      })
    }

    window.dispatchEvent(new CustomEvent('dates-updated'))
  }

  refresh(options) {
    let currentSelection = this.fieldTarget.value
    this.selectNewValue = false
    this.fieldTarget.innerHTML = options

    if (this.fieldTarget.disabled) {
      const blankOption = document.createElement('option')
      blankOption.value = ''
      this.fieldTarget.add(blankOption, this.fieldTarget.options[0])
      this.fieldTarget.value = ''
    }

    // Get the values from the options HTML and if the current selection
    // is no longer available for this date range, notify the user and select 'unassigned' enterprise.
    // The code is quite obscure, and I could not find a practical nicer way.
    const optionValues = (options.match(/value="-?\d+"/g) || [])
      .map(o => o.match(/-?\d+/)[0]) || []

    if (currentSelection && !optionValues.includes(currentSelection)) {
      currentSelection = this.unassignedEnterpriseOptionValue
      window.notify('The Enterprise selected is not valid for this date so has been removed.')
    }

    $(this.fieldTarget).val(currentSelection).trigger('chosen:updated')
    window.allowEnterToTab()
  }

  // javascript events using the nested form gem seem to run before relevant stimulus.js files have loaded in.
  // use this method to call any functions that need to be called now that this component has been fullly
  // initialised.
  updateRelevantJavascriptFunctions() {
    // method used to allow tabbing over it.
    window.allowEnterToTab()
  }

  dispatchEventOnUpdate() {
    document.dispatchEvent(new CustomEvent('enterprises-select-updated'))
  }
}
