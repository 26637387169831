/* Code that relates to adding nominal accounts anywhere */
window.checkNeedForNewNominalOption = function(section) {
  if (section) {
    var elements = section.find('select.js-add-new-nominal');
  } else {
    var elements = $('select.js-add-new-nominal');
  }

  if (elements.length > 0) {
    elements.on('chosen:ready', function(event, chosen) {
      var select = $(event.target);
      var chosen_container = select.siblings('div.chosen-container');
      addNewNominalOption(chosen_container);
      setLinkWithAllowedNominalTypes(select);
    });
  }
}

function addNewNominalOption(chosen_container) {
  if (chosen_container.find('button.js-new-nominal-button').length === 0) {
    chosen_container.find('ul.chosen-results').after("<button class='btn btn-primary btn-xs pull-right js-new-nominal-button' tabindex='-1'>+ Add new</button>")
    registerNewNominalClickListener(chosen_container);
  }
}

window.setLinkWithAllowedNominalTypes = function(select) {
  allowed_types = select.data('allowed-nominal-types') || ''
  select.siblings('a.add-new-nominal-link').remove();
  select.after("<a href='/nominal_accounts/new?allowed_types="+allowed_types+"' data-remote='true' class='add-new-nominal-link' tabindex='-1'><i class='add-new-nominal-link'></i></a>")
}

function registerNewNominalClickListener(chosen_container) {
  chosen_container.find('button.js-new-nominal-button').click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    hideAllPopovers();
    newNominalButtonClicked($(this));
  });
}

function newNominalButtonClicked(button) {
  $(".new-nominal-button-clicked").removeClass("new-nominal-button-clicked");
  button.addClass('new-nominal-button-clicked');
  showNewNominalForm(button);
}

function showNewNominalForm(button) {
  if ($('body.modal-open').length > 0) {
    showNewNominalFormInPopover(button);
  } else {
    showNewNominalFormInModal(button);
  }
}

function showNewNominalFormInModal(button) {
  button.closest('div.chosen-container').siblings('a.add-new-nominal-link').find('i').click();
}

function showNewNominalFormInPopover(button) {
  var div = button.closest('.js-modal-account-select');
  initFormPopover(div);
  initNominalAccountsModal();
  $('#nominal_account_account_type').change();
}

function initFormPopover(element) {
  destroyPopover($(element));
  
  var form_content = element.find('.popover-form-content').first().data('popover-form-content');
  showPopoverWithContent($(element), form_content);

  formatFormsIn($('.js-popover-form'));
  $('.popover-submit-button').off().click(function(event) {
    // Prevent it trying to submit the main form
    event.preventDefault();
    event.stopPropagation();
    $(this).closest('.popover').find('form').first().trigger('submit.rails');
  });
  $('.popover-dismiss-button').off().click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    destroyPopover($(element));
    $('.js-modal-account-select').val('').trigger('chosen:updated');
  });
};
