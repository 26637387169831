window.initCompaniesHouseSearch = function($fieldElement, fields_setters, reset_setters) {
  initListenerOnFieldKeyUpEvent($fieldElement);
  initListenerOnFieldUpdate($fieldElement, fields_setters, reset_setters)
}

function initListenerOnFieldKeyUpEvent($fieldElement) {
  var timer = null;
  $fieldElement.keyup(function(){
    var selectedSearchTerm = $fieldElement.val();
    $('#company_company_name').val(selectedSearchTerm);
    clearTimeout(timer);
    timer = setTimeout(function(){ callCompaniesSearchEndpoint(selectedSearchTerm, insertOptionElementToSelectField, null) }, 1000)
  });
 //Farmplan remove resetContactFields()
}

function insertOptionElementToSelectField(response){
  var companies = response['companies'];
  var optionsTags = "<option></option>";
  companies.forEach(function (company) {
    optionsTags += '<option value="' + company.company_name + '" data-company-number="' + company.company_number + '">' + company.company_name + '</option>'
  });

  $('#companies_house_search_select_field').html(optionsTags);
  $('#companies_house_search_select_field').trigger("chosen:updated").change();
}

function initListenerOnFieldUpdate($fieldElement, fields_setters, reset_setters) {
  var $companySelectionField = $('#companies_house_search_select_field');
  $companySelectionField.on('chosen:updated', function(event){
    event.preventDefault();
    if ($companySelectionField.find('option:selected').data('company-number')){
      fields_setters();
    } else {
      reset_setters();
    }
    $fieldElement.change();
  });
}
