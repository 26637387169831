import { Controller } from '@hotwired/stimulus'
import { DateHelper } from 'helpers/date_helper'

export default class extends Controller {
  static targets = ['dateStart', 'dateEnd']

  dateStartInYearlyRange() {
    const startDate = new DateHelper(this.dateStartTarget.value, this.dateStartTarget.dataset.format).addYear()
    const endDate = new DateHelper(this.dateEndTarget.value, this.dateEndTarget.dataset.format)

    if (startDate.toDate() <= endDate.toDate()) {
      this.dateEndTarget.value = startDate.toMonthFormat()
      this.dateEndTarget.dataset.value = startDate.toISOFormat()
    };
  };

  dateEndInYearlyRange() {
    const startDate = new DateHelper(this.dateStartTarget.value, this.dateStartTarget.dataset.format)
    const endDate = new DateHelper(this.dateEndTarget.value, this.dateEndTarget.dataset.format).subtractYear()

    if (endDate.toDate() >= startDate.toDate()) {
      this.dateStartTarget.value = endDate.toMonthFormat()
      this.dateStartTarget.dataset.value = endDate.toISOFormat()
    };
  };
};
