(function () {
    'use strict';
  
    window.initInvoiceModal = function (row_id) {
      $('#invoice_button' + row_id).html("Invoices");
      var modal = $('#invoice-modal' + row_id);
      if (!modal.data('persisted')) {
        if (!($('#inv-tbody' + row_id).children('tr').length - 1)) {
          $('#no_results_found').removeClass('hidden');
        }
      }
      setInvoiceOtherPayments(row_id);
      if (modal.data('initme')) {
        initEventsForInvoiceModal(row_id);
        modal.removeData('initme');
      }
      $('#inv-tbody' + row_id).children('tr').each(function () {
        calculateOutstandingAmount(this.id);
      });
    }
  
    // Sets the 'other-payment' attribute on each invoice within modal, so
    // that outstanding can be calculated correctly for unsaved batch
    function setInvoiceOtherPayments(row_id) {
      var modal = $('#invoice-modal' + row_id);
      var type = modal.data('type');
      var account = modal.data('account');
      var my_invoices = {};
      // find relevant invoices
      $('#inv-tbody' + row_id).children('tr').not('.hidden').each(function () {
        var inv = this.id.split('inv')[1];
        if (inv) {
          my_invoices[inv] = { net: 0, tax: 0, total: 0 }
        }
      });
      // add payments on each invoice modal
      $.each(getInvoiceRowIds(type, account), function (index, rival_row_id) {
        if (rival_row_id == row_id) { return true; }
        $.each(my_invoices, function (key, val) {
          var rival_net_val = $('#net_payment' + rival_row_id + 'inv' + key).val();
          var rival_tax_val = $('#tax_payment' + rival_row_id + 'inv' + key).val();
          var rival_total_val = $('#total_payment' + rival_row_id + 'inv' + key).val();
          my_invoices[key]['net'] = accurateAddition(my_invoices[key]['net'], rival_net_val);
          my_invoices[key]['tax'] = accurateAddition(my_invoices[key]['tax'], rival_tax_val);
          my_invoices[key]['total'] = accurateAddition(my_invoices[key]['total'], rival_total_val);
        });
      });
      $.each(my_invoices, function (key, val) {
        $('#net_outstanding' + row_id + 'inv' + key).data('other-payments', val['net']);
        $('#tax_outstanding' + row_id + 'inv' + key).data('other-payments', val['tax']);
        $('#total_outstanding' + row_id + 'inv' + key).data('other-payments', val['total']);
      });
    }
  
    // Returns the ids of all rows containing invoices matching type/account
    function getInvoiceRowIds(type, account) {
      var match = [];
      $('#bank-transaction-rows').children('tr.batch_row').each(function () {
        var row_id = this.getAttribute('data-id');
        var modal = $('#invoice-modal' + row_id);
        if (type === modal.data('type') && account === modal.data('account')) {
          match.push(row_id);
        }
      });
      return match;
    }
  
    function initEventsForInvoiceModal(row_id) {
      var $modal = $('#inv-tbody' + row_id).parents('.payment_allocations_modal');
      $modal.find('.pif a.pif_btn').off('click', payInFullButtonClick);
      $modal.find('.total_amount_cell > .total_amount_tf').off('change', totalAmountFieldChange);
      $modal.find('.overpayment_amount_tf').off('change', overpaymentAmountFieldChange);
  
      $modal.find('.pif a.pif_btn').on('click', payInFullButtonClick);
      $modal.find('.total_amount_cell > .total_amount_tf').on('change', totalAmountFieldChange);
      $modal.find('.overpayment_amount_tf').on('change', overpaymentAmountFieldChange);
      $('#modal-close-btn' + row_id).off().on('click', tickConfirmationCheckbox);

    }
  
    // Set payment amount to external outstanding
    function payInFullButtonClick(event) {
      event.preventDefault();
      var row_id = getClosestInvSelectorRowIdToEvent(event);
      var total_outstanding = getTotalOutstandingAmount(row_id);
      updateTotalPaymentAndTriggerChange(row_id, total_outstanding)
    }
  
    function getClosestInvSelectorRowIdToEvent(event) {
      return $(event.currentTarget).closest('tr').data('id');
    }
  
    function getTotalOutstandingAmount(row_id) {
      var transaction_row_id = row_id.split('inv')[0];
      var transaction_total = $('#total_amount_field' + transaction_row_id).data('expectedTotal');
  
      var external_total = externalOutstanding(row_id, 'total');
      if (transaction_total != "undefined" && transaction_total < external_total && transactionAndCategorySeletedInSameCurrency(transaction_row_id)) {
        return transaction_total;
      } else {
        return external_total;
      }
    }
  
    // Outstanding amount, not including this modal
    window.externalOutstanding = function (row_id, ntt_class) {
      var outstandingField = $('#' + ntt_class + '_outstanding' + row_id);
      var original = outstandingField.data('original-outstanding');
      var other = outstandingField.data('other-payments'); // paid on other live invoice modals
      return original - other;
    }
  
    window.transactionAndCategorySeletedInSameCurrency = function (transaction_row_id) {
      var transaction_currency_id = $('#currency_field' + transaction_row_id).data('bankAccountCurrency');
      var category_currency_code = $('#account_field' + transaction_row_id).find('option:selected').data('currencyId');
      if ( transaction_currency_id ) {
        return transaction_currency_id == category_currency_code
      }else{
        return true
      }
    }
  
    function updateTotalPaymentAndTriggerChange(row_id, total_outstanding) {
      var payment = $('#total_payment' + row_id);
      if (payment.val() == total_outstanding) {
        payment.val('');
      } else {
        payment.val(total_outstanding);
      }
      payment.trigger('change');
    }
  
    function totalAmountFieldChange(event, change_was_overpayment) {
      var $target = $(event.currentTarget);
      var inv_row_id = getClosestInvSelectorRowIdToEvent(event);
      var parent_row_id = getParentRowID($target)
  
      updateTotalAmount($target, inv_row_id);
      calculatePaNetAndTax(inv_row_id);
      calculateInvoiceTotal($target, event);
      calculateOutstandingAmount(inv_row_id);
      if (!change_was_overpayment) {
        warnIfTransactionDatedBeforeInvoice(parent_row_id, inv_row_id);
        updateOverpaymentField(parent_row_id);
      }
    }
  
    function getParentRowID(element) {
      return element.parents('.payment_allocations_modal').find('table').data('parent-row');
    }
  
    function updateTotalAmount($total, inv_row_id) {
      var outstanding = externalOutstanding(inv_row_id, 'total');
      if ($total.val() < 0) {
        $total.val(0); // User mistakes..
      } else if ($total.val() > outstanding) {
        $total.val(outstanding);
      }
    }
  
    function modalFieldTotal(parent_row_id, field_class) {
      var $modal_rows = $('#inv-tbody' + parent_row_id + " .invoice_row")
      var fields = $modal_rows.find(field_class)
      var total = 0
      fields.each(function () {
        total += Number($(this).val());
      });
      return total
    }
  
    function anyPaymentsLessThanTotals(parent_row_id) {
      var totals_remaining = $('#inv-tbody' + parent_row_id).find('.inv_total_amt_outstanding')
      var payments = $('#inv-tbody' + parent_row_id).find('.total_amount_tf')
      var less_than_total_payments = false
      totals_remaining.each(function (i, total) {
        if ($(total).val() > $(payments[i]).val()) { less_than_total_payments = true }
      });
      return less_than_total_payments
    }

    function calculatePaNetAndTax(row_id) {
      // Logic matching calculate_net_and_tax for PAs
      var total_val = parseFloat($('#total_payment' + row_id).val());
      var total_outstanding_val = externalOutstanding(row_id, 'total');
      var net_field = $('#net_payment' + row_id);
      var tax_field = $('#tax_payment' + row_id);
      var net_outstanding_val = externalOutstanding(row_id, 'net');
      var tax_outstanding_val = externalOutstanding(row_id, 'tax');
  
      if (total_val === total_outstanding_val) {
        net_field.val(net_outstanding_val);
        tax_field.val(tax_outstanding_val);
      } else {
        var proportion = total_val / total_outstanding_val;
        var tax_val = (tax_outstanding_val * proportion).toFixed(2);
        var net_val = accurateSubtraction(total_val, tax_val).toFixed(2);
        tax_field.val(tax_val);
        net_field.val(net_val);
      }
    }
  
    // Sets the amount fields in main table based on modal invoice selection
    function calculateInvoiceTotal(modal_total_amount_field, event) {
      var $modal = modal_total_amount_field.parents('.payment_allocations_modal');
      var row_id = $modal.find('table').data('parent-row');
      var new_total = totalCalc($modal.find('.total_amount_tf'));
      if ($('#bank_transaction_table').hasClass('hide_vat')) { // FIXME
        // net and tax will update automatically due to the change event
        var total_field = $('#net_amount_field' + row_id); // net = total if no vat
        total_field.val(new_total);
      } else {
        var total_field = $('#total_amount_field' + row_id);
        var net_field = $('#net_amount_field' + row_id);
        var tax_field = $('#tax_amount_field' + row_id);
        var new_tax = totalCalc($modal.find('.tax_amount_tf'));
        var new_net = totalCalc($modal.find('.net_amount_tf'));
        total_field.val(new_total);
        net_field.val(new_net);
        tax_field.val(new_tax);
      }
      updateRunningBalances(row_id);
      checkInvoiceExistsWithAmountsAndNominalAccount(event.currentTarget);
      checkForDuplicateBankTransfers(event);
      checkForPotentialDuplicates(event);
      setConversionRateIfPossible(row_id, $modal, new_total);
      updateTransactionDescription(row_id, $modal);
    }
  
    function totalCalc(elements) {
      var new_total = null;
      elements.each(function () {
        if ($(this).val()) {
          if (new_total == null) {
            new_total = 0;
          }
          new_total += truncateAmountToPennyPrecisionInElement($(this));
        }
      });
      if (new_total != null) {
        new_total = new_total.toFixed(2);
      }
      return new_total;
    }
  
    function setConversionRateIfPossible(row_id, $modal, customer_supplier_currency_total){
      var customer_supplier_currency_id = $('#account_field' + row_id).find('option:selected').data('currencyId');
      var bank_account_currency_id = $('#bank_account_field' + row_id).data('currencyId');
      var base_currency_id = companySettings.base_currency.id;
      var bank_account_currency_total = $('#total_amount_field' + row_id).data('expectedTotal');
      if (typeof (bank_account_currency_total) == 'undefined') { return; }
      if (bank_account_currency_id === base_currency_id) {
        if (customer_supplier_currency_id != base_currency_id) {
          setConversionRateFromTotals(row_id, bank_account_currency_total, customer_supplier_currency_total);
        }
      } else {
        if (customer_supplier_currency_id === base_currency_id) {
          setConversionRateFromTotals(row_id, customer_supplier_currency_total, bank_account_currency_total);
        }
      }
    }
  
    function setConversionRateFromTotals(row_id, base_total, non_base_total) {
      if (base_total == 0) { return; }
      // Multiplying and then dividing by the precision allows us to use the floor function
      // (which rounds down to an integer) when really we want a decimal value
      var PRECISION = 1000000000000.0;
      var rate = Math.floor((non_base_total / base_total) * PRECISION + 0.00000000001) / PRECISION;
      $('#conversion_rate_field' + row_id).val(rate);
    }
  
    // Sets the main table description field to show the selected invoice refs
    function updateTransactionDescription(row_id, modal_table) {
      var $desc_field = $('#description_field' + row_id);
      $desc_field.typeahead('val', getTransactionDescription(modal_table));
        hideFieldIndicators($desc_field.parents('.form-group'));
    }
  
    // Returns all invoice refs present on invoice as string
    function getTransactionDescription(table) {
      var refs = [];
      table.find('.invoice_ref').each(function () {
        var payment_amount = parseFloat($(this).closest('tr').find('input.total_amount_tf').val());
        if (payment_amount > 0) {
          refs.push($(this).val());
        }
      });
      return refs.join(', ');
    }
  
    // Calculates current outstanding amount for invoice row.
    // This could be optimised further using ids.
    function calculateOutstandingAmount(row_id) {
      var out = $('#total_outstanding' + row_id);
      var original = out.data('original-outstanding');
      var other = out.data('other-payments'); // paid on other live invoice modals
      var payment = $('#total_payment' + row_id).val();
      var outstanding = accurateSubtraction(original, other);
      outstanding = accurateSubtraction(outstanding, payment);
      if (outstanding < 0) { outstanding = 0; } // Fix User (hopefully ;)) mistakes
      else if (outstanding > original) { outstanding = original; }
      out.val(formatAsCurrency(outstanding));
    };
  
    function updateOverpaymentField(parent_row_id) {
      var total_outstanding = modalFieldTotal(parent_row_id, '.inv_total_amt_outstanding');
      var $overpayment_total = $('#total_paymentoverpayment' + parent_row_id);
      var is_import = Boolean($('#invoice-modal' + parent_row_id).data('is-import'));
  
      if (total_outstanding == 0) {
        if (is_import) {
          setOverpaymentValue($overpayment_total, calculateOverpayment(parent_row_id))
          if($overpayment_total.val() > 0){
            notify(`An overpayment of ${$overpayment_total.val()} has been calculated and applied automatically.`, 'info', ''); //FARMPLAN: Added conditional to the overpayment popup
          }
        } else { 
          setOverpaymentState($overpayment_total, false);
        }
      } else if (!$overpayment_total.prop('disabled')) {
        setOverpaymentValue($overpayment_total, "")
        setOverpaymentState($overpayment_total, true);
      }
      $overpayment_total.trigger('change');
    }
  
    function setOverpaymentValue($overpaymentField, value) {
      $overpaymentField.val(value);
    }
  
    function setOverpaymentState($overpaymentField, readonly_state) {
      $overpaymentField.attr('readonly', readonly_state);
    }
  
    function calculateOverpayment(parent_row_id) {
      var payment_total = modalFieldTotal(parent_row_id, '.total_amount_cell > .total_amount_tf');
      var parent_transaction_value = $('#invoice-modal-table' + parent_row_id).find('#expected_total').val();
      var overpayment_difference = parent_transaction_value - payment_total;
      return Math.max(0, overpayment_difference).toFixed(2);
    }
  
    function warnIfTransactionDatedBeforeInvoice(parent_row_id, inv_row_id) {
      var bt_date = dateFromDateInput($('#date_field' + parent_row_id));
      var inv_date = dateFromDateInput($('#date' + inv_row_id))
      if (bt_date < inv_date) {
        notify('This invoice is dated after the payment date.', 'info', '');
      }
    }
  
    function overpaymentAmountFieldChange(event){
      totalAmountFieldChange(event, true)
    }
  
    // On 'invoice' button click, do we send ajax request for invoices, or
    // is the data already on page?
    window.showInvoiceModal = function (event) {
      var row_id = getClosestBtRowIdToEvent(event);
      var inv_modal = $('#invoice-modal' + row_id);
      if (inv_modal.data('persisted')) {
        return readOnlyInvoiceModal(inv_modal, row_id);
      }
      var type = $('#type_field' + row_id).val();
      var account = getBtAccountSelect(row_id).val();
      var date = $('#date_field' + row_id).val();
  
      if (!type || !account || !date) {
        alert('You must specify the date, type and category before choosing invoices.');
        return false;
      }
      var existing_type = inv_modal.data('type');
      var existing_account = inv_modal.data('account');
      if (existing_type == type && existing_account == account) {
        // modal already loaded on page, skip ajax request
        // Note: we don't set the the existing_type attribute here- if request
        // fails, it won't be accurate. Instead, set via #invoice format.js
        initInvoiceModal(row_id);
        inv_modal.modal('show');
        return false; // == event.preventDefault(); event.stopPropagation();
      }
      account = account.split('#')[1];
      // update the get parameters for the ajax request
      var button = $('#invoice_button' + row_id);
      var href = button.attr('href').split(); // make array with href, but keep params
      button.html("<i class='zmdi zmdi-rotate-right zmdi-hc-spin'></i>");
      href[1] = 'type=' + type; // add/change the others
      href[2] = 'account=' + account;
      button.attr('href', href.join('&'));
      // Now its up to the controller #invoice action
    }
  
    function readOnlyInvoiceModal(inv_modal, row_id) {
      if (inv_modal.children().length > 0) {
        initInvoiceModal(row_id);
        inv_modal.modal('show');
        return false; // == event.preventDefault(); event.stopPropagation();
      }
      // Else nothing; let the link do its ajax direct normally
    }

    function tickConfirmationCheckbox(event) {
      var inv_row_id = getClosestInvSelectorRowIdToEvent(event);
      $('#confirmation' + inv_row_id).prop('checked', false).click();
    }
  
    window.emptyModalIfTypeHasChanged = function (row_id, type) {
      var modal = $('#invoice-modal' + row_id);
      if (type != modal.data('type')) {
        modal.empty();
        modal.data('type', '');
      }
    }
  })();
  
