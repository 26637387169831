(function() {
  'use strict';

  //COMPANY TYPE
  function companyWizardCompanyTypeChange(event) {
    var companyTypeId = $('#company_typer_id').val();
    var companyTypeName = $('#company_typer_id').data('type-info')[companyTypeId];
    toggleCompanyTypeDependentFields(companyTypeName);
  }

  function toggleCompanyTypeDependentFields(company_type){
    if ("Limited Company" == company_type) {
      slideAndDisableOrEnableInputs(".directors", false);
    } else {
      slideAndDisableOrEnableInputs(".directors", true);
    }

    if ("Charity" == company_type) {
      slideAndDisableOrEnableInputs(".trustees", false);
    } else {
      slideAndDisableOrEnableInputs(".trustees", true);
    }


    if ("Global Company" == company_type) {
      slideAndDisableOrEnableInputs(".owners", false);
    } else {
      slideAndDisableOrEnableInputs(".owners", true);
    }

    if (["Partnership", "Limited Liability Partnership (LLP)", "Global Partnership"].includes(company_type)) {
      slideAndDisableOrEnableInputs(".partners", false);
    } else {
      slideAndDisableOrEnableInputs(".partners", true);
    }

    // FARMPLAN
    if ("Trust/Estate".includes(company_type)) {
      slideAndDisableOrEnableInputs(".beneficiaries", false);
    } else {
      slideAndDisableOrEnableInputs(".beneficiaries", true);
    }
    // FARMPLAN END

    if (["Limited Company", "Limited Liability Partnership (LLP)" ,"Global Company"].includes(company_type)) {
      slideAndDisableOrEnableInputs(".js-company-incorporation", false);
      slideAndDisableOrEnableInputs(".js-charity-incorporation", true);
      slideAndDisableOrEnableInputs(".js-non_incorporation", true);
      slideAndDisableOrEnableInputs(".js-trading-name", false);
    } else {
      slideAndDisableOrEnableInputs(".js-company-incorporation", true);
      slideAndDisableOrEnableInputs(".js-charity-incorporation", true);
      slideAndDisableOrEnableInputs(".js-non_incorporation", false);
      slideAndDisableOrEnableInputs(".js-trading-name", true);
    }

    if (["Charity"].includes(company_type)){
      slideAndDisableOrEnableInputs(".js-charity-incorporation", false);
      slideAndDisableOrEnableInputs(".js-non_incorporation", true);
      slideAndDisableOrEnableInputs(".js-trading-name", false);
    }

    if (["Limited Company", "Limited Liability Partnership (LLP)"].includes(company_type)) {
      companyNameSelect(true);
      var reg_no = $('#company_company_reg_no').val();
      if (company_type == "Limited Company") {
        var officer_selector = '.js-director-name'
      } else if (company_type == "Limited Liability Partnership (LLP)") {
        var officer_selector = '.js-partner-name'
      }

      var officers_are_nameless = $(officer_selector).val() == "";
      if (reg_no != "" && officers_are_nameless){
        $('#company_company_reg_no').change();
      } else {
        disableFinancialYearEndIfCompanyRegNo();
      }
    } else {
      companyNameSelect(false);
      $('#company_prog_date_end_for_ch').attr('readonly', false);
      $('#company_prog_date_end_for_ch').val($('#company_prog_date_end').data("date"));
    }
  }

  function slideAndDisableOrEnableInputs(selector, disabled){
    disabled ? $(selector).slideUp() : $(selector).slideDown()
    disableOrEnableInputs(selector, disabled)
  }

  function disableOrEnableInputs(selector, disabled){
    $(selector+' input').each(function() {
      $(this).prop("disabled", disabled);
    });
  }

  function companyNameSelect(selection) {
    selection ? showCompanySelectInput() : showCompanyTextInput()
  }

  function showCompanyTextInput() {
    $('.company-text-input').show();
    $('.company-text-input #company_company_name').attr('disabled', false)
    $('.form-control.company-text-input').val();
    $('.company-select-input').hide();
    $('.company-select-input #company_company_name').attr('disabled', true)
  }

  function showCompanySelectInput() {
    $('.company-text-input').hide();
    $('.company-text-input #company_company_name').attr('disabled', true)
    $('.company-select-input').show();
    $('.company-select-input #company_company_name').attr('disabled', false)
  }

  //COUNTRY
  function onCountryChange(event){
    setBaseCurrencyFromCountry(event);
  }

  function setBaseCurrencyFromCountry(event) {
    var currency_code = $(event.target).find('option:selected').data('currencyCode');
    if (!currency_code.length) { return; }
    var $base_currency_field = $('#company_base_currency_id');
    var new_base_currency = $base_currency_field.find('option[data-currency-code="'+currency_code+'"]');
    if (!new_base_currency.length) {
      $base_currency_field.val('').trigger('change').trigger('chosen:updated');
      $('#hidden_base_currency_field').val('');
    } else {
      $base_currency_field.val(new_base_currency.val()).trigger('change').trigger('chosen:updated');
      $('#hidden_base_currency_field').val($base_currency_field.val());
    }
  }

  //INVOICING
  function setInvoicingVisibility() {
    showOrHideSectionWithCheckbox('#new_business_wizard', '#company_customer_credit', '.invoice_creator_fields');
  }

  function notifyInvoicing(){
    var message = 'Invoice settings have now been enabled in the options menu'
    var type = 'info'
    notify(message, type)
  }

  function setInvoiceTypeVisibility() {
    showOrHideSectionWithCheckbox('#new_business_wizard', '.pandle_creates_invoices', '.pandle_invoice_fields');
  }

  //PREVIOUS BOOK KEEPING
  function setPreviousBookKeepingFields() {
    var isOther = $('#company_previous_bookkeeping_drop_down').val() === "Other";
    var isNone = $('#company_previous_bookkeeping_drop_down').val() === "None - Business is a startup";

    if(isOther){
      setPreviousBookKeepingTypeText("");
      $('.previous_bookkeeping_method').slideDown();
      $("#startup-checkbox").prop('checked', false);
    } else if(isNone) {
      $('.previous_bookkeeping_method').hide();
      setPreviousBookKeepingTypeText("None - Business is a startup");
      $("#startup-checkbox").prop('checked', true);
    } else {
      $('.previous_bookkeeping_method').hide();
      var drop_down_text = $('#company_previous_bookkeeping_drop_down').val();
      setPreviousBookKeepingTypeText(drop_down_text);
      $("#startup-checkbox").prop('checked', false);
    }
  }

  function setPreviousBookKeepingTypeText(value){
    $('#company_previous_bookkeeping_method').val(value);
  }

  //VAT
  function addVatTransactionStartWarning() {
    var msg = I18n.t('companies.new.vat_transactions_start_warning');
    addHelpMessageUnlessExists('',HELP_WARNING,msg,'vat-period-start-transactions');
  };

  function setVatVisibility() {
    showOrHideSectionWithCheckbox('#new_business_wizard', '#company_vat_registered', '.vat_reg_fields');

    setTimeout(function() {
      if($('.vat_reg_fields').is(":visible")) {
        addVatTransactionStartWarning();
      }
    }, 500);
  };

  function setEuVatVisibility() {
    showOrHideSectionWithCheckbox('#new_business_wizard', '#company_uses_eu_vat', '.eu_vat_reg_fields');
  }

  // BANK ACCOUNTS - CURRENCIES
  function addCompanyWizardBankAccountEventListeners() {
    initCheckForPersonalBankAccount();
    $('#company_multiple_currencies').click(setCurrencyVisibility);
    $(document).on('nested:fieldAdded:bank_accounts', initInsertedBankAccountFields);
  }

  function setCurrencyVisibility() {
    // We can't use is(":visible") here because the currency field might be invisible for another
    // reason (eg merchant account not yet checked)
    var disabled = !$('#company_data').data('currency');
    var fields = $('.multi_currency_fields');
    // Set visibility of editors
    $(fields).toggle(!disabled);
  }

  function initInsertedBankAccountFields(event){
    setCurrencyVisibility();
    initCheckForPersonalBankAccount();
    $('.form-control.chosen-select.bank_account_currency').last().chosen();
  }

  function setMinMaxProgDates(){
    if (isFeatureToggleActive('financial_year_in_setup')) {
      afterDatepickersComponentHaveBeenInitialised(setFinancialYearDatePicker);
    } else {
      afterDatepickersComponentHaveBeenInitialised(function(){
        var startDate = getDateTimePicker($('#company_prog_date_start'));
        var endDate = getDateTimePicker($('#company_prog_date_end'))
        if (endDate.date()){
          startDate.maxDate(endDate.date());
        }
        if (startDate.date()){
          endDate.minDate(startDate.date());
        }
      })
    }
  }

  function addCompanyWizardCompanyInfoListeners() {
    $("#company_typer_id").change(companyWizardCompanyTypeChange);
    $("#company_company_reg_no").change(disableFinancialYearEndIfCompanyRegNo);
    $('#company_prog_date_end').on('dp.change', setMinMaxProgDates);
    $('#company_previous_bookkeeping_drop_down').change(setPreviousBookKeepingFields);
    if(!isFeatureToggleActive('financial_year_in_setup')){
      $('#company_prog_date_start').on('dp.change', setMinMaxProgDates);
    }
    $('#industry_selector').change(checkAndShowOtherField);
  }

  function addCompanyWizardFinancialInfoEventListeners() {
    // Show invoicing fields only when have customer credit
    $('#company_customer_credit').click(setInvoicingVisibility);
    // Show invoice type fields only when pandle creates invoices
    $('.pandle_creates_invoices').click(setInvoiceTypeVisibility);
    // Show VAT fields only when VAT registered
    $('#company_vat_registered').click(setVatVisibility);
    $('#company_uses_eu_vat').click(setEuVatVisibility);
    $('.vat_scheme').change(vatSchemeTypeChange);
    $('#company_has_an_accountant').click(setAccountantFieldsVisibility);
  }

  function setupPartnersAndDirectorsFields() {
    // FARMPLAN: Added beneficiaries count and max
    var partnersFieldsCount, directorsFieldsCount, trusteesFieldsCount, ownersFieldsCount, beneficiariesFieldsCount,
      maxPartnerFieldsCount = 9, maxDirectorFieldsCount = 9, maxTrusteeFieldsCount = 9, maxOwnersFieldsCount = 9, maxbeneficiaryFieldsCount = 19;

    function togglePartnerAddLink() {
      toggleLink('#add_partner', partnersFieldsCount <= maxPartnerFieldsCount);
    }

    function toggleTrusteeAddLink() {
      toggleLink('#add_trustee', trusteesFieldsCount <= maxTrusteeFieldsCount);
    }

    // FARMPLAN
    function toggleBeneficiaryAddLink() {
      toggleLink('#add_beneficiary', beneficiariesFieldsCount <= maxbeneficiaryFieldsCount);
    }
    // FARMPLAN END

    function toggleDirectorAddLink() {
      toggleLink('#add_director', directorsFieldsCount <= maxDirectorFieldsCount);
    }

    function toggleOwnerAddLink() {
      toggleLink('#add_owner', ownersFieldsCount <= maxOwnersFieldsCount);
    }

    function toggleLink(selector, show) {
      if (show){
        $(selector).show();
      }else{
        $(selector).hide();
      }
    }

    function addNestedFieldEventListener(event, handlerFunction){
      $(document).off(event).on(event, handlerFunction)
    }

    addNestedFieldEventListener('nested:fieldAdded:trustees', function() {
      trusteeFieldsCount += 1;
      toggleTrusteeAddLink();
    });

    addNestedFieldEventListener('nested:fieldRemoved:trustees', function() {
      trusteeFieldsCount -= 1;
      toggleTrusteeAddLink();
    });

    addNestedFieldEventListener('nested:fieldAdded:partners', function() {
      partnersFieldsCount += 1;
      togglePartnerAddLink();
    });

    addNestedFieldEventListener('nested:fieldRemoved:partners', function() {
      partnersFieldsCount -= 1;
      togglePartnerAddLink();
    });

    // FARMPLAN
    addNestedFieldEventListener('nested:fieldAdded:beneficiaries', function() {
      beneficiariesFieldsCount += 1;
      toggleBeneficiaryAddLink();
    });
    // FARMPLAN END

    // FARMPLAN
    addNestedFieldEventListener('nested:fieldRemoved:beneficiaries', function() {
      beneficiariesFieldsCount -= 1;
      toggleBeneficiaryAddLink();
    });
    // FARMPLAN END

    addNestedFieldEventListener('nested:fieldAdded:owners', function() {
      ownersFieldsCount += 1;
      toggleOwnerAddLink();
    });

    addNestedFieldEventListener('nested:fieldRemoved:owners', function() {
      ownersFieldsCount -= 1;
      toggleOwnerAddLink();
    });

    addNestedFieldEventListener('nested:fieldAdded:directors', function() {
      directorsFieldsCount += 1;
      toggleDirectorAddLink();
    });

    addNestedFieldEventListener('nested:fieldRemoved:directors', function() {
      directorsFieldsCount -= 1;
      toggleDirectorAddLink();
    });

    // Disable director/partner input fields on page load, unless needed
    var companyTypeId = $('#company_typer_id').val();
    var company_type = $('#company_typer_id').data('type-info')[companyTypeId];
    if (!["Limited Company"].includes(company_type)) {
      disableOrEnableInputs('.directors', true)
    }
    if (!["Global Company"].includes(company_type)) {
      disableOrEnableInputs('.owners', true)
    }
    if (!["Partnership", "Limited Liability Partnership (LLP)", "Global Partnership"].includes(company_type)) {
      disableOrEnableInputs('.partners', true)
    }
    // FARMPLAN
    if (!["Trust/Estate"].includes(company_type)) {
      disableOrEnableInputs('.beneficiaries', true)
    }
    // FARMPLAN END
    if (!["Limited Liability Partnership (LLP)","Limited Company","Global Company"].includes(company_type)) {
      disableOrEnableInputs('.incorporation', true)
    }

    // count existing nested fields after page was loaded
    partnersFieldsCount = $('form .partners .fields').length;
    beneficiariesFieldsCount = $('form .beneficiaries .fields').length;
    directorsFieldsCount = $('form .directors .fields').length;
    trusteesFieldsCount = $('form .trustees .fields').length;
    ownersFieldsCount = $('form .owners .fields').length;

    togglePartnerAddLink();
    toggleBeneficiaryAddLink(); // FARMPLAN
    toggleDirectorAddLink();
    toggleTrusteeAddLink();
    toggleOwnerAddLink();
  }

  function initAreYouSureCompaniesNew() {
    checkFormChangedOnPageUnload('#new_company');
  }

  function disableFinancialYearEndIfCompanyRegNo(){
    if(isFeatureToggleActive('financial_year_in_setup')){
      var reg_no = $('#company_company_reg_no').val();
      if(reg_no != ""){
        callCompanyInfoEndpoint(reg_no, disableAndSetFinancialYearEndIfCompaniesHouseResponse, enableFinancialYearEndField);
      } else {
        enableFinancialYearEndField();
      }
    }
  }

  function disableAndSetFinancialYearEndIfCompaniesHouseResponse(response){
    var next_financial_year_end = parseAndFormatDate(response['accounts']['next_financial_year_end']);
    $('#company_prog_date_end_for_ch').val(next_financial_year_end);
    $('#company_prog_date_end_for_ch').attr('readonly', true)
  }

  function enableFinancialYearEndField() {
    $('#company_prog_date_end_for_ch').removeAttr('readonly');
  }

  function initCustomNestedFormInsertFieldsNewCompany() {
    // Custom behaviour of nested_form link_to_add in amounts mult-edit table
    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      // New ID part of nested_form doesn't seem to be working: fix here
      // name="journal_entry[journal_entry_transaction_attributes][amounts_attributes][2][amount]"
      // --> "...[123456789][amount]"
      var new_id = new Date().getTime();
      content = $.trim(content.replace(/\d+(?=\]\[[a-z_]+\])/g, new_id));

      // Insert new content after current table row
      var $tr = $($(link).data('target'));
      var field = $(content).appendTo($tr);
      return field;
    }
  }

  function setAccountantFieldsVisibility() {
    if (isFeatureToggleActive('accountant_details_on_company_wizard')) {
      showOrHideSectionWithCheckbox('#new_business_wizard', '#company_has_an_accountant', '.accountant_fields');
    }
  }

  // Initiate JS for each tab
  function initNewCompanyWizard() {
    initBootstrapWizard();
    focusFirstFieldOnTabChange();
    initAreYouSureCompaniesNew();
  }

  //TAB 1 - Personal Information
  function initChooseCountryStep(){
    $('#company_country_id').change(onCountryChange);
  }

  //TAB 2 - Your Business
  function initCompanyInfoStep(){
    initCustomNestedFormInsertFieldsNewCompany();
    afterDatepickersComponentHaveBeenInitialised(initProgramDateFields);
    setupPartnersAndDirectorsFields();
    checkCompaniesHouseOnChange();
    addCompanyWizardCompanyInfoListeners();
    companyWizardCompanyTypeChange();
    disableFinancialYearEndIfCompanyRegNo();
  }

  //TAB 3 - Contact details
  function initCompanyAddressStep(){
    addAddressFromCompaniesHouse();
    initListenerForTelephoneField($('#company_telephone_number'));
  }

  //TAB 4 - Financial info
  function initFinancialInfoStep(){
    initCustomNestedFormInsertFieldsNewCompany();
    addCompanyWizardFinancialInfoEventListeners();
    // INVOICING
    setInvoicingVisibility();
    setInvoiceTypeVisibility();
    // VAT
    setVatVisibility();
    setEuVatVisibility();

    setAccountantFieldsVisibility();
  }

  //TAB 5 - Bank Accounts
  function initBankAccountsStep(){
    initCustomNestedFormInsertFieldsNewCompany();
    addCompanyWizardBankAccountEventListeners();
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#new_company').length === 0) { return }
    initNewCompanyWizard();
    if ($('.company_wizard_step.basic_details').length > 0 ){
      initChooseCountryStep();

    } else if ($('.company_wizard_step.company_info').length > 0 ){
      initCompanyInfoStep();

    } else if ($('.company_wizard_step.company_address').length > 0 ){
      initCompanyAddressStep();

    } else if ($('.company_wizard_step.financial_info').length > 0 ){
      initFinancialInfoStep();

    } else if ($('.company_wizard_step.bank_accounts').length > 0 ){
      initBankAccountsStep();
    }
  }));
})();
