(function() {
  'use strict';

  // in javascript all numbers are floating point. This is more accurate
  // with validation as a bonus
  window.accurateAddition = function(x, y) {
    if (!$.isNumeric(x)) { x = 0; }
    if (!$.isNumeric(y)) { y = 0; }
    return ((x * 100) + (y * 100)) / 100;
  };

  window.accurateSubtraction = function(x, y) {
    if (!$.isNumeric(x)) { x = 0; }
    if (!$.isNumeric(y)) { y = 0; }
    return ((x * 100) - (y * 100)) / 100;
  };

  // Javascript's alternatives are suboptimal:
  // Math.round(x) only rounds to the nearest integer
  // x.ToFixed(n) rounds differently from Ruby's round method in a few cases
  //          0.015      0.025
  //  Ruby:   0.02       0.03
  //  JS:     0.01       0.03
  window.roundToTwoDp = function(x) {
    if (!$.isNumeric(x)) { x = 0; }
    return Math.round(x * 100 + 0.0000001) / 100.0;
  }

  window.formatAsCurrency = function(x, decimal_place) {
    if (decimal_place == undefined) { decimal_place = 2; }
    return addDelimiterToNumber(roundToTwoDp(x).toFixed(decimal_place));
  }

  window.formatWithoutDelimiter = function(value) {
    if (value== undefined) { return "" } 
    return parseFloat(value.replaceAll(',', ''));
  }

  function addDelimiterToNumber(number) {
    // https://stackoverflow.com/a/2901298
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
})();
