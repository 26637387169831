(function() {
  'use strict';

  window.checkNumberRows = function(numRows) {
    if (numRows === 10) {
      notify('You have entered 10 transactions since your last save', 'warning', 'Warning! ');
    }
  };

  window.removeErrorDivs = function(newRow) {
    $(newRow).find('.has-error').each(function() {
      var contents = $(this).contents();
      $(this).parent().prepend(contents);
      $(this).remove();
    });
    return newRow;
  };

  /**
   * Set the value of a field to the value of the row directly above it
   *
   * @param row
   * @param selector
   */
  window.duplicateFieldValue = function(row, selector) {
    var $field = $($(row).find(selector)[0]);
    $field.val(getFieldValueFromRowAbove($field, selector)).change();
  };

  function getFieldValueFromRowAbove($field, selector) {
    return $($field.closest('tr').prev()[0]).find(selector).val();
  }
})();
