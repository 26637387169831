BatchInvoiceModalBehaviour = {
  "jQuerySelector": function(row_id) {
    return '#batch_invoice_modal' + row_id;
  },
  "actionsAfterInitialize": function($modal) {
    var invoice_modal = createInvoiceModal($modal);
    checkAllInvoiceCardsAreValid(invoice_modal)();
  },
  "enableInvoiceModalEvent": function($modal) {
    addModalEventListeners($modal);
  }
}

function addModalEventListeners($modal) {
  var invoice_modal = createInvoiceModal($modal);

  errors.tryClick(invoice_modal.back_button, redirectToInvoiceSelectModal);
  errors.tryClick(invoice_modal.close_button, initAreYouSureAlert(invoice_modal));
  errors.tryOn(invoice_modal.confirm_button, 'change', checkAllInvoiceCardsAreValid(invoice_modal));
  errors.tryOn(invoice_modal.confirm_button, 'click', submitBatchModalData(invoice_modal));
}

function createInvoiceModal($modal) {
  var invoice_modal = {
    base: $modal,
    row_id: $modal.data('row-id'),
    back_button: $modal.find('.modal-back-btn'),
    confirm_button: $modal.find('.modal-save-btn'),
    close_button: $modal.find('#invoice-credit-selection-close'),
    parent_row: $('#credit_note_row' + $modal.data('row-id'))
  }
  return invoice_modal;
}

function redirectToInvoiceSelectModal() {
  setTimeout(function() { $('#modal').modal(); }, 300);
}

function initAreYouSureAlert(invoice_modal) {
  return function(event) {
    event.preventDefault();
    event.stopPropagation();
    closeCreditNoteModalAlert(invoice_modal);
  }
}

function closeCreditNoteModalAlert(invoice_modal) {
  sweetAlert({
    title: "Are you sure you want to close?",
    text: 'Any progress made with this credit note will be lost.',
    html: true,
    allowEscapeKey: true,
    showCancelButton: true,
    confirmButtonText: 'Back',
    cancelButtonText: 'Close',
    customClass: 'sweetalert-pandle-theme'
  }, function(isConfirmed) {
    if (!isConfirmed) {
      invoice_modal.base.modal('hide')
      setTimeout(function() {
        BatchInvoiceModalState.resetModalToState(invoice_modal.base);
      }, 300);
    }
  });
};

function checkAllInvoiceCardsAreValid(invoice_modal) {
  return function() {
    // FARMPLAN - fix bug when going in and out of invoice options could
    // leave invalid data hidden on page
    var $validation_checks = invoice_modal.base.find(
      '.invoice-line-item-container:not(.flagged_for_deletion) .invoice-validation'
    )
    var disable_confirm_button = false;
    $validation_checks.each(function() {
      if ($(this).data('valid') == false) { disable_confirm_button = true; }
    });
    invoice_modal.confirm_button.attr('disabled', disable_confirm_button);
  }
}

function submitBatchModalData(invoice_modal) {
  return function() {
    CreditedInvoiceDataHandler.creditedInvoices.setHash(invoice_modal.row_id)
    calculateAndSetRowAmounts(invoice_modal);
    setNewDescriptionForParentRow(invoice_modal);
    setTaxTextForParentRow(invoice_modal);
    actionInvoiceCards(invoice_modal);
  }
}


// function setTaxTextForParentRow(invoice_modal){
//     var tax_code = generateTaxCode(invoice_modal);
//     dropdown = invoice_modal.parent_row.find('#credit_notes__tax_code_id');
//     $(dropdown).val(tax_code).trigger("chosen:updated");
// }

function generateTaxCode(invoice_modal) {
    var relevant_tax_codes = getRelevantTaxCodeIds(invoice_modal);
    return getCorrectTaxCode(relevant_tax_codes);
}

// function getRelevantTaxCodeIds(invoice_modal){
//   var $line_item_rows = invoice_modal.base.find('.line_item_row:not(.flagged_for_deletion)')
//   var tax_codes = []
//   Array.from($line_item_rows).forEach(element => {
//     $inputField = $(element).find('.line_item_total_amount_tf');
//     if ($inputField.val() > 0) {
//       tax_codes.push($(element).find('.line_item_tax_code')[0].value)
//     }
//   });
//   return tax_codes;
// }

function getCorrectTaxCode(tax_codes, invoice_modal){
  var unique_tax_codes = [...new Set(tax_codes)]
  if (unique_tax_codes.length > 1){
    return -1;
  }else{
    return tax_codes[0];
  }
}

function calculateAndSetRowAmounts(invoice_modal) {
  invoice_modal.parent_row.find('.quantity_amount_tf').val(_calculateStockInModal(invoice_modal, 'quantity', 3)); //FARMPLAN: Add quantities together when selecting invoices on batch
  invoice_modal.parent_row.find('.price_amount_tf').val(_calculateStockInModal(invoice_modal, 'price', 3)); //FARMPLAN: Add prices together when selecting invoices on batch
  invoice_modal.parent_row.find('.net_amount_tf').val(_calculateBalanceInModal(invoice_modal, 'net', 2));
  invoice_modal.parent_row.find('.tax_amount_tf').val(_calculateBalanceInModal(invoice_modal, 'tax' , 2));
  invoice_modal.parent_row.find('.total_amount_tf').val(_calculateBalanceInModal(invoice_modal, 'total', 2));
}

// FARMPLAN
function _calculateStockInModal(invoice_modal, amount_type) {
  const line_item_rows = invoice_modal.base.find('.line_item_row:not(.flagged_for_deletion)')
  if (line_item_rows.length > 1) { return 'Split' }
  return $(line_item_rows[0]).find('.line_item_' + amount_type + '_amount_tf').val()
}

function _calculateBalanceInModal(invoice_modal, amount_type, decimalPlaces) { //FARMPLAN: Added decimal places parameter to have different dp for price and quantity
  var $line_item_rows = invoice_modal.base.find('.line_item_row:not(.flagged_for_deletion)')
  var sum = 0;
  $line_item_rows.each(function() {
    var $targetField = $(this).find('.line_item_' + amount_type + '_amount_tf'),
    $inputField = $(this).find('.line_item_total_amount_tf');
    sum += Number($targetField.val()); //FARMPLAN: removed zero check to support contras
  })
  return sum.toFixed(decimalPlaces);
}

function setNewDescriptionForParentRow(invoice_modal) {
  var description = _joinModalDescriptions(invoice_modal);
  invoice_modal.parent_row.find('.description_tf').typeahead("val", description);
}


function setTaxTextForParentRow(invoice_modal){
  var tax_code_text = generateTaxCodeText(invoice_modal);
  var span = invoice_modal.parent_row.find('#credit_notes__tax_code_id_chosen').find('span');
  span.text(tax_code_text);
}


function generateTaxCodeText(invoice_modal) {
  var relevant_tax_codes = getRelevantTaxCodeIds(invoice_modal);
  return setCorrectTaxCode(relevant_tax_codes, invoice_modal);

}

function getRelevantTaxCodeIds(invoice_modal){
  var $line_item_rows = invoice_modal.base.find('.line_item_row:not(.flagged_for_deletion)')
  var tax_codes = []
  Array.from($line_item_rows).forEach(element => {
    $inputField = $(element).find('.line_item_total_amount_tf');
    if ($inputField.val() > 0) {
      tax_codes.push($(element).find('.line_item_tax_code')[0].value)
    }
  });
  return tax_codes;
}

function setCorrectTaxCode(tax_codes, invoice_modal){
  var unique_tax_codes = [...new Set(tax_codes)]
  if (unique_tax_codes.length > 1){
    return "Multiple"
  }else{
    var tax_code_value = invoice_modal.parent_row.find('.js-add-new-taxcode')[0].querySelector(`[value='${tax_codes[0]}']`).textContent;
    return tax_code_value
  }
}


function _joinModalDescriptions(invoice_modal) {
  var desc = [];
  var $descriptions = invoice_modal.base.find('#invoice_description:visible');
  $.each($descriptions, function(index, description) {
    var desc_val = $(description).data('description').toString();
    if (desc_val.length > 0) { desc.push(desc_val); }
  })
  return desc.join('; ');
}

function actionInvoiceCards(invoice_modal) {
  var $invoiceCards = invoice_modal.base.find('.invoice-line-item-container')
  $invoiceCards.each(function() {
    var $invoiceCard = $(this);
    if ($invoiceCard.is(':visible')) {
      storeCreditedAmountsInDataHandler($invoiceCard)
    } else {
      var invoice_id = $invoiceCard.data('invoice-id');
      CreditedInvoiceAmountDataHandler.deleteInvoiceData(invoice_id);
    }
  })
  CreditedInvoiceAmountDataHandler.afterInvoiceSet();
}

function storeCreditedAmountsInDataHandler($invoiceCard) {
  var invoice_id = $invoiceCard.data('invoice-id');
  var $lineItemRows = $invoiceCard.find('.line_item_row');
  var total_creditable = parseFloat($invoiceCard.data('total-creditable'));
  CreditedInvoiceAmountDataHandler.setInvoiceData(invoice_id, total_creditable, $lineItemRows);
}
