import CONVERTER from './_bootgrid_converters.js'

window.initBalanceSheetTables = function() {
  balanceSheetTableSetup($('#assets_table'));
  balanceSheetTableSetup($('#liabilities_table'));
  balanceSheetTableSetup($('#capital_table'));
}

function balanceSheetTableSetup(table) {
  table.bootgrid({
    css : {
      icon: 'zmdi icon',
      iconColumns: 'zmdi-view-module',
      iconDown: 'zmdi-caret-down',
      iconRefresh: 'zmdi-refresh',
      iconUp: 'zmdi-caret-up'
    },
    columnSelection : false,
    navigation : 0,
    rowCount : -1,
    converters : {
      reports_numeric_ignore_zero: CONVERTER.reports_numeric_ignore_zero,
      order_by_link_text: CONVERTER.order_by_link_text
    },
    labels : { noResults : ' ' }
  });
}
