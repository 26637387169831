import CONVERTER from '../bootgrid_tables/_bootgrid_converters.js'

// TEMPORARY OVERRIDE - THIS FILE CAN BE REMOVED ONCE THE CHANGES COME THROUGH FROM PANDLE
(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#content.world_domination_reports.index').length === 0) { return; }

    $('#global_statistics_table').bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up'
      },
      columnSelection: false,
      navigation: 0,
      rowCount: -1,
      converters: {
        numeric_ignore_zero: CONVERTER.numeric_ignore_zero,
        order_by_link_text: CONVERTER.order_by_link_text
      },
      labels: { noResults: ' ' }
    });

  }));
})();
