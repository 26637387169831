(function() {
  'use strict';

  window.checkForDuplicateBankTransfers = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    var account = getBtAccountSelect(row_id).find('option:selected').val();

    if (/BankAccount/.test(account)) {
      _getBankTransferDetailsAndCheckForDuplicate(row_id, account);
    }
  }

  window.checkForPotentialDuplicates = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    _getBankTransactionDetailsAndCheckForDuplicate(row_id);
  }

  window.addDuplicateBankTransactionWarning = function(apparentDuplicate, row_id) {
    if (!apparentDuplicate) { return; };
    var date = moment(apparentDuplicate['date'], 'YYYY-MM-DD')
    addHelpMessageWithTitle(
      'Possible Duplicate Found', HELP_WARNING,
      _duplicateMessage(_duplicateTransactionMessage(row_id), apparentDuplicate)
    );
  }

  window.addDuplicateBankTransferWarning = function(apparentDuplicate, row_id) {
    if (!apparentDuplicate) { return; };
    addHelpMessageWithTitle(
      'Possible Duplicate Found', HELP_WARNING,
      _duplicateMessage(_duplicateTransferMessage(row_id), apparentDuplicate)
    );
  }

  function _duplicateTransferMessage(row_id) {
    return 'Bank transfer on row ' + row_id +
      ' matches an existing transaction. Have you already imported the other account\'s statement?'
  }

  function _duplicateTransactionMessage(row_id) {
    return 'Bank transaction on row ' + row_id +
      ' is very similar to an existing transaction. Have you already entered this transaction?'
  }

  function _duplicateMessage(explanation, apparentDuplicate) {
    var date = moment(apparentDuplicate['date'], 'YYYY-MM-DD');
    return explanation +
      '<p><em>Date:</em> ' + formatDateForCompany(date) + '<br/>' +
      '<em>Total:</em> ' + apparentDuplicate['total_amount'] + '<br/>' +
      '<em>Description:</em> ' + apparentDuplicate['description'] + '</p>' +
      '<p>Consider deleting, or create an automated ignore rule for this item.</p>'
  }

  function _getBankTransferDetailsAndCheckForDuplicate(row_id, account) {
    var bank_account = $('#bank_account_field'+row_id).val();
    var date = getGbDateStringFromClosestDateTimePicker($('#date_field'+row_id));
    var total = $('#total_amount_field'+row_id).val();

    if (bank_account && date && total) {
      _queryDatabaseForReverseTransfer(account, bank_account, date, total, row_id);
    }
  }

  function _queryDatabaseForReverseTransfer(account, bank_account, date, total, row_id) {
    getJsonOrShowMaintenanceModal(
      '/bank_transactions/check_for_reverse_transfer?date=' + date + '&total=' + total + '&account=' +
        encodeURIComponent(account) + '&bank_account=' + bank_account,
      function(apparentDuplicate) {
        addDuplicateBankTransferWarning(apparentDuplicate, row_id)
      }
    );
  }

  function _getBankTransactionDetailsAndCheckForDuplicate(row_id) {
    var bank_account = $('#bank_account_field' + row_id).val();
    var account = getBtAccountSelect(row_id).find('option:selected').val();
    var date = getGbDateStringFromClosestDateTimePicker($('#date_field'+row_id));
    var total = $('#total_amount_field' + row_id).val();
    var type = $('#type_field' + row_id).val();
    var old_id = $('#bank_transactions__old_id' + row_id).val();

    if (bank_account && account && date && total) {
      _queryDatabaseForDuplicateTransaction(account, bank_account, date, total, type, row_id, old_id);
    }
  }

  function _queryDatabaseForDuplicateTransaction(account, bank_account, date, total, type, row_id, old_id) {
    var url_with_params = '/bank_transactions/check_for_potential_duplicate?date=' + date +
      '&total='+total+'&account=' + encodeURIComponent(account) + '&bank_account=' + bank_account +
      '&bt_type=' + encodeURIComponent(type);

    if (typeof(old_id) != 'undefined') {
      url_with_params += '&old_id=' + old_id
    }
    getJsonOrShowMaintenanceModal(url_with_params, function(apparentDuplicate) {
        addDuplicateBankTransactionWarning(apparentDuplicate, row_id)
      }
    );
  }
})();
