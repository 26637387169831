import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['swapper', 'option', 'disabled']
  static classes = ['hidden']

  initialize() {
    if (!this.hasSwapperTarget) return

    $(this.swapperTarget).chosen({ width: '100px' })
    $(this.swapperTarget).on('change', (event) => this.swap(event))
    this.optionTargets.forEach(option => this.disableOption(option))
    this.enableOption(this.swapperTarget.value)
  }

  swap(event) {
    this.optionTargets.forEach(option => this.disableOption(option))
    this.enableOption(this.swapperTarget.value)
  }

  enableOption(id) {
    if (id === '') return

    const option = document.querySelector(`#${id}`)
    option.classList.remove(this.hiddenClass)
    option.querySelectorAll('select').forEach(field => {
      field.disabled = false
      $(field).trigger('chosen:updated')
    })
    this.disabledTargets.forEach(field => {
      field.disabled = true
      $(field).trigger('chosen:updated')
    })
  }

  disableOption(option) {
    option.classList.add(this.hiddenClass)
    option.querySelectorAll('select').forEach(field => {
      field.selectedIndex = 0
      field.disabled = true
      $(field).trigger('chosen:updated')
    })
  }
}
