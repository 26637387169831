(function() {
  'use strict';

  var TEMPLATE = (
    '<div class="preloader pl-lg">' +
      '<svg class="pl-circular" viewBox="25 25 50 50">' +
        '<circle class="plc-path" cx="50" cy="50" r="20"></circle>' +
      '</svg>' +
    '</div>'
  );

  var preloader_factory = new PreloaderElementFactory();

  window.createPreloader = function() {
    return new Preloader(preloader_factory)
  };

  window.replaceContentsWithPreloader = function($elem, preloader) {
    preloader = preloader || createPreloader();
    $elem.empty();
    appendPreloader($elem, preloader);
  };

  window.appendPreloader = function($elem, preloader) {
    preloader = preloader || createPreloader();
    $elem.append($(preloader.getElement()))
  };

  window.prependPreloader = function($elem, preloader) {
    preloader = preloader || createPreloader();
    $elem.prepend($(preloader.getElement()))
  }

  /**
   * @param {PreloaderElementFactory} factory
   * @constructor
   */
  function Preloader(factory) {
    this.elem = factory.create();
  }

  Preloader.prototype.getElement = function() {
    return this.elem;
  };

  /**
   * Only use this method to remove the element, then the factory will be able to reuse it
   */
  Preloader.prototype.dispose = function() {
    var parent = this.elem.parentNode;

    if (parent) {
      parent.removeChild(this.elem);
    }
  };

  /**
   * @constructor
   */
  function PreloaderElementFactory() {
    this._elements = [];
    this._prototypeElement = null;
  }

  PreloaderElementFactory.prototype.create = function() {
    return this._getFirstAvailableElement() || this._createElement();
  };

  PreloaderElementFactory.prototype._getFirstAvailableElement = function() {
    return this._elements.find(canUseElement);
  };

  PreloaderElementFactory.prototype._createElement = function() {
    if (this._prototypeElement === null) {
      this._prototypeElement = this._createPrototypeElement();
    }

    return this._cloneFromPrototype();
  };

  PreloaderElementFactory.prototype._createPrototypeElement = function() {
    var elem = document.createElement('div');
    elem.className = 'preloader-container';
    elem.innerHTML = TEMPLATE;
    return elem;
  };

  PreloaderElementFactory.prototype._cloneFromPrototype = function() {
    var elem = this._prototypeElement.cloneNode(true);
    this._elements.push(elem);
    return elem;
  };

  function canUseElement(elem) {
    return elem && !elem.parentNode;
  }
})();
