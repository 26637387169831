import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['runningTotal', 'runningTotalWithOverpayment']
  static values = {
    id: String
  }

  connect() {
    const instance = this
    const elements = document
      .querySelector(`#invoice-modal-table${this.idValue}`)
      .querySelectorAll('[class*=invoice_total_amount')

    elements.forEach((element) => {
      try {
        const descriptor = Object.getOwnPropertyDescriptor(
          Object.getPrototypeOf(element),
          'value'
        )

        Object.defineProperty(element, 'value', {
          set: function (t) {
            instance.runningTotalTarget.innerHTML = '0.00'

            if (instance.hasRunningTotalWithOverpaymentTarget) {
              instance.runningTotalWithOverpaymentTarget.innerHTML = '0.00'
            }

            if (t) {
              instance.onChange(elements)
            }

            return descriptor.set.apply(this, arguments)
          },
          get: function () {
            return descriptor.get.apply(this)
          }
        })
      } catch (error) {}
    })
  }

  onChange(elements) {
    let sum = 0

    for (let i = 0; i < elements.length; i++) {
      const value = parseFloat(elements[i].value)

      if (!isNaN(value)) {
        sum += value
      }
    }

    this.setFormatedValue(this.runningTotalTarget, sum)

    if (this.hasRunningTotalWithOverpaymentTarget) {
      const overpaymentElement = document.getElementById(
        'total_paymentoverpayment1'
      ).innerHTML
      const runningTotalWithOverpayment =
        sum + parseFloat(overpaymentElement > 0 ? overpaymentElement : 0)

      this.setFormatedValue(
        this.runningTotalWithOverpaymentTarget,
        runningTotalWithOverpayment
      )
    }
  }

  setFormatedValue(target, value) {
    target.innerHTML = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
}
