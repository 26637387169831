(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#new_custom_file_import_format_dropzone').length === 0) { return }
    configureDropzone(
      $('#new_custom_file_import_format_dropzone'),
      $('#new_custom_file_import_format')[0],
      'custom_file_import_format[uploaded_file]'
    );
  }));
})();
