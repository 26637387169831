(function() {
  'use strict';

  function initCreditNoteForm() {
    formatNestedFormTable();
    addNbInvEventListeners();
    allowEnterToTab();
    disableTaxAmountIfTaxCodeIsZero($('tr.line_item_row'), '.line_item_tax_amount_tf', '.split_tax_code_field');
    updateCustomerDocumentAmounts();
    checkCreditLimitOnSubmit();
    setExistingCreditedInvoices(function() {
      if ($("#invoice_ref_tf").val() == undefined) {
        $("#customer_select_single").trigger('change');
      }
    });
  }

  function nbInvDatePickerChange() {
    var $elem = $(this);
    var datepicker = getDateTimePicker($elem);

    addDatePeriodWarning($elem);
    addDateInFutureWarning($elem);
    updateConversionRate($('#cd_currency_tf').val(), datepicker.date());

    // If batch or credit note then we don't want to update tax code options based off of the date picker onChange.
    if (!$('.nb_form').length) { return ; }
    if ($('#new_sales_credit_note').length) { return ; }
  }

  function nbInvCustomerSelectChange() {
    var $form = $('form');

    $('#hidden_customer_supplier_id').val($('#customer_select_single').val());
    reloadCustomerSelectField($form);
    setNominalAccountAndUpdateCurrencyToCustomersCurrency();
    checkForEuVatCustomer();
    updateSelectInvoicesButton();
  }

  function addNbInvEventListeners() {
    onceAfterDatepickersComponentHaveBeenInitialised(function() {
      $('#customer_document_date_div').on('dp.change', nbInvDatePickerChange);
    });

    [ [ $("#customer_select_single"), nbInvCustomerSelectChange ],
      [ $(".currency_select.show-disabled-select"), custDocCurrencyChange ],
      [ $('#cd_net_amount_tf'), updateCustDocTotal ],
      [ $('#cd_tax_amount_tf'), updateCustDocTotal ]
    ].forEach(function(args) {
      errors.tryOn(args[ 0 ], 'change', args[ 1 ]);
    });

    errors.tryOn($('.attachment_button'), 'click', openNonBatchUploadsModal);

    if ($("#invoice_ref_tf").val() == undefined) {
      $("#customer_select_single").trigger('change');
    }

    $('#no-invoices-selected').show();
    $('.credit-note-totals').hide();
  }

  function updateSelectInvoicesButton() {
    var customerId = $('#customer_select_single').val();
    var selectInvoicesButtons = $('.select_invoices_button');

    if (!customerId) {
      selectInvoicesButtons.addClass('disabled');
    } else {
      selectInvoicesButtons.removeClass('disabled');
    }
  }

  function reloadCustomerSelectField($form) {
    $('.customer_single_select select').trigger('chosen:updated');
  }

  function setExistingCreditedInvoices(callback) {
    var $invoiceCards = $('.invoice-line-item-container');
    if ($invoiceCards.length !== 0) {
      var customer_id = $("#customer_select_single").val(),
        customer_type = $('.customer_supplier_type').val();
      CreditedInvoiceDataHandler.creditedInvoices.initHashOnExistingInvoiceCards($invoiceCards, callback);
    } else {
      callback();
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.nb_form.non_batch_credit_notes_form').length === 0) { return }
    initNonBatchInvFieldStateController();
    initCreditNoteForm();
    registerCustomerDocumentChangeListener();
    var $invoice_cards = $('.invoice-line-item-container');
    InvoiceCardBehaviour.enableInvoiceCardBehaviour($invoice_cards);
    InvoiceCardBehaviour.enableLineItemEvent($('.line_item_row'));
    InvoiceCardBehaviour.actionsAfterInitialize($invoice_cards);
  }));
})();

