(function() {
  'use strict';

  var formChanges = {}

  window.stripeButtonListener = function(formObjectName) {
    $('#stripe-integration-button').click(function() {
      addParamsandRequestStripeSettings(formObjectName);
    });
  }

  function addParamsandRequestStripeSettings(formObjectName) {
    var returnPath = $('#return-path').data('return-path');
    var formObjectId = $('#form-object-id').data('form-object-id')
    $.ajax({
      method : 'get',
      url : $('#stripe-integration-button').attr('data-stripe-settings-url') + '?' +
        jQuery.param({
          'return_path': returnPath,
          'form_object_name': formObjectName,
          'form_object_id': formObjectId
        }),
      dataType : 'script',
    });
  }

  window.setStripeFormStateListeners = function(formSelector) {
    formChanges = {}
    $(formSelector + ' :input:not(:checkbox)').each(storeFormFieldChanges);
    $(formSelector + ' :checkbox').each(storeFormCheckboxChanges);
  }

  function storeFormFieldChanges() {
    $(this).change(function() {
      formChanges[$(this).attr('name')] = $(this).val();
    });
  }

  function storeFormCheckboxChanges() {
    $(this).change(function() {
      formChanges[$(this).attr('name')] = $(this).is(':checked');
    });
  }

  window.stripeConnectionButtonOnClick = function(returnPath, formObjectName, formObjectId) {
    $('#stripe-credential-button').click(function() {
      $.post('/form_states', {
        'form_state': {
          'state_hash' : jQuery.param({state_hash: formChanges}),
          'uid' : $('#stripe-credential-button').data('user-preference-id'),
          'form_object_id': formObjectId,
          'return_path' : returnPath
        }
      });
    });
  }
})();
