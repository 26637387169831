(function() {
  'use strict';

  function onDatePickersInitialised() {
    if ($('.nb_form.quotes').length === 0) { return } // because turbolinks
    typeAheadInput($('.typeahead'), $('.typeahead_descriptions'));
    initQuotesForm();
    registerCustomerDocumentChangeListener();
  }

  function initQuotesForm() {
    initQuoteFieldStateController();
    formatNestedFormTable();
    addQuoteEventListeners();
    allowEnterToTab();
    updateCustomerDocumentAmounts();
    disableTaxAmountIfTaxCodeIsZero($('tr.line_item_row'), '.line_item_tax_amount_tf', '.split_tax_code_field');
  }

  function addQuoteEventListeners() {
    onceAfterDatepickersComponentHaveBeenInitialised(function() {
      $('#customer_document_date_div').on('dp.change', quoteDatePickerChange);
    });
    $('#customer_select_single').change(quoteCustomerSelectChange);
    $('.currency_select.show-disabled-select').change(custDocCurrencyChange);
    $('#cd_net_amount_tf').change(updateCustDocTotal);
    $('#cd_tax_amount_tf').change(updateCustDocTotal);

    errors.tryOn($('.attachment_button'), 'click', openNonBatchUploadsModal);
    addLineItemEventListeners($('.line_item_row'));

    initCustomNestedFormForQuoteLineItems();
    $('.nb_form.quotes').on('nested:fieldAdded', function(event) {
      formatNewLineItemRow(event.field);
    });

    $('.nb_form.quotes').on('nested:fieldRemoved', function(event) {
      addLineItemRowIfNeeded();
      updateCustomerDocumentAmounts();
    });
  }

  var initCustomNestedFormForQuoteLineItems = function() {
    // Custom behaviour of nested_form link_to_add in line_items
    window.NestedFormEvents.prototype.insertFields = function(content, assoc, link) {
      // Insert new content before row with add new button
      var $new_row = $(content);
      var $last_row = $('tr.line_item_row:last');
      var row_id = $('tr.line_item_row').size() + 1;
      setRowDataAndTaxAmountId($new_row, row_id);
      $new_row.find("div.chosen-container").remove();
      updateQuoteFieldStatesWithSection($new_row, row_id); // Update the blueprint row
      disableTaxAmountIfTaxCodeIsZero($new_row, '.line_item_tax_amount_tf', '.split_tax_code_field');
      $new_row.find('.nb_split__account select').html($last_row.find('.nb_split__account select').html()); // Copy category options from previous row
      $new_row.insertAfter($last_row);
      return $new_row;
    }
  }

  function quoteDatePickerChange(event) {
    var datepicker_div = $(event.currentTarget);
    var datepicker = getDateTimePicker(datepicker_div);
    addDatePeriodWarning(datepicker_div);
    addDateInFutureWarning(datepicker_div);
    updateConversionRate($('#cd_currency_tf').val(), datepicker.date());
    updateTaxCodeOptionsForNonBatch();
  }

  function quoteCustomerSelectChange() {
    setNominalAccountAndUpdateCurrencyToCustomersCurrency();
    checkForEuVatCustomer();
    updateTaxCodeOptionsForNonBatch();
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.nb_form.quotes').length === 0) { return }
    onceAfterDatepickersComponentHaveBeenInitialised(onDatePickersInitialised);
  }));
})();
