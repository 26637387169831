// TEMPORARY OVERRIDE - THIS FILE CAN BE REMOVED ONCE THE CHANGES COME THROUGH FROM PANDLE
(function() {
  'use strict';

  function deleteDuplicateLegendItems() {
    var seen = {};
    $('#legend-holder').find('table tr').each(function() {
      var txt = $(this).text();
      if (seen[txt]) {
        $(this).remove();
      } else {
        seen[txt] = true;
      }
    });
  }

  function initDateRange() {
    setStartDateValues();
    setEndDateValues();
    setProjectionStartDateValues();
    $('#start').on('dp.change', updateDatesFromStartDate);
    $('#end').on('dp.change', updateDatesFromEndDate);
    $('#projection_start').on('dp.change', updateDatesFromProjectionStartDate);
  }

  var updateDatesFromStartDate = function(){
    setEndDateValues();
    setProjectionStartDateValues();
  }

  var updateDatesFromEndDate = function(){
    setStartDateValues();
    setProjectionStartDateValues();
  }

  var updateDatesFromProjectionStartDate = function(){
    setEndDateValues();
    setStartDateValues();
  }

  function setStartDateValues(){
    var $dpStart = getDateTimePicker($('#start'));
    var $dpEnd = getDateTimePicker($('#end'));
    if (!$dpEnd) { return; }

    adjustDatesToFitMinMax($dpStart)
  }

  function setEndDateValues(){
    var $dpStart = getDateTimePicker($('#start'));
    var $dpEnd = getDateTimePicker($('#end'));
    if (!$dpStart) { return; }

    adjustDatesToFitMinMax($dpEnd)
  }

  function setProjectionStartDateValues(){
    if ( $('#p-start').length == 0 ) { return; }

    var $dpStart = getDateTimePicker($('#start'));
    var $dpProjectionStart = getDateTimePicker($('#p-start'));
    if (!$dpStart || !$dpProjectionStart) { return; }

    adjustDatesToFitMinMax($dpProjectionStart)
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.cash_flow_report').length === 0) { return }
    var $chart = $('#cash_flow-multi-bar-chart');
    initMultiBarChart($chart, $chart.data('value'), true);
    deleteDuplicateLegendItems();
    initDateRange();

    $(window).on('resize', function() {
      deleteDuplicateLegendItems();
    });

    $('#projection_selector').change(function() {
      if ($('#projection_selector').val() === 'percentage') {
        $('#percentage_hidden').css('display', 'block');
      } else {
        $('#percentage_hidden').css('display', 'none');
      }

      if ($('#projection_selector').val() === 'absolute') {
        $('#absolute_hidden').css('display', 'block');
      } else {
        $('#absolute_hidden').css('display', 'none');
      }
    });

    $('.text-right').on('focusout', function(event) {
      var $changed_cell = $(event.target);
      var $row = $changed_cell.parent();
      var $table = $row.parent();
      var value = parseFloat($changed_cell.html());
      var rowIndex = $row.children().index($changed_cell);
      var sum = 0

      $table.find('tr').each(function() {
        value = parseFloat($($(this).find('td')[rowIndex]).html());
        if (!isNaN(value)) {
          sum = sum + value;
        }
      })

      var totalCell = $table.find('tr').last().find('th')[rowIndex];
      $(totalCell).html(sum.toFixed(2));
    });
  }));
})();
