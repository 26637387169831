/**
 * @fileoverview Exposes a js interface for storing a user's bootgrid table
 * settings on the server.
 */

(function(){
  /**
   * @namespace
   */
  window.bootgridPreferences = {};
  /**
   * @param $grid
   */
  bootgridPreferences.decorate = function($grid) {
    _decorateColumnSelection($grid);
  };

  function _decorateColumnSelection($grid) {
    if(_canDecorateColumnSelection($grid)){
      _addColumnSelectionChangeEventListener($grid);
    }
  }

  function _canDecorateColumnSelection($grid) {
    return ($grid.header
      && $grid.options.columnSelection
      && $grid.columns.length > 1);
  }

  function _addColumnSelectionChangeEventListener($grid) {
    $grid.header.on('change', _onColumnSelectionChange(_getId($grid)));
  }

  function _getId($grid){
    return $grid.element.attr('id');
  }

  /**
   * Here we assume that any change event will be triggered by a column
   * selection checkbox. Additional checks will need to be performed if other
   * input elements are added to the bootgrid templates.
   *
   * @returns {Function}
   * @private
   */
  function _onColumnSelectionChange(table_id) {
    return function (e) {
      var elem = e.target;
      UserPreferences.set(getPreferenceKey(table_id, elem.name), elem.checked);
    }
  }

  function getPreferenceKey(table_id, elem_name){
    return ['col_select', table_id, elem_name].join('_');
  }

})();
