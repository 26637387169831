(function() {
  'use strict';

  window.initNonBatchInvFieldStateController = function() {
    window.nb_invoice_field_enabling_rules = readEnablingRulesFromPage();
    $('.non_batch_invoices_form').change(_updateNonBatchInvFieldStates);
  }

  // updateNonBatchInvFieldStates with a section - called in invoices__new_or_edit_non_batch
  window.updateNonBatchInvFieldStatesWithSection = function($section, row_id) {
    var nb_invoice = new FieldStatesNonBatchInvoice($('.non_batch_invoices_form'), row_id, $section);
    var field_state_selector = new FieldStateSelector(nb_invoice);
    new FieldStateController(nb_invoice, field_state_selector).update();
  }

  function _updateNonBatchInvFieldStates(event) {
    var $row_id = getClosestDataRowIdToEvent(event)
    var nb_invoice = new FieldStatesNonBatchInvoice($('.non_batch_invoices_form'), $row_id);
    var field_state_selector = new FieldStateSelector(nb_invoice);
    new FieldStateController(nb_invoice, field_state_selector).update(event.target);
  }
})();
