/**
* @fileoverview Provides support for memoizing the result of jQuery ajax
* functions using the Promise API.
*/

(function() {
  'use strict';

  var registry = {};

  /**
   * If no stored value is found, evaluates the function and stores the parameters passed into the deferredCallback.
   * If a stored value is found, returns a jQuery.Deferred that will resolve the initial invocation's deferredCallback
   * parameters.
   *
   * @param {string} key
   * @param {Function} fn This must return a jQuery.Deferred
   * @param {...*} arguments for function fn
   */
  window.memoized = function(key, fn) {
    return evaluateIfNotFound(key, fn, arrayFromArguments(arguments).slice(2));
  };

  function arrayFromArguments(args) {
    return Array.apply(null, args);
  }

  function evaluateIfNotFound(key, fn, args) {
    var stored = registry[ key ];

    if (typeof stored === 'undefined') {
      return fn.apply(null, args).promise().then(storeResult(key));
    } else {
      return createSelfResolvingDeferredWithParams(stored);
    }
  }

  function storeResult(key) {
    return function() {
      registry[ key ] = arrayFromArguments(arguments);
      return createSelfResolvingDeferredWithParams(arguments);
    }
  }

  function createSelfResolvingDeferredWithParams(params) {
    var def = $.Deferred();
    return def.resolve.apply(def, params).promise();
  }
})();
