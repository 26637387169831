(function() {
  'use strict';

  window.initializeStripeSubscriptionForm = function(public_key) {
    // Initialize Stripe
    var stripe = Stripe(public_key);
    var elements = stripe.elements();

    // Setup the 3 card fields
    var number_elt = mountStripeElement(elements, 'cardNumber', '')
    var expiry_elt = mountStripeElement(elements, 'cardExpiry', 'MM/YY')
    var cvc_elt = mountStripeElement(elements, 'cardCvc', '')

    // Handle address fields
    $('#address_country').change(adjustAddressFormOnCountryChange)

    // Handle form behaviour
    var $form = $('#subscription_form');
    $form.submit(function(event) {

      if (formValidForTokenCreation($form)) {
        // Disable the submit button to prevent repeated clicks:
        $form.find(':submit').prop('disabled', true);

        // Create a PaymentMethod in Stripe
        stripe.createPaymentMethod('card', number_elt,
          {
            billing_details: {
              name: $('#name').val(),
              address: addressDetailsHash()
            }
          }
        ).then(stripeResponseHandler);
      }

      // Prevent the form from being submitted:
      return false;
    });
  }

  window.showErrorsOnStripeForm = function(errors, $form) {
    $form.find('#payment_errors').text(errors);
    $form.find('#payment_errors_div').show();
    $form.find(':submit').prop('disabled', false); // Re-enable submission
  }

  function formValidForTokenCreation($form) {
    var errors_to_display = [];
    if (billingAddressIsMissing()) {
      errors_to_display.push('Billing Address details are required (Address Line and Post/Zip Code minimum).')
    };
    if (authorisationCheckboxNotTicked()) {
      errors_to_display.push('Please tick the checkbox to confirm that you authorize payments to be taken.')
    }
    if (errors_to_display.length > 0){
      showErrorsOnStripeForm(errors_to_display.join(' '), $form)
      return false
    } else {
      return true
    }
  }

  function billingAddressIsMissing(){
    return $('#address_line1').val() == '' || $('#address_zip').val() == '';
  }

  function authorisationCheckboxNotTicked(){
    return !$('#accept_terms').is(':checked');
  }

  function addressDetailsHash(){
    var hsh = {};
    var line1 = $('#address_line1').val();
    var line2 = $('#address_line2').val();
    var city = $('#address_city').val();
    var state = $('#address_state_text:enabled, #address_state_select:enabled').first().val();
    var postal_code = $('#address_zip').val().trim();
    var country = $('#address_country').val();
    if (line1.length) { hsh['line1'] = line1 };
    if (line2.length) { hsh['line2'] = line2 };
    if (city.length) { hsh['city'] = city };
    if (state.length) { hsh['state'] = state };
    if (postal_code.length) { hsh['postal_code'] = postal_code };
    if (country.length) { hsh['country'] = country };
    return hsh;
  }

  function stripeResponseHandler(response) {
    // Grab the form:
    var $form = $('#subscription_form');

    if (response.error) { // Problem!
      showErrorsOnStripeForm(response.error.message, $form);
    } else { // Payment Method was created!
      // Get the payment method ID:
      var token = response.paymentMethod.id;

      // Insert the payment method ID into the form so it gets submitted to the server:
      $form.append($('<input type="hidden" name="payment_method_id_stripe">').val(token));
      $('.page-loader').fadeIn();

      // Submit the form:
      $form.trigger('submit.rails');
    }
  }

})();
