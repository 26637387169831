(function() {
  'use strict';

  function addCustomFileImportEventListeners() {
    $("select[id$='field_to_populate']").change(populatedFieldChange);
    $("select[id$='field_to_populate']").change();
    $('button[type=submit]').on('click', removeUnusedSelectsForSubmit);
    $('.format-select.amount').change(onFormatSelectAmountChange);
  }

  function populatedFieldChange(event) {
    var row = $(event.currentTarget).closest('tr');
    var field_name = $(event.currentTarget).val();
    hideOrShowFormatSelectsInRow(row, field_name);
  }

  function hideOrShowFormatSelectsInRow(row, field_name) {
    var date_select_cell = $(row).find('.format-select.date, .format-select.datepicker-component');
    var amount_select_cell = $(row).find('.format-select.amount');

    // Make the appropriate one visible
    if (field_name === "Date") {
      $(amount_select_cell).addClass('hidden');
      $(amount_select_cell).find('.chosen-select').val('').trigger('chosen:updated');
      $(date_select_cell).removeClass('hidden');
    } else if (field_name === "Amount") {
      $(date_select_cell).addClass('hidden');
      $(date_select_cell).find('.chosen-select').val('').trigger('chosen:updated');
      $(amount_select_cell).removeClass('hidden');
    } else {
      // hide all format selects
      $(row).find('.format-select').addClass('hidden');
      $(row).find('.chosen-select.format').val('').trigger('chosen:updated');
    }
  }

  function removeUnusedSelectsForSubmit() {
    // Clear hidden account selects for irrelevant types
    $('#custom_file_import_columns_form').find('tbody td#format_cell').find('div.hidden').remove();
  }

  function onFormatSelectAmountChange(e) {
    updateAmountFormatNotice(e.target.value);
  }

  function updateAmountFormatNotice(amountFormat) {
    var content = getAmountFormatNoticeContent(amountFormat);

    if (content instanceof jQuery) {
      showAmountFormatNoticeContent(amountFormat, content);
      displayAmountFormatNotification(amountFormat, content);
    } else {
      $memoize('#amount-format-notice').slideUp();
    }
  }

  function getAmountFormatNoticeContent(amountFormat) {
    var elem = $memoize('.content', '#amount-format-notice').get().find(
      amountFormatMatchesContent(amountFormat)
    );
    return elem && $(elem);
  }

  function amountFormatMatchesContent(amountFormat) {
    return function(elem) {
      return new RegExp(elem.dataset[ 'pattern' ]).test(amountFormat);
    }
  }

  function showAmountFormatNoticeContent(amountFormat, content) {
    updateAmountFormatNoticeHeader(amountFormat);
    hideCurrentAmountFormatNoticeContent();
    bootstrap.show(content);
    $memoize('#amount-format-notice').slideDown();
  }

  function updateAmountFormatNoticeHeader(amountFormat) {
    $memoize('.header', '#amount-format-notice').text(formatTitleForAmountFormat(amountFormat));
  }

  function formatTitleForAmountFormat(amountFormat) {
    return 'You have selected ' + amountFormat
  }

  function hideCurrentAmountFormatNoticeContent() {
    bootstrap.hide($memoize('.content', '#amount-format-notice'));
  }

  function initAreYouSureCustomFileImportFormatsEdit() {
    checkFormChangedOnPageUnload('#custom_file_import_format_form');
  }

  function displayAmountFormatNotification(amountFormat, content) {
    addHelpMessage(document, 'warning', createAmountFormatMessage(amountFormat, content));
  }

  function createAmountFormatMessage(amountFormat, content) {
    return '<strong>You have selected ' + amountFormat + '</strong><br />' + content.html();
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#custom_file_import_format_form').length === 0) { return }
    addCustomFileImportEventListeners();
    initAreYouSureCustomFileImportFormatsEdit();
  }));
})();
