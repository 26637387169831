/**
* This allows us to use dropzone to attach one or files to a form without eagerly uploading it to the server, which is
* dropzone's default behaviour. Instead, the file will be encoded and sent along with the form data.
*/

/**
 * Disable dropzone autodiscover. We need to configure dropzone instances
 * manually to make them play nicely with forms.
 *
 * @type {boolean}
 */

Dropzone.autoDiscover = false;

(function() {
  'use strict';

  var DEFAULT_DICT_MESSAGE = (
    "<div class='dz_upload_message'>" +
      "<i class='zmdi zmdi-upload zmdi-hc-3x'></i>" +
      "<p>Drag & drop a file here to upload</p>" +
      "<button type='button' class='btn btn-sm btn-primary btn-icon-text waves-effect'>" +
        "<i class='zmdi zmdi-search m-r-10'></i>Browse files" +
      "</button>" +
    "</div>"
  );

  var PREVIEW_TEMPLATE = (
    "<div class='dz-preview dz-file-preview'>" +
      "<div class='dz-details'>" +
        "<div class='dz-filename'><span data-dz-name></span></div>" +
        "<div class='dz-size' data-dz-size></div>" +
        "<img data-dz-thumbnail />" +
      "</div>" +
      "<div class='dz-progress'><span class='dz-upload' data-dz-uploadprogress></span></div>" +
      "<div class='dz-success-mark'><span></span></div>" +
      "<div class='dz-error-mark'><span></span></div>" +
      "<div class='dz-error-message'><span data-dz-errormessage></span></div>" +
    "</div>"
  );

  var DEFAULT_CONFIG = {
    url : '/', // This isn't used, but dropzone complains otherwise.
    autoProcessQueue : false,
    uploadMultiple : true,
    parallelUploads : 1,
    maxFiles : 1,
    dictDefaultMessage : DEFAULT_DICT_MESSAGE,
    previewTemplate : PREVIEW_TEMPLATE,
    thumbnailMethod: 'contain'
  };

  window.configureDropzone = function(zone, form, file, dropzone_html) {
    DEFAULT_CONFIG["dictDefaultMessage"] = dropzone_html || DEFAULT_DICT_MESSAGE;
    zone.dropzone(createDropzoneFormConfig(form, file, {}));
  };

  /**
   * Configuration for dropzone:
   *
   * @example
   * Dropzone.options.form_id = getDropzoneFormConfig(formDomElement, 'param_name',{
   *   sendingmultiple  : function() { ... },
   *   successmultiple : function(files, response) { ... },
   *   errormultiple : function(files, response) { ... },
   *   maxfilesexceeded : function(file) { ... }
   *   ...
   * });
   *
   * @param form
   * @param fileParam
   * @param eventHandlerMap
   * @param fn
   * @returns {*|{url, autoProcessQueue, uploadMultiple, parallelUploads, maxFiles, init, dictDefaultMessage,
   *   previewTemplate}}
   */

  function createDropzoneFormConfig(form, fileParam, eventHandlerMap, fn) {
    return getDefaultConfig(dzInitializerFn, form);

    function dzInitializerFn() {
      $(form).one('submit', getFormSubmitHandler(this));
      addMaxFilesExceededHandler(eventHandlerMap, this);
      addFileAddedHandler(eventHandlerMap, this);
      addFileUploadErrorHandler(eventHandlerMap, this);
      addEventHandlers(eventHandlerMap, this);
    }

    function getFormSubmitHandler(dz) {
      return function(e) {
        e.preventDefault();
        e.stopPropagation();
        disableFormSubmitButton(form);
        submitFormWithFile(getFormData(form), getFirstDzFile(dz), fn);
      }
    }

    function disableFormSubmitButton(form) {
      var $submit_btn = $(form).find(':submit');
      $submit_btn.attr('disabled', 'disabled');
    }

    function submitFormWithFile(formData, file, fn) {
      createAndSendXhr(form.action, addFileToFormData(fileParam, file, formData), fn);
    }
  }

  function getFormData(form) {
    return new FormData(form);
  }

  function getFirstDzFile(dz) {
    return dz.files[ 0 ]
  }

  function decorateEventFn(fn, scope, handler) {
    return (function(file) {
      fn(file);
      handler.call(this, file)
    }).bind(scope);
  }

  function addMaxFilesExceededHandler(ob, dz) {
    if (ob.maxfilesexceeded) {
      setDecoratedMaxFilesCallback(ob, dz);
    } else {
      ob.maxfilesexceeded = onMaxFilesExceeded.bind(dz);
    }
  }

  function setDecoratedMaxFilesCallback(ob, scope) {
    ob.maxfilesexceeded = decorateEventFn(ob.maxfilesexceeded, scope, onMaxFilesExceeded);
  }

  function onMaxFilesExceeded(f) {
    this.removeAllFiles(true);
    this.addFile(f);
  }

  function addFileAddedHandler(ob, dz) {
    if (ob.addedfile) {
      setFileAddedCallback(ob, dz);
    } else {
      ob.addedfile = onFileAddedSuccess.bind(dz);
    }
  }

  function setFileAddedCallback(ob, scope) {
    ob.addedfile = decorateEventFn(ob.addedfile, scope, onFileAddedSuccess);
  }

  function onFileAddedSuccess(f) {
    var $nameField = $('#upload_name');
    if ($nameField.length > 0) {
      $nameField.val(f.name);
    }
  }

  function addFileUploadErrorHandler(ob, dz) {
    if (ob.error) {
      setDecoratedFileUploadErrorCallback(ob, dz);
    } else {
      ob.error = onFileUploadError.bind(dz);
    }
  }

  function setDecoratedFileUploadErrorCallback(ob, scope) {
    ob.error = decorateEventFn(ob.error, scope, onFileUploadError);
  }

  function onFileUploadError(file, error) {
    this.removeAllFiles(true);
    notify(error, 'warning', 'Error! ');
  }

  function addEventHandlers(ob, target) {
    Object.keys(ob).forEach(getEventMapIterator(ob, target));
  }

  function getEventMapIterator(ob, target) {
    return function(eventType) {
      target.on(eventType, ob[ eventType ].bind(target));
    };
  }

  function addFileToFormData(_fileParam, _file, _formData) {
    if (_file) {
      _formData.append(_fileParam, _file);
    }
    return _formData;
  }

  function createAndSendXhr(url, data, fn) {
    $.ajax(getJqueryAjaxConfig(url, data)).always(fn);
  }

  function getJqueryAjaxConfig(url, data) {
    return {
      url : url + '.js',
      dataType : 'script',
      method : 'POST',
      data : data,
      processData : false,
      contentType : false
    };
  }

  function getDefaultConfig(fn, form) {
    var config = Object.create(DEFAULT_CONFIG);
    if ( $(form).data('maxFileSize') ) {
      config.maxFilesize = $(form).data('maxFileSize');
    }
    config.init = fn;
    return config;
  }
})();
