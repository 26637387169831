(function() {
  'use strict';

  window.onClickSubmitRequestWithHmrcHeaderData = function(button_id) {
    var $button = $(button_id);
    $button.on('click', function(event){
      $button.html('<i class="zmdi zmdi-rotate-right zmdi-hc-spin zmdi-hc-fw"></i>');
      var data_params = hmrc_header_data.values();
      data_params.lock_transactions = $(this).parents('#modal').find('#lock_transactions').prop('checked');
      data_params.prevent_unlocking_transactions = $(this).parents('#modal').find('#prevent_unlocking_transactions').prop('checked');
      $.ajax({
        url: $button.data('url'),
        method: $button.data('method'),
        data: data_params
      });
      return false;
    })
  };

  window.HmrcHeaderDataFinder = function() {
    this._local_ip = '';
    this._findLocalIpFromWebRTC();
    this._window_width = document.documentElement.clientWidth;
    this._window_height = document.documentElement.clientHeight;
    this._timezone_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this._screen_height = window.screen.height;
    this._screen_width = window.screen.width;
    this._screen_scaling_factor = window.devicePixelRatio;
    this._screen_colour_depth = window.screen.colorDepth;
    this._user_agent = navigator.userAgent;
    this._do_not_track = navigator.doNotTrack;
    this._local_ips_timestamp = new Date().toISOString();
    this._plugin_list = this._get_list_of_plugins();
  };

  HmrcHeaderDataFinder.prototype.values = function() {
    return {
      hmrc_header_data: {
        window_width: this._window_width,
        window_height: this._window_height,
        timezone_name: this._timezone_name,
        screen_height: this._screen_height,
        screen_width: this._screen_width,
        screen_scaling_factor: this._screen_scaling_factor,
        screen_colour_depth: this._screen_colour_depth,
        user_agent: this._user_agent,
        do_not_track: this._do_not_track,
        plugin_list: this._plugin_list,
        local_ip: this._local_ip,
        local_ips_timestamp: this._local_ips_timestamp
      }
    }
  };

  HmrcHeaderDataFinder.prototype._findLocalIpFromWebRTC = function(){
    var RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    if (typeof(RTCPeerConnection) === 'undefined') { return; }
    var noOperation = function(){};
    var peer_connection = new RTCPeerConnection({iceServers:[]});
    try {
      peer_connection.createDataChannel("");
      peer_connection.createOffer(peer_connection.setLocalDescription.bind(peer_connection), noOperation);
      peer_connection.onicecandidate = this._functionToSetLocalIp(this, peer_connection, noOperation);
    } catch(_err) {
      return;
    }
  };

  HmrcHeaderDataFinder.prototype._functionToSetLocalIp = function(hmrc_header_data_finder, peer_connection, noOperation){
    return function(ice){
      if(!ice || !ice.candidate || !ice.candidate.candidate)  return;
      var myIP = /((\S+.local)|([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7}))/.exec(ice.candidate.candidate)[1];
      hmrc_header_data_finder._local_ip = myIP;
      peer_connection.onicecandidate = noOperation;
    };
  }

  HmrcHeaderDataFinder.prototype._get_list_of_plugins = function(){
    var plugins;
    try { // Supported in only some browsers
      plugins = $.makeArray(navigator.plugins);
    } catch(error) {
      logMessage(error);
    }
    if (typeof(plugins) === 'undefined' || (jQuery.isArray(plugins) === true && plugins.length === 0)) { return; }
    return $.map(plugins, function(elt, ind) {
        if (elt.name.length > 0) {
            return elt.name;
        }
    });
  }

})();
