(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#content.imported_bank_transactions').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#imported_bank_transactions',
      user_preference_key: 'ibt_tab_id',
      tab_map: {
        'check': {
          options_id: 'check',
          multi_sort: true
        },
        'confirmed': {
          options_id: 'confirmed',
          table_id: 'imports_transactions_table',
          multi_sort: true
        },
        'pending': {
          options_id: 'pending',
          table_id: 'imports_pending_transactions_table',
          multi_sort: true
        },
        'ignored': {
          options_id: 'ignored',
          table_id: 'imports_ignored_transactions_table',
          multi_sort: true
        }
      }
    });
  }));
})();
