CreditedInvoiceData = {
  'tempInvoices': {},
  'creditedInvoices': {},
  'creditTotal': 0.0
}

CreditedInvoiceDataHandler = {
  "tempInvoices": {
    "getData": function(id) {
      return CreditedInvoiceData.tempInvoices[id]
    },
    "getHash": function() {
      return CreditedInvoiceData.tempInvoices
    },
    "setData": function(invoice_data){
      var objKeys =  Object.keys(CreditedInvoiceData.tempInvoices);
      var invoice_id = invoice_data.id;

      if (objKeys.length && objKeys.includes(invoice_id.toString())) {
        delete CreditedInvoiceData.tempInvoices[invoice_id];
      } else {
        CreditedInvoiceData.tempInvoices[invoice_id] = invoice_data;
      }
    },
    "setHash": function(row_id) {
      if(row_id != undefined) {
        initializeNewRowHash(row_id);
        CreditedInvoiceData.tempInvoices = Object.assign({}, CreditedInvoiceData.creditedInvoices[row_id]);
      } else {
        CreditedInvoiceData.tempInvoices = Object.assign({}, CreditedInvoiceData.creditedInvoices);
      }
    },
    "deleteData": function(id) {
      delete CreditedInvoiceData.tempInvoices[id];
    }
  },
  "creditedInvoices": {
    "initHashOnExistingInvoiceCards": function($invoiceCards, callback, row_id) {
      CreditedInvoiceDataHandler.tempInvoices.setHash(row_id);
      $invoiceCards.each(function() {
        var invoice_id = $(this).data('invoice-id');
        CreditedInvoiceDataHandler.tempInvoices.setData({id: invoice_id});
      })
      CreditedInvoiceDataHandler.creditedInvoices.setHash(row_id);
      if (callback != null) { callback(); }
    },
    "getHash": function() {
      return CreditedInvoiceData.creditedInvoices;
    },
    "getData": function(id) {
      return CreditedInvoiceData.creditedInvoices[id]
    },
    "setData": function(invoice_data, row_id){
      var objKeys =  Object.keys(tempInvoices);
      var invoice_id = invoice_data.id;
      if(row_id != undefined) {
        initializeNewRowHash(row_id);
        CreditedInvoiceData.creditedInvoices[row_id][invoice_id] = invoice_data;
      } else {
        CreditedInvoiceData.creditedInvoices[invoice_id] = invoice_data;
      }
    },
    "deleteData": function(id) {
      delete CreditedInvoiceData.creditedInvoices[id];
    },
    "setHash": function(row_id) {
      if (row_id != undefined) {
        CreditedInvoiceData.creditedInvoices[row_id] = Object.assign({}, CreditedInvoiceData.tempInvoices)
      } else {
        CreditedInvoiceData.creditedInvoices = Object.assign({}, CreditedInvoiceData.tempInvoices)
      }
    }
  },
  "setCreditedInvoicesData": function(rawRowDataFromServer){
    var objKeys =  Object.keys(creditedInvoices);

    if (objKeys.length && objKeys.includes(rawRowDataFromServer.id.toString())) {
      delete CreditedInvoiceData.creditedInvoices[rawRowDataFromServer.id];
    } else {
      CreditedInvoiceData.creditedInvoices[rawRowDataFromServer.id] = rawRowDataFromServer;
    }
  },
  "creditTotal": {
    "addAmount": function(amount) {
      CreditedInvoiceData.creditTotal += amount;
    },
    "subtractAmount": function(amount) {
      CreditedInvoiceData.creditTotal -= amount;
    }
  }
}

function initializeNewRowHash(row_id) {
  if (CreditedInvoiceData.creditedInvoices[row_id] != undefined) { return; }
  CreditedInvoiceData.creditedInvoices[row_id] = {};
}

function _setCreditedInvoiceFromInvoiceResponse(rows, row_id) {
  rows.forEach(function(invoice_row) {
    CreditedInvoiceDataHandler.creditedInvoices.setData(invoice_row, row_id);
  })
}
