import { selectorIsMultiPicker, updateMultiSelector, getMultiPickerValuesExcludingAll, resetMultiSelector, allOptionsSelected } from 'helpers/multipicker'

export function updateFilterForSelector(controller, selector) {
  const filterName = selector.dataset.filterName
  const values = getValuesFromSelector(selector)

  if (values.length === 0 || values === '-1' || allOptionsSelected(selector)) {
    delete controller.filters[filterName]
  } else {
    controller.filters[filterName] = values
  }
  return controller.filters
}

export function toggleResetButtonDisable(filters, resetButton) {
  if (Object.keys(filters).length === 0) {
    resetButton.classList.add('disabled')
  } else {
    resetButton.classList.remove('disabled')
  }
}

export function toggleFilterButtonDisable(table, filters) {
  const disableFilterButton = Boolean(Object.keys(filters).length > 0)
  const tableBootgridAction = document.getElementById(table.id + '-header')
  tableBootgridAction.querySelector('.advanced-filter').disabled = disableFilterButton
}

export function toggleFilterRowVisiblity(filters, advanceSearchElement) {
  if (Object.keys(filters).length !== 0) {
    advanceSearchElement.classList.add('in')
  }
}

export function resetDatepickersAndSelectors() {
  Array.from(document.querySelectorAll('.multi-selectpicker')).forEach(function (selector) {
    resetMultiSelector(selector)
  })

  Array.from(document.querySelectorAll('#advanced-search .advanced-search-selector, #project_selector')).forEach(function (selector) {
    $(selector).val(-1).trigger('chosen:updated')
  })
}

export function setSelectorsToFilterValues(filters) {
  if (Object.keys(filters).length !== 0) {
    Object.entries(filters).forEach(function (array) {
      const selector = document.querySelector('[data-filter-name=' + array[0] + ']')
      if (selectorIsMultiPicker(selector)) {
        updateMultiSelector(selector, array[1])
      } else {
        selector.value = array[1]
      }
    })
  } else {
    resetDatepickersAndSelectors()
  }
}

export function updateSelectorValue(selector, filters) {
  if (!selectorIsMultiPicker(selector)) { return }
  const filterName = selector.dataset.filterName
  const filterValues = filters[filterName]
  if (filterValues === undefined || filterValues.length === 0) {
    resetMultiSelector(selector)
  } else {
    updateMultiSelector(selector, filterValues)
  }
}

function getValuesFromSelector(selector) {
  if (selectorIsMultiPicker(selector)) {
    return getMultiPickerValuesExcludingAll(selector)
  } else {
    return selector.value
  }
}
