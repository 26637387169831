import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reset', 'disable']

  reset(event) {
    this.resetTargets.forEach(field => {
      field.selectedIndex = 0
      $(field).trigger('chosen:updated')
    })
    this.disableTargets.forEach(field => {
      field.disabled = true
      $(field).trigger('chosen:updated')
    })
  }
}
