import { Controller } from '@hotwired/stimulus'
import { initBankAccountWidgetClickEvent } from 'helpers/widgets/bank_account_widget'

export default class extends Controller {
  static values = {
    use_currency: Boolean,
    integer_value: Boolean,
    type: String
  }

  connect() {
    switch (this.typeValue) {
      case 'pie':
        window.initPieChartOrDisplayNoData($(this.element))
        break
      case 'bar':
        window.initBarChartOrDisplayNoData($(this.element), this.useCurrencyValue, this.integerValue)
        initBankAccountWidgetClickEvent()
        break
      default:
        window.initBarChartOrDisplayNoData($(this.element), this.useCurrencyValue, this.integerValue)
    }
  }
}
