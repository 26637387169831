(function() {
  'use strict';

  window.initQuoteFieldStateController = function() {
    window.quote_field_enabling_rules = readEnablingRulesFromPage();
    $('.nb_form.quotes').change(_updateQuoteFieldStates);
  }

  // updateQuoteFieldStates with a section - called in invoices__new_or_edit_non_batch
  window.updateQuoteFieldStatesWithSection = function($section, row_id) {
    var quote = new FieldStatesQuote($('.nb_form.quotes'), row_id, $section);
    var field_state_selector = new FieldStateSelector(quote);
    new FieldStateController(quote, field_state_selector).update();
  }

  function _updateQuoteFieldStates(event) {
    var row_id = getClosestDataRowIdToEvent(event)
    var quote = new FieldStatesQuote($('.nb_form.quotes'), row_id);
    var field_state_selector = new FieldStateSelector(quote);
    new FieldStateController(quote, field_state_selector).update(event.target);
  }
})();
