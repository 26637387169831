(function() {
  "use strict";

  window.isFeatureToggleActive = function(feature_key) {
    return activeFeatures.FEATURES.includes(feature_key);
  };

  window.isFeatureAvailableToCountry = function(feature_key, country_id) {
    return countryFeatures[feature_key].includes(Number(country_id));
  };
})();
