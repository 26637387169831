window.initPieChart = function(div, pie_data) {
  if (div.length === 0) { return }
  $.plot(div, pie_data, {
    colors: ['#03a9f4', '#9c27b0', '#ff9800', '#4caf50', '#009688', '#00bcd4', '#607d8b', '#2196f3'],
    series: {
      pie: {
        show: true
      },
    },
    grid: {
      hoverable: true,
      clickable: true
    },
    tooltip: true,
    tooltipOpts: {
      content: '%s - ' + companySettings.base_currency.currency_symbol + '%y.2 (%p.0%)',
      shifts: {
        x: 20,
        y: 0
      },
      defaultTheme: false,
      cssClass: 'flot-tooltip'
    }
  });
}