(function() {
  'use strict';

  window.initNotificationBell = function() {
    if(isFeatureToggleActive('notify_users_for_new_announcements_only') == false) { return }

    var $notificationBell = $("#notification-bell");

    $notificationBell.click(function() {
      $notificationBell.off("click");
      clearAnnouncementsCounter();
      $.ajax({
        url: Routes.mark_as_seen_user_announcements_path(),
        method: 'put',
        data: {
          user_announcements_ids: $notificationBell.data("user-announcements-ids")
        }
      });
    });
  }

  window.clearAnnouncementsCounter = function() {
    $('#item-count-container').fadeOut();
    $('#item-count-container').html('');
  }
})();
