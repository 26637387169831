
window.checkCreditLimitOnSubmit = function() {
  $('.credit_limit_check_button').on('click', function(e) {
    e.stopImmediatePropagation();
    e.preventDefault();

    if ($('.credit_limit_check_button').prop('disabled') === true) {
      return
    } // They double-clicked and the previous request hasn't finished yet

    // Disable the buttons so they can't click again
    $.rails.disableFormElements($('#invoice-save-options'))
    $.rails.disableElement($('#save_and_recur_button'))
    
    var transactions = getAllRows().map(function(row){ return {
        'invoice_id': row[0],
        'customer_id': row[1],
        'amount': formatWithoutDelimiter(row[2])
      }
    });

    $.post("/credit_checks", { "credit_checks_attributes": transactions},function(resp) {showCreditLimitWarning(resp, e)} ,'json')
  });
}

function getAllRows(){
  if (isBatchForm()){
    return $('tr.invoice_row').map(function(index, row){
      var wrappedRow = wrapBatchInvoiceRow(row)
      return [[wrappedRow.getRow().data('invoice-id'), wrappedRow.getCustomerSupplierSelect().val(), wrappedRow.getTotalAmountTf().val()]]
    }).toArray();
  }else{
    return [[$('.non_batch_invoices_form').data('invoice-id'), $('#customer_select_single').val(), $('#cd_total_amount_tf').val()]]
  }
}

function showCreditLimitWarning(resp, e){
  if (resp.length > 0){
    if (isRecurringSubmit(e)) {
      initRecurringBreachAlert(e, e.target.value, resp)
      return;
    }

    $("#modal .modal-header h4").html("Exceeding Credit Limit");

    // Content Body
    if (resp.length > 1) { // http://stackoverflow.com/a/6700/3767922
      $("#modal .modal-body-and-footer").html(
        BATCH_BREACH_MODAL_CONTENT + BREACH_MODAL_CONTENT_SHARED
      );
      multipleCustomerUpdateList(resp);
    } else {
      $("#modal .modal-body-and-footer").html(
        NON_BATCH_BREACH_MODAL_CONTENT + BREACH_MODAL_CONTENT_SHARED
      );
      singleCustomerUpdateList(resp);
    }

    // Init button and show modal
    initAllowBreachButton(e, e.target.value); // After .html(BREACH_MODAL_CONTENT)

    $('#modal').on('hidden.bs.modal', function(){
      $('.credit_limit_check_button').removeAttr("disabled");
      $.rails.enableElement($('.credit_limit_check_button'))
    });

    $("#modal").modal();
  }else{
    continueSubmit(e, e.target.value);
  }
}

function isBatchForm() {
  if(typeof window.isBatchCache !== 'undefined'){
    return window.isBatchCache
  }

  if ($('#invoice_form').length > 0) {
    window.isBatchCache = true
    return window.isBatchCache
  }

  if ( _isNonBatchNewForm() || isNonBatchEditForm() ) {
    window.isBatchCache = false
    return window.isBatchCache
  }

  throw new Error('isBatchForm() did not find an expected form');
}

function isRecurringSubmit(event) {
  return event.target.classList.contains('recurring_submit_button')
}

function _isNonBatchNewForm(){
  return $('#new_sales_invoice').length > 0 || $('#new_sales_credit_note').length > 0;
}

window.isNonBatchEditForm = function(){
  return $('[id^="edit_sales_invoice"], [id^="edit_sales_credit_note"], [id^="edit_customer_group_invoice"]').length > 0;
}

function initAllowBreachButton(event, button_text) {
  $('#allow_breach').on('click', function() {
    continueSubmit(event, button_text);
  });
  $('#do_not_breach').on('click', resetSubmitButtons)
}

function initRecurringBreachAlert(event, button_text, customers) {
  var alert_text = getSweetAlertText(customers)

  resetSubmitButtons()
  sweetAlert({
    title: "Exceeding Credit Limit",
    text: alert_text,
    html: true,
    allowEscapeKey: false,
    showCancelButton: true,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    customClass: 'sweetalert-pandle-theme'
  }, function(isConfirmed) {
    if(isConfirmed) { continueSubmit(event, button_text); } else { return; }
  });
}

function getSweetAlertText(customers) {
  if (customers.length > 1) {
    var alert_text = "Continuing will put the following customers over their credit limit: "
    alert_text += customers.map(function(customer) { return customer.name }).join(', ')
    alert_text += ". Are you sure you want to continue?"
  } else {
    var customer = customers[0];
    var alert_text = "Continuing will put " + customer.name +
      " over their credit limit of " + currencySymbol() + customer.credit_limit + ". " +
      customer.name + "'s new balance will be " + currencySymbol() + newAccountCurrencyBalance(customer) +
      ". Are you sure you want to continue?"
  }
  return alert_text
}

function resetSubmitButtons() {
  $.rails.enableFormElements($('#invoice-save-options'))
  $('.credit_limit_check_button').removeClass('waves-effect')
  $.rails.enableElement($('#save_and_recur_button'))
}

function continueSubmit(event, button_text) {
  if (isRecurringSubmit(event)) {
    resetSubmitButtons();
    return recurringContinueSubmit()
  } else if (isBatchForm()) {
    return batchContinueSubmit();
  } else {
    return nonBatchContinueSubmit(button_text);
  }
}

function batchContinueSubmit() {
  $('#invoice_form').submit();
}

function nonBatchContinueSubmit(button_text) {
  if (_isNonBatchNewForm() || isNonBatchEditForm()) {
    // Submit with commit param for Save/Next Save/View, etc
    // http://stackoverflow.com/a/5097295/3767922
    var $form = $('#new_sales_invoice, #new_sales_credit_note, [id^="edit_sales_invoice"], [id^="edit_sales_credit_note"], [id^="edit_customer_group_invoice"]');
    $form.append("<input type='hidden' name='commit' value='"+ button_text +"' />");
    $form.submit();
  }
}

function recurringContinueSubmit() {
  $('#recurring_transactions_form').submit();
}

function singleCustomerUpdateList(customers) {
  $.each(customers, function(index, customer) {
    $('span#customer_name').text(customer.name);
    $('span#customer_amount').text(currencySymbol() + formatAsCurrency(customer.credit_limit));
    $('span#account_currency_balance').text(currencySymbol() + newAccountCurrencyBalance(customer));
  });
}

function multipleCustomerUpdateList(customers) {
  $.each(customers, function(index, customer) {
    $('table.customer_list_table').append(
      "<tr>" +
        "<td>" + customer.name + "</td>" +
        "<td>" + currencySymbol() + formatAsCurrency(customer.credit_limit) + "</td>" +
        "<td>" + currencySymbol() + newAccountCurrencyBalance(customer) + "</td>" +
      "</tr>"
    );
  });
}

function currencySymbol() {
  return $('.currency-symbol').first().text()
}

function invoiceTotal() {
  var $amountField = $('#cd_net_amount_tf');
  if ($("#cd_total_amount_tf").length) { $amountField = $("#cd_total_amount_tf") } 
  if ($("#invoices__total_amount").length) { $amountField = $("#invoices__total_amount") } 
  return formatWithoutDelimiter($amountField.val())
}

function newAccountCurrencyBalance(customer) {
  var currentBalance = parseFloat(customer.account_currency_balance);
  var newBalance = currentBalance + parseFloat(invoiceTotal());
  var accountBalance = (Math.round(newBalance * 100) / 100).toFixed(2);
  return formatAsCurrency(accountBalance); 
}

window.isCustomerGroupInvoice = function(form) {
  var customer_multiple_select = $(form).find('.customer_multiple_select');
  return customer_multiple_select.length > 0 && !(customer_multiple_select.hasClass('hidden'));
}

var NON_BATCH_BREACH_MODAL_CONTENT = (
  "<div class='modal-body'>" +
    "<p>Continuing will put " +
      "<span id='customer_name'></span> " +
      "over their credit limit of " +
      "<span id='customer_amount'></span>.<br><br>" +
      "<span id='customer_name'></span>" +
      "'s new balance will be " +
      "<span id='account_currency_balance'></span>." +
    "</p>"
);

var BATCH_BREACH_MODAL_CONTENT = (
  "<div class='modal-body'>" +
    "<p>Continuing will put the following customers over their credit limit:</p>" +
    "<p>" +
      "<table class='table table-hover table-striped customer_list_table'>" +
        "<tr>" +
          "<th>Customer</th>" +
          "<th>Credit Limit</th>" +
          "<th>New Account Balance</th>" +
        "</tr>" +
      "</table>" +
    "</p>"
);

var BREACH_MODAL_CONTENT_SHARED = (
    "<p>Are you sure you want to continue?</p>" +
  "</div>" +
  "<div class='modal-footer'>" +
    "<button id='allow_breach'" + // Used for initAllowBreachButton
      "class='btn-primary btn waves-effect' data-dismiss='modal'"+
    ">" +
      "<i class='zmdi zmdi-check zmdi-hc-fw m-r-10'></i> Continue" +
    "</button>" +
    "<button id='do_not_breach' class='btn-danger btn waves-effect' data-dismiss='modal'>" +
      "<i class='zmdi zmdi-close zmdi-hc-fw m-r-10'></i> Cancel" +
    "</button>" +
  "</div>"
);
