(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    $('.btn-expand').click(function () {
      $('div.toggleable').toggleClass('container-fluid');
      $('#expandotron').toggleClass('expanded');
      if ($('#expandotron').hasClass('expanded')){
        UserPreferences.set('expandotron_activated', 'true')
      }else{
        UserPreferences.set('expandotron_activated', 'false')
      }
    });
  }));
})();
