(function() {
  'use strict';

  var SUBSCRIPTIONS_TAB_MAP = {
    'subscriptions': {
      options_id: 'subscriptions',
      table_id: 'subscriptions_table',
      multi_sort: false
    },
    'stripe': {
      options_id: 'stripe',
      table_id: 'stripe_table',
      multi_sort: false
    }
  };

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.user-subscriptions-index').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#subscriptions_index',
      user_preference_key: 'subscriptions_tab_id',
      tab_map: SUBSCRIPTIONS_TAB_MAP
    });
  }));
})();
