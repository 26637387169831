import { Controller } from '@hotwired/stimulus'

const searchFieldSelector = '.search-field'

/**
 * This Stimulus controller handles the restoration and storage of a search phrase
 * in a search field. It uses sessionStorage to persist the search phrase across
 * page navigations and reloads. It also uses a MutationObserver to detect changes
 * in the DOM and restore the search phrase when necessary.
 */
export default class SearchCriteriaController extends Controller {
  connect() {
    // Add event listener for turbo:load to handle Turbo navigation
    document.addEventListener('turbo:load', this.restoreSearchPhrase.bind(this))

    // Add event listener to search when the bootgrid table loads
    $(document.body).on('loaded.rs.jquery.bootgrid', event => {
      // Use the input event to store the search phrase in session storage
      const searchField = document.querySelector(searchFieldSelector)
      if (searchField) {
        searchField.addEventListener('input', (e) => {
          const searchPhrase = e.target.value
          sessionStorage.setItem("searchPhrase", searchPhrase)
        })
        this.restoreSearchPhrase();
      }
    })
  }

  /**
   * Restores the search phrase from sessionStorage to the search field.
   * Triggers a keyup event to initiate a search if a stored search phrase is found.
   */
  restoreSearchPhrase() {
    const searchField = document.querySelector(searchFieldSelector)
    const transactions = document.getElementById("financial_transactions_index")
    if (searchField && transactions) {
      const storedSearchPhrase = sessionStorage.getItem('searchPhrase')
        if (storedSearchPhrase) {
          searchField.value = storedSearchPhrase
          // Create and dispatch a keyup event to trigger the search on bootgrid
          const event = new Event('keyup', { bubbles: true })
          searchField.dispatchEvent(event)
        }
      } else {
        if(!transactions) sessionStorage.setItem("searchPhrase","")
    }
  }
}
