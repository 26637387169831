(function() {
  'use strict';

  var ACCEPTABLE_VERSIONS = [
    "v1"
  ]

  function initApiDocVersionChangeListener() {
    var $version_selector = $('#apidoc-version-selector');
    $version_selector.on('change', function() {
      $('.apidoc-button').css('display', 'inline-block');
      var version = $version_selector.val();
      if ($.inArray(version, ACCEPTABLE_VERSIONS) == -1) { return; }
      updateVersionLinks(version);
    })
  }

  function updateVersionLinks(version) {
    $('#apidoc-oauth-button').find('a.apidoc-link').attr('href', '/api/docs/' + version + '/oauth');
    $('#apidoc-swagger-button').find('a.apidoc-link').attr('href', '/api/docs/' + version + '/swagger');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#apidoc-version-selector').length === 0) { return }
    initApiDocVersionChangeListener();
  }));
})();
