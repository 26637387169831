import consumer from './consumer'

consumer.subscriptions.create('IbtConfirmationChannel', {
  connected: function () {
    // Called when the subscription is ready for use on the server
  },

  disconnected: function () {
    // Called when the subscription has been terminated by the server
  },

  received: function (data) {
    if (data.has_failed_confirmations) {
      notify('', 'danger', 'Error confirming one or more transactions. Click here to check your unconfirmed transactions', null, data.link)
    } else {
      notify('Your transactions were confirmed. Click here to view your confirmed transactions', 'success', 'Success! ', null, data.link)
    }
  }
})
