export function selectorIsMultiPicker(selector) {
  return selector.classList.contains('multi-selectpicker')
}

export function updateMultiSelector(selector, values) {
  $(selector)
    .val(values)
    .selectpicker('refresh')
}

export function getMultiPickerValuesExcludingAll(selector) {
  return Array
    .from(selector.options)
    .filter(function (option) { return option.selected && option.value !== '-1' })
    .map(function (option) { return option.value })
}

export function resetMultiSelector(selector) {
  if (selectorHasAllOption(selector)) {
    updateMultiSelector(selector, ['-1'])
  } else {
    $(selector).selectpicker('selectAll')
  }
}

function selectorHasAllOption(selector) {
  return Boolean(Array.from(selector.children).filter(function (option) { return option.value === '-1' }).length > 0)
}

export function allOptionsSelected(selector) {
  const options = Array.from(selector.children)
  const selectedOptions = options.filter(function (option) { return option.selected })
  return Boolean(options.length === selectedOptions.length)
}
