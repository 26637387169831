/**
 * @fileoverview
 * This decorates the modal jquery plugin, to make it play nicely with chosen
 * select boxes
 */
(function() {
  'use strict';
  var _modal = $.fn.modal;
  $.fn.modal = modalChosenSelectDecorated;

  /**
   * Extends behaviour in
   * @link{http://getbootstrap.com/javascript/#modals-usage} with an optional
   * chosenSelect key in the options object.
   *
   * This option defaults to true. If it is set, then a form inside the modal
   * will not be submitted if the enter key is pressed.
   *
   * @param {Object=} options Optional options object
   * @returns {jQuery} The JQuery object upon which modal() was called.
   */
  function modalChosenSelectDecorated(options){
    if(!(options && options.chosenSelect === false)){
      initialiseModalWithChosenSelect(this);
    }

    return _modal.apply(this, arguments);
  }
  /**
   * If the modal contains any chosen select elements, add an event listener.
   * @param $modal {jQuery} jQuery instance of modal element
   */
  function initialiseModalWithChosenSelect($modal) {
    if(_containsChosenSelect($modal)) {
      _addModalKeyDownEventListener($modal);
    }
  }
  /**
   *
   * @param $elem {jQuery}
   * @private
   */
  function _containsChosenSelect($elem){
    return $elem.find('.chosen-select').length;
  }
  /**
   * Here we set 'useCapture' to true so that we can intercept the event before
   * it propagates to other handlers.
   * @param $modal
   * @private
   */
  function _addModalKeyDownEventListener($modal){
    $modal.get()[0].addEventListener('keydown', _onKeyDown, true);
  }

  /**
   *
   * @param e {KeyboardEvent}
   * @private
   */
  function _onKeyDown(e){
    var activeElement = document.activeElement;

    if (_isEnterKey(e) && !$(activeElement).hasClass('note-editable panel-body') && !$(activeElement).hasClass('multiline-modal')) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  /**
   *
   * @param e {KeyboardEvent}
   * @returns {boolean}
   * @private
   */
  function _isEnterKey(e){
    return e.key == 'Enter' || e.keyCode == 13;
  }
})();
