 window.capitalize = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

 window.titleize = function (string) {
  var string_array = string.toLowerCase().split(' ');
  string_array = string_array.map(function(str) {
    return capitalize(str);
  });

  return string_array.join(' ');
}