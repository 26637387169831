import { Controller } from '@hotwired/stimulus'
import { splitDateToFormatArray, getSeparatorFromDateFormat } from 'helpers/date_helper'

export default class extends Controller {
  static targets = ['inputDate']

  connect() {
    this.input_field = $(this.inputDateTarget)
    this.date_format = this.input_field.data('format')
    this.date_separator = getSeparatorFromDateFormat(this.date_format)
  }

  completeInput(event) {
    const controller = this
    if (event.key !== controller.date_separator) { return }
    const currentDate = controller.input_field.val()
    const newDate = controller.constructNewDate(controller, currentDate)
    controller.setDate(controller.input_field, newDate)
  }

  autoCompleteDate(event) {
    const controller = this
    controller.input_field.change()
  }

  constructNewDate(controller, date) {
    const separator = controller.date_separator
    const splitFormat = controller.date_format.split(separator)
    const dateArray = splitDateToFormatArray(date, separator, splitFormat)
    return dateArray.join(separator)
  }

  setDate($field, date) {
    $field.val(date)
  }
}
