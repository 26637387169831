/**
* @fileoverview Encapsulates xhr polling logic and exposes a simple pub/sub
* interface for updating the UI based on server sent messages.
*
* This is a stand in for ActionCable/Websockets behaviour
*/

(function() {
  // 'use strict'; is disabled as it seems to cause the payload (a bootgrid
  // table as json) passed to _addChannelState (line 80) to be a string
  // rather than a JSON object.

  var CHANNEL_STATE = {
    INACTIVE : 0,
    CONNECT : 1,
    ACTIVE : 2,
    UPDATE : 3,
    RETRY : 4,
    FAIL : 5
  };

  /**
   *
   * @constructor
   */
  window.PollingChannel = function(ajax_opts) {
    this._ajax_opts = ajax_opts;
    this._state = CHANNEL_STATE.INACTIVE;
    this._subscribers = {};
    this._delay = 2000;
    this._timeoutRef = null;
    this._retryCount = 0;
    this._retryLimit = 9;
    this._xhr = null;
  };

  PollingChannel.prototype.connect = function() {
    this._state = CHANNEL_STATE.CONNECT;
    this._performRequest();
  };

  PollingChannel.prototype.subscribe = function(on_publish_fn) {
    var key = createUid();
    this._subscribers[key] = on_publish_fn;

    return key;
  };

  PollingChannel.prototype.disconnect = function() {
    if (this._xhr) {
      this._xhr.abort();
      this._xhr = null;
    }

    clearTimeout(this._timeoutRef);
    this._state = CHANNEL_STATE.INACTIVE;
  };

  PollingChannel.prototype._performRequest = function() {
    this._xhr = $.ajax(this._getAjaxOpts());
  };

  PollingChannel.prototype._getAjaxOpts = function() {
    var opts = this._ajax_opts;
    opts.success = this._getSuccessCallbackFn();
    opts.error = this._onError.bind(this);
    opts.status = null;
    opts._noModal = true;

    return opts;
  };

  PollingChannel.prototype._getSuccessCallbackFn = function() {
    if (this._state === CHANNEL_STATE.CONNECT) {
      return this._onConnectSuccess.bind(this);
    } else if (this._state === CHANNEL_STATE.ACTIVE) {
      return this._onUpdateSuccess.bind(this);
    }
  };

  PollingChannel.prototype._onConnectSuccess = function(response) {
    this._state = CHANNEL_STATE.ACTIVE;
    this._publish(response);
    this._scheduleRequest();
  };

  PollingChannel.prototype._publish = function(payload) {
    this._forEachSubscriber(publishToSubscriber(this._addChannelState(payload)));
  };

  PollingChannel.prototype._addChannelState = function(payload) {
    payload['channel_state'] = this._state;
    return payload;
  };

  PollingChannel.prototype._forEachSubscriber = function(fn) {
    Object.keys(this._subscribers).forEach(function(key) {
      fn(this._subscribers[key]);
    }, this);
  };

  PollingChannel.prototype._scheduleRequest = function() {
    if (this._timeoutRef) {
      clearTimeout(this._timeoutRef);
    }

    this._timeoutRef = setTimeout(this._performRequest.bind(this), this._delay);
  };

  PollingChannel.prototype._onUpdateSuccess = function(response) {
    this._state = CHANNEL_STATE.ACTIVE;
    this._publish(response);
    this._scheduleRequest();
  };

  PollingChannel.prototype._onError = function() {
    if (this._shouldRetry()) {
      this._retry();
    } else {
      this._fail();
    }
  };

  PollingChannel.prototype._shouldRetry = function() {
    return this._retryCount < this._retryLimit;
  };

  PollingChannel.prototype._retry = function() {
    this._state = CHANNEL_STATE.RETRY;
    this._retryCount++;
    console.warn(this._createRetryLogMessage());
    this._scheduleRequest();
  };

  PollingChannel.prototype._createRetryLogMessage = function() {
    return 'Polling channel could not reach server, retrying in ' + this._delay
      + 'ms (' + this._retryCount + '/' + this._retryLimit + ').'
  };

  PollingChannel.prototype._fail = function() {
    this._state = CHANNEL_STATE.FAIL;
    console.error('Polling channel could not reach server, giving up.');
  };

  var uid_count = 0;

  function createUid() {
    return uid_count++;
  }

  function publishToSubscriber(payload) {
    return (function(subscriber) {
      subscriber.call(null, payload);
    });
  }

  /**
   * @public
   * @enum
   */
  PollingChannel.CHANNEL_STATE = CHANNEL_STATE;
})();
