// Shows an element if the target value is selected otherwise hides it.
//
// Add the following data attributes to a select to enable this functionality:
//
// data-show-on-change-target="#some-identifier"
//   The target element to show/hide depending on the value selected.
//
// data-show-on-change-value="some value"
//   The value of the select tag to trigger showing the target.
//
(function(window) {
  $(document).on("turbo:load shown.bs.modal", function(e) {
    $("[data-show-on-change-target]").on("change", function (e) {
      var $select = $(this);
      var value = $select.val();
      var data = $select.data();
      var showValue = data.showOnChangeValue;
      var target = data.showOnChangeTarget;

      if (value == showValue) {
        $(target).show();
      } else {
        $(target).hide();
      }
    });
  });
})(this);
