(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#ecsl_report_table').length > 0) {
      initBootgridTable($('#ecsl_report_table'));
    }

    if ($('#ecsl_submission_summary_table').length > 0) {
      initBootgridTable($('#ecsl_submission_summary_table'));
    }
  }));
})();