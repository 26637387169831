function tooltipPlacement(context, source) {
  var $source = $(source);
  var $parent = $source.closest('table');
  var off1 = $parent.offset();
  var w1 = $parent.width();

  var off2 = $source.offset();

  if (parseInt(off2.left) < parseInt(off1.left) + parseInt(w1 / 2)) return 'right';
  return 'left';
}
