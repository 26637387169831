(function() {
  'use strict';

  window.initRecurringTransactionsModal = function() {
    $('#recurring_transactions_form').find('#date_end_checkbox').click(function() {
      showOrHideAndNullSectionWithCheckbox('#recurring_transactions_form', '#date_end_checkbox', '#date_end_fields')
    });
    initEndDateOptions();
    liquidInserts();
  };

  function initEndDateOptions() {
    if ($('#date_end_container input').val() !== "") {
      onDateEndChanged();
    }
    $('#date_end_container').on('dp.change', onDateEndChanged);
    $('#no_of_occurrences').on('change', onNoOfOccurrencesChanged);
    initElementsResettingEndDateField();
  }

  function onDateEndChanged() {
    var $dateStart = $('#date_start_container input');
    var m_dateStart = moment($dateStart.val(), $dateStart.data('format'));

    var $dateEnd = $('#date_end_container input');
    var m_dateEnd = moment($dateEnd.val(), $dateEnd.data('format'));

    var frequency = parseInt($('#recurring_transaction_frequency').val());
    var frequencyUnit = $('#recurring_transaction__frequency_select').val();

    var noOfOccurrences = 0;

    while (m_dateStart.toDate() <= m_dateEnd.toDate()) {
      noOfOccurrences++;
      m_dateStart.add(frequency, frequencyUnit);
    }

    $('#no_of_occurrences').val(noOfOccurrences);
  }

  function onNoOfOccurrencesChanged() {   
    var $dateStart = $('#date_start_container input');
    var m_dateStart = moment($dateStart.val(), $dateStart.data('format'));

    var $dateEnd = $('#date_end_container input');
    
    var frequencyUnit = $('#recurring_transaction__frequency_select').val();
    var newDate = m_dateStart.add(unitToAdd(), frequencyUnit);

    $dateEnd.val(formatDate(newDate, $dateEnd.data('format')));
  }

  function unitToAdd() {
    var frequency = parseInt($('#recurring_transaction_frequency').val());
    var noOfOccurrences = parseInt($('#no_of_occurrences').val());
    return (frequency * noOfOccurrences) - 1;
  }

  function initElementsResettingEndDateField() {
    function resetEndDateFields() {
      $('#date_end_container > input.form-control').val('');
      $('#no_of_occurrences').val('');
    }

    $('#date_start_container').on('dp.change', resetEndDateFields);
    $('#recurring_transaction_frequency').on('change', resetEndDateFields);
    $('#recurring_transaction__frequency_select').on('change', resetEndDateFields);
  }

  window.initEmailTemplateForm = function() {
    initBootstrapWizard();
    setSendInvoicesCheckboxListener('#recurring_transactions_form', '#send_invoices', '#email_template_fields');
    initTabChangeHandler(".email-template", tabChangeHandler);
    tabChangeHandler();
    displayAttachmentsInfoIfNecessary();
  };

  function setSendInvoicesCheckboxListener(parent, checkbox, form) {
    $(checkbox).unbind();
    showOrHideSectionWithCheckbox(parent, checkbox, form);

    $(checkbox).change(function() {
      showOrHideSectionWithCheckbox(parent, checkbox, form);
      autoSizeEmailContentTextArea();
    });
  }

  function initTabChangeHandler(selector, handler) {
    var mut = new MutationObserver(function (mutations, mut) {
      handler();
    });
    mut.observe(document.querySelector(selector), { 'attributes': true });
  }

  function tabChangeHandler() {
    if ($('#tab2nav').hasClass('active')) {
      autoSizeEmailContentTextArea();
      displayButtonsFor('tab2');
    } else {
      displayButtonsFor('tab1');
    }
  }

  function autoSizeEmailContentTextArea() {
    autosize.update($('.auto-size'));
  }

  function displayButtonsFor(tab) {
    var previous_button = $('.previous');
    var next_button = $('.next');
    var submit_button =  $('#submit');

    if (tab === 'tab2') {
      previous_button.show();
      next_button.hide();
      submit_button.show();
    } else {
      previous_button.hide();
      next_button.show();
      submit_button.hide();
    }
  }

  function displayAttachmentsInfoIfNecessary() {
    if ($("#upload_ids").val()) {
      $("#attachments-info").show();
    }
  }
})();
