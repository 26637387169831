(function() {
  'use strict';

  window.initSupplierModal = function() {
    verifyAllEmailInputs();
    $('#has_invoice_ref_prefix').on('click', setInvoiceRefVisibility);
    $('#cs_status_checkbox').on('click', function() { initRecurringTransactionsAlertModal("supplier") });
    $('#supplier_uses_eu_vat').on('click', warnUnitedKingdomEuSelection);
    $('.modal #supplier_name').on('change', supplierNameChangeModal);
    $('.modal #company_selection_field').on('change', supplierNameChangeModal);
    $('.js-input_mask_country_for_postcode').on('change', customerSupplierCountryChange);
    initCompaniesHouseSearch($('#supplier_name'), autoFillFields, resetFields)
    submitOnEnterPress('#new_supplier_form'); // Submit the supplier form, not the hidden new nominal account form
    initCollapse($('#new_supplier_form'));
    initBootstrapWizard();
    initTabChangeHandler('#new_supplier_form');
    checkSupplierInCompanyHouseAndHideVatField();
    initChosenDropdown($('#supplier_name'), $('#companies_house_search'));
    initListenerForTelephoneField($('#supplier_telephone_number'));
  };

  function autoFillFields(){
    var reg_val = $('#companies_house_search_select_field').find('option:selected').data('company-number')
    callCompanyInfoEndpoint(reg_val, setAddressFields, resetContactFields);
  }

  function setAddressFields(response) {
    var $line1 = $('#supplier_address_attributes_address_line_1');
    var $line2 = $('#supplier_address_attributes_address_line_2');
    var $postcode = $('#supplier_address_attributes_postcode');
    var $locality = $('#supplier_address_attributes_town_city');
    autofillAddressWithCompaniesHouseResponse($line1, $line2, $postcode, $locality, response);
    autoFillContactNameWithFirstOfficer($('#supplier_contact_name'), response['officers']['active_officers']);
    setEuVatCheckboxVisibility(false);
    if (response.length == 1){
      $('#supplier_contact_name').val(response[0])
    }
  }

  function resetFields() {
    setEuVatCheckboxVisibility(true);
  }

  function supplierNameChangeModal() {
    if ($(this).val() != 'nil'){
      csNameChangeModal('supplier', $(this).val());
    }
  }

  function checkSupplierInCompanyHouseAndHideVatField() {
    var $supplierNameField = _getCustomerNameField();
    if ($supplierNameField.length == 0) { return; }
    if ($supplierNameField.val().length > 0) { callCompaniesSearchEndpoint($supplierNameField.val(), hideCheckboxIfCompanyInResponse, null); }
  }

  function hideCheckboxIfCompanyInResponse(response) {
    var companies = response['companies'];
    var $supplierNameField = _getCustomerNameField();
    companies.forEach(function (s) {
      if (s.company_name.toLowerCase() == $supplierNameField.val().toLowerCase()) {
        $('.modal').find('#eu_vat_checkbox_field').hide();
      }
    });
  }

  function _getCustomerNameField() {
    return $('.modal #supplier_name');
  }
})();
