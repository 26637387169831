(function() {
  'use strict';

  window.configureFileImportForm = function() {
    configureDropzone($('#new_file_import_dropzone'), $('#new_file_import')[0], "file_import[uploaded_file]");
    $('#new_file_import').on('submit', function(){
      displayExplanatoryPageLoader("Your transactions are being imported.", 'left-65');
    });
  };

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#new_file_import_dropzone').length === 0) { return }
    configureFileImportForm();
  }));
})();