(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#imported_suppliers').length === 0) { return }
    if ($('#imported_supplier_form').length > 0) {
      errors.tryChange($('.js-input_mask_country_for_postcode'), customerSupplierCountryChange);
      initModals($('[id^="address_modal_"]'));
    }
    initBootgridTable($('#imported_suppliers_index_table'));
  }));
})();
