/**
* @fileoverview Javascript common to all forms
*/

(function() {
  'use strict';

  /**
   * Intended for use by rails-injected js views
   *
   * Replaces the element's content with html and performs any necessary post-rendering behaviour.
   *
   * @param {jQuery} $formParent
   * @param {string} html
   * @returns {jQuery}
   * @throws TypeError
   */
  window.renderForm = function($formParent, html) {
    if (!($formParent instanceof jQuery) || !($formParent.length)) {
      throw new TypeError('$formParent ' + $formParent + ' is not a jQuery instance and match at least one element')
    }

    if (typeof html !== 'string') {
      throw new TypeError('html ' + html + ' is not a string')
    }

    $formParent.html(html);
    $('html, body').animate({ scrollTop: 0 }, 'slow')
    formatFormsIn($formParent);

    return $formParent
  };
  /**
   * Attaches commonly used form listeners and initialises datepicker, masks etc.
   * Run only after changing the DOM
   */
  window.formatForms = function() {
    initFields();
    initDatepickerComponent();

    // Must be before chosen is initiated!
    checkNeedForNewNominalOption();
    checkNeedForNewCustomerOption();
    checkNeedForNewSupplierOption();
    checkNeedForNewProjectOption();
    checkNeedForNewStockItemOption();
    checkNeedForNewTaxCodeOption();

    initLoadCustGroupButton();
    initChosenSelects();
    initSelectPickers();
    initLoadSingleCustomerButton();
    _initInputMasks($('body'));
    _initAutoSize($('body'));
    verifyAllEmailInputs();
  };

  /**
   * Attaches commonly used form listeners within a section
   * and initialises datepicker, masks etc.
   * Run when a modal is opened  (and on OBW invoice creation)
   */
  window.formatFormsIn = function(section) {
    initFields(section);
    initDatepickerComponent(section);

    //Must be before chosen is initiated!
    checkNeedForNewNominalOption(section);
    checkNeedForNewCustomerOption(section);
    checkNeedForNewSupplierOption(section);
    checkNeedForNewProjectOption(section);
    checkNeedForNewTaxCodeOption(section);
    checkNeedForNewStockItemOption(section);

    initChosenSelects(section);
    _initInputMasks(section);
    _initAutoSize(section);
  };

  window.initFields = function($parent) {
    $parent = $parent || $('body');

    if ($parent.find('.fg-line')[0]) {
      _addFgLineEventListeners($parent);
    }
  };

  /**
   * @returns {Date}
   */
  function convertLocalDateToUtcDate() {
    var currentLocalDate = new Date(),
        utcTimestamp = Date.UTC(currentLocalDate.getUTCFullYear(), currentLocalDate.getUTCMonth(),
                                currentLocalDate.getUTCDate(), currentLocalDate.getUTCHours(),
                                currentLocalDate.getUTCMinutes(), currentLocalDate.getUTCSeconds(),
                                currentLocalDate.getUTCMilliseconds());
      return new Date(utcTimestamp);
  }

  function parseISOLocalDate(dateString) { // Date.parse is BAD! https://stackoverflow.com/a/33909265
    if (dateString.length === 0) { return }
    var rawDate = dateString.split(/\D/);
    return new Date(rawDate[0], rawDate[1]-1, rawDate[2]);
  }

  window.initDatepickerComponent = function(section) {
    var inputDateComponents = section ? section.find('.datepicker-component') : $('.datepicker-component');

    $.each(inputDateComponents, function(i, elt) {
      var inputElement = $(this).find('.form-control');

      var defaultDate = parseISOLocalDate($(inputElement).data('value')) || null,
          currentUtcDate = convertLocalDateToUtcDate();

      var minDate = Math.min(defaultDate, new Date(1000, 0, 0));
      var maxDate = false //FARMPLAN: Don't set a max date


      var dateTimePicker = ($(this).find('.dateButton').length > 0) ? this : inputElement;
      $(dateTimePicker).datetimepicker({
        format: $(inputElement).data('format'),
        useCurrent: !$(elt).hasClass('do_not_default_to_current_date'),
        keyBinds: {
          'enter': function() {
            this.hide();
          }
        },
        defaultDate: defaultDate,
        minDate: minDate,
        maxDate: maxDate,
        widgetPositioning: { horizontal: 'auto', vertical: 'bottom' } //FARMPLAN: prevents responsive repositioning clipping under navbar
      });

      var inputMask = $(inputElement).data('input-mask');
      $(inputElement).inputmask(inputMask);
    });

    inputDateComponents.find('.form-control').focus(_focusDatePicker);

    if (inputDateComponents.length > 0) {
      datepickersComponentAlreadyInitialised = true;
      var evt = _constructDatepickersInitialisedEvent('datepickersComponentInitialised');
      document.dispatchEvent(evt);
    }
  };

  // The two following function are copies of the ones above
  // They are present only while both component and non component date pickers are used
  window.afterDatepickersComponentHaveBeenInitialised = function(onDatepickerInit){
    if ( datepickersComponentAlreadyInitialised === true ) {
      // initDatepicker has already been run, so do the thing now
      onDatepickerInit();
    }
    // Add the event listener in case the event happens again
    document.addEventListener('datepickersComponentInitialised', onDatepickerInit);
  };

  window.onceAfterDatepickersComponentHaveBeenInitialised = function(onDatepickerInit){
    if ( datepickersComponentAlreadyInitialised === true ) {
      // initDatepicker has already been run, so do the thing now
      return onDatepickerInit();
    } else {
      // Add the event listener for when the event does happen
      document.addEventListener('datepickersComponentInitialised', onDatepickerInit, { once: true });
    }
  };

  window.initChosenSelects = function(section) {
    var $elements = (section) ? section.find('.chosen-select') : $('.chosen-select');

    var chosen_defaults = {
      width: '100%',
      search_contains: true
    };

    $elements.filter('.chosen-no-split-word-search').chosen(
      $.extend({}, chosen_defaults, { search_contains: false, enable_split_word_search: false} )
    );
    $elements.filter('.chosen-allow-deselect').chosen(
      $.extend({}, chosen_defaults, { allow_single_deselect: true} )
    );
    $elements.filter('.chosen-width-480').chosen(
      $.extend({}, chosen_defaults, { allow_single_deselect: true, width: '480px' } )
    );
    $elements.filter('.chosen-hide-disabled-options').chosen(
      $.extend({}, chosen_defaults, { display_disabled_options: false } )
    );
    $elements.filter(':not(.chosen-no-split-word-search):not(.chosen-allow-deselect):not(.chosen-width-480)').chosen(chosen_defaults);
  };

  // The selectpicker plugin doesn't play nicely with turbolinks, so use class multi-selectpicker instead of its
  // default 'selectpicker'
  window.initSelectPickers = function() {
    $('.multi-selectpicker').selectpicker();
  }

  window.initPostcodeInputMaskInModal = function() {
    // Appears to be a duplicate of same function WithSection
    // but passing the $('.modal') section from the other call doesn't seem to work
    var country_code = $('.modal').find('.js-input_mask_country_for_postcode').chosen().val();
    if (country_code === "GB" || country_code === "XI") {
      $('#contact').find('.input-mask-postcode').inputmask(('A*9* 9AA'|'A** 9AA'));
    } else {
      $('.modal').find('.input-mask-postcode').inputmask('remove');
    }
  };

  window.initPostcodeInputMaskWithSection = function(section) {
    var country_code = section.find('.js-input_mask_country_for_postcode').chosen().val();
    if (country_code === "GB" || country_code === "XI") {
      section.find('.input-mask-postcode').inputmask(('A*9* 9AA'|'A** 9AA'));
    } else {
      section.find('.input-mask-postcode').inputmask('remove');
    }
  };

  window.alterCountyInputType = function(event, $county_fields) {
    //If the country is United Kingdom, set the county input type to a select_box, rather than a text_field
    var country_code = $(event.target).val();
    var $great_britain_county_fields = $county_fields.find('.great_britain_counties');
    var $northern_ireland_county_fields = $county_fields.find('.northern_ireland_counties');
    disable_select_field($great_britain_county_fields)
    disable_select_field($northern_ireland_county_fields)

    if (country_code === "GB") {
      $county_fields.find('.text_field').addClass('hidden');
      $county_fields.find('.text_field .form-control').prop('disabled', true);
      enable_select_field($county_fields.find('.great_britain_counties'))
    }else if(country_code === "XI"){
      $county_fields.find('.text_field').addClass('hidden');
      $county_fields.find('.text_field .form-control').prop('disabled', true);
      enable_select_field($county_fields.find('.northern_ireland_counties'))
    } else {
      $county_fields.find('.text_field .form-control').prop('disabled', false);
      $county_fields.find('.text_field').removeClass('hidden');
    }
  };

  window.disable_select_field = function($select_field){
    $select_field.addClass('hidden');
    $select_field.prop('disabled', true).val('').trigger('chosen:updated');
    $select_field.find('.chosen-search input').addClass('hidden');
  }

  window.enable_select_field = function($select_field){
    $select_field.removeClass('hidden');
    $select_field.find('select').prop('disabled', false).trigger('chosen:updated');
    $select_field.find('.chosen-search input').removeClass('hidden');
  }

  // Function for populating a selector
  $.fn.selectPopulator = function(data) {
    var select = this;
    // FARMPLAN: Iterate over the selects to get an array of values
    var values = [];
    select.each(function(_, element) { values.push($(element).val()) }); // Store the original values
    // /FARMPLAN
    select.empty();
    select.append('<option value=""></option>');
    $.each(data, function() {
      var option_string = '<option value="' + this.id + '"';
      for (var key in this.data_attributes) {
        option_string += ' data-' + key + '= "' + this.data_attributes[key] + '"';
      }
      option_string += '>' + this.form_name + '</option>'
      select.append(option_string);
    });
    // FARMPLAN: Iterate over the selects to reset the values
    select.each(function(index, element) { $(element).val(values[index]) }); // Set back to the original values (if exists)
    // /FARMPLAN
    $('.chosen-select').trigger('chosen:updated');
  };

  window.submitOnEnterPress = function(form_id, element_type, key_event) {
    element_type = element_type || ' input';
    key_event = key_event || 'keyup'
    $(form_id + element_type).on(key_event, function(event) {
      if (event && event.which === 13 && !event.shiftKey) {
        event.preventDefault();
        $(form_id).submit();
        $(form_id)[0].reset();
      }
    });
  };

  window.focusFirstField = function(section) {
    if (section.find('[autofocus]').length > 0) {
      section.find('[autofocus]').first().focus();
    } else {
      section.find('.form-control:not([type="checkbox"])').first().focus();
    }
  };

  window.focusFirstFieldOnTabChange = function($section) {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      focusFirstField(_activeTabPane($section));
    });
  };

  window.highlightCell = function(event) {
    $(event.target).select();
  };

  window.getTabbedFormTabId = function(elem) {
    return elem.id.split('_')[0];
  };

  window.disableFields = function($elem) {
    return $elem.attr('disabled', 'disabled');
  };

  window.enableFields = function($elem) {
    return $elem.removeAttr('disabled');
  };

  window.triggerChosen = function($elem, eventType) {
    return $elem.trigger('chosen:' + eventType);
  };

  window.initCollapse = function($section) {
    var $collapse = $section.find('.collapse');

    if ($collapse[0]) {
      $collapse.on('show.bs.collapse', function (e) {
          $(this).closest('.panel').find('.panel-heading').addClass('active');
      });

      $collapse.on('hide.bs.collapse', function (e) {
          $(this).closest('.panel').find('.panel-heading').removeClass('active');
      });

      $collapse.each(function(){
          $(this).closest('.panel').find('.panel-heading').addClass('active');
      });
    }
  };

  /**
  * Return first active tab pane scoped to $section if provided otherwise return
  * the first active tab on the page
  */
  function _activeTabPane($section) {
    if ($section && $section.find('.tab-pane.active').length) {
      return $section.find('.tab-pane.active');
    } else {
      return $('.tab-pane.active');
    }
  }


  /**
   * Add blue animated border and remove with condition when focus and blur
   */
  function _addFgLineEventListeners($parent) {
    _addFgLineEventListenersForClass($parent, '.chosen-container');
    _addFgLineEventListenersForClass($parent, '.form-control');
  }

  /**
   * Add blue animated border and remove with condition when focus and blur
   */
  function _addFgLineEventListenersForClass($parent, selector) {
    $parent.on('focus', '.fg-line ' + selector, function() {
      var $fg = $(this).closest('.fg-line');
      $fg.addClass('fg-toggled');
    }).on('blur', selector, function() {
      var $fg = $(this).closest('.fg-line');
      $fg.removeClass('fg-toggled');
    });
  }

  function _initInputMasks(section) {
    initPostcodeInputMaskWithSection($(section));
    $(section).find('.input-mask-number').inputmask('[9][9][9][9][9][9][9][9][9][9]');
    //$(section).find('.input-mask-amount').inputmask("decimal", { mask: '[9{1,}][.][9][9]', digits: 2 });
    $(section).find('.input-mask-conversion-rate').inputmask('decimal', { digits: 12 });
    $(section).find('.input-mask-decimal').inputmask('decimal', { digits: 2, greedy: false })
    $(section).find('.input-mask-decimal-percentage').inputmask('[9{1,3}][.9]'); // up to 3 digits before decimal point, 1 after
  }

  function _initAutoSize(section) {
    var elements = $(section).find('.auto-size');
    if (elements[0]) {
      autosize(elements);
    }
  }

  function _constructDatepickersInitialisedEvent(eventName) {
    var is_using_internet_explorer = _usingInternetExplorer();
    if ( is_using_internet_explorer ) {
      return _constructOldStyleDatepickersInitialisedEvent(eventName);
    } else {
      try {
        return new Event(eventName);
      } catch (e) {
        // Safari 5 definitely doesn't cope with new Event, others may also not
        return _constructOldStyleDatepickersInitialisedEvent(eventName);
      }
    }
  }

  function _usingInternetExplorer() {
    var ua = window.navigator.userAgent;
    var old_ie = ua.indexOf('MSIE ');
    var new_ie = ua.indexOf('Trident/');

    if ((old_ie > -1) || (new_ie > -1)) {
      return true;
    }
  }

  function _constructOldStyleDatepickersInitialisedEvent(eventName) {
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(eventName, true, true, {});
    return evt;
  }

  // When a date field is focused, select the contents so you can overwrite the existing date
  function _focusDatePicker(event) {
    var elem = event.currentTarget;
    $(elem).select();
  }
})();
