(function () {
  'use strict';

  function onInteractiveHelpClick() {
    var sidebar = $('aside#chat');
    // Make the help section slide in and out
    // Remove transition added by transitionFixedSidebar
    sidebar.css({ 'transition': '' });

    UserPreferences.set(
      'interactive_help_enabled',
      getNewInteractiveHelpToggleState()
    );
  }

  function getNewInteractiveHelpToggleState() {
    return $('aside#chat').hasClass('toggled') ? 'false' : 'true';
  }

  function initSmileyFeedback() {
    $('#middle-link').click(function () {
      $('#feedback-buttons').hide();
      $('#middle').show();
    });

    $('#happy-link').click(function () {
      $('#feedback-buttons').hide();
      $('#middle').show();
      $('#happy').show();
    });

    $('#sad-link').click(function () {
      $('#feedback-buttons').hide();
      $('#sad').show();
    });
  }

  function initCollapsableAreas() {
    var preferences = $('#chat').data('preferences');
    if (preferences) {
      ['collapseZero','collapseOne','collapseTwo','collapseThree'].forEach(function(collapse){
        initOpenOrClose(collapse, preferences);
        $('#'+collapse).on('show.bs.collapse', onSubSectionClick);
        $('#'+collapse).on('hide.bs.collapse', onSubSectionClick);
      });
    }
  }

  function initOpenOrClose(section, preferences){
    if (preferences['interactive_help_'+section+'_shown'] == 'true'){
      $('.panel-heading.'+section+'_div').addClass('active')
      $('.collapse.'+section+'_div').addClass('in')
    }else{
      $('.accordion.'+section+'_div').addClass('collapsed')
    }
  }

  function onSubSectionClick(evt) {
    setSubSectionPreferences(evt.type, this.id);
  }

  function setSubSectionPreferences(shown, section) {
    if (shown == 'show') {
      UserPreferences.set('interactive_help_' + section + '_shown', true);
    } else {
      UserPreferences.set('interactive_help_' + section + '_shown', false);
    }
  }

  window.initSidebar = function () {
    $('a#toggleInteractiveHelp').click(onInteractiveHelpClick);
    initFixedSidebar();
    initCollapsableAreas();
    initSmileyFeedback();
  }

  document.addEventListener('turbo:load', errors.tryFn(function () {
    if ($('#chat').length > 0) {
      initSidebar();
    }
  }));
})();
