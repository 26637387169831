(function() {
  'use strict';

  function initTabs(){
    $('#tab-nav-component a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
    });
    $('#tab-nav-component a:first').tab('show');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#errors-line-chart').length === 0) { return }

    var tooltipContent = function(label, xval, yval, x, y, z) {
      return String(yval)
    }

    initLineChartOrDisplayNoData($('#errors-line-chart'), tooltipContent);
    initTabs();
  }));
})();
