window.initModalInvoicesTable = function() {
  $('#vat_return_invoices_transactions_table').bootgrid({
    css: {
      icon: 'zmdi icon',
      iconColumns: 'zmdi-view-module',
      iconDown: 'zmdi-caret-down',
      iconRefresh: 'zmdi-refresh',
      iconUp: 'zmdi-caret-up'
    },
    ajaxSettings: { method: 'GET' },
    multiSort: false,
    labels: { search: 'Search by description or ref' }
  });
}

function _vatReturnTransactionTable() {
  return $('#vat_return_invoices_transactions_table')
}

window.alertVatModalAfterDestroy = function() {
  if(_vatReturnTransactionTable().length != 0 && document.querySelectorAll("div.alert.alert-warning[data-growl]").length == 0) {
      notify('Please recalculate your VAT report to ensure changes are reported', "warning", "Warning! ");
  }
}

window.reloadVatModal = function() {
  if(_vatReturnTransactionTable().length != 0) { _vatReturnTransactionTable().bootgrid('reload') }
}
