import { AlertController } from './alert_controller'

export default class extends AlertController {
  // FARMPLAN - override to remove references to stock and inventory
  alertTextValue(event) {
    const categoryName = event.target.value
    const categoryMatch = categoryName.match(/debtor|creditor|receivable|payable/i)[0]
    return `We have noticed you have created a category called '${categoryMatch.charAt(0).toUpperCase() + categoryMatch.slice(1)}'. We recommend that opening receivables are broken down in this wizard by the individual outstanding invoices to ensure the accuracy in your accounts.`
  }
}
