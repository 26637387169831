(function() {
  'use strict';

  window.ModalLineItemRow = function($row) {
    this._row = $row;
    this._$fields = {}
  };

  ModalLineItemRow.prototype.lineItemSelect = function() {
    return this._getField('.invoice_line_items_select');
  }

  ModalLineItemRow.prototype.selectedLineItem = function() {
    return this._getField('.invoice_line_items_select option:selected');
  }

  ModalLineItemRow.prototype.accountField = function() {
    return this._getField('.split_account');
  }

  // FARMPLAN
  ModalLineItemRow.prototype.enterpriseField = function() {
    return this._getField('.enterprise_selector');
  }
  // FARMPLAN END

  ModalLineItemRow.prototype.descriptionField = function() {
    return this._getField('.split_description_tf');
  }

  ModalLineItemRow.prototype.stockItemField = function() {
    return this._getField('.split_stock_item');
  }

  ModalLineItemRow.prototype.stockQuantityField = function() {
    return this._getField('.split_stock_item_quantity_tf');
  }

  ModalLineItemRow.prototype.priceField = function() {
    return this._getField('.split_price_field');
  }

  ModalLineItemRow.prototype.netAmountField = function() {
    return this._getField('.split_net_amount_field');
  }

  ModalLineItemRow.prototype.taxCodeField = function() {
    return this._getField('.split_tax_code_field');
  }

  ModalLineItemRow.prototype.taxRate = function() {
    return getTaxRateFromTaxCodeSelect(this.taxCodeField());
  }

  ModalLineItemRow.prototype.taxAmountField = function() {
    return this._getField('.split_tax_amount_field');
  }

  ModalLineItemRow.prototype.totalAmountField = function() {
    return this._getField('.split_total_amount_field');
  }

  ModalLineItemRow.prototype.destroyField = function() {
    return this._getField('.flagged_for_deletion');
  }

  ModalLineItemRow.prototype.isReadOnlyTotal = function() {
    return this.totalAmountField().is('[readonly]');
  }

  ModalLineItemRow.prototype.allocatableIdField = function() {
    return this._getField('.line-item-allocatable-id');
  }

  ModalLineItemRow.prototype.allocatableTypeField = function() {
    return this._getField('.line-item-allocatable-type');
  }

  ModalLineItemRow.prototype._getField = function(selector) {
    if(!this._$fields[selector]) {
      this._$fields[selector] = this._row.find(selector);
    }
    return this._$fields[selector];
  }
})();
