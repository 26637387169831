(function(){
  window.bootgrid = window.bootgrid || {};
  
  bootgrid.queryHelpers = {
    
    getHeaderColumnIds : function($table){
      return $table.find('thead tr').children().get().map(getColumnId);
    },
  
    getRowCellMap: function(row_index, col_ids, $table){
      var ob = {};
      col_ids.forEach(assignEachRowCellToMap(ob, row_index, $table));
      return ob;
    },
    
    getCellAt : function($table, col, row) {
      return $(this.getRowAt($table, row).children()[ col ]);
    },
    
    getRowAt : function($table, index) {
      return $($table.find('tbody tr')[ index ]);
    }
    
  };
  
  function getColumnId(elem) {
    var col_id = elem.dataset.columnId;
    
    if(col_id && col_id.length){
      return col_id
    } else {
      console.error(elem);
      throw new Error('Could get column id from bootgrid header column element');
    }
  }
  
  function assignEachRowCellToMap(ob, row_index, $table){
    return function(col_id, col_index){
      ob[ col_id ] = bootgrid.queryHelpers.getCellAt($table, col_index, row_index);
    };
  }
  
})();
