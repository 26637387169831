(function() {
  'use strict';

  window.initCsvImportForm = function(){
    if ($('#new_csv_import').length > 0) {
      $('#csv_import_type').change(typeChanged);
      toggleCsvImportDateFormat();
      addSubmitEventSpinner()
    }
    configureCsvDropzone();
  }

  function toggleCsvImportDateFormat() {
    var div = $('#imported_invoice_date_format_div');

    if ($('#csv_import_type').val().indexOf('InvoiceCsvImport') >= 0 ) {
      div.removeClass('hidden');
    } else {
      div.addClass('hidden');
    }
  }

  function configureCsvDropzone() {
    if ($('#csv_import_dropzone').length === 0) { return }
    configureDropzone(
      $('#csv_import_dropzone'),
      $('#new_csv_import')[0],
      'csv_import[uploaded_file]'
    );
  }

  function typeChanged(event) {
    showCsvImportTypeAdvice(event);
    toggleCsvImportDateFormat();
  }

  function showCsvImportTypeAdvice(event) {
    getJsonOrShowMaintenanceModal('/csv_imports/expected_csv_headings?type=' + $(event.target).val(), function(data){
      $('#csv_headings_advice').html('<em>' + data['headings_list'] + '</em>')
    });
  }

  function addSubmitEventSpinner() {
    $('#new_csv_import').on("submit", function() { $('#csv-import-submit-button').html(
      '<i class="zmdi zmdi-rotate-right zmdi-hc-fw m-r-10 zmdi-hc-spin"></i> Uploading...'
    )})
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    initCsvImportForm();
  }));
})();
