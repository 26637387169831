(function() {
  'use strict';

  window.initTransactionsTable = function($table) {
    initTransactionUpdates();
    initBootgridTableWithCheckboxes($table, true);
    errors.tryOn($table, 'loaded.rs.jquery.bootgrid', function() {
      copyToClipboardOnClick();
    });
    _initFilters($table);
    var filters = _getTransactionFilters($table);
    disableFilterDropdown(filters);
  }

  function _initFilters($table) {
    [ $('#start-date'), $('#end-date') ].forEach(function(args) {
      args.on('dp.change', function() {
        _onDatePickerChange($table);
      });
    });

    [ [$('#amount_from_field'), 'amount_from'],
      [$('#amount_to_field'), 'amount_to']
    ].forEach(function(args) {
      args[0].on('change', function(_) {
        if (checkAmountsAreValid(args[0])) {
          _onChange($table, args[0], args[1])
        }
      });
    });

    [ [$('#transaction_type_selector'), 'transaction_type'],
      [$('#category_selector'), 'category_id'],
      [$('#entry_method_selector'), 'entry_method_id'],
      [$('#bank_account_selector'), 'bank_account_id'],
      [$('#tax_code_selector'), 'tax_code_id'],
      [$('#enterprise_multi_selector'), 'enterprise_id']
    ].forEach(function(args) {
      if (args[0].length > 0) { updateSelectorName(args[0]) }

      args[0].on('changed.bs.select', function(_, selectedIndex) {
        _onSelectorChange($table, args[0], args[1], selectedIndex)
      });
    });

    $('#project_selector').on('change', function() {
      var filters = _getTransactionFilters($table);
      updateFilterForSelector(filters,  $('#project_selector'), 'project_id');
      _sendFilter($table, filters);
    });

    //FARMPLAN: Filter by enterprise
    $('#enterprise_selector').on('change', function() {
      var filters = _getTransactionFilters($table);
      updateFilterForSelector(filters,  $('#enterprise_selector'), 'enterprise_id');
      _sendFilter($table, filters);
    });
    // /FARMPLAN

    $('#reset-button').click(function(e) {
      e.preventDefault();
      _onResetClick($table);
    });

    $('#reset-button').addClass('disabled')
  }

  function _onSelectorChange($table, $selector, name, selectedIndex) {
    var filters = _getTransactionFilters($table);
    var values = $selector.val() || [];
    var selectedValue = $selector.find('option').eq(selectedIndex).val();

    if (selectedValue == "-1" || values.length == 0) {
      delete filters[name];
      refreshPicker($selector, ["-1"]);
    } else {
      if (values.includes("-1")) {
        var updatedFilters = $selector.val().splice(1);
        refreshPicker($selector, updatedFilters);
      }

      filters[name] = values;
    }

    updateSelectorName($selector);

    _sendFilter($table, filters);
  }

  function _onChange($table, $field, name) {
    var filters = _getTransactionFilters($table);
    var value = $field.val();
    updateFilterForInput(filters, value, name);
    _sendFilter($table, filters);
  }

  function updateSelectorName($selector) {
    var selectorId = $selector.attr('id');
    var button = "button[data-id=" + selectorId + "]"
    var select_value = $selector.val();

    if (select_value == null) { return; }
    if (select_value == "-1") {
      $(button).text("All");
    } else if ($selector.val().length == 1) {
      var selectedOption = $selector.find("option:selected").text()
      $(button).text(selectedOption)
    } else {
      $(button).text("Multiple");
    }
  }

  function _onProjectSelectorChange($table, $selector, name) {
    var filters = _getTransactionFilters($table);
    updateFilterForSelector(filters, $selector, name);
    _sendFilter($table, filters);
  }

  function refreshPicker($selector, val) {
    $selector.val(val)
    $selector.selectpicker("refresh")
  }

  function _onDatePickerChange($table) {
    afterDatepickersComponentHaveBeenInitialised(function(){
      var filters = _getTransactionFilters($table);
      updateFilterForDateRange(filters);
      _sendFilter($table, filters);
     })
  }

  function _getTransactionFilters($table) {
    var filters = $table.attr('data-transaction-filters');
    return JSON.parse(filters);
  }

  function _sendFilter($table, filters) {
    $table.attr('data-transaction-filters', JSON.stringify(filters));
    evaluateResetButtonVisibilityFromFilters(filters);
    disableFilterDropdown(filters);
    $table.bootgrid('filterAndLoadFirstPage');
  }

  function _onResetClick($table) {
    resetDatepickersAndSelectors();
    _sendFilter($table, {});
  }

  function disableFilterDropdown(filters) {
    var disable_filter_button = Boolean(Object.keys(filters).length > 0);
    $('.advanced-filter').attr('disabled', disable_filter_button);
  }

  function checkAmountsAreValid($currentField) {
    var from_amount = parseFloat($('#amount_from_field').val());
    var to_amount = parseFloat($('#amount_to_field').val());
    if ($currentField.is('#amount_from_field')) {
      var message = "The amount from cannot be greater than the amount to"
    } else {
      var message = "The amount to cannot be less than amount from"
    }
    if (!isNaN(from_amount) && !isNaN(to_amount) && to_amount < from_amount) {
      notify(message)
      $currentField.val('');
      return false;
    } else {
      return true;
    }
  }
})();
