import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).popover()
  }

  showPopover(event) {
    $(this.element).popover('show')
  }

  showPopoverWithContent(event) {
    $(this.element).popover({ content: event.detail.content })
    $(this.element).popover('show')
  }

  showPopoverWithContainer(event) {
    $(this.element).popover({ container: event.detail.container })
    $(this.element).popover('show')
  }

  updatePopoverContent(event) {
    $(this.element).attr('data-content', event.detail.content)
  }

  hidePopover() {
    $(this.element).popover('hide')
  }

  destroyPopover(event) {
    $(this.element).popover('destroy')
  }
}
