(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#gl_table_container').length === 0) { return }
    $('#gl_report_table').bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up'
      },
      columnSelection : false,
      rowCount : 10,
      converters: { order_by_date: order_by_date }
    });
    submitFormOnDateChange( $('#gl_date_end') );
  }));
})();
