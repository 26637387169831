(function() {
  'use strict';

  function initAdminAnalyticsDash() {
    initNavTabsWithTables({
      tabbable_id: '#analytics_dashboard',
      tab_map: 0, //Non-standard usage: Only 1 bootgrid table in non-standard location
      user_preference_key: 'admin_analytics_dash_tab_id'
    });
    $('#analytics-dash-form').trigger('submit.rails');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#content.analytics_dashboards').length === 0) { return }
    errors.tryFn(initAdminAnalyticsDash());
  }));
})();
