(function() {
  'use strict';

  window.initStockItemForm = function(){
    initFormEventListeners();

    if($('#stock_item_modal_form').length > 0) {
      $('#modal').on('shown.bs.modal', function (e) {
        setStockCheckboxes();
        showOpeningInventoryFields();
        showOrHideItemCodeSection();
      });
    } else {
      setStockCheckboxes();
      showPriceWarning();
      showOpeningInventoryFields();
      showOrHideItemCodeSection();
    }

    if($('#stock_item_is_tracked').data('openingInventory')){
      checkboxReadOnly($('#stock_item_is_tracked'), true);
    }
  }

  function initFormEventListeners() {
    $(".stock-item-form").keypress(function(e) {
      if (e.which == '13' && !$(e.target).hasClass('multiline-modal')) {
        e.preventDefault();
        return false;
      }
    });

    $('#purchases_checkbox').change(function(){
      checkboxActionForFormType('#purchases', '#purchases_checkbox', '#purchases_fields');
      showPriceWarning();
    });

    $('#sales_checkbox').change(function(){
      checkboxActionForFormType('#sales', '#sales_checkbox', '#sales_fields');
      showPriceWarning();
    });
    
    $('#stock_item_is_tracked').click(onIsTrackedCheckboxClicked);

    $('#stock_item_purchase_price').change(function(){
      updatePriceField(this);
      setTotalValueFromPriceAndQuantity();
      showPriceWarning();
    });

    $('#stock_item_sales_price').change(function(){
      updatePriceField(this);
      showPriceWarning();
    });

    $('#stock_item_quantity').change(function(){
      setTotalValueFromPriceAndQuantity();
    });

    $('.account_select').change(function(){
      setTaxCodeFromNominalAccountDefault($(this));
    });

    $('#nominals_for_tracked').on('shown.bs.popover', function (e) {
      $('input#nominal_account_is_direct_cost').prop('checked', true);
    });
  }

  function updatePriceField(field) {
    $(field).val(parseFloat($(field).val()).toFixed(2));
  }

  function onIsTrackedCheckboxClicked(event) {
    function updateUI() {
      setStockCheckboxes();
      setPurchaseNominalsBasedOnTracked();
      showOpeningInventoryFields();
      showOrHideItemCodeSection();
    }
    function updateInvoiceCreator(){
      $.ajax({
        url: $form.data("update-invoice-creator-url"),
        method: 'patch',
        dataType: 'js',
        data: { company_invoice_creator: { has_product_invoices: '1' } }
      }).always(function() { $("#invoice-creator-update-warning").hide() });
    }
    var $form = $(event.target).parents("form");
    var companyHasProductInvoices = $form.data("company-has-product-invoices");
    var itemIsNotTracked = $form.find("#stock_item_is_tracked").is(':unchecked');

    if (companyHasProductInvoices || itemIsNotTracked) {
      updateUI();
    } else {
      sweetAlert({
        title: "Tracked items keep track of the products you buy and sell, and your stock levels.\nPlease confirm you would like to turn on tracked items.",
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        customClass: 'sweetalert-pandle-theme',
      }, function(isConfirmed) {
        if (isConfirmed) {
          updateInvoiceCreator();
        }
        $form.find("#stock_item_is_tracked").prop("checked", isConfirmed);
        updateUI();
      });
    }
  }

  window.setStockCheckboxes = function(){
    setCheckboxBasedOnTracked('#purchases', '#purchases_checkbox', '#purchases_fields');
    setCheckboxBasedOnTracked('#sales', '#sales_checkbox', '#sales_fields');
  }

  window.updateStockAccountDebitAmount = function(){
    getStockData(function(data){
      var $stockDebitField = getStockRow(data.stock_account).find('.debit-cell').find('input');
      if (data.total_amount >= 0){
        $stockDebitField.val(data.total_amount);
      }
    });
  }

  function checkboxActionForFormType(parent_selector, checkbox_selector, section_selector) {
    if($('#stock_item_modal_form').length > 0) {
      showOrHideSectionWithCheckbox(parent_selector, checkbox_selector, section_selector);
    } else {
      enableOrDisableSectionWithCheckbox(parent_selector, checkbox_selector, section_selector)
    }
  }

  function setCheckboxBasedOnTracked(parent_selector, checkbox_selector, section_selector){
    var $checkbox = $(checkbox_selector)
    if (isTracked()) {
      $checkbox.prop("checked", true);
      checkboxReadOnly($checkbox, true);
      checkboxActionForFormType(parent_selector, checkbox_selector, section_selector);
    } else {
      checkboxReadOnly($checkbox, false);
    }
  }

  window.setPurchaseNominalsBasedOnTracked = function(){
    var $nominals_for_tracked = $('#nominals_for_tracked');
    var $nominals_for_untracked = $('#nominals_for_untracked');

    if(isTracked() && $('#nominals_for_tracked').length > 0){
      swapfields($nominals_for_tracked, $nominals_for_untracked);
    }else{
      swapfields($nominals_for_untracked, $nominals_for_tracked);
    }
    $(".js-add-new-nominal").trigger("chosen:updated");
  }

  window.swapfields = function(fieldToShow, fieldToHide){
    fieldToHide.hide();
    fieldToHide.find('.account_select').prop("disabled", true);
    fieldToShow.find('.account_select').prop("disabled", false);
    fieldToShow.show();
  }

  window.isTracked = function(){
    var $trackedCheckbox = $('#stock_item_is_tracked')
    return $trackedCheckbox.length > 0 && $trackedCheckbox.is(':checked')
  }

  function setTotalValueFromPriceAndQuantity(){
    var $price = $('#stock_item_purchase_price');
    var $quantity = $('#stock_item_quantity');
    var $totalValue = $('#stock_item_opening_inventory_attributes_net_amount');
    $totalValue.val($price.val() * $quantity.val());
  } 

  function setTaxCodeFromNominalAccountDefault($select_field){
    var default_tax_code = $select_field.find('option:selected').data('default-tax-code');
    $select_field.parents('.fields').find('.tax_select').val(default_tax_code).trigger('chosen:updated');
  }

  function showPriceWarning(){
    if (
      $('#purchases_checkbox').is(':checked') &&
      $('#sales_checkbox').is(':checked') &&
      $('#stock_item_sales_price').val() &&
      ( parseFloat($('#stock_item_sales_price').val()) < parseFloat($('#stock_item_purchase_price').val()) )
    ) {
      showPopoverWithContainer($('#price-warning'), 'body');
    } else {
      destroyPopover($('#price-warning'));
    }
  }

  function enableOrDisableSectionWithCheckbox(parent_selector, checkbox_selector, section_selector) {
    var $parent = $(parent_selector);
    var $section = $parent.find(section_selector);
    var disabled = !$parent.find(checkbox_selector).is(':checked');
    ['input:not(.hidden_date_format_field)', 'textarea', 'select'].forEach(function(element) {
      $section.find(element).prop('readonly', disabled).prop('disabled', disabled);
    });
    $section.find('select').trigger('chosen:updated');
    enableOrDisableObwFields(parent_selector, disabled);
  }

  function enableOrDisableObwFields(parent_selector, disabled) {
    if ($('#opening_inventory').length == 0 || parent_selector != "#sales") {
      return;
    }
    $('#opening_inventory_fields').find('.amount_field').prop('readonly', disabled).prop('disabled', disabled);
  }

  function showOpeningInventoryFields(){
    if($('#opening_inventory_fields').length == 0) { return; };
    checkboxActionForFormType('#stock_item_modal_form', '#stock_item_is_tracked', '#opening_inventory_fields');
  }

  function showOrHideItemCodeSection() {
    showOrHideAndNullSectionWithCheckbox('#item_details', '#stock_item_is_tracked', '#item_code_field');
  }
})();
