(function() {
  'use strict';

  var HELP_MESSAGE_INVOICES_EXIST = 'The amounts and nominal account specified for this transaction match those for ' +
  'at least one existing invoice. </br> Did you intend to enter an invoice payment/receipt instead?';

  window.checkForSmallAssetAmounts = function(row_id, total_amount_field) {
    showWarningIfIsSmallAssetAmount({
      amount: total_amount_field.val(),
      row_id: row_id,
      selected_category: getBtAccountSelect(row_id).find(':selected'),
      warning_function: _addAssetHelpMessage(row_id)
    });
  };

  window.checkForSmallAssetAmountsSplit = function(category, total_amount_field){
    showWarningIfIsSmallAssetAmount({
      amount: total_amount_field.val(),
      selected_category: category.find(':selected'),
      warning_function: _notifyAssetWarning
    });
  }

  window.checkInvoiceExistsWithAmountsAndNominalAccount = function(row) {
    row = $(row).closest('tr')[0];
    if (_isBankPaymentOrBankReceipt(row)) {
      _requestInvoicesForNominalAccount(_getInvoiceParamsFromRow(row), _addHelpMessageIfInvoicesExist);
    }
  }

  window.checkInvoiceExistsWithAmountsAndNominalAccountSplit = function($row) {
    var $bt_row = $row.closest('tr.batch_row');
    if (_isBankPaymentOrBankReceipt($bt_row)) {
      _requestInvoicesForNominalAccount(_getInvoiceParamsFromSplitRow($row, $bt_row), _addNotificationIfInvoicesExist);
    }
  }

  /*
  * @param {Object} arg
  * @param {number} arg.amount
  * @param {jQuery} arg.selected_category
  * @param {function} arg.warning_function
  */
  function showWarningIfIsSmallAssetAmount(arg){
    if ( _isLargeAmount(arg.amount) ) { return }
    if ( _totalIsNotSet(arg.amount) ) { return }
    if ( _isNotNominalAccount(arg.selected_category)) { return }
    if ( _isAssetCategory(arg.selected_category)) {
      arg.warning_function();
    }
  }

  function _isLargeAmount(amount){
    return amount >= 50
  }

  function _totalIsNotSet(amount){
    return !amount || amount == 0
  }

  function _isNotNominalAccount(category){
    return category.val().indexOf('NominalAccount') === -1
  }

  function _isAssetCategory(category){
    return category.data('accountType') == 'Asset'
  }

  function _addAssetHelpMessage(row_id){
    return function(){
      addHelpMessageUnlessExists(
        null, HELP_WARNING,
        'Small value items are not typically assets, please choose an expense category instead',
        'small_assets_' + row_id
      );
    }
  }

  function _notifyAssetWarning(){
    // When entering split amounts in a modal, you can't see the help notifications
    // We use notify() instead so the user can read it
    notify('Small value items are not typically assets, <br/>please choose an expense category instead', 'warning', 'Warning! ')
  }

  function _isBankPaymentOrBankReceipt(row) {
    return [ BANK_PAYMENT, BANK_RECEIPT].indexOf(getBankTransactionType(row)) > -1;
  }

  function _requestInvoicesForNominalAccount (params, onResponse) {
    if(params.nominal_account_id && params.total_amount){
      _requestInvoicesByAmounts(params).then(onResponse);
    }
  }

  function _requestInvoicesByAmounts (params) {
    return $.ajax({
      url: '/invoices/invoices_by_amounts.json',
      data: params
    });
  }

  function _addHelpMessageIfInvoicesExist(invoices_exist) {
    if (invoices_exist && !invoices_exist.error) {
      addHelpMessage(null, HELP_INFO, HELP_MESSAGE_INVOICES_EXIST);
    }
  }

  function _addNotificationIfInvoicesExist(invoices_exist){
    if (invoices_exist && !invoices_exist.error) {
      notify(HELP_MESSAGE_INVOICES_EXIST, 'info');
    }
  }

  function _getInvoiceParamsFromRow (row) {
    return {
      net_amount: $(row).find('.net_amount_field').val(),
      tax_amount: $(row).find('.tax_amount_field').val(),
      total_amount: $(row).find('.total_amount_transaction_tf').val(),
      nominal_account_id: _getNominalAccountIdFromSelectVal($(row).find('.account').val()),
      date: getGbDateStringFromClosestDateTimePicker($(row).find('.bt_date_field'))
    };
  }

  function _getInvoiceParamsFromSplitRow($row, $bt_row){
    return {
      net_amount: $row.find('.split_net_amount_field').val(),
      tax_amount: $row.find('.split_tax_amount_field').val(),
      total_amount: $row.find('.split_total_amount_field').val(),
      nominal_account_id: _getNominalAccountIdFromSelectVal($row.find('.split_account').val()),
      date:getGbDateStringFromClosestDateTimePicker($bt_row.find('.bt_date_field'))
    };
  }

  function _getNominalAccountIdFromSelectVal (val) {
    if ( val ) {
      val = val.split('#');
      return (val[0] == 'NominalAccount') ? val[1] : false;
    }
  }
})();
