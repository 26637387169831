(function() {
  'use strict';

  var USER_SETTINGS_FORM_SELECTOR = '#edit_user_settings_form';

  var USER_PROFILE_DROPZONE = (
    "<div class='dz_upload_message'>" +
      "<div class='avatar_or_icon'>" +
        "<i class='zmdi zmdi-face'></i>" +
      "</div>" +
      "<button type='button' class='btn btn-sm btn-primary btn-icon-text waves-effect'>" +
        "<i class='zmdi zmdi-cloud-upload m-r-10'></i>" +
        "Upload A Profile Photo!" +
      "</button>" +
    "</div>"
  );

  window.initUserSettingsForm = function() {
    enableTabPersistence();
    configureAvatarDropzone();
    hideAvatarOnDelete();
    initStripeEmailCheck($('.stripe-emails'));
  };

  function enableTabPersistence () {
    $('ul.tab-nav a').click(onTabClick)
  }

  function onTabClick (e) {
    UserPreferences.set('user_settings_active_tab', getTabId(e.target));
  }

  function getTabId (elem) {
    return elem.id.split('_').slice(0,-1).join('_');
  }

  function configureAvatarDropzone() {
    if ($('#new_user_avatar_dropzone:not(.hidden)').length === 0) { return }
    configureDropzone($('#new_user_avatar_dropzone'), $('#edit_user_settings_form')[0], "user[avatar]", USER_PROFILE_DROPZONE);
  }

  function hideAvatarOnDelete() {
    $('#delete_avatar').on(
      'change', function() {
        $('#user_avatar_preview').toggle();
        $('#new_user_avatar_dropzone').toggleClass('hidden');
        $('#delete_avatar_container').hide();
        configureAvatarDropzone();
      }
    )
  }

  function initStripeEmailCheck($stripe_message) {
    if ($stripe_message.length === 0) { return }
    addEmailInputEventListeners($('#user_email'), $stripe_message);
  }

  function addEmailInputEventListeners($user_email, $stripe_message) {
    $user_email.change(onUserEmailChange($user_email.val(), $stripe_message));
  }

  function onUserEmailChange(initial_value, $stripe_message) {
    return function(e) {
      if (e.target.value !== initial_value) {
        $stripe_message.slideDown();
      } else {
        $stripe_message.slideUp();
      }
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($(USER_SETTINGS_FORM_SELECTOR).length === 0) { return }
    errors.tryFn(initUserSettingsForm());
  }));
})();
