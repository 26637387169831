(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#ft_bulk_edit_form').length === 0) { return }
    $('#ft_bulk_edit_form').change(function(){
      $('#bulk-submit').attr("disabled", false);
    });
    
    $('#ft_bulk_edit_form').on('dp.change', function(){
      $('#bulk-submit').attr("disabled", false);
    });
  }));
})();
