(function() {
  'use strict';

  window.setPageHelp = function(element) {
    var pageTitle = _getElementHelpTitle(element);
    var pageHelp = _getElementHelpContent(element);
    $('#page-info-title').html(pageTitle || '');
    $('#page-info-content').html(pageHelp || '');
  };

  window.setFieldHelp = function(element) {
    var fieldTitle = _getElementHelpTitle(element);
    var fieldHelp = _getElementHelpContent(element);
    $('#field-help-title').html(fieldTitle || '');
    $('#field-help-content').html(fieldHelp || '');
  };


  window.navTabClick = function(event) {
    setTimeout(function() {
      setPageHelp($(event.currentTarget).closest('.tabbable').find('.tab-content > .tab-pane.active'));
    }, 50);
  };

  window.initialiseNavTabsHelp = function(section){
    section.find('.tab-nav li a').click(navTabClick);
    setPageHelp($('.tab-pane.active'));
  };

  window.initialiseHelpFields = function(section) {
    if (section == undefined) { section = $('body'); }
    section.find('[data-help-title],[data-help-content]').focus(function(event) {
      setFieldHelp(event.currentTarget);
    });
    section.find('.modal').on('hidden.bs.modal', function () {
      setFieldHelp(section);
    });
  };

  function _getElementHelpTitle(element) {
    return $(element).data('help-title');
  }

  function _getElementHelpContent(element) {
    return $(element).data('help-content');
  }
})();
