import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['child']

  toggle(event) {
    if (event.target.checked) {
      this.childTarget.classList.remove(this.data.get('class'))
    } else {
      this.children.forEach((child) => { child.classList.add(this.data.get('class')) })
      this.checkboxes.forEach((checkbox) => { checkbox.checked = false })
    }
  }

  get checkboxes() {
    return [...this.element.querySelectorAll('input[type=checkbox]')]
  }

  get children() {
    return [...this.element.querySelectorAll("[data-target='nested-checkbox.child']")]
  }
}
