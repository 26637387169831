FancyEllipsisComponentBehaviour = {
  "afterTooltipCloseButtonClicked": function(tooltipToClose){
    var iconToDeactivate = $(tooltipToClose).siblings(FancyEllipsisIconComponent.jQuerySelector());
    FancyEllipsisIconComponent.deactivate(iconToDeactivate);
  },
  "shouldIconDeactivateAfterMouseout": function(ellipsisIconMousedOut){
    var ellipsisTooltipAssociatedWithEllipsisIconMousedOver = ellipsisIconMousedOut.nextElementSibling;
    return FancyEllipsisTooltipComponent.isClosed(ellipsisTooltipAssociatedWithEllipsisIconMousedOver);
  },
  "onIconClick": function(ellipsisIconClicked){
    var tooltipAssociatedWithEllipsisIconClicked = ellipsisIconClicked.nextElementSibling;
  
    if( FancyEllipsisTooltipComponent.isOpen(tooltipAssociatedWithEllipsisIconClicked) ){
      FancyEllipsisIconComponent.deactivate(ellipsisIconClicked);
      FancyEllipsisTooltipComponent.close(tooltipAssociatedWithEllipsisIconClicked);
    } else {
      $(FancyEllipsisIconComponent.jQuerySelector())
        .not(ellipsisIconClicked)
        .filter(function() { return FancyEllipsisIconComponent.isActive(this) })
        .each(function(){ FancyEllipsisIconComponent.deactivate(this) });
  
      $(FancyEllipsisTooltipComponent.jQuerySelector())
        .not(tooltipAssociatedWithEllipsisIconClicked)
        .filter(function() { return FancyEllipsisTooltipComponent.isOpen(this) })
        .each(function(){ FancyEllipsisTooltipComponent.close(this) });
  
      FancyEllipsisIconComponent.activate(ellipsisIconClicked)
      FancyEllipsisTooltipComponent.open(tooltipAssociatedWithEllipsisIconClicked);
    }
  }
}