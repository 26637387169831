(function() {
  'use strict';

  window.displayExplanatoryPageLoader = function(explanation, extra_class, callback) {
    $('.page-loader').fadeIn(400, callback);
    $('.page-loader').addClass('with_explanation');
    window.setTimeout(function() {
      $('.page-loader p.explain').remove();
      $('.page-loader').find('p').after("<p class='explain " + extra_class + "'>" + explanation + "</p>");
    }, 500);
  }
})();