// TEMPORARY OVERRIDE - THIS FILE CAN BE REMOVED ONCE THE CHANGES COME THROUGH FROM PANDLE
(function() {
  'use strict';

  window.initBarChartOrDisplayNoData = function(chart, use_currency, integer_value) {
    if (chart.length === 0 ) { return; }
    var chart_data = chart.data('value');

    if (chart_data && chart_data.length > 0) {
      initBarChart(chart, chart_data, use_currency, integer_value);
    } else {
      initBarChart(chart, [{ 'data': [] }], use_currency, integer_value);
    }
  }

  window.initPieChartOrDisplayNoData = function(chart) {
    if ( chart.length == 0 ) { return; }
    var chart_data = chart.data('value');
  
    if (chart_data && chart_data.length > 0) {
      initPieChart(chart, chart_data);
    } else {
      chart.removeClass("flot-chart-pie").addClass('no-data').html("No data...");
    }
  }

  window.initMultiBarChart = function(div, bar_data, use_currency) {
    // generateMoreDataForSmallSeries(bar_data);
    use_currency = use_currency !== false ? true : use_currency;

    var plot_data = bar_data[0];
    var bars = []
    var colours = []
    var nets = []

    for (var i = 0; i < plot_data.length; i++) {
      bars.push(
        {
          data  : plot_data[ i ][1],
          bars  : {
            show      : true,
            barWidth  : 0.4,
            lineWidth : 0,
            align     : plot_data[ i ][3],
            fill      : 1
          },
          label : plot_data[ i ][0]
        }
      )

      colours.push(
        plot_data[ i ][2]
      )

      nets.push(
        plot_data[i][4]
      )
    }

    $.plot(div,
      bars, {
      colors: colours,
      nets: nets,
      grid: {
        borderWidth: 1,
        borderColor: '#eee',
        show : true,
        hoverable : true,
        clickable : true
      },
      yaxis: {
        tickColor: '#eee',
        tickDecimals: 0,
        font: {
          lineHeight: 13,
          style: 'normal',
          color: '#9f9f9f',
        },
        shadowSize: 0
      },
      xaxis: {
        mode         : 'categories',
        tickColor    : '#fff',
        tickDecimals : 0,
        font         : {
          lineHeight: 13,
          style: 'normal',
          color: '#9f9f9f'
        },
        shadowSize   : 0
      },
      legend: { show: true, placement: 'outsideGrid', container: $('#legend-holder') },
      tooltip: true,
      tooltipOpts: {
        content: function(label, xval, yval, obj) {
          var net = nets[obj.seriesIndex]
          if (use_currency == true) {
            if (yval.charAt(0) === '-') {
              return xval + '<br>' +label + ': ' + yval.slice(0, 1) + companySettings.base_currency.currency_symbol + parseFloat(yval.slice(1, -1)).toFixed(2) +'<br> Net: ' + net
            } else {
              return xval + '<br>' +label + ': ' + companySettings.base_currency.currency_symbol + parseFloat(yval).toFixed(2)+'<br> Net: ' + net
            }
          } else {
            return xval + '<br>' + label + '<br>' + parseFloat(yval).toFixed(2) + '<br> Net: '+ net
          }
        }
      },
      shifts: {
        x: 20,
        y: 0
      }
    });
  }

  function initBarChart(div, bar_data, use_currency, integer_value) {
    generateMoreDataForSmallSeries(bar_data);
    use_currency = use_currency !== false ? true : use_currency;

    $.plot(div, bar_data, {
      grid: {
        borderWidth: 1,
        borderColor: '#eee',
        show : true,
        hoverable : true,
        clickable : true
      },
      yaxis: {
        tickColor: '#eee',
        tickDecimals: 0,
        font: {
          lineHeight: 13,
          style: 'normal',
          color: '#9f9f9f',
        },
        shadowSize: 0
      },
      xaxis: {
        mode: 'categories',
        tickColor: '#fff',
        tickDecimals: 0,
        font: {
          lineHeight: 13,
          style: 'normal',
          color: '#9f9f9f'
        },
        shadowSize: 0,
      },
      bars: {
        show: true,
        barWidth: 0.75,
        lineWidth: 0,
        align: 'center',
        fill: 1
      },
      tooltip: true,
      tooltipOpts: {
        content: tooltipGeneratorFunction(use_currency, bar_data[0]['currencies'], integer_value)
      },
      shifts: {
        x: 20,
        y: 0
      }
    });
  }

  function generateMoreDataForSmallSeries(bar_data) {
    var data_array = bar_data[0]['data'] || bar_data[0];

    if (data_array.length < 5) {
      // The following has different labels ('' vs ' ')
      // They must be different, else the table groups them into 1 category
      data_array.splice(0, 0, ['', '0.0']);
      data_array.push([' ', '0.0']);
      return data_array
    }
  }

  function tooltipGeneratorFunction(use_currency, currency_data, integer_value) {
    if (use_currency == true && currency_data !== undefined) {
      return tooltipGeneratorWithCurrencyData(currency_data);
    } else if (use_currency == true) {
      return tooltipGeneratorWithDefaultCurrency();
    } else if (integer_value == true ) {
      return tooltipGeneratorInteger();
    } else {
      return tooltipGeneratorWithoutCurrency();
    }
  }

  function tooltipGeneratorWithCurrencyData(currency_data) {
    return function(label, xval, yval) {
      var data_for_this_column = $.grep(currency_data, function(e) { return e[0] == xval; })[0];
      var currency_symbol;
      if (data_for_this_column !== undefined) {
        currency_symbol = data_for_this_column[1];
      } else {
        currency_symbol = companySettings.base_currency.currency_symbol;
      }
      return tooltipValueWithCurrencySymbol(yval, currency_symbol);
    }
  }

  function tooltipGeneratorWithDefaultCurrency() {
    return function(label, xval, yval) {
      return tooltipValueWithCurrencySymbol(yval, companySettings.base_currency.currency_symbol);
    }
  }

  function tooltipValueWithCurrencySymbol(yval, currency_symbol) {
    var yval = yval.toString();
    if (yval.charAt(0) === '-') {
      return yval.slice(0, 1) + currency_symbol + parseFloat(yval.slice(1)).toFixed(2);
    } else {
      return currency_symbol + parseFloat(yval).toFixed(2);
    }
  }

  function tooltipGeneratorWithoutCurrency() {
    return function(label, xval, yval) {
      return parseFloat(yval).toFixed(2);
    }
  }

  function tooltipGeneratorInteger(){
    return function(label, xval, yval) {
      return yval.toString();
    }
  }
})();
