// TEMPORARY OVERRIDE - THIS FILE CAN BE REMOVED ONCE THE CHANGES COME THROUGH FROM PANDLE
(function() {
  'use strict';

  window.DATE_POPOVER_ERROR_CONTENT = '<p>The date entered is invalid or incomplete</p>';

  var DATE_HELP_WARNING_CONTENT = '<p>The date entered is outside of the current program period.</p>' +
    '<p>Did you mean to enter a date within the range specified on the Categories page?</p>';
  var INVOICE_DATE_WARNING_CONTENT = '<p>The date entered is prior to the invoice date</p>';
  var date_warning_enabled;

  window.addDatePeriodWarning = function(datepicker_div) {
    try {
      _setDatePeriodWarning(getDateFromDateTimePicker($(datepicker_div)));
    } catch (e) {
      if (e instanceof DatePickerError) {
        console.warn('addDatePeriodWarning called before date picker initialised');
      } else {
        throw e
      }
    }
  };

  window.setDateWarningEnabled = function(enabled) {
    date_warning_enabled = enabled;
  };

  window.addInvoiceDateWarning = function(datepicker_div) {
    try {
      var current_row = $(datepicker_div).closest('tr')[0];
      var bt_type = getBankTransactionType(current_row);

      if(bt_type === 'SalesReceipt' || bt_type === 'PurchasePayment' ) {
        _setInvoiceDateWarning(getDateFromDateTimePicker($(datepicker_div)), current_row);
      }
    } catch(e) {
      if (e instanceof DatePickerError) {
        console.warn('addInvoiceDateWarning called before date picker initialised');
      } else {
        throw e
      }
    }
  };

  window.dateIsValid = function(datepicker) {
    //inputmask doesn't allow entering an invalid date, so we only need to check for blank dates here
    return datepicker && datepicker.date();
  };

  window.dateInFuture = function(date) {
    return date.isAfter(moment());
  };

  window.addDateInFutureWarning = function(datepicker_div) {
    try {
      var date = getDateFromDateTimePicker(datepicker_div);

      if (date && dateInFuture(date)) {
        addHelpMessage($(datepicker_div), HELP_WARNING, "The transaction date is in the future i.e. after today's date");
      }
    } catch (e) {
      if (e instanceof DatePickerError) {
        console.warn('addDateInFutureWarning called before date picker initialised');
      } else {
        throw e
      }
    }
  };

  // Use this helper to get a Moment date object from an input that *doesn't* have a DateTimePicker
  window.dateFromDateInput = function(obj) {
    var format = obj.data('format');
    return moment.utc(obj.val(), format);
  };

  window.getClosestDateTimePicker = function($elem) {
    return getDateTimePicker($elem.closest('.datepicker-component'));
  };

  // Use this when we want to send a date in DD/MM/YYYY format as a URL param for an ajax request
  window.getGbDateStringFromClosestDateTimePicker = function($elem) {
    var dtp = getClosestDateTimePicker($elem);
    if (dtp) {
      return formatDateGb(dtp.date());
    } else {
      return;
    }
  }

  // Use this helper to get a DateTimePicker object from an input with a DateTimePicker
  window.getDateTimePicker = function($elem) {
    return $elem.data('DateTimePicker') || $elem.find('input').data('DateTimePicker');
  };

  window.adjustDatesToFitMinMax = function(datePicker) {
    if(datePicker.minDate() && (datePicker.date() < datePicker.minDate())){
      datePicker.date(datePicker.minDate())
    }else if (datePicker.maxDate() && (datePicker.date() > datePicker.maxDate())) {
      datePicker.date(datePicker.maxDate())
    }
  };

  window.getDateFromDateTimePicker = function($elem) {
    onceAfterDatepickersComponentHaveBeenInitialised(function(){
      var dp = getDateTimePicker($elem);

      if(typeof dp == 'object' && dp.date) {
        return dp.date();
      } else {
        throw new DatePickerError();
      }
    })
  };

  window.formatDate = function(date, format) {
    if ( date && typeof date == 'object' && date._isAMomentObject ) {
      return date.format(format);
    }
  };

  window.formatDateGb = function(date) {
    return formatDate(date, 'DD/MM/YYYY');
  };

  window.formatDateForCompany = function(date) {
    return formatDate(date, companySettings.company_date_format);
  };

  window.getBankTransactionType = function(row) {
    return $(row).find('.bt_type_field').val();
  };

  // Use this helper to get a Moment date object from an input that might or might not have a DateTimePicker
  // (eg the BT form where the date field might be disabled)
  window.dateFromElement = function(element) {
    var picker = getClosestDateTimePicker(element);

    if (picker) {
      return picker.date();
    } else {
      return dateFromDateInput(element);
    }
  }

  /**
   * Raised when anything goes wrong to do with datepickers.
   *
   * @constructor
   * @extends Error
   */

  function DatePickerError(message) {
    if(typeof message !== 'undefined') {
      this.message = message;
    }

    this.stack = new Error().stack;
  }

  DatePickerError.prototype = Object.create(Error.prototype);
  DatePickerError.prototype.constructor = DatePickerError;
  DatePickerError.prototype.name = 'DatePickerError';
  DatePickerError.prototype.message = 'Element does not have a datepicker attached';

  window.DatePickerError = DatePickerError;

  /**
   * Add an event listener to a start date picker so that, when changed, the end
   * date picker's value will be updated according to the given interval.
   *
   * For the interval parameter,
   * @see{http://momentjs.com/docs/#/parsing/object/}
   *
   * @param {jQuery} $start jQuery selector that contains a DateTimePicker
   * @param {Object} interval
   * @param {jQuery} $end jQuery selector that contains a DateTimePicker
   */
  window.setEndDateOnStartDateChange = function($start, interval, $end) {
    $start.on('dp.change', function() {
      setDatePickerWithInterval(getDateFromDateTimePicker($start), $end, interval);
    });
  };

  function _setDatePeriodWarning(date) {
    if(date) {
      getJsonOrShowMaintenanceModal('/companies/date_in_period?date=' + encodeURIComponent(date), function(isInPeriod) {
        if (!isInPeriod && date_warning_enabled) {
          addHelpMessage(null, HELP_WARNING,DATE_HELP_WARNING_CONTENT);
        }
      }).fail(function() {
        addHelpMessage(null, HELP_ERROR,DATE_POPOVER_ERROR_CONTENT.substr(3, DATE_POPOVER_ERROR_CONTENT.length - 4));
      });
    }
  }

  function _getLatestInvoiceDate(row_id) {
    //  Finds the latest invoice date with a non-zero amount
    var lastDate = 0;
    var rows = $('#inv-tbody'+row_id).children('tr');
    rows.each(function() {
      var uid = $(this).data('id');
      var payment_field = $('#total_payment'+uid);
      if ( payment_field.val() && payment_field.val() > 0 ) {
        var inv_date = dateFromDateInput($('#date'+uid));
        if ( inv_date > lastDate ) {
          lastDate = inv_date;
        }
      }
    });
    return lastDate;
  }

  function _setInvoiceDateWarning(date, current_row) {
    var invoiceDate = _getLatestInvoiceDate($(current_row).data('id'));

    if(date && invoiceDate && date.isBefore(invoiceDate)) {
      addHelpMessage(null,HELP_ERROR,INVOICE_DATE_WARNING_CONTENT.substr(3,INVOICE_DATE_WARNING_CONTENT.length-4));
    }
  }

  function setDatePickerWithInterval(base, $dp, interval) {
    if (base) {
      getDateTimePicker($dp).date(base.add(interval).subtract({ days: 1 }));
    }
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    setDateWarningEnabled(true);
  }));
})();
