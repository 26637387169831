(function() {
  'use strict';

  window.initCustomerInvoiceIndex = function() {
    initBootgridTableWithCheckboxes($('#customer_invoices_table'), true);
    initTransactionUpdates();
    $("#customer_invoices_table").on('loaded.rs.jquery.bootgrid', disablePrintWhenBatchSelected);
    bulkPrintOnClick();
  }

  window.bulkPrintOnClick = function() {
    var $bulk_print = $('.bulk-print');
    $bulk_print.click(function() {
      var confirmation_warning = $bulk_print.data("confirm-warning");
      var confirmed = true;
      if (typeof confirmation_warning != 'undefined' && confirmation_warning != null) {
        confirmed = confirm(confirmation_warning);
      }
      if (confirmed == true) {
        refreshInvoicePdfs();
      } else {
        return false;
      }
    });
  }

  var selectBoxState = {
    _batchCount: 0,
    _nonBatchCount : 0,

    update: function() {
      var $selectBoxes = $('td .select-box').filter(':checked');
      this._batchCount = $selectBoxes.filter(function() {
        return $($(this).parents()[3]).find(".print-button").hasClass('disabled');
      }).length;
      this._nonBatchCount = $selectBoxes.length - this._batchCount;
      return this;
    },

    nothingChecked: function() {
      return this._batchCount == 0 && this._nonBatchCount == 0;
    },

    onlyBatchChecked: function() {
      return this._batchCount != 0 && this._nonBatchCount == 0;
    },

    bothTypesChecked: function() {
      return this._batchCount != 0 && this._nonBatchCount != 0;
    },

    onlyNonBatchChecked: function() {
      return this._batchCount == 0 && this._nonBatchCount != 0;
    },
  };

  function disablePrintWhenBatchSelected() {
    $('.bulk-print').addClass('disabled');
    $('.select-box').change(function() {
      updatePrintButtonState(selectBoxState.update())
    });
   }

  function updatePrintButtonState(state) {
    if (state.nothingChecked() || state.onlyBatchChecked()) {
      disablePrintButton();
    } else if (state.bothTypesChecked()) {
      enablePrintWarning(state);
      enablePrintButton();
    } else if (state.onlyNonBatchChecked()) {
      disablePrintWarning();
      enablePrintButton();
    }
  }

  function disablePrintButton() {
    $('.bulk-print').addClass('disabled');
  }

  function enablePrintButton() {
    $('.bulk-print').removeClass('disabled');
  }

  function enablePrintWarning(state) {
    var warning = 'Only invoices created by ' + $('#product_name').text() + ' will be printed. You have selected ' +
      state._batchCount + ' batch invoices and ' + state._nonBatchCount + ' invoices created by ' + $('#product_name').text();
    $('.bulk-print').data('confirm-warning', warning );
  }

  function disablePrintWarning() {
    $('.bulk-print').data("confirm-warning", null);
  }

  function refreshInvoicePdfs() {
    $('.bulk_form').attr("action", $('.bulk_form').attr("action") + ".pdf");
    if(navigator.userAgent.includes("Firefox")){
      $('.bulk_form').attr("target","_blank");
      $('.bulk_form').trigger('submit.rails');
    }else{
      $('.bulk_form').attr("target","print_frame");
      $('.bulk_form').on('ajax:complete', printInvoice);
      $('.bulk_form').trigger('submit.rails');  
    }
    $('.bulk_form').attr("action", $('.bulk_form').attr("action").replace(".pdf", ""));
    $('.bulk_form').attr("target","");
  }

  function printInvoice() {
    $('#print_frame').one('load', function() {
      frames[ 'print_frame' ].print();
    });
    $('.print-button').html('<i class="zmdi zmdi zmdi-print zmdi-hc-fw"></i>');
    $('.bulk-print').html('<i class="zmdi zmdi zmdi-print zmdi-hc-fw"></i> Print Checked');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if (
      $('.sales_invoices.index').length === 0 &&
      $('.sales_credit_notes.index').length === 0
    ) { return }
    initCustomerInvoiceIndex();
  }));
})();
