(function() {
  'use strict';

  // When net amount changes, calculate tax and total
  window.splitSetTaxAndTotalAmountsFromNet = function(event) {
    var target = $(event.currentTarget);
    if (target.val() == '') { return; }
    performAmountChecksAndUpdateBalancesForSplit(event);
  }

  // when tax code changes, set tax amount
  window.splitSetAmountsFromTaxCode = function(event) {
    var row = getClosestSplitRowToEvent(event);
    updateBalanceAndCheckForInvoices(row);
  }

  // when total amount changes, set net and tax
  window.splitSetNetAndTaxAmountsFromTotal = function(event) {
    performAmountChecksAndUpdateBalancesForSplit(event);
    return false; // stop propagation
  }

  window.performAmountChecksAndUpdateBalancesForSplit = function(event) {
    if ($(event.currentTarget).val() == '') { return; }
    var row = getClosestSplitRowToEvent(event);
    var total_amount_field = getTotalAmountField(row);
    checkForSmallAssetAmountsSplit(
      getAccountSelectField(row), total_amount_field
    );
    updateBalanceAndCheckForInvoices(row);
  }

  function getAccountSelectField(row){
    return $(row).find('.split_account');
  }

  function getTotalAmountField(row){
    return $(row).find('.split_total_amount_field');
  }

  function updateBalanceAndCheckForInvoices(row) {
    updateSplitBalance(row);
    checkInvoiceExistsWithAmountsAndNominalAccountSplit(row);
  }

})();
