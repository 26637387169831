(function() {
  'use strict';

  function triggerGettingStarted() {
    if ($('section.dashboards.show').length === 0) { return }

    getJsonOrShowMaintenanceModal('/user_preferences/tutorials', function(preference) {
      if (preference === null) {
        $.ajax({ url : Routes.user_tutorials_path({format: 'js'}) });
        UserPreferences.set('tutorials', 'true');
      }
    });
  }

  function loadBankAccountChartWidget() {
    if ($('section.dashboards.show').length === 0) { return }
    $("#bank_account_chart_currency_id").change(function(e) {
      $("#bank_account_chart_loader").show();
      $("#bank_account_chart").hide();
      $("#bank_account_chart").empty();
      $("#bank_account_chart").attr('data-value', '');
      
      $.post({
        url: $("#bank_account_chart_card").data('url'),
        dataType: "script",
        data: {
          currency_id: $(e.target).val()
        }
      });
    });
    $("#bank_account_chart_currency_id").change();
  
    $.post({
      url: $("#bank_account_chart_card").data('url'),
      dataType: "script"
    }); 
  }

  function loadInvoiceTypeModal(){
    $.ajax({
      dataType: 'script',
      url: $('#invoice-type-modal').data('url'),
      method: "get"
    });
  }

  function initBankAccountWidget(){
    $("#bank_account_chart_currency_id").change(function(e){
      var currency_id = $(e.target).val()
      var $poller = $(e.target)
        .closest('.dashboard-card')
        .find('.poller')
      var widgetId = $poller.attr('data-widget-id')
      $poller.attr('data-content-loader-url-value', Routes.bank_account_chart_path({id: widgetId},{currency_id: currency_id}))
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#edit-dashboard-button-row').length == 0) { return }
    triggerGettingStarted();
    // loadBankAccountChartWidget();
    initBankAccountWidget()
    loadInvoiceTypeModal();
  }));
})();


