export default {
  numeric_ignore_zero: {
    from: function (value) {
      if (typeof(value) === 'undefined') { return ""; }
      // Step 1: remove commas
      value = value.replace(/,/g, '');
      // Step 2: convert string to numeric, ignoring zeros
      value = parseFloat(value);
      if ( isNaN(value) || value === 0 ) { return ""; }
      else { return value; } // Convert to numeric
    },
    to: function (value) {
      if ( value === "") { return ""; }
      else {
        // Step 1: convert numeric to string with 2dp
        value = parseFloat(value).toFixed(2) + "";
        // Step 2: add commas
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
      }
    }
  },

  reports_numeric_ignore_zero: {
    from: function (value) {
      if (typeof(value) === 'undefined') { return ""; }
      // Step 1: remove commas
      value = value.replace(/,/g,'');
      // Step 2: convert (x) to -x
      var match = /^\((\d*\.\d*)\)$/.exec(value);
      if (!!match && match[ 1 ]) {
        value = -1 * match[ 1 ];
      }
      // Step 3: convert string to numeric, ignoring zeros
      value = parseFloat(value);
      if ( isNaN(value) || value === 0 ) { return ""; }
      else { return value; } // Convert to numeric
    },
    to: function (value) {
      if ( value === "") { return ""; }
      else {
        // Step 1: convert numeric to string with 2dp
        value = parseFloat(value).toFixed(2) + "";
        // Step 2: add commas
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Step 3: convert -x to (x)
        var match = /^-([\d\,]*\.\d*)$/.exec(value);
        if ( !!match && match[1] ){ value = '('+match[1]+')'; }
        return value;
      }
    }
  },

  order_by_link_text: {
    from: function (value) {
      // put the link text in a span at the start....
      var match = /href.*\>(.*)<\/a>.*$/.exec(value)
      if ( !!match && match[1] ){ value = "<span id='sort_value' style='display:none;'>"+match[1]+"</span>"+value }
      else { value = "<span id='sort_value' style='display:none;'>"+value+"</span>"+value } // For rows without a link
      return value;
    },
    to: function (value) {
      // remove the extra span
      var match = /^<span id='sort_value'.*?<\/span>(.*)$/.exec(value)
      if ( !!match && match[1] ){ value = match[1] }
      return value;
    }
  },

  order_by_date: {
    from: function (value) {
      return moment(value, companySettings.company_date_format);
    },
    to: function (value) {
      return formatDateForCompany(value);
    }
  }
}