(function() {
  'use strict';

  var USER_INVITATION = {
    REQUIRED_FIELDS: '#company_user_invitation_first_name, #company_user_invitation_last_name, #company_user_invitation_email'
  }

  window.initUserInvitationModal = function() {
    $("#invite-user-button").click(onFormSubmit);
    hideIndicatorWhenMissingFieldIsSelected();
  }

  function onFormSubmit(event) {
    var $invalid_fields = validateUserInvitationFields();
    if ($invalid_fields.length > 0) {
      showFieldIndicators($invalid_fields.parent('div'));
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  function validateUserInvitationFields() {
    var $fields = $(USER_INVITATION.REQUIRED_FIELDS);
    hideFieldIndicators($fields.parent('div'));
    return $fields.filter(function(index, element) { return $(element).val() == ""; });
  }

  function hideIndicatorWhenMissingFieldIsSelected() {
    var $fields = $(USER_INVITATION.REQUIRED_FIELDS);
    errors.tryOn($fields, 'focusin', function(e) {
      $(e.target).parent('div').addClass('selected-required-field');
    });
    errors.tryOn($fields, 'focusout', function(e) {
      $(e.target).parent('div').removeClass('selected-required-field');
    });
  }
})();
