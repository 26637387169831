(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#new_user_deletion').length === 0) { return };
    $('#deletion_category').change(_updateUserDeletionFieldStates);
    $('#intercom_live_chat').on('click', function() {
      window.Intercom('showNewMessage');
    });
  }));

  function _updateUserDeletionFieldStates(event) {
    var selected_category = $(event.target).val();
    $('.hidden-div').addClass('hidden');
    $('.comment-label').addClass('hidden');
    if (selected_category != "") {
      $('.hidden-div').removeClass('hidden');
      $('#'+selected_category).removeClass('hidden');
    };
  };

})();
