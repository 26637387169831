import CONVERTER from './_bootgrid_converters.js'

window.initObTables = function() {
  initBootgridTable($('#customer_table'), undefined, true);
  initBootgridTable($('#supplier_table'), undefined, true);
  initBootgridTable($('#vat_scheme_journals_table'));
  
  $('#ob_nominal_table').bootgrid({
    css: {
      icon: 'zmdi icon',
      iconColumns: 'zmdi-view-module',
      iconDown: 'zmdi-caret-down',
      iconRefresh: 'zmdi-refresh',
      iconUp: 'zmdi-caret-up'
    },
    converters: {
      order_by_link_text: CONVERTER.order_by_link_text
    },
    columnSelection: false,
    navigation: 0,
    rowCount: -1
  }).on("loaded.rs.jquery.bootgrid", function(e){
    // NB: any change listeners on form fields in a bootgrid table have to be added after it has loaded
    initObBalancesForm();
  });

  updateStockAccountDebitAmount();
}