import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['enterpriseField', 'invoiceField']

  initialize() {
    if (this.hasInvoiceFieldTarget) {
      $(this.invoiceFieldTarget).on('change', null, { controller: this }, (event) => {
        event.data.controller.changed(event)
      })
    }
  }

  changed(event) {
    if (this.hasEnterpriseFieldTarget) {
      this.enterpriseFieldTarget.value = event.target.options[event.target.selectedIndex].dataset.enterpriseId || ''
      $(this.enterpriseFieldTarget).trigger('chosen:updated')
    }
  }
}
