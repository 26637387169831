(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#new_partner_accountant').length === 0) { return }
    initBootstrapWizard();
    focusFirstFieldOnTabChange();
    $('#partner_accountant_operating_country_id').on('change', setAddressCountryFromCountryField);
    $('#country_select').on('change', adjustAddressFormOnCountryChange);
    initListenerForTelephoneField($('#partner_accountant_telephone_number'));
  }));
})();

