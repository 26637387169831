// PANDLE: overridden to prevent races conditions with related Stimulus controllers
// on Farmplan
(function () {
  "use strict";

  window.initBtFieldStateController = function () {
    window.bt_field_enabling_rules = readEnablingRulesFromPage();
    $("#bank-transaction-rows").change(_updateBtFieldStates);
  };

  function _updateBtFieldStates(event) {
    // FARMPLAN: add timeout to delay execution
    setTimeout(() => {
      var bt_row = new BatchBtRow(_getClosestBtRowIdToEventTarget(event));
      var field_state_selector = new BatchBtFieldStateSelector(bt_row);
      new FieldStateController(bt_row, field_state_selector).update(
        event.target,
      );
    }, 100);
  }

  // Use this method if we're handling an event that has bubbled up the dom, but we want to know which row it originally
  // related to
  function _getClosestBtRowIdToEventTarget(event) {
    return $(event.target).closest("tr.batch_row").data("id");
  }
})();
