(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#stripe_authentication_data').length === 0) { return }

    var data = $('#stripe_authentication_data').data()

    var stripe = Stripe(data['publicKey']);
    stripe.handleCardSetup(data['clientSecret'])
      .then(function(result) {
        if (result.error) {
          $.ajax({
            url: data['failureUrl'],
            method: 'GET',
            success: function(){
              showErrorsOnStripeForm(result.error.message, $('#subscription_form'))
            }
          })
          $('.page-loader').fadeOut();
        } else {
          window.location = data['successUrl'];
        }
      });


  }));
})();
