import { normalizeDateString } from "helpers/normalizeDateString";

(function() {
  'use strict';
  window.TaxCodeFiltering = function($elem, $tax_code_fields, invoice_id, customer_supplier_id, customer_supplier_type){
    this.$tax_code_fields = $tax_code_fields;
    this.invoice_id = invoice_id
    this.date = getGbDateStringFromClosestDateTimePicker($elem) || $elem.find('input').val() || new Date(Date.now()).toDateString();
    this.customer_supplier_id = customer_supplier_id;
    this.customer_supplier_type = customer_supplier_type;
  }

  TaxCodeFiltering.prototype.getTaxCodesForInvoice = function(){
    var that = this;
    if (this.invoice_id == undefined) {
      var url = Routes.get_tax_codes_for_date_companies_path({ format: 'json', date: normalizeDateString(this.date), customer_supplier_id: this.customer_supplier_id, customer_supplier_type: this.customer_supplier_type });
    } else {
      var url = Routes.get_tax_codes_for_invoice_companies_path({ format: 'json', invoice_id: this.invoice_id, customer_supplier_id: this.customer_supplier_id, customer_supplier_type: this.customer_supplier_type });
    }

    getJsonOrShowMaintenanceModal(url, function(data) {
      if (data == null) { return; }
      that.setAvailableTaxCodesToDropdowns(data.tax_codes);
      that.setVatSchemeCheckAlert(data.date_not_within_vat_scheme);
    });
  };

  TaxCodeFiltering.prototype.setAvailableTaxCodesToDropdowns = function(tax_codes){
    this.$tax_code_fields.selectPopulator(tax_codes);
    this.setToLastValueIfNoneSelected();
  };

  TaxCodeFiltering.prototype.setToLastValueIfNoneSelected = function(){
    var $dropdowns_with_no_value = this.$tax_code_fields.filter(function(i, obj) { return $(obj).val() === "" || $(obj).val() === null });
    $dropdowns_with_no_value.each(function(index, element) {
      $(element).find('option').not(':empty').first()
        .prop('selected', true)
        .trigger('chosen:updated')
        .trigger('change');
    });
  };
  
  TaxCodeFiltering.prototype.setVatSchemeCheckAlert = function(boolean_value){
    this.$tax_code_fields.each(function(_, taxField) {
      var $targetField = $(taxField).parents('td');
      $targetField.attr('data-notify-condition-value', boolean_value);
    })
  }
})();
