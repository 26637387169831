(function() {
  'use strict';

  var QUOTES_FILTER_OPTIONS = [
    { id : 'show_pending', name : 'Pending', visible : true },
    { id : 'show_accepted', name : 'Accepted', visible : true },
    { id : 'show_declined', name : 'Declined', visible : true },
    { id : 'show_converted', name : 'Converted to invoice', visible : true }
  ];

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#quotes_index_table').length === 0) { return }
    initTransactionUpdates();
    initBootgridTableWithCheckboxes($('#quotes_index_table'), true, QUOTES_FILTER_OPTIONS);
  }));
})();
