import { Controller } from '@hotwired/stimulus'

// This file was originally forked from pandles 'input_restriction',
// but is included as a new file since they are likely to diverge
// further. Views can therefore use either vanilla pandle logic or this.

const POSITIVE = /[0-9|.]/
const NUMERIC = /[0-9|.-]/

export default class extends Controller {
  static targets = ['input']

  inputMaskPositive(event) {
    this.amountOnly(event)
    this.limitDecimal(event)
  }

  inputMaskPositive3dp(event) {
    this.amountOnly(event)
    this.limitDecimal(event, 3)
  }

  inputMaskNumeric(event) {
    this.amountOnly(event, true)
    this.limitDecimal(event)
  }

  inputMaskNumeric3dp(event) {
    this.amountOnly(event, true)
    this.limitDecimal(event, 3)
  }

  amountOnly(event, allowNegative = false) {
    const allowedInputValues = allowNegative ? NUMERIC : POSITIVE
    const notAlphabetKeycode = event.keyCode <= 48

    if (!allowedInputValues.test(event.key) && !notAlphabetKeycode) {
      event.preventDefault()
    }
  }

  limitDecimal(event, decimalPoints = 2) {
    if (event.keyCode <= 48) { return }
    const inputValue = this.getNewInput(event)
    const decimalIndex = inputValue.indexOf('.')
    if (decimalIndex > 0 && inputValue.substr(decimalIndex).length > 1 + decimalPoints) {
      event.preventDefault()
    }
  }

  getNewInput(event) {
    const currentValue = event.srcElement.value
    const eventKey = String.fromCharCode(event.charCode || event.keyCode)
    const currentValueArray = currentValue.split('')
    currentValueArray.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), eventKey)
    return currentValueArray.join('')
  }

  formatTo3dp(event) {
    this.formatToNdp(event, 3)
  }

  formatTo2dp(event) {
    this.formatToNdp(event, 2)
  }

  formatToNdp(event, N) {
    const currentValue = event.srcElement.value
    if (this.isBlank(currentValue)) { return }
    const multiple = Math.pow(10, N)
    event.srcElement.value = (Math.round(currentValue * multiple) / multiple).toFixed(N)
  }

  isBlank = (x) => x === null || x === undefined || x === ''
}
