(function() {
    'use strict';
  
    window.initBatchInvFieldStateController = function() {
      window.invoice_field_enabling_rules = readEnablingRulesFromPage();
      // $('#invoice_form, #batch_credit_note_form, #imported_invoice_form').change(_updateBatchInvFieldStates) //FARMPLAN: Remove batch invoice states so that farmplan can directly change state of batch fields
    }
  
    function _updateBatchInvFieldStates(event) {
      var parent_row = getClosestInvoiceRowToEventOriginalTarget(event);
      if (parent_row.length ) {
        var batch_row = new FieldStatesBatchInvoiceRow(parent_row);
        var field_state_selector = new FieldStateSelector(batch_row);
        new FieldStateController(batch_row, field_state_selector).update(event.target);
      }
    }
  })();
  