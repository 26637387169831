(function() {
  'use strict';

  /*
  * FieldStatesQuote takes the non batch invoice form, checks various values and uses them to set flags
  * It is used by the FieldStateController code
  */
  window.FieldStatesQuote = function($form, $row_id, $section) {
    this._rules = window.quote_field_enabling_rules;
    this._form = $form;
    this._rowId = $row_id;
    this._section = $section;
    this._$fields = {};
    this._taxRateIsZero = null;
  };

  /*
  * Call this method when a change has occurred in the form, to update fields according to the rules
  *
  * @param {jQuery} event_target
  */
  FieldStatesQuote.prototype.update = function(event_target) {
    if ( this._form ) {
      this._setFlagsFromQuote();
    }
  };

  FieldStatesQuote.prototype.after_update = function(target) {
    // Nothing needed here
  }

  FieldStatesQuote.prototype.getFlagValue = function(flag) {
    return this['_'+flag];
  }

  FieldStatesQuote.prototype.getField = function(selector) {
    var rowIdIsPresent = Boolean(this._rowId);
    if (rowIdIsPresent) {
      this._$fields[selector] = $(selector.replace('ROW_ID', this._rowId));
    }
    if (!this._$fields[selector]) {
      var found_fields = this._form.find(selector);
      if (this._section) { // Blue Print when adding a new row, hasn't been added to form at this stage
        found_fields = found_fields.add(this._section.find(selector));
      }
      this._$fields[selector] = found_fields;
    }
    return this._$fields[selector];
  }

  FieldStatesQuote.prototype.getRules = function() {
    return this._rules;
  }

  FieldStatesQuote.prototype._setFlagsFromQuote = function() {
    this._taxRateIsZero = this._toggleTaxAmountReadOnlyBasedOnTaxRate();
  }

  FieldStatesQuote.prototype._toggleTaxAmountReadOnlyBasedOnTaxRate = function() {
    var rowIdIsPresent = Boolean(this._rowId);
    if (rowIdIsPresent) {
      var row = this._form.find('tr[data-row-id="' + this._rowId + '"].line_item_row.fields.batch_row.line_item_description')
      var taxRateField = row.find('.nb_split__tax_code.nb_split_cell');
      var taxRate = getTaxRateAsFloat(taxRateField);
      return (taxRate == 0 && this.getField('#line_item_tax_amountROW_ID').length > 0);
    }
  }
})();
