window.setUpPayPalButtonListener = function() {
  $('#paypal_feed_button').click(function(e) {
    e.preventDefault();
    paypalConnectionButtonClickedEvent(this);
  });
}

function paypalConnectionButtonClickedEvent(button) {
  var $button = $(button);
  var $form = $button.parents('#new_bank_account');
  var currentPath = $button.attr('href');
  var name = $form.find('input[name="bank_account[name]"]').val()
  var nominal = $form.find('input[name="bank_account[nominal_code]"]').val()
  var nameParams = 'bank_account[name]=' + name;
  var nominalParams = 'bank_account[nominal_code]=' + nominal;
  var pathWithParams = currentPath + '?' + nameParams + '&' + nominalParams;
  var confirmMessage = $button.data('modal-confirm');
  var companyData = $button.data('company');
  var companyId = companyData.id;
  var isMultiCurrency = !!companyData.multiple_currencies; // default is null
  var enableMultiCurrencyPath = companyData.enable_multi_currency_path;
  var enableMultiCurrencyMessage = companyData.enable_multi_currency_message;

  if (isMultiCurrency) {
    triggerConfirmPayPalRedirectModal(
      confirmMessage,
      pathWithParams,
      $button
    );
  } else {
    triggerEnableMultiCurrencyModel(
      enableMultiCurrencyMessage,
      enableMultiCurrencyPath,
      $button
    );
  }
}

function triggerConfirmPayPalRedirectModal(message, url, $button) {
  confirmModal({
    content: message,
    onConfirm: function() { window.location = url; },
    afterCancel: function() { $.rails.enableElement($button) }
  });
}

function triggerEnableMultiCurrencyModel(message, url, $button) {
  confirmModal({
    content: message,
    afterCancel: function() { $.rails.enableElement($button) },
    onConfirm: function() {
      $.ajax({
        url: url,
        method: 'PATCH',
        dataType: 'json',
        data: { company: { multiple_currencies: true } }
      }).done(function() {
        $button.data('company').multiple_currencies = true;
        $.rails.enableElement($button);
        notify('Company settings updated', "success", "Success! ");
      })
    }
  });
}
