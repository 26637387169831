creditedInvoiceAmount = {};

CreditedInvoiceAmountDataHandler = {
  "setInvoiceData": function(invoice_id, total_creditable, $lineItemRows) {
    $lineItemRows.each(function() {
      $lineItemRow = $(this);
      var record_type = $lineItemRow.data('record-type');
      var record_id = $lineItemRow.data('record-id');
      var inputElement = $lineItemRow.find('.line_item_total_amount_tf')[0];

      addInvoiceHashIfNotInstantiated(invoice_id, total_creditable);
      setElementToAmountVariableBasedOnType(invoice_id, record_type, record_id, inputElement);
    })
  },
  "afterInvoiceSet": function() {
    Object.keys(creditedInvoiceAmount).forEach(function(invoice_id) {
      filterUniqueElementsInCreditedAmountHash(invoice_id);
      checkAndUpdateIfInvoiceHasBeenFullyCredited(invoice_id);
    });
  },
  "getOtherCredits": function(line_item) {
    if (line_item.invoice_card.batch_modal.length == 0) { return 0; }
    return getCreditedAmountForLineItem(line_item);
  },
  "checkInvoiceIsFullyCredited": function(invoice_id, invoice_ids_to_ignore) {
    if (invoice_ids_to_ignore.includes(invoice_id) || creditedInvoiceAmount[invoice_id] == undefined) { return false };
    return creditedInvoiceAmount[invoice_id].fully_credited;
  },
  "deleteInvoiceData": function(invoice_id) {
    if (invoice_id == undefined || creditedInvoiceAmount[invoice_id] == undefined) { return false };
    delete creditedInvoiceAmount[invoice_id]
  }
}

function addInvoiceHashIfNotInstantiated(invoice_id, total_creditable) {
  if (creditedInvoiceAmount[invoice_id] == undefined) { 
    creditedInvoiceAmount[invoice_id] = { line_items: {}, input_fields: [], fully_credited: false, total_creditable: total_creditable };
  }
}

function addLineItemHashIfNotInstantiated(invoice_id, record_id) {
  if (creditedInvoiceAmount[invoice_id]['line_items'][record_id] == undefined) { 
    creditedInvoiceAmount[invoice_id]['line_items'][record_id] = { input_fields: [] };
  }
}

function setElementToAmountVariableBasedOnType(invoice_id, record_type, record_id, inputElement) {
  if (record_type == "invoice") {
    creditedInvoiceAmount[invoice_id]['input_fields'].push(inputElement);
  } else if (record_type == "line_item") {
    addLineItemHashIfNotInstantiated(invoice_id, record_id);
    creditedInvoiceAmount[invoice_id]['line_items'][record_id]['input_fields'].push(inputElement);
  }
}

function filterUniqueElementsInCreditedAmountHash(invoice_id) {
  creditedInvoiceAmount[invoice_id].input_fields = creditedInvoiceAmount[invoice_id].input_fields.filter(filterByUniqueElementOrDestroy);
  var line_item_hash = creditedInvoiceAmount[invoice_id].line_items;
  Object.keys(line_item_hash).forEach(function(key) {
    creditedInvoiceAmount[invoice_id].line_items[key].input_fields = creditedInvoiceAmount[invoice_id].line_items[key].input_fields.filter(filterByUniqueElementOrDestroy);
  });
}

function filterByUniqueElementOrDestroy(value, index, self) {
  if (_rowSetToDestroy(value) || _elementDestroyed(value)) { return false; };
  return self.indexOf(value) === index;
}

function _rowSetToDestroy(value) {
  return $(value).closest('.line_item_row').find('.line_item_destroy').val() == "true";
}

function _elementDestroyed(value) {
  return !document.body.contains(value);
}

function getCreditedAmountForLineItem(line_item) {
  var invoice_id = line_item.invoice_card.invoice_id;
  if (creditedInvoiceAmount[line_item.invoice_card.invoice_id] == undefined || creditedInvoiceAmount[invoice_id] == undefined) { return 0; }
  if (line_item.record_type == "invoice") {
    var credit_element_array = creditedInvoiceAmount[invoice_id].input_fields;
  } else if (line_item.record_type == "line_item" && creditedInvoiceAmount[invoice_id].line_items[line_item.record_id] != undefined) {
    var credit_element_array = creditedInvoiceAmount[invoice_id].line_items[line_item.record_id].input_fields;
  }
  credit_element_array = _removeSelfFromArray(line_item, credit_element_array);
  return calculateSumOfElementValues(credit_element_array)
}

function _removeSelfFromArray(line_item, credit_element_array) {
  return credit_element_array.filter(function(value, index, self) {
    return !(value === line_item.total[0]);
  })
}

function calculateSumOfElementValues(array_of_elements) {
  var sum = 0;
  array_of_elements.forEach(function(element) {
    sum += parseFloat($(element).val());
  });
  return sum;
}

function checkAndUpdateIfInvoiceHasBeenFullyCredited(invoice_id) {
  var invoice_data = creditedInvoiceAmount[invoice_id];
  var total_creditable = invoice_data.total_creditable;
  var invoice_credited_amounts = calculateSumOfInputAmountsForInvoice(invoice_data);
  if (invoice_credited_amounts >= total_creditable) {
    creditedInvoiceAmount[invoice_id].fully_credited = true;
  } else {
    creditedInvoiceAmount[invoice_id].fully_credited = false;
  };
} 

function calculateSumOfInputAmountsForInvoice(invoice_data) {
  if (invoice_data.input_fields.length > 0) {
    var sum_of_credited_amounts_for_invoice = calculateSumOfElementValues(invoice_data.input_fields); 
  } else {
    var sum_of_credited_amounts_for_invoice = 0;
    Object.keys(invoice_data.line_items).forEach(function(key) {
      sum_of_credited_amounts_for_invoice += calculateSumOfElementValues(invoice_data.line_items[key].input_fields)
    });
  }
  return sum_of_credited_amounts_for_invoice;
}
