(function() {
  'use strict';

  var INVOICE_SETTINGS_FORM_SELECTOR = '#edit_invoice_settings_form';

  window.initInvoiceSettingsForm = function() {
    enableTabPersistence();
    setCheckboxShowOrHideListener(INVOICE_SETTINGS_FORM_SELECTOR, '.pandle_create_invoices', '.pandle_invoice_creator_fields');
    setCheckboxShowOrHideListener(INVOICE_SETTINGS_FORM_SELECTOR, '.pandle_reminders', '.pandle_reminder_fields');
    setCheckboxShowOrHideListener(INVOICE_SETTINGS_FORM_SELECTOR, '.pandle_quotes','.pandle_quote_fields');
    setCheckboxShowOrHideListener(INVOICE_SETTINGS_FORM_SELECTOR, '.custom_units', '.default_unit_type');
    setCheckboxShowOrHideListener(INVOICE_SETTINGS_FORM_SELECTOR, '.stripe_connection', '.stripe_button');
    setStripeFormStateListeners(INVOICE_SETTINGS_FORM_SELECTOR);
    stripeButtonListener('company_invoice_creator');

    configureInvoiceCreatorDropzone();
    hideLogoOnDelete();
    initStripeButton();
    var index = $('*[data-style="' + $('#company_invoice_creator_invoice_style').val() + '"]').index();
    $('#invoice-style-carousel').carousel(index);
    onSlid();
    openIntegrationMenu();
  };

  function initStripeButton() {
    $('#stripe_button').click(function(event) {
      $('#stripe_button_hidden_field').val(true);
      $('#edit_invoice_settings_form').trigger('submit.rails')
    });
  }

  function enableTabPersistence() {
    $('ul.tab-nav a').click(onTabClick);
  }

  function onTabClick(e) {
    UserPreferences.set('invoice_settings_active_tab', getTabId(e.target));
  }

  function getTabId(elem) {
    return elem.id.split('_').slice(0, -1).join('_');
  }

  function configureInvoiceCreatorDropzone() {
    if ($('#new_company_logo_dropzone:not(.hidden)').length === 0) { return }
    configureDropzone(
      $('#new_company_logo_dropzone'),
      $(INVOICE_SETTINGS_FORM_SELECTOR)[0],
      'company_invoice_creator[company_logo]'
    );
  }

  function setCheckboxShowOrHideListener(parent, checkbox, form) {
    $(checkbox).unbind();
    showOrHideSectionWithCheckbox(parent, checkbox, form);

    $(checkbox).change(
      function() {
        showOrHideSectionWithCheckbox(parent, checkbox, form);
      }
    );
  }
  
  function hideLogoOnDelete() {
    $('#delete_logo').on(
      'change', function() {
        $('#company_logo_preview').toggle();
        $('#new_company_logo_dropzone').toggleClass('hidden');
        configureInvoiceCreatorDropzone();
        $('#company_logo_preview_container').hide();
      }
    )
  }

  function onSlid() {
    $('#invoice-style-carousel').on(
      'slid.bs.carousel', function() {
        $('#company_invoice_creator_invoice_style').val($('.carousel-inner').find('.active').data('style'))
      }
    )
  }

  function openIntegrationMenu() {
    if ($('#redirected-from-stripe').length === 0) { return }
    $.ajax({
      url : $('#redirected-from-stripe').data('url'),
      dataType : 'script'
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($(INVOICE_SETTINGS_FORM_SELECTOR).length === 0) { return }
    errors.tryFn(initInvoiceSettingsForm());
  }));
})();
