SelectMenuComponent = {
  "render": function(selectMenuJson){
    var optionsHtml = SelectMenuOptionsComponent.render(selectMenuJson.options);
    return '<div class="select"><select class="chosen-select" id="' + selectMenuJson.id + '">' + optionsHtml + '</select></div>';
  },
  "setupOn": function(idOfElementContainingComponent, componentId, arrayOfHtmlStringsToAppendToEachOption) {
    $(".chosen-select").chosen({disable_search_threshold: 10});
    window.initChosenSelects($("#" + idOfElementContainingComponent));
    if(arrayOfHtmlStringsToAppendToEachOption && arrayOfHtmlStringsToAppendToEachOption.length > 0){
      $('#' + componentId)
        .siblings('.chosen-container')
        .click(function(){
          $(this)
            .find('li')
            .each(function(index, element){
              $(element).prepend(arrayOfHtmlStringsToAppendToEachOption[index])
            })
          ;
        })
      ;
    }
  },
  "getSelectedOption": function(menuId){
    return $("#" + menuId + " option:selected").attr('value');
  }
}
