// Functions that help us setup a card form for use with Stripe Elements
(function(){

  window.mountStripeElement = function(elements, name, placeholder) {
    var styles = { style: { base: { fontFamily: 'Mulish, sans-serif', fontWeight: 400, fontSize: '11px'} } }
    var stripe_elt = elements.create(name, $.extend({ placeholder: placeholder }, styles));
    stripe_elt.mount('#'+name);
    stripe_elt.on('focus', function(){
      $('#'+name).closest('.fg-line').addClass('fg-toggled');
    })
    stripe_elt.on('blur', function(){
      $('#'+name).closest('.fg-line').removeClass('fg-toggled');
    })
    stripe_elt.on('change', function(event){
      if (event.error) {
        notify(event.error.message, 'warning', 'Warning! ')
      }
    })
    return stripe_elt;
  }

})();