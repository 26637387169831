InvoiceStatusIndicatorComponent = {
  "render": function(statuses, statusGroupElementId, statusGroupTextPlacement = 'right'){
    statusGroupTextPlacement = (typeof statusGroupTextPlacement !== 'undefined') ? statusGroupTextPlacement : 'right';
    const statusColourMapping = $('#invoice-credit-specification-table').data('status-colour-mapping');
    const statusGroupText = statuses.map(s => statusColourMapping[s]['name']).join(', ')
    const statusIndicatorColours = statuses.map(s => statusColourMapping[s]['colour'])
    const uniqueStatusIndicatorColours = Array.from(new Set(statusIndicatorColours))

    const uniqueColouredStatusIndicatorsHtml = uniqueStatusIndicatorColours.map(function(statusIndicatorColour) {
      return ColouredStatusIndicatorComponent.render(statusIndicatorColour)
    }).join('')

    const hoverContainer = HoverContainerComponent.render(uniqueColouredStatusIndicatorsHtml)
    return renderPopoverComponent(statusGroupElementId, statusGroupTextPlacement, statusGroupText, hoverContainer);
  }
}

const STATUS_COLOUR = {
  "paid": "green",
  "part_paid": "orange",
  "part_credited": "orange",
  "overdue": "red",
  "unpaid": "grey"
}

function renderPopoverComponent(popoverElementId, popoverPlacement, popoverText, html) {
  const popover_controller_actions = 'show-popover->popover#showPopover ' +
    'show-popover-with-content->popover#showPopoverWithContent ' +
    'show-popover-with-container->popover#showPopoverWithContainer ' +
    'update-popover-content->popover#updatePopoverContent ' +
    'hide-all-popovers@window->popover#hidePopover ' +
    'destroy-popover->popover#destroyPopover';
  return '<div id="' + popoverElementId + '" data-controller="popover" data-action="' + popover_controller_actions + '" style="display:inline-block"  data-toggle="popover" data-placement="' + popoverPlacement + '" data-html="true" data-trigger="hover" data-content="' + popoverText + '" data-original-title="" title="">' + html + '</div>';
}
