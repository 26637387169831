(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#uploads-index').length === 0) { return }
    initNonBootgrid('#uploads-index', '/uploads');
  }));

  window.reloadUploadTable = function() {
    if ($('#uploads-index').length === 0) { return }
    onResetValues('#uploads-index', '/uploads');
  };
})();
