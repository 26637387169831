(function() {
  'use strict';
  /*
    Checks whether the transaction date is covered by a VAT scheme, if the tax code requires one

    @param {Object} arg
    @param {jQuery} arg.tax_amount_field
    @param {jQuery} arg.tax_code_field
    @param {jQuery} arg.datepicker - should be a DateTimePicker, get one from getDateTimePicker
  */
  window.addNoVatSchemeWarning = function(arg) {
    if (arg.tax_amount_field.length && arg.tax_amount_field.val().length && arg.tax_amount_field.val() !== '0.00' && arg.datepicker !== undefined) {
      _checkTaxCodeWithTax(arg.tax_code_field, arg.datepicker);
    }
  }

  function _checkTaxCodeWithTax(tax_code_field, datePicker) {
    getJsonOrShowMaintenanceModal('/tax_codes/' + encodeURIComponent(tax_code_field.val()),
      _checkCoveredByVatScheme(datePicker.date()));
  }

  function _checkCoveredByVatScheme(date) {
    return function(tax_code) {
      if (tax_code.include_in_vat_return && date) {
        getJsonOrShowMaintenanceModal('/vat_scheme_journals/is_covered_by_vat_scheme_journal.json?date=' +
          encodeURIComponent(formatDateGb(date)),
          _displayNotCoveredByVatSchemeWarning());
      }
    }
  }

  function _displayNotCoveredByVatSchemeWarning() {
    return function(isCoveredByVatScheme) {
      if (!isCoveredByVatScheme) {
        addHelpMessageOrNotification(
          HELP_WARNING, 'warning',
          I18n.t('invoices.sanity_checks.not_covered_by_vat_scheme'),
          'not-covered-by-vat-scheme'
        );
      }
    }
  }
})();
