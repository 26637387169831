(function() {
  'use strict';

  var LEDGER_FILTER_OPTIONS = [
    { id: 'show_empty', name: 'No Balance', visible: true },
    { id: 'show_inactive', name: 'Inactive', visible: false }
  ]

  var LEDGER_TAB_MAP = {
    'salesledger': {
      options_id: 'customers',
      table_id: 'customer_table',
      filter_options: LEDGER_FILTER_OPTIONS,
      multi_sort: true
    },
    'cashbook': {
      options_id: 'banking',
      table_id: 'bank_accounts_table',
      filter_options: LEDGER_FILTER_OPTIONS,
      multi_sort: true
    },
    'purchaseledger': {
      options_id: 'suppliers',
      table_id: 'supplier_table',
      filter_options: LEDGER_FILTER_OPTIONS,
      multi_sort: true
    }
  };

  function openBankAccountIfFromStripe() {
    if ($('#redirected_from_stripe').length === 0) { return }
    $.ajax({
      url : $('#redirected_from_stripe').data('url'),
      dataType : 'script'
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#content.ledger').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#ledger',
      tab_map: LEDGER_TAB_MAP,
      user_preference_key: 'ledger_tab_id'
    });
    initLedgerKeyboardCodes();
    openBankAccountIfFromStripe();
  }));
})();
