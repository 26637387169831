import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tagSelect', 'tagFilter', 'chosenTag']

  check(event) {
    const selectedId = this.tagSelectTarget.dataset.groupAndTagSelectsSelectedIdValue
    if (selectedId) {
      const id = 'enterprise_tag_ids_' + selectedId
      const checkbox = document.getElementById(id)
      checkbox.checked = true
      checkbox.closest('label').style.display = 'block'
      this.clearFilter()
    }
  }

  uncheck(event) {
    const id = event.target.dataset.value
    const checkbox = document.getElementById(id)
    checkbox.checked = false
    checkbox.closest('label').style.display = 'none'
  }

  clearFilter() {
    this.tagFilterTarget.value = ''
    this.tagFilterTarget.dispatchEvent(new Event('input'))
  }

  filterTags(event) {
    const tags = this.chosenTagTargets
    const filterValue = event.target.value
    tags.forEach(function (tag) {
      if (tag.querySelector('input').checked) {
        if (filterValue) {
          if (tag.dataset.name.includes(filterValue.toLowerCase())) {
            tag.style.display = ''
          } else {
            tag.style.display = 'none'
          }
        } else {
          tag.style.display = ''
        }
      }
    })
  }
}
