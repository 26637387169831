CreditableInvoiceModalBehaviour = {
  "enableCreditInFullFieldBehaviourOn": function(tableId){
    var tableBodyElement = TableHelper.getBody(tableId);
    EventDelegation.create(tableBodyElement, 'click', CheckboxIndicator.jQuerySelector, updateCreditTotalOnCreditInFullStateChange);
  },
  "enableTotalToCreditTotalComparisonBehaviourOn": function(tableId){
    var tableBodyElement = TableHelper.getBody(tableId);
    NumberComparison.addNumberComparisonEventDelegation(tableBodyElement, 'keyup', AmountInput.jQuerySelector, comparisonReactionFunction);
  },
  "enableHandleConfirmationEvent": function(tableId) {
    var modal = $('#invoice-credit-specification-table').parent();
    EventDelegation.create(
      modal,
      'click',
      '#invoice-credit-selection-confirmation',
      callEndpointAndRenderInvoices
    );
  },
  "enableCloseEvent": function($batchInvoiceModal) {
    EventDelegation.create(
      modal,
      'click',
      '#invoice-credit-selection-close',
      function() { BatchInvoiceModalState.resetModalToState($batchInvoiceModal) }
    );
  }
}

function callEndpointAndRenderInvoices(event) {
  $(this).attr('disabled', true);
  var invoice_ids = $.map(CreditedInvoiceDataHandler.tempInvoices.getHash(), function(invoice) { return invoice.id; });
  var row_id = $(this).data('row-id');
  CreditableInvoiceModalSubmitBehaviour.submitInvoicesToConfirmedInvoicesAndRenderInvoices(invoice_ids, row_id)
}

function updateCreditTotalOnCreditInFullStateChange(event){
  var indicatorCheckbox = event.target;
  var indicatorCheckboxRow = TableHelper.getRowThatElementIsContainedIn(indicatorCheckbox);

  var totalElementInIndicatorCheckboxRow = CreditableInvoiceModalTableComponent.getTotalElementFromRow(indicatorCheckboxRow);
  var creditTotalElementInIndicatorCheckboxRow = CreditableInvoiceModalTableComponent.getCreditTotalElementFromRow(indicatorCheckboxRow);

  if (indicatorCheckbox.checked) creditTotalElementInIndicatorCheckboxRow.value = totalElementInIndicatorCheckboxRow.textContent;
  else creditTotalElementInIndicatorCheckboxRow.value = "";
}

function comparisonReactionFunction(comparisonResultJson){
  var rowContainingSourceElement = TableHelper.getRowThatElementIsContainedIn(NumberComparisonResultLens.sourceElement(comparisonResultJson));
  var creditInFullElementToUpdate = CreditableInvoiceModalTableComponent.getCreditInFullElementFromRow(rowContainingSourceElement);
  var checked = false;
  var indeterminate = false;
  var sourceNumberReplacement = false;

  if(NumberComparisonResultLens.sourceNumber(comparisonResultJson) == 0) {
    sourceNumberReplacement = "";
  } else if (NumberComparisonService.sourceNumberLessThanTargetNumber(comparisonResultJson)) {
    indeterminate = true;
  } else {
    checked = true;

    if(NumberComparisonService.sourceNumberGreaterThanTargetNumber(comparisonResultJson)) {
      sourceNumberReplacement = NumberComparisonResultLens.targetNumber(comparisonResultJson);
    }
  }

  creditInFullElementToUpdate.checked = checked;
  creditInFullElementToUpdate.indeterminate = indeterminate;
  if(sourceNumberReplacement !== false) NumberComparisonResultLens.sourceElement(comparisonResultJson).value = sourceNumberReplacement;
}
