(function() {
  'use strict';

  window.removePlaidSetupSpinner = function(){
    $('#plaid_feed_connection_button').closest('.modal-body-and-footer').removeClass('hidden');
    $('#plaid_feed_connection_button').closest('.modal-content').find('.preloader-container').remove();
  }

  window.plaid_supported_country_codes = function() {
    return ['GB','US','CA']; //FARMPLAN: removed unsupported countries
  }

  window.plaid_feed_setup = {

    sentPlaidRequests: {},

    createPlaidHandler: function(response, handler_config){
      var handler = Plaid.create(handler_config);
      return handler;
    },

    onEventHandler: function(eventName, metadata) {
      if ( eventName === "ERROR") {
        logMessage(metadata.error_message, { extra: {
          error_type: metadata.error_type,
          error_code: metadata.error_code,
          institution_id: metadata.institution_id,
          link_session_id: metadata.link_session_id,
          request_id: metadata.request_id,
          status: metadata.status
        } })
      }
    },

    logPlaidRequest: function(metadata, status){
      if (this.sentPlaidRequests[status] && (moment(this.sentPlaidRequests[status]) > moment(metadata['timestamp']).subtract(5, 'seconds'))){
        this.logRavenMessage("Plaid request already sent", metadata);
        return;
      }
      this.sentPlaidRequests[status] = metadata['timestamp']
      $.post(Routes.plaid_requests_path({format: 'js'}), {
        plaid_request: {
          request_id_plaid: metadata.request_id,
          link_session_id_plaid: metadata.link_session_id,
          institution_id_plaid: getInstitutionId(metadata),
          state: status
        }
      })
    },

    logRavenMessage: function(err, metadata) {
      logMessage(err, { extra: {
        institution: metadata.institution,
        link_session_id: metadata.link_session_id,
        request_id: metadata.request_id,
        status: metadata.status
      } })
    }
  }

  function getInstitutionId(metadata) {
    if (metadata.institution) {
      return metadata.institution.institution_id;
    } else {
      return metadata.institution_id;
    }
  }
})();
