(function() {
  'use strict';

  var $tabs;
  var $tabs_bootgrid_options;
  var nav_tabs_opts;

  /*
  * Sets up the necessary javascript to cope with a page with nav-tabs containing bootgrid tables
  *
  * @param {Object} opts
  * @param {string} opts.tabbable_id
  * @param {hash}   opts.tab_map
  * @param {string} opts.user_preference_key
  */
  window.initNavTabsWithTables = function(opts) {
    nav_tabs_opts = opts;
    $tabs = $('.tab-nav li a[href]');
    $tabs_bootgrid_options = $('.tabs-bootgrid-options');
    initNavTabs();
  }

  function initNavTabs() {
    var activeTabId = _getActiveTabId();
    if (activeTabId) {
      showTab(activeTabId);
      $tabs.on('click', onTabClick);
    }
  }

  function _getActiveTabId() {
    var activeTab = $('.tab-nav li.active a').attr('id');
    return activeTab ? getTabIdFromIdAttribute(activeTab) : null;
  }

  function getTabIdFromIdAttribute(tab) {
    return tab.split('_')[0];
  }

  function showTab(tabId) {
    var tab = nav_tabs_opts.tab_map[tabId];

    UserPreferences.set(nav_tabs_opts.user_preference_key, tabId);
    if (tab) {
      showBootgridOptions(tab.options_id);
      initBootgridTableForTab(tab);
    }
    showTabSpecificContent(tabId);
  }

  function showBootgridOptions(id) {
    $tabs_bootgrid_options.hide();
    $('#tabs_bootgrid_options__' + id).show();
  }

  function onTabClick(e) {
    var tab_id = e.target.id
    if (tab_id == "") { tab_id = e.target.parentElement.id }
    showTab(getTabIdFromIdAttribute(tab_id));
  }

  function initBootgridTableForTab(tab) {
    var $bg_table = getBootgridTableElement(tab);
    if ( $bg_table.length == 0 ) { return; }
    if (tab.filter_options) {
      initBootgridTable($bg_table, undefined, applyCsvExport(tab), tab.filter_options);
    } else {
      initBootgridTable($bg_table, undefined, applyCsvExport(tab));
    }
  }

  /*
   * Optionally show the CSV export button.
   * Non-default options should be set in tab maps
   * Default: true
   */
  function applyCsvExport(tab) {
    if (typeof tab.csv_export === "undefined") {
      return true;
    } else {
      return tab.csv_export;
    }
  }

  function showTabSpecificContent(tabId) {
    $('.tab-specific-article').toggle(false);
    if ( tabId.length > 0 ) {
      $('.tab-specific-article.' + tabId).toggle(true);
    }
  }

  function getBootgridTableElement(tab) {
    return $('#' + tab.table_id);
  }
})();
