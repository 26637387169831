(function(){

  window.bootgridCsvExporter = {};

  bootgridCsvExporter.decorate = function($bootgrid, actions){
    var templates = $bootgrid.options.templates;
    var css = $bootgrid.options.css;
    var exportIcon = templates.icon.resolve(
      $bootgrid.getParams({ iconCss: css.iconExport }));

    var csvExport = $(templates.actionButton.resolve($bootgrid.getParams({
      content: exportIcon,
      text: $bootgrid.options.labels.csvExport
    })))
    .addClass('bgm-green')
    .on("click" + $.fn.bootgrid.namespace, onExportButtonClick($bootgrid));

    actions.append(csvExport);
  };

  function onExportButtonClick ($bootgrid){
    return function(e){
      if ($bootgrid.options.ajax) {
        loadCsvExport($bootgrid, e.target);
      }
    }
  }

  function loadCsvExport($bootgrid, button) {
    var css = $bootgrid.options.css;
    var button = getCsvExportButton(button, css);
    var initial_button_class = button.attr('class');

    displayCsvLoadingIconAndDisableButton(button, css);
    abortCurrentXhr($bootgrid);
    sendXhr($bootgrid, button, initial_button_class);
  }

  function getCsvExportButton(button, css) {
    return $(button).hasClass(css.icon) ? $(button) : $(button).find('.' + css.iconExport);
  }

  function displayCsvLoadingIconAndDisableButton (export_button_span, css) {
    export_button_span.attr('class', css.icon + ' ' + css.iconLoading);
    export_button_span.parent().attr('disabled', 'disabled');
  }

  function abortCurrentXhr ($bootgrid) {
    if ($bootgrid.xqr) {
      $bootgrid.xqr.abort();
    }
  }

  function sendXhr ($bootgrid, button, initial_button_class) {
    return ($bootgrid.xqr = $.ajax($.extend($bootgrid.options.ajaxSettings, {
      url: getUrl($bootgrid),
      data: getRequest($bootgrid),
      success: onXhrSuccess($bootgrid, button, initial_button_class),
      error: onXhrFail()
    })));
  }

  function getUrl ($bootgrid){
    return Bootgrid.getUrl.call($bootgrid).replace(".bootgrid", ".csv");
  }

  function getRequest ($bootgrid){
    var request = Bootgrid.getRequest.call($bootgrid);
    request.rowCount = "-1"; // We want all the rows
    return request;
  }

  function onXhrFail () {
    return function() {
      notify('An error occurred downloading your export, please try again or contact support', 'danger', 'Error ');
    }
  }

  function onXhrSuccess ($bootgrid, button, initial_button_class) {
    return function(response) {
      $bootgrid.xqr = null;
      response = JSON.parse(response);
      // We need to make a distinction between new background jobs processes for now - TB-12379
      if (Number.isInteger(response)) {
        connectToChannel(response, button, initial_button_class);
      } else {
        saveData(response.filename || getDefaultFilename(), response.csv);
        restoreCsvButton(button, initial_button_class);
      }
    }
  }

  function getDefaultFilename() {
    return branding.product_name + " Data.csv";
  }

  function saveData(filename, csv){
    saveAs(new Blob([csv], { type: "text/plain;charset=utf-8"}), filename);
  }

  // This needs to stay in until all csv exports have been moved to background jobs - TB-12379
  function restoreCsvButton (export_button_span, old_button_class) {
    export_button_span.attr('class', old_button_class);
    export_button_span.parent().attr('disabled', false);
  }

  function connectToChannel(response, button, initial_button_class) {
    notify('Your report is being calculated and will soon be available for download.', 'success', 'Success! ');
    createSubscriptionToBackgroundExports(response, button, initial_button_class, function() {
      Routes.bootgrid_table_background_export_path(response)
    });
  }
  })();
