(function () {
  'use strict';

  var PROJECT_TAB_MAP = {
    'open': {
      options_id: 'open',
      table_id: 'open_projects_table',
      multi_sort: false
    },
    'complete': {
      options_id: 'complete',
      table_id: 'complete_projects_table',
      multi_sort: false
    }
  };

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#project_index').length === 0) { return };
    initNavTabsWithTables({
      tabbable_id: '#project_index',
      user_preference_key: 'project_tab_id',
      tab_map: PROJECT_TAB_MAP
    });
  }));
})();