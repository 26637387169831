import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { id: String }

  initialize() {
    this.idValue = this.inputTarget.id
    $(this.inputTarget).on('focusout', function (event) {
      const date = $(event.target).val()
      window.dispatchEvent(new CustomEvent('batchdate:changed', { detail: { id: this.idValue, date } }))
    }.bind(this))
  }
}
