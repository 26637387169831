FancyEllipsisIconComponentState = {
  "isActive": function(element) {
    return FancyEllipsisIconComponentGetter.dataActive(element) === 'true';
  },
  "makeActive": function(element){
    FancyEllipsisIconComponentStyling.active(element);
    FancyEllipsisIconComponentSetter.dataActive(element, 'true');
  },
  "makeInactive": function (element){
    FancyEllipsisIconComponentStyling.inactive(element);
    FancyEllipsisIconComponentSetter.dataActive(element, 'false');
  }
}