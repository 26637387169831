// Things we actually want to do on every pageload, but which don't warrant a whole file in this directory
(function () {
  "use strict";

  window.initModals = function (modals) {
    modals.on("show.bs.modal", function (e) {
      formatFormsIn($(e.currentTarget));
      $(".popover").remove();
    });

    modals.on("shown.bs.modal", function (e) {
      focusFirstField($(this));
    });

    modals.on("hidden.bs.modal", function () {
      $(".popover").remove();
    });

    // Add active tab
    $("#top_menu [href]").each(function () {
      if (this.href == window.location.href) {
        $(this).addClass("active");
      }
    });
  };

  function resetPageLoadedFlags() {
    window.datepickersComponentAlreadyInitialised = false;
  }

  function getAppropriateSpinner() {
    if (spinnerUrl()) {
      return (
        "<div class='row'> \
                  <div class = 'col-md-6 col-md-offset-3 text-center p-t-30 p-b-30'> \
                      <img src='" +
        spinnerUrl() +
        "' height='150' width='150'>\
                    <h5>Loading...</h5> \
                  </div>\
                </div>"
      );
    } else {
      return "<div class='row'> \
                  <div class = 'col-md-6 col-md-offset-3 text-center p-t-30 p-b-30'> \
                    <div class='preloader pl-xxl'> \
                      <svg class='pl-circular' viewBox='25 25 50 50'> \
                        <circle class='plc-path' cx='50' cy='50' r='20'></circle> \
                      </svg> \
                    </div>\
                    <h5>Loading...</h5> \
                  </div>\
                </div>";
    }
  }

  function spinnerUrl() {
    var header = $("#header-2");
    if (header.length == 0) {
      header = $("#pandle_header");
    }
    return header.attr("data-spinner-url");
  }

  // Due to turbolinks, event listeners that we add on document hang around across pageloads
  // Since we only want them on the pages where we explicitly add them, remove them ourselves
  function removeDocumentEventListeners() {
    document.removeEventListener(
      "datepickersComponentInitialised",
      initProgramDateFields,
    );
  }

  resetPageLoadedFlags();

  document.addEventListener("turbo:before-visit", function () {
    resetPageLoadedFlags();
    removeDocumentEventListeners();
  });

  document.addEventListener(
    "turbo:load",
    errors.tryFn(function () {
      formatForms();
      initModals($("#modal, #modal-lg"));
      initConfirmModal();
      initKeyboardShortcuts();
      loadSweetDeleteActions();
      initNotificationBell();
    }),
  );

  document.addEventListener("turbo:before-cache", function () {
    $(".waves-ripple").remove();
    $(".bootgrid-table").each(function () {
      $(this).bootgrid("destroy");
    });
    $('[data-growl="container"]').remove();
    $(".chosen-container").remove();
    $(".card tbody").each(function () {
      $(this).html(
        "<tr style='background-color: transparent;'>\
          <td colspan='7'>\
            <div class='preloader-container'>\
              <div class='preloader pl-lg'>\
                <svg class='pl-circular' viewBox='25 25 50 50'>\
                  <circle class='plc-path' cx='50' cy='50' r='20'></circle>\
                </svg>\
              </div>\
            </div>\
          </td>\
        </tr>",
      );
    });
    $("[id^=plaid-link-iframe-]").remove();
  });

  document.addEventListener("turbo:visit", function(event){
    if (event.detail.action === "restore"){
      location.reload();
    }
  });

  document.addEventListener("turbo:before-cache", function(){
    $('#flash').empty();
    $('.main-content').html(getAppropriateSpinner());
  });
})();
