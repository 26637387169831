/**
* @fileoverview For use with e.g. choppy element resizing.
*/

(function() {
  'use strict';

  /**
   * Return a function that will only call fn once per interval.
   *
   * @param fn
   * @param interval
   * @param scope
   * @returns {Function}
   */
  window.throttleFn = function(fn, interval, scope) {
    var throttler = new Throttler(fn, interval, scope);

    return function() {
      throttler.call(arguments);
    }
  };
  /**
   * Wraps a function and exposes a method that only allows it to be called once per interval
   *
   * @param {Function} fn
   * @param {=Number} interval
   * @param {=Object} scope
   * @constructor
   */
  function Throttler(fn, interval, scope) {
    this._fn = fn;
    this._interval = interval || 200;
    this._scope = scope || window;
    this._can_call = true;
    this._delayed_call = false;
    this._last_args = null;
    this._timer = null;
  }

  Throttler.prototype.call = function(args) {
    if (this._can_call) {
      this._fn.apply(this._scope, args);
    } else {
      this._storeDelayedCall(args);
    }

    this._startTimer();
  };

  Throttler.prototype._startTimer = function() {
    this._can_call = false;
    this._clearTimeout();
    this._timer = window.setTimeout(this._onTimerDone.bind(this), this._interval);
  };

  Throttler.prototype._clearTimeout = function() {
    if (this._timer) {
      window.clearTimeout(this._timer);
    }
  };

  Throttler.prototype._onTimerDone = function() {
    this._can_call = true;
    this._timer = null;
    this._performDelayedCall();
  };

  Throttler.prototype._performDelayedCall = function() {
    if (this._delayed_call) {
      this._fn.apply(this._scope, this._last_args);
      this._delayed_call = false;
    }
  };

  Throttler.prototype._storeDelayedCall = function(args) {
    this._delayed_call = true;
    this._last_args = args;
  };
})();
