(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#obw_index_table').length === 0) { return }

    $('#obw_index_table').bootgrid({
      columnSelection: false,
      navigation: 0,
      rowCount: -1,
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up'
      }
    });
    initBootgridTable($('#obw_invoices_table'), undefined, true);
  }));
})();
