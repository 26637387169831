(function() {
  'use strict';

  var VAT_CLAIM_BACK_MESSAGE = 'Did you know that you can claim back VAT on purchases up to 4 years before registering?<br>Please ask us for further details!';

  function showVatClaimBackMessage () {
    if ($('[id^=edit_company]').find('#company_vat_registered').is(':checked')) {
      addHelpMessage($('input#company_vat_registered'), HELP_INFO, VAT_CLAIM_BACK_MESSAGE);
    }
    UserPreferences.set('notification_vat_claim_back_displayed', true);
    $('#company_vat_registered').unbind('change', showVatClaimBackMessage);
  }

  function setEditorVatVisibility () {
    showOrHideSectionWithCheckbox('[id^=edit_company]', '#company_vat_registered', '.vat_reg_fields');
  }

  function setEditorEuVatVisibility() {
    showOrHideSectionWithCheckbox('[id^=edit_company]', '#company_uses_eu_vat', '.eu_vat_reg_fields');
  }

  function setEditorInvoicingVisibility () {
    showOrHideSectionWithCheckbox('[id^=edit_company]', '.pandle_creates_invoices', '.pandle_invoice_fields')
  }

  function notifyInvoiceSettingsChanged(){
    showOrHideSectionWithCheckbox('[id^=edit_company]', '#company_customer_credit', '#invoice_settings_notification')
  }

  function setEditorReminderVisibility (slideToggle) {
    showOrHideSectionWithCheckbox('[id^=edit_company]', '.pandle_reminders', '.pandle_reminder_fields')
  }

  function setLockedPeriodCheckboxVisibility (){
    var $date_field = $('#company_locked_period_date_end');
    var $checkbox_div = $('#company_locked_period_access_checkbox');
    if ($checkbox_div.is(':hidden') && $date_field.val().length > 0) {
      $checkbox_div.slideDown().removeClass('hidden');
    } else if ($checkbox_div.is(':visible') && $date_field.val().length == 0) {
      $checkbox_div.slideUp();
      $('#company_locked_period_user_access').prop('checked', false);
    };
  }

  function initAreYouSureCompaniesEdit () {
    var companies_form_are_you_sure_controller = checkFormChangedOnPageUnload('.edit_company');
  }

  window.initCompanySettingsForm = function() {
    if ($('.companies .edit_company').length === 0) { return }

    //GENERAL
    addTabEventListeners();
    initialiseNavTabsHelp($('form[id^="edit_company_"]'));
    initAreYouSureCompaniesEdit();
    focusFirstFieldOnTabChange();
    focusFirstField($('.tab-pane.active'));

    checkCompaniesHouseOnChange();
    checkLockTransactionDateOnFormSubmit();

    // TAB 1 - Company Info
    afterDatepickersComponentHaveBeenInitialised(initProgramDateFields);
    if( $("#company_locked_period_date_end").length > 0 ) {
      if ( $('#company_locked_period_date_end').is(':disabled') ) {
        $("#locked_period_date_field").on('click', function() {
          if ( $('[data-growl="container"]').length == 0 ) {
            notify('Locked transactions has been disabled. Please contact your Accountant', 'warning', 'Error! ')
          }
        });
      };

      if ( $('#company_locked_period_access_checkbox').length > 0 ) {
        $("#company_locked_period_date_end").on('blur', function() { setTimeout(setLockedPeriodCheckboxVisibility, 50) });
      }
    }
    $('#industry_selector').change(checkAndShowOtherField);

    // TAB 2 - Company Address
    updateAddressFormWhenCountryChanges('#company_address_attributes_country');
    initListenerForTelephoneField($('#company_telephone_number'));

    // TAB 3 - Financial Info
    // VAT
    var company_editor_form = $('[id^=edit_company]');

    // Show VAT fields only when VAT registered
    $(company_editor_form).find('#company_vat_registered').unbind();
    $(company_editor_form).find('#company_vat_registered').change(setEditorVatVisibility);
    setEditorVatVisibility();

    // Show EU VAT fields only when EU VAT registered
    $(company_editor_form).find('#company_uses_eu_vat').unbind();
    $(company_editor_form).find('#company_uses_eu_vat').change(setEditorEuVatVisibility);
    setEditorEuVatVisibility();

    $('.vat_scheme').on('change', vatSchemeTypeChange);

    if ($(".card-body.card-padding.company_form").data("vat-notification")) {
      $('#company_vat_registered').on('change', showVatClaimBackMessage);
    }

    // TAB 4 - Invoicing
    $(company_editor_form).find('#company_customer_credit').unbind();
    $(company_editor_form).find('#company_customer_credit').change(notifyInvoiceSettingsChanged);
    $('#invoice_settings_notification').hide();

    $(company_editor_form).find('.pandle_creates_invoices').unbind();
    $(company_editor_form).find('.pandle_creates_invoices').change(setEditorInvoicingVisibility);
    $(company_editor_form).find('.pandle_reminders').change(setEditorReminderVisibility);

    setEditorInvoicingVisibility();
    setEditorReminderVisibility();

    //Hide the logo preview when 'delete' is checked
    $('#delete_logo').on('change', function (event) {
      $('#company_logo_preview').toggle();
      $('#new_company_logo_dropzone').toggleClass('hidden');
      $('#company_logo_preview_container').hide();
    });

    // TAB 5 - Officers
    // TAB 6 - Users
  }

  function addTabEventListeners() {
    $('ul.tab-nav a')
      .click(updateCompanyTabPreference)
      .click(updateCompanyActionButtonLayout)
  }

  function updateCompanyTabPreference(e) {
    UserPreferences.set('company_settings_active_tab', getTabbedFormTabId(e.target));
  }

  function updateCompanyActionButtonLayout(e) {
    var $formActions = $('#update-company-controls');
    if (e.target.id == 'permissions_tab') {
      $('#actions-for-permissions-tab').append($formActions);
    } else {
      $('#actions-for-non-permissions-tab').append($formActions);
    }
  }

  function checkLockTransactionDateOnFormSubmit() {
    var maxLockDate = $('#company_locked_period_date_end').parent().data('max-lock-date');

    if (maxLockDate == "" || typeof maxLockDate == "undefined") {
      return;
    }

    $('#edit_company_form :submit').on('click', function(event) {
      event.preventDefault();
      var selectedDate = dateFromElement($('#company_locked_period_date_end'));

      if (selectedDate == null || selectedDate.isBefore(moment(maxLockDate))) {
        $('form#edit_company_form').submit();
      } else {
        var message = "You have draft bank transactions dated on or before " + selectedDate.format("MMMM Do YYYY");
        confirmFormSubmission(message, $('form#edit_company_form'), 'sweetalert-scary-warning');
      }
    });
  }


  window.notifySubscriptionCouldBeCheaperNowVatRegistered = function(){
    addHelpMessageWithTitle(I18n.t('companies.edit.subscription_could_be_cheaper_now_title'), 'info',
      I18n.t('companies.edit.subscription_could_be_cheaper_now_message'))
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.companies .edit_company').length > 0) {
      // Company Settings
      initCompanySettingsForm();
    }

    if ($('#company_users_table').length > 0) {
      initBootgridTable($('#company_users_table'));
    }

    if ($('#company_officers_table').length > 0) {
      initBootgridTable($('#company_officers_table'));
    }
  }));
})();
