(function() {
  'use strict';

  window.addAddressFromCompaniesHouse = function() {
    if($('#company_reg_no').length > 0){
      var $reg_field = $('#company_reg_no');
      var reg_val = $reg_field.data('reg');
      callCompanyInfoEndpoint(reg_val, setCompanyAddress, null);
    }
  };

  function setCompanyAddress(response){
    clearAddressData();
    setCompanyAddressValues(response);
  }

  function clearAddressData(){
    $('#company_address_attributes_address_line_1').val("");
    $('#company_address_attributes_address_line_2').val("");
    $('#company_address_attributes_address_line_3').val("");
    $('#company_address_attributes_town_city').val("");
    $('#company_address_attributes_county_chosen > a > span').html("")
    $('#company_address_attributes_postcode').val("");
  }

  function setCompanyAddressValues(response) {
    updateFieldToMatchCompaniesHouse($('#company_address_attributes_address_line_1'), response['address']['address_line_1']);
    updateFieldToMatchCompaniesHouse($('#company_address_attributes_address_line_2'), response['address']['address_line_2']);
    updateFieldToMatchCompaniesHouse($('#company_address_attributes_postcode'), response['address']['postal_code']);
    updateFieldToMatchCompaniesHouse($('#company_address_attributes_town_city'), response['address']['locality']);
  }

  function updateFieldToMatchCompaniesHouse($field, response_val) {
    if(response_val) {
      $field.val(response_val);
    }
  }
})();
