(function() {
  'use_strict'

  window.checkForMatchingInvoices = function(row_id) {
    if(transactionAndCategorySeletedInSameCurrency(row_id)) {
      getInvoiceButton(row_id).html("<i class='zmdi zmdi-rotate-right zmdi-hc-spin'></i>");
      requestAndSelectMatchingInvoice(row_id);
    };
  }

  function requestAndSelectMatchingInvoice(row_id) {
    requestInvoicesBankTransaction(row_id).then(function(){
      var $modal = $('#inv-tbody'+row_id).parents('.payment_allocations_modal');
      var $matching_inv_btn = $modal.find('#matched_inv_'+row_id);

      if ($matching_inv_btn.length > 0 && checkInvoiceOutstandingEqualsBankTransactionAmount($matching_inv_btn)) {
        $matching_inv_btn.click();
        notify('Matching invoice has been found for this bank transaction.', 'info', '');
      }
    });
  }

  function requestInvoicesBankTransaction(row_id) {
    return $.ajax({
      url: Routes.invoices_bank_transactions_path(),
      method: 'get',
      dataType: 'script',
      data: {
        row_id: row_id,
        ibt_id: getIbtId(row_id),
        type: getBtType(row_id),
        account: getAccountId(row_id)
      }
    })
  }

  function checkInvoiceOutstandingEqualsBankTransactionAmount($matching_inv_btn) {
    var modal_row_id = $matching_inv_btn.closest('tr').data('id');
    var transaction_total = $('#total_amount_field' + modal_row_id.split('inv')[0]).data('expectedTotal');
    var invoice_outstanding_total = externalOutstanding(modal_row_id, 'total');
    return transaction_total == invoice_outstanding_total
  }

  function getInvoiceButton(row_id) {
    return $('#invoice_button'+row_id);
  }

  function getAccountId(row_id) {
    return getBtAccountSelect(row_id).find('option:selected').val().split('#')[1];
  }

  function getIbtId(row_id) {
    return getInvoiceButton(row_id).data('ibt-id');
  }
})();