(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#ec_sales_lists_table').length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: '#ecsl_index',
      user_preference_key: 'ecsl_index_tab_id',
      tab_map: {
        'ecsl': {
          options_id: 'ecsl',
          table_id: 'ec_sales_lists_table',
          multi_sort: true
        },
        'hmrc-submissions': {
          options_id: 'hmrc_submissions',
          table_id: 'hmrc_submissions_table',
          multi_sort: true
        }
      }
    });
  }));
})();
