/**
* @fileoverview
*
* Add an event handler for jquery xhr errors.
* Displays a modal and reports any errors to sentry.
*/
(function() {
  'use strict';

  [ shouldNotReport,
    couldNotConnect,
    jsError,
    serverError,
    unauthorizedError,
    gatewayError,
    defaultError
  ].forEach(function(fn) {
    $(document).ajaxError(fn);
  });

  function shouldNotReport(e, jqXHR, ajaxSettings, thrownError) {
    if (isCancelledOrAbort(thrownError) || hasNoModalFlag(ajaxSettings)) {
      e.stopImmediatePropagation();
    }
  }

  function hasNoModalFlag(ajaxSettings) {
    return ajaxSettings._noModal === true;
  }

  function isCancelledOrAbort(s) {
    return ['canceled', 'abort'].some(function(i) {
      return (i === s);
    });
  }

  function couldNotConnect(e, jqXhr, ajaxSettings, thrownError) {
    if (statusCodeAndTextAreBlank(jqXhr, thrownError)) {
      displayCouldNotConnectModal();
      e.stopImmediatePropagation();
    }
  }

  function statusCodeAndTextAreBlank(jqXhr, thrownError) {
    return jqXhr.status === 0 && thrownError == '';
  }

  function displayCouldNotConnectModal() {
    errors.showModal(
      document.createTextNode('Could not connect to server.'),
      createNotConnectedModal());
  }

  function createNotConnectedModal() {
    return [
      $('<div>').addClass('modal-body').html(
        '<p>' +
          'Could not connect to the ' + branding.product_name + ' server. Please check your internet connection.' +
        '</p>' +
        '<p>' +
          'If your device is connected to the internet, then please try again in a few minutes. ' +
          'If the problem persists, then please contact ' +
          '<a href="mailto:' + branding.support_email +
            'subject=' + branding.product_name + '%20Connection%20Fault">' +
            branding.support_email +
          '</a>' +
        '</p>' +
      '</div>'),
      $('<div>').addClass('modal-footer').html(
        '<button class="btn-danger btn waves-effect" data-dismiss="modal" id="">' +
          '<i class="zmdi zmdi-close zmdi-hc-fw m-r-10"></i>Close' +
        '</button>' +
      '</div>')
    ];
  }

  function jsError(e, jqXHR, ajaxSettings, thrownError) {
    if (isInjectedScriptException(thrownError)) {
      sendErrorReportToSentry(thrownError, ajaxSettings.url, ajaxSettings.type, jqXHR.status);
      errors.showErrorModal(thrownError, ajaxSettings.url, thrownError.line);
      console.error(thrownError);
      e.stopImmediatePropagation();
    }
  }

  function isInjectedScriptException(e) {
    return typeof(e) === 'object';
  }

  function sendErrorReportToSentry(thrownError, ajax_url, ajax_type, status) {
    logMessage(thrownError, { extra: { ajax_url: ajax_url, ajax_type: ajax_type, status: status }})
  }

  function serverError(e, jqXHR, ajaxSettings, thrownError) {
    if (isInternalServerError(jqXHR)) {
      errors.showErrorModal(thrownError, ajaxSettings.url, thrownError.line);
      e.stopImmediatePropagation();
    }
  }

  function isInternalServerError(jqXHR) {
    return jqXHR.status == 500
  }

  function unauthorizedError(e, jqXHR, ajaxSettings, thrownError) {
    if (isUnauthorizedError(jqXHR)) {
      errors.showModal(
        document.createTextNode('Warning'),
        '<div class="modal-body">' +
          '<p>Your request was unauthorized. This may be due to timeout or logging out in another tab.' +
          '<p>Please try <a href="' + window.location.href + '">refreshing the page</a></p>' +
        '</div>'
      );
      e.stopImmediatePropagation();
    }
  }

  function isUnauthorizedError(jqXHR) {
    return jqXHR.status == 401 || jqXHR.status == 422
  }

  function gatewayError(e, jqXHR, ajaxSettings, thrownError) {
    if (isGatewayError(jqXHR)) {
      errors.showErrorModal(thrownError, ajaxSettings.url, thrownError.line);
      e.stopImmediatePropagation();
      sendErrorReportToSentry(thrownError, ajaxSettings.url, ajaxSettings.type, jqXHR.status);
    }
  }

  function isGatewayError(jqXHR) {
    return jqXHR.status == 502;
  }

  function defaultError(e, jqXHR, ajaxSettings, thrownError) {
    sendErrorReportToSentry(thrownError, ajaxSettings.url, ajaxSettings.type, jqXHR.status);
  }
})();
