(function() {
  'use strict';

  window.verifyAllEmailInputs = function(){
    $('.js-mailgun input').each(verifyEachEmailInput);
  };
  /**
   *  To work, the input field and the suggestion field must be within a div with class="js-mailgun"
   *  @see {app/helpers/forms#mail_check}
  */
  window.verifyEmailInput = function(field) {
    if (field.is('[readonly]') || field.is('[disabled]')) {
      return;
    }

    setupMailgunListener(field);
  };

  function verifyEachEmailInput() {
    verifyEmailInput($(this));
  }
  /**
   * @see {@link https://documentation.mailgun.com/api-email-validation.html|Suggestions}
   */
  function setupMailgunListener(field) {
    memoized('apiKey', $.getJSON, '/third_party/mailgun/get_public_key')
      .then(onPublicKeyResponse(field));
  }

  function onPublicKeyResponse(field) {
    return function(public_key) {
      field.mailgun_validator({
        api_key : public_key, // PUBLIC KEY
        in_progress : validationProgress,
        success : validationSuccess, // called when validator has returned
        error : validationError // called when an error reaching the validator has occured
      });
    };
  }

  function validationProgress() {
    console.log('Waiting for API');
  }

  function validationSuccess(response, e) {
    var field = $(e.currentTarget);

    if (response['did_you_mean'] !== null) {
      showSuggestionsToUser(field, field.val(), [response['did_you_mean']], [response['did_you_mean']]);
    } else if (!response['is_valid']) {
      showInvalidAddressError(field, response['address']);
    } else { // Email is valid with no recommendations
      checkMailgunForSuppression(field);
    }
  }

  function validationError(response, e) {
    var field = $(e.currentTarget);
    validateEmailsUsingMailcheck(field, field.val());
  }

  function validateEmailsUsingMailcheck(field, field_val) {
    var acceptable = [];
    var multiple   = [];

    if (field_val.indexOf('@') > -1) {
      $.each(field_val.split(', '), performMailCheck(field, acceptable, multiple));
      showSuggestionsToUser(field, field_val, acceptable, multiple);
    } else {
      showInvalidAddressError(field, field_val);
    }
  }

  function performMailCheck(field, acceptable, multiple) {
    return function(i, v) {
      field.val(v).mailcheck({
        suggested : function(element, suggestion) {
          acceptable.push(suggestion.full);
          multiple.push(suggestion.full);
        },
        empty : function() {
          acceptable.push(v);
        }
      });
    }
  }

  function showSuggestionsToUser(field, initial_field_values, acceptable_emails, multiple_suggestions) {
    updateMailSpans(field, multiple_suggestions, []);
    allowUserToUpdateEmail(field, acceptable_emails);
    field.val(initial_field_values);
  }

  function checkMailgunForSuppression(field) {
    var initial_field_values = field.val();
    var suppressed_emails = [];

    getJsonOrShowMaintenanceModal('/third_party/mailgun/get_bounces', function(response) {
      if (response) {
        $.each(initial_field_values.split(', '), function(i, v) {
          if ($.inArray(v, response) !== -1) {
            suppressed_emails.push(v);
          }
        });
        updateMailSpans(field, [], suppressed_emails);
      }
    });
  }

  function updateMailSpans(field, multiple_suggestions, suppressed_emails) {
    var closest_suggestion  = field.closest('div.js-mailgun').find('span.js-suggestion');
    var closest_suppression = field.closest('div.js-mailgun').find('span.js-suppression');

    closest_suggestion.html('');
    closest_suppression.html('');

    updateSuggestionsSpan(multiple_suggestions, closest_suggestion);
    updateSuppressionsSpan(suppressed_emails, closest_suppression);
  }

  function updateSuggestionsSpan(multiple_suggestions, closest_suggestion) {
    if (multiple_suggestions.length > 0) {
      closest_suggestion.html(
        "Did you mean <b><a class='js-suggested-email'>" + multiple_suggestions.join(', ') + '</a></b>?<br />');
    } else {
      closest_suggestion.html('');
    }
  }

  function updateSuppressionsSpan(suppressed_emails, closest_suppression) {
    if (suppressed_emails.length > 0) {
      closest_suppression.html(
        "Can\'t send to <b style='color:#428bca;'>" + suppressed_emails.join(', ') + '</b> as mailbox doesn\'t exist.');
    } else {
      closest_suppression.html('');
    }
  }

  function showInvalidAddressError(field, address) {
    var closest_suppression = field.closest('div.js-mailgun').find('span.js-suppression');
    closest_suppression.html("<b style='color:#428bca;'>" + address + '</b> is not a valid email address.');
  }

  function allowUserToUpdateEmail(field, acceptable_emails) {
    var closest_div = field.closest('div.js-mailgun');
    closest_div.find('.js-suggested-email').on('click', function() {
      field.val(acceptable_emails.join(', '));
      closest_div.find('span.js-suggestion').html('');
      field.change(); // Rerun validation
    });
  }
})();
