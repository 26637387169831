// TEMPORARY OVERRIDE - THIS FILE CAN BE REMOVED ONCE THE CHANGES COME THROUGH FROM PANDLE
(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#aged_report_table').length === 0) { return }
    initBootgridTable($('#aged_report_table'), -1, true);
    submitFormOnDateChange($('#date'));
    afterDatepickersComponentHaveBeenInitialised(function() {
      getClosestDateTimePicker($('#date')).maxDate(moment()); // Not after today
    })
  }));
})();
