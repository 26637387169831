(function() {
  'use strict';

  var vat_return_form_are_you_sure_controller;

  // FARMPLAN OVERRIDE TO ADD DOUBLE SURE
  window.initVatReturnEditForm = function() {
    checkForVATRegistrationDate();
    onceAfterDatepickersComponentHaveBeenInitialised(initVatReturnDateCheck);
    addCheckboxAreYouDoubleSure( // FARMPLAN CHANGE
      $('#final-return'),
      "<p class='m-b-15'>If you are still VAT registered, selecting 'Final return' can lead to incorrect figures being reported to HMRC</p>" +
      "<p class='m-b-15'>Are you sure you are de-registering for VAT and this is your final VAT return?</p>",
      "<p class='m-b-15'>You will NEVER be able to submit another VAT return for this business ARE YOU SURE?</p>"
    );
    initAreYouSureVatReturnsEdit();
    $('#unlock_submitted_vat_return').click(showUnlockSubmittedWarning);
  };

  window.disposeVatReturnAreYouSureController = function() {
    //vat_return_form_are_you_sure_controller.dispose();
  };

  function checkForVATRegistrationDate() {
    getJsonOrShowMaintenanceModal('/companies/needs_vat_registration_date_for_fr_discount.json',
      onVatRegistrationResponse);
  }

  function onVatRegistrationResponse(needsDate) {
    if (needsDate) {
      addHelpMessage($('#vat_return_sales_vat'),
        HELP_WARNING,
        'First year flat rate discount cannot be applied, please add the VAT registration date in settings');
    }
  }

  function initVatReturnDateCheck() {
    $('#vat_return_date_end').off('dp.change', onVatReturnEndDateChange).on('dp.change', onVatReturnEndDateChange);
    onVatReturnEndDateChange();
  }

  function initAreYouSureVatReturnsEdit() {
    vat_return_form_are_you_sure_controller = checkFormChangedOnPageUnload('#vat_return_form');
  }

  function showUnlockSubmittedWarning(event) {
    event.preventDefault()
    disposeVatReturnAreYouSureController();
    var csv_download_link = $(event.target).data('csvDownloadLink');
    confirmFormSubmission(
      "<p class='m-b-15'>This VAT return has already been submitted to HMRC. " +
      "If you unlock it, the values may change. Are you really sure you want to unlock it?</p>" +
      "<p class='m-b-15'>Changes made to a VAT return which had been submitted to HMRC should be reported by " +
      "<a href='https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-correct-errors-on-your-vat-return' target='_blank'>" +
      "writing to the error corrections team</a>.</p>" +
      "<p class='m-b-15'>Alternatively, if the difference in liability is below £10,000 then you can adjust the entries on your next VAT return. " +
      "You should lock (but not submit) the next return in Farmplan Cloud Business and submit the return directly on HMRC's portal with the adjustments added.</p>" +
      "<p class='m-b-15'>We recommend saving a copy of your submitted VAT calculations before you unlock the VAT return. Click <a href='" + csv_download_link +
      "'>here</a> to export.</p>" +
      "<p>Are you sure you want to continue?</p>",
      $('#vat_return_form')
    )
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#vat_return_form').length === 0) { return }
    initVatReturnEditForm();
  }));
})();
