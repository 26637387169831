CreditableInvoiceModalSubmitBehaviour = {
  "submitInvoicesToConfirmedInvoicesAndRenderInvoices": function(invoice_ids, row_id) {
    var is_batch_form = Boolean($('#batch_credit_note_form').length > 0);
    if (is_batch_form) {
      var $batchModal = $(BatchInvoiceModalBehaviour.jQuerySelector(row_id));
      var $invoiceCards = $batchModal.find('.invoice-line-item-container');
      var credit_note_id = $('#batch_credit_note_form').data('credit-note-id');
    } else {
      var $invoiceCards = $('.invoice-line-item-container');
      var credit_note_id = $('.non_batch_credit_notes_form').data('credit-note-id');
    }
    var striped_invoice_ids = removeInvoiceIdsAlreadyCreditedOnElement(invoice_ids, $invoiceCards);

    if (striped_invoice_ids.length > 0) {
      callEndpointToGetInvoiceDetails(invoice_ids, striped_invoice_ids, row_id, is_batch_form, credit_note_id)
    } else {
      onSuccess(row_id, is_batch_form, invoice_ids);
    }
  }
}

function callEndpointToGetInvoiceDetails(invoice_ids, invoice_ids_to_get, row_id, is_batch_form, credit_note_id) {
  $.get({
    url: Routes.invoices_confirmed_path({ format: "js" }),
    data: {
      invoice_ids: invoice_ids_to_get,
      row_id: row_id,
      row_index_offset: $('.line_item_row').length,
      is_batch_form: is_batch_form,
      credit_note_id: credit_note_id
    },
    success: function(response) {
      onSuccess(row_id, is_batch_form, invoice_ids);
    }
  })
}

function removeInvoiceIdsAlreadyCreditedOnElement(invoice_ids, $invoiceCards) {
  var ids = []
  var invoice_card_invoice_ids = getInvoiceIdsFromCards($invoiceCards);
  invoice_ids.forEach(function(invoice_id) {
    if(!invoice_card_invoice_ids.includes(invoice_id)) { ids.push(invoice_id); };
  })
  return ids;
}

function getInvoiceIdsFromCards($invoice_cards) {
  var inv_ids = [];
  $invoice_cards.each(function() { inv_ids.push($(this).data('invoice-id')); })
  return inv_ids;
}

function onSuccess(row_id, is_batch_form, invoice_ids) {
  $("#modal").modal('hide');
  $('#invoice-credit-selection-confirmation').attr('disabled', false);
  if(is_batch_form) {
    var $batchModal = $(BatchInvoiceModalBehaviour.jQuerySelector(row_id));
    setTimeout(function() { $batchModal.modal(); }, 300);
    $batchModal.find('.invoice-line-item-container').each(function() {
      resetInvoiceCard($(this));
      removeInvoiceCard($(this), invoice_ids);
    })
  } else {
    CreditedInvoiceDataHandler.creditedInvoices.setHash()
    $('.invoice-line-item-container').each(function() {
      removeInvoiceCard($(this), invoice_ids);
    })
  }
}

function resetInvoiceCard($invoiceCard) {
  $invoiceCard.show().removeClass('flagged_for_deletion');
  $invoiceCard.find('.line_item_row').show().removeClass('flagged_for_deletion');
  $invoiceCard.find('.invoice-validation').change();
}

function removeInvoiceCard($invoiceCard, invoice_ids) {
  if (!invoice_ids.includes($invoiceCard.data('invoice-id'))) {
    $invoiceCard.find('.invoice-card-delete').click();
  }
}
