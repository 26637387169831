FancyEllipsisComponent = {
  'render': function(contentBeforeIcon, tooltipContent){
    return '<div class="fancy-ellipsis">' + 
      FancyEllipsisTextComponent.render(contentBeforeIcon) + 
      FancyEllipsisIconComponent.render() + 
      FancyEllipsisTooltipComponent.render(tooltipContent) +
      '</div>'
    ;
  },
  'setupOn': function(elementId){
    FancyEllipsisTooltipComponent.setupOn(
      elementId, 
      FancyEllipsisComponentBehaviour.afterTooltipCloseButtonClicked
    );

    FancyEllipsisIconComponent.setupOn(
      elementId, 
      FancyEllipsisComponentBehaviour.shouldIconDeactivateAfterMouseout, 
      FancyEllipsisComponentBehaviour.onIconClick
    );    
  },
  'renderAsPlaintextTooltipSection': function(sectionHeading, sectionContent){
    return FancyEllipsisTooltipComponent.renderAsPlaintextTooltipSection(sectionHeading, sectionContent);
  },
  'renderAsDictionaryTooltipSection': function(sectionHeading, dictionaryKeyValueArray){
    return FancyEllipsisTooltipComponent.renderAsDictionaryTooltipSection(sectionHeading, dictionaryKeyValueArray);
  },
  'getVisibleText': function(element){
    return $(element).find(FancyEllipsisTextComponent.jQuerySelector())[0];
  },
  'removeIconAndTooltip': function(element){
    $(element)
      .find(FancyEllipsisIconComponent.jQuerySelector() + ', ' + FancyEllipsisTooltipComponent.jQuerySelector())
      .remove();
  }
}