(function() {
  'use strict';

  window.dom = window.dom || {};

  dom.createButton = function(colour, icon, label) {
    var button = $('<button>')
      .addClass('btn-' + colour + ' btn waves-effect')
      .append(createButtonIcon(icon), label);

    return button;
  };

  function createButtonIcon(icon) {
    return $('<i>').addClass('zmdi zmdi-' + icon + ' zmdi-hc-fw m-r-10')
  }
})();
