(function() {
  'use strict';

  function initBulkActionForm() {
    toggleFormSubmitAction('#ft_bulk_action_form', '#bulk_edit_fts_button');
    toggleFormSubmitAction('#ft_bulk_action_form', '#bulk_destroy_fts_button');
  }

  function _getFtIndexTable() {
    return $('#financial_transactions_table, #ftbe_job_table, #ftbd_job_table');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if (_getFtIndexTable().length === 0) { return }
    initTransactionsTable(_getFtIndexTable());
    initBulkActionForm();
    if (_getFtIndexTable().attr('data-transaction-filters-present') == "true") {
      $("#advanced-search").addClass("in");
      $("#reset-button").removeClass('disabled');
    }
  }));
})();