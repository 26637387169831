// FARMPLAN: added to prevent totals from being affected by tax code changes
// (https://yozudev.atlassian.net/browse/FP-1089)
import { LineItemRowBaseController } from './line_item_row_base_controller'
import { isNull } from './helpers/_calculations'

export default class extends LineItemRowBaseController {
  splitCloneFirstRowInputFields() {
    return ['descriptionTarget']
  }

  updateTaxTotalFromNet() {
    // Overrides the base controller due to imported bank transactions
    // having a slightly distinct behaviour from other transaction rows:
    // in non split transactions, the total amount is a fixed value
    // and should **never** be recalculated when other fields change.
    if (this.isElementFromSplitRow()) {
      // If is a split row behaves like all the other transactions
      super.updateTaxTotalFromNet()
    } else if (!isNull(this.getNet())) {
      // If is not a split row, do not recalculate the total and update the net and tax instead
      this.updateNetTaxFromTotal()
    }
  }

  updateNetAndPriceFromTax() {
    this.setNet(this.getTotal() - this.getTax())
    if (isNull(this.getPrice())) return
    this.setPrice(this.getNet() / this.getQuantity())
  }

  checkForPriceGreaterThanTotal() {
    const net = this.getNet()

    if (this.getPrice() > net + this.getTax()) {
      this.setPrice(net / this.getQuantity())
    }
  }

  // FARMPLAN: Overrided to check for price greater than imported total
  updateNetAndQuantityFromPrice() {
    const quantity = this.getQuantity()
    const net = this.getNet()

    if (!net && !quantity) {
      return
    }

    this.checkForPriceGreaterThanTotal()
    this.calculateQuantity()
    this.calculateNet()
  }
}
