var comparisonSourceDataAttributeName = 'data-number-comparison-source';
var comparisonTargetDataAttributeName = 'data-number-comparison-target';
var comparisonSetIdDataAttributeName = 'data-number-comparison-set-id';

var comparisonSourceElementSelector = '[' + comparisonSourceDataAttributeName + '="true"]';
var comparisonTargetElementSelector = '[' + comparisonTargetDataAttributeName + '="true"]';

NumberComparison = {
  "addNumberComparisonEventDelegation": function(element, event, comparisonSourceElementSelector, comparisonReactionFunction){
    EventDelegation.create(element, event, comparisonSourceElementSelector, function(event) { compareNumbers(event, comparisonReactionFunction) });
  },
  "setupElementAsAComparisonSource": function(element, comparisonSetId) {
    $(element).attr(comparisonSourceDataAttributeName, 'true');
    addNumberComparisonSetIdToElement(element, comparisonSetId);
  },
  "setupElementAsAComparisonTarget": function(element, comparisonSetId) {
    $(element).attr(comparisonTargetDataAttributeName, 'true');
    addNumberComparisonSetIdToElement(element, comparisonSetId);
  }
}

function addNumberComparisonSetIdToElement(element, numberComparisonSetId){
  $(element).attr(comparisonSetIdDataAttributeName, numberComparisonSetId);
}

function compareNumbers(event, comparisonReactionFunction){
  var source = event.target;
  var sourceSetId = source.dataset.numberComparisonSetId;
  var comparisonTargetsInSourcesSet = '[' + comparisonSetIdDataAttributeName + '="' + sourceSetId + '"]' + comparisonTargetElementSelector;

  $(comparisonTargetsInSourcesSet).each(function(){
    var target = this;
    var sourceValue = source.value;
    var targetValue = target.textContent;

    if(!sourceValue) sourceValue = "0";
    var sourceNumber = Math.round(parseFloat(sourceValue)*100)/100;
    var targetNumber = Math.round(parseFloat(targetValue)*100)/100;

    var comparisonResult = 0;
    if (sourceNumber > targetNumber) comparisonResult = 1;
    else if(sourceNumber < targetNumber) comparisonResult = -1;

    var result = new NumberComparisonResultJson(source, target, sourceValue, targetValue, comparisonResult);
    comparisonReactionFunction(result);
  })
}