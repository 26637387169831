(function() {
  'use strict';

  window.initPlaidFeedConnectionButton = function(){
    $('#plaid_feed_connection_button').on('click', function(e) {
      callPlaidConnectCredentialsEndpoint(initConnectHandler, relevantPlaidCountries());
    });
  }

  function initConnectHandler(response) {
    showSpinnerInModal();
    initHandlerAndOpen(response, relevantPlaidCountries())
  }

  function initConnectHandlerForAllCountries(response) {
    showSpinnerInModal();
    initHandlerAndOpen(response, plaid_supported_country_codes())
  }

  function initHandlerAndOpen(response, country_codes) {
    var handler_config = connectHanderConfig(response,  country_codes);
    var handler = plaid_feed_setup.createPlaidHandler(response, handler_config)
    handler.open();
  }

  function connectHanderConfig(response, country_codes) {
    return {
      token: response.link_token,
      onSuccess: onConnectSuccess,
      onExit: onConnectExit,
      onEvent: onConnectEvent
    }
  }

  function onConnectSuccess(public_token, metadata) {
    plaid_feed_setup.logPlaidRequest(metadata, 'Connect - Success');
    var accounts = formatAccounts(metadata.accounts);
    postPlaidFeedCredential(public_token, metadata, accounts);
  }

  function formatAccounts(metadata_accounts) {
    var accounts = [];
    metadata_accounts.forEach(function(account) {
      accounts.push({ name: account.name, mask: account.mask })
    });
    return accounts;
  }

  function postPlaidFeedCredential(public_token, metadata, accounts) {
    $.post(Routes.company_plaid_feed_credentials_path({ format: 'js' }), {
      public_token: public_token,
      bank_account_id: $('#bank_account_id').val(),
      institution_id: metadata.institution.institution_id,
      accounts: JSON.stringify(accounts)
    });
  }

  function onConnectEvent(eventName, metadata) {
    plaid_feed_setup.logPlaidRequest(metadata, "Event: "+eventName);
    plaid_feed_setup.onEventHandler(eventName, metadata);
  }

  function onConnectExit(err, metadata) {
    plaid_feed_setup.logPlaidRequest(metadata, "Connect - Exit: "+metadata.status);
    if (err != null) { plaid_feed_setup.logRavenMessage(err, metadata); };
    checkUserFoundInstitution(metadata.status);
    removePlaidSetupSpinner();
  }

  function relevantPlaidCountries(){
    var current_company_locode = companySettings.country.un_locode;
    if ( typeof(current_company_locode) !== 'undefined' && plaid_supported_country_codes().includes(current_company_locode) ){
      return [current_company_locode];
    } else {
      return plaid_supported_country_codes();
    }
  }

  function showSpinnerInModal(){
    $('#plaid_feed_connection_button').closest('.modal-body-and-footer').addClass('hidden');
    var preloader = createPreloader();
    appendPreloader($('#plaid_feed_connection_button').closest('.modal-content'), preloader);
    $('.preloader-container').find('.explain').remove();
    $('.preloader-container').find('.pl-lg').after("<p class='explain left-65'>Please wait while we retrieve your data from remote feeds.</p>");
  }

  function checkUserFoundInstitution(status) {
    switch(status) {
      case 'institution_not_found':
        showInstitutionNotFoundAlert(institutionNotFoundAlert(), 'Use File Imports', institutionNotFoundConfirm);
        break;
      case 'requires_oauth':
        showInstitutionNotFoundAlert(requiresOauthAlert(), 'Try again', requiresOauthConfirm);
        break;
      default:
        break;
    }
  }

  function showInstitutionNotFoundAlert(alertText, confirmButtonText, confirmCallback) {
    sweetAlert({
      title: "Couldn't find your bank?",
      text: alertText,
      html: true,
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Close',
      customClass: 'sweetalert-pandle-theme'
    }, function(isConfirmed) {
      if (isConfirmed) { confirmCallback(); }
    });
    initListenerForAllCountriesHandler();
  };
  
  function institutionNotFoundAlert() {
    return "<p class='m-t-10'>New banks are being added regularly, so direct feeds for your bank should be available in the future.</p>" +
      "<p class='m-t-10'>Do you have a bank account in another country?<br>Click <a id='allInstitutionsLink' href='#'>here</a> to search international banks</p>" +
      "<p class='m-t-10'>Otherwise, please switch entry method to 'File Import' to import your transactions.</p>";
  }

  function institutionNotFoundConfirm() {
    $('#bank_account_entry_method').val('File Import').change().trigger('chosen:updated');
  }

  function requiresOauthAlert() {
    return "<p class='m-t-10'>You can use Plaid's search bar to find your bank!</p>" +
      "<p class='m-t-10'>Their search bar is at the top of the institution list. Start typing your bank name to find your institution. </p>" +
      "<p class='m-t-10'>Otherwise, you can switch entry method to 'File Import' to import your transactions.</p>";
  }
  
  function requiresOauthConfirm() {
    $('#plaid_feed_connection_button').click();
  }

  function initListenerForAllCountriesHandler() {
    $('#allInstitutionsLink').click(function(e) {
      swal.close();
      e.preventDefault();
      callPlaidConnectCredentialsEndpoint(initConnectHandlerForAllCountries, plaid_supported_country_codes());
    });
  }
})();
