(function() {
  'use strict';

  window.initFixedElement = function(element, container) {
    _determineElementPosition(element, container);
    _transitionFixedElement(element, container);
  };

  window.initFixedSidebar = function() {
    var sidebar = $('#chat');
    var top_height = 88;

    var top = ($(this).scrollTop() > top_height) ? 0 : top_height - $(this).scrollTop();
    sidebar.css({ 'top': top });
    _transitionFixedSidebar(top_height);
  };

  function _determineElementPosition(element, container) {
    var isPositionFixed = ($(element).css('position') === 'fixed');

    if ($(this).scrollTop() > 200 && !isPositionFixed) {
      _fixedElementCss(element, 'fixed', '15px', $(container).width());
    }

    if ($(this).scrollTop() < 200 && isPositionFixed) {
      _fixedElementCss(element, 'relative', '0px', $(container).width());
    }
  }

  function _fixedElementCss(element, position, top, width) {
    $(element).css({ 'position': position, 'top': top, 'width': width });
  }

  function _transitionFixedElement(element, container) {
    $(window).scroll(function(e) {
      _determineElementPosition(element, container);
    });
  }

  function _transitionFixedSidebar(top_height) {
    var sidebar = $('#chat');
    $(window).scroll(function(e) {
      var top = ($(this).scrollTop() > top_height) ? 0 : top_height - $(this).scrollTop();
      sidebar.css({ 'top': top, 'transition': 'initial' });
    });
  }
})();
