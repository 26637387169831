import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'name', 'currencyId']

  onFormSubmit(event) {
    event.preventDefault()
    const warningMessage = this.evaluateForm()
    if (warningMessage === '') {
      this.form.submit()
    } else {
      if (this.warningOptions.wrong_currency) {
        return sweetAlert({
          title: 'Warning!',
          text: warningMessage,
          html: true,
          type: 'warning',
          allowEscapeKey: false,
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Edit Settings',
          customClass: 'sweetalert-scary-warning'
        }, isConfirmed => this.sweetAlertTriggered(isConfirmed))
      }

      window.confirmFormSubmission(warningMessage, this.form, 'sweetalert-scary-warning')
    }
  }

  sweetAlertTriggered(isConfirmed) {
    if (isConfirmed) { return this.form.submit() }

    window.location.href = `companies/${this.companyId}/edit`
  }

  evaluateForm() {
    let result = ''
    if (this.warningOptions.inactive_field_change) {
      if (this.form.find('.inactive_checkbox').is(':checked')) {
        result += "<p class='m-b-15'>Any bank feed connection to this account will be removed</p>"
      }
    }
    if (this.warningOptions.wrong_currency) { // TODO: Will be removed in PN-3653
      if (this.selectedCurrencyCouldBeWrong()) {
        result += "<p class='m-b-15'>The currency mentioned in the description doesn’t seem to match the base currency of your company. Please edit your company settings or chose to proceed with the current description.</p>"
      }
    }
    return result
  }

  selectedCurrencyCouldBeWrong() {
    for (const currencyCode in this.currencyKeywords) {
      const keywords = this.currencyKeywords[currencyCode]
      for (const k in keywords) {
        for (const w in this.wordsInName) {
          if (this.wordsInName[w].toLowerCase() === keywords[k].toLowerCase()) {
            if (this.selectedCurrencyCode !== currencyCode) { return true }
          }
        }
      }
    }

    return false
  }

  get form() {
    return $(this.formTarget)
  }

  get warningOptions() {
    return JSON.parse(this.data.get('warningOptions'))
  }

  get companyId() {
    return this.data.get('companyId')
  }

  get currencyKeywords() {
    return JSON.parse(this.data.get('currencyKeywords'))
  }

  get wordsInName() {
    return this.nameTarget.value.toLowerCase().split(' ')
  }

  get selectedCurrencyCode() {
    return this.currencyCodes[this.currencyIdTarget.value]
  }

  get currencyCodes() {
    return JSON.parse(this.data.get('currencyCodes'))
  }
}
