import consumer from './consumer'

window.openSpinnerConnection = function () {
  consumer.subscriptions.create('DemoCompanySpinnerChannel', {
    connected: function () {
      // eslint-disable-next-line no-undef
      displayExplanatoryPageLoader(
        'Your test company is being created',
        'left-65',
        function () { }
      )
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (_data) {
      $('.page-loader').fadeOut()
      window.initPartnerAccountantEditForm()
      consumer.subscriptions.remove(this)
    }
  })
}
