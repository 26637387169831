(function() {
  'use strict';

  window.bootstrap = {
    show: function($elem) {
      return $elem.removeClass('hidden');
    },
    hide: function($elem) {
      return $elem.addClass('hidden');
    }
  }
})();
