import CONVERTER from './_bootgrid_converters.js'

window.initPnLTables = function() {
  PnlTableSetup($('#sales_table'));
  PnlTableSetup($('#direct_costs_table'));
  PnlTableSetup($('#expenses_table'));
}

function PnlTableSetup(table) {
  table.bootgrid({
    css: {
      icon: 'zmdi icon',
      iconColumns: 'zmdi-view-module',
      iconDown: 'zmdi-caret-down',
      iconRefresh: 'zmdi-refresh',
      iconUp: 'zmdi-caret-up'
    },
    columnSelection: false,
    navigation: 0,
    rowCount: -1,
    converters: {
      reports_numeric_ignore_zero: CONVERTER.reports_numeric_ignore_zero,
      order_by_link_text: CONVERTER.order_by_link_text
    },
    labels: { noResults: ' ' }
  });
}