(function() {
  'use strict';

  var selectorMap = {};

  window.$memoize = function(selector, opt_ancestor) {
    if (opt_ancestor) {
      return memoizeDescendantElement(selector, opt_ancestor)
    } else {
      return memoizeElement(selector)
    }
  };

  window.$rememoize = function(selector, opt_ancestor) {
    if (opt_ancestor) {
      return storeDescendant(selector, opt_ancestor)
    } else {
      return store(selector, $(selector))
    }
  };

  function memoizeDescendantElement(selector, ancestor) {
    return storeIfNeeded(
      buildDescendantSelector(selector),
      findDescendantFn(selector, ancestor));
  }

  function buildDescendantSelector(selector, ancestor) {
    return ancestor + ' ' + selector
  }

  function findDescendantFn(selector, ancestor) {
    return function() {
      return $(ancestor).find(selector)
    }
  }

  function storeIfNeeded(selector, fn) {
    var $memoized = selectorMap[ selector ];

    if (typeof $memoized == 'undefined') {
      return store(selector, fn())
    } else {
      return $memoized;
    }
  }

  function store(selector, $elem) {
    return selectorMap[ selector ] = $elem;
  }

  function memoizeElement(selector) {
    return storeIfNeeded(selector, findFn(selector))
  }

  function findFn(selector) {
    return function() {
      return $(selector)
    }
  }

  function storeDescendant(descendant, ancestor) {
    return store(
      buildDescendantSelector(descendant, ancestor),
      findDescendantFn(descendant, ancestor)())
  }
})();
