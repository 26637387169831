(function() {
  'use strict';

  window.instrumentation = window.instrumentation || {};

  instrumentation.logDuration = function(fn, scope, args) {
    var t0  = performance.now();
    var val = fn.apply(scope, args);
    console.info('Duration for function named ' + fn.name + ': ' + (performance.now() - t0).toFixed(3) + ' ms');
    return val;
  };
})();
