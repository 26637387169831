(function() {
  'use strict';

  /**
   * @namespace
   * @module
   */
  var has_message_event_listener = false;

  function getUpdateUrl($connection_button) {
    return $connection_button.data('updatedUrl');
  }

  function onConnectionClick($connection_button) {
    return function() {
      addMessageEventListener(onMessage);
      addModalHiddenEventListener(updateForm(getUpdateUrl($connection_button), getFormHeaderContent(), getFormData()));
    }
  }

  function addMessageEventListener(fn) {
    if (!has_message_event_listener) {
      window.addEventListener('message', fn);
      has_message_event_listener = true;
    }
  }

  function onMessage() {
    modals.getModal().modal('hide');
  }

  function getFormHeaderContent() {
    return modals.getModalHeader().html();
  }

  function getFormData() {
    return setMethodToPost(modals.getModalBodyAndFooter().find('form').serializeArray());
  }

  function setMethodToPost(formDataArray) {
    formDataArray[ formDataArray.findIndex(isMethod) ] = {
      'name' : '_method',
      'value' : 'post'
    };

    return formDataArray;
  }

  function isMethod(ob) {
    return ob.name == '_method';
  }

  function updateForm(update_url, form_header, form_data) {
    return function() {
      modals.getModalHeader().html('Updating form...');
      sendBankFeedOptionsRequest(update_url, form_header, form_data, displayPreloader());
    }
  }

  function displayPreloader() {
    var preloader = createPreloader();
    replaceContentsWithPreloader(modals.getModalBodyAndFooter(), preloader);
    return preloader;
  }

  function sendBankFeedOptionsRequest(update_url, form_header, form_data, preloader) {
    $.ajax({
      method : 'post',
      url : update_url,
      data : form_data
    }).then(onBankFeedOptionsResponse(form_header, preloader));
  }

  function onBankFeedOptionsResponse(form_header, preloader) {
    return function(form_content) {
      preloader.dispose();
      modals.getModalHeader().html(form_header);
      modals.getModalBodyAndFooter().html(form_content);
      formatForms();
      checkCompanyTypeForBankAccount();
      addEntryMethodListener();
      toggleBankFeedOptions();
      stripeButtonListener();
    }
  }

  function addModalHiddenEventListener(fn) {
    modals.getLargeModal().one('hidden.bs.modal', fn);
  }
})();
