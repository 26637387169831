(function() {
  'use strict';

  window.autofillOfficerInformation = function(response) {
    var companyType = $("#company_typer_id").find('option:selected').text()
    if ($('.directors:visible').length > 0 && companyType == "Limited Company") {
      _autofillPartnersOrDirector('.js-director-name', '.directors', response);
    } else if ($('.partners:visible').length > 0 && companyType == "Limited Liability Partnership (LLP)") {
      _autofillPartnersOrDirector('.js-partner-name', '.partners', response);
    }
  }

  window.removeOfficers = function(parent_class, new_number_of_officers){
    var remaining_officers = $(parent_class + ' .fields').length
    while (remaining_officers > new_number_of_officers){
      $(parent_class + ' .fields')[0].remove();
      remaining_officers--;
    }
  }

  function _autofillPartnersOrDirector(field_class, parent_class, response) {
    if (response && response['officers']['active_officers'].length > 0) {
      var officers = response['officers']['active_officers']
      _addNewOfficers(officers, field_class, parent_class)
      if ($(field_class + ':first').val().length === 0) {
        $(field_class + ':first').closest('.fields').remove();
      }
      var new_number_of_officers = officers.length;
      removeOfficers(parent_class, new_number_of_officers);
    }
  }

  function _addNewOfficers(officers, field_class, parent_class) {
    $.each(officers, function(index, officer) {
      var $fields = $(field_class);
      for (var officer_index = 0; officer_index < $fields.length; ++officer_index) {
        var existing_officer = $fields[officer_index].value;
        if (officer === existing_officer) { return; }
      }
      var $lastOfficerElement = $(field_class + ':last')
      if ($lastOfficerElement.length > 0 && $lastOfficerElement.val().length > 0) {
        $(parent_class + ' a.add_nested_fields').click();
      }
      $(field_class + ':last').val(officer);
    });
  }
})();
