(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#wages_journal_entry_form').length === 0) { return }
    var form_id = '#wages_journal_entry_form';
    var input_classes = '#journal_entry_date, #journal_entry_description, #journal_entry_net_wages,' +
      ' #journal_entry_tax, #journal_entry_employee_ni, #journal_entry_employer_ni';
    checkFormChangedOnPageUnload(form_id, input_classes);

    errors.tryOn($('.attachment_button'), 'click', openNonBatchUploadsModal);
  }));
})();
