var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

window.GoogleAnalytics = function () {
  function GoogleAnalytics() {
    _classCallCheck(this, GoogleAnalytics);
  }

  _createClass(GoogleAnalytics, null, [{
    key: 'load',
    value: function load() {
      var firstScript, ga;
      // Google Analytics depends on a global _gaq array. window is the global scope.
      window._gaq = [];
      window._gaq.push(['_setAccount', GoogleAnalytics.analyticsId()]);
      // Create a script element and insert it in the DOM
      ga = document.createElement('script');
      ga.type = 'text/javascript';
      ga.async = true;
      ga.src = ('https:' === document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
      firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(ga, firstScript);
      // If Turbo is supported, set up a callback to track pageviews on page:change.
      // If it isn't supported, just track the pageview now.
      if (Turbo) {
        return document.addEventListener('page:change', function () {
          return GoogleAnalytics.trackPageview();
        }, true);
      } else {
        return GoogleAnalytics.trackPageview();
      }
    }
  }, {
    key: 'trackPageview',
    value: function trackPageview(url) {
      if (!GoogleAnalytics.isLocalRequest()) {
        if (url) {
          window._gaq.push(['_trackPageview', url]);
        } else {
          window._gaq.push(['_trackPageview']);
        }
        return window._gaq.push(['_trackPageLoadTime']);
      }
    }
  }, {
    key: 'isLocalRequest',
    value: function isLocalRequest() {
      return GoogleAnalytics.documentDomainIncludes('local');
    }
  }, {
    key: 'documentDomainIncludes',
    value: function documentDomainIncludes(str) {
      return document.domain.indexOf(str) !== -1;
    }
  }, {
    key: 'analyticsId',
    value: function analyticsId() {
      // your google analytics ID(s) here...
      return 'UA-18216917-5';
    }
  }]);

  return GoogleAnalytics;
}();

GoogleAnalytics.load();
