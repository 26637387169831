(function() {
  'use strict';

  var vat_return_form_are_you_sure_controller;

  window.initGenericVatReportEditForm = function() {
    onceAfterDatepickersComponentHaveBeenInitialised(initVatReportDateCheck);
    addCheckboxAreYouSure(
      $('#final-return'),
      "<p class='m-b-15'>" + I18n.t('generic_vat_reports.edit.final_return_are_you_sure_p1') + "</p>" +
      "<p class='m-b-15'>" + I18n.t('generic_vat_reports.edit.final_return_are_you_sure_p2') + "</p>"
    );
    initAreYouSure();
    initTogglingFormTargetByButtonClicked();
  }

  function initVatReportDateCheck() {
    $('#vat_return_date_end').off('dp.change', onVatReturnEndDateChange).on('dp.change', onVatReturnEndDateChange);
    onVatReturnEndDateChange();
  }

  function initTogglingFormTargetByButtonClicked(){
    toggleFormSubmitAction('#generic_vat_report_form', '#unlock_button', vat_return_form_are_you_sure_controller);
    toggleFormSubmitAction('#generic_vat_report_form', '#calculate_button', vat_return_form_are_you_sure_controller);
    toggleFormSubmitAction('#generic_vat_report_form', '#lock_button', vat_return_form_are_you_sure_controller);
  }

  function initAreYouSure() {
    vat_return_form_are_you_sure_controller = checkFormChangedOnPageUnload('#generic_vat_report_form');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#generic_vat_report_form').length === 0) { return }
    initGenericVatReportEditForm();
  }));
})();
