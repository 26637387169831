(function() {
  'use strict';

  /**
  * @fileoverview Methods shared between Batch and Non-batch invoices
  */
  window.SALES_INVOICE = 'SalesInvoice';
  window.PURCHASE_INVOICE = 'PurchaseInvoice';
  window.SALES_CREDIT_NOTE = 'SalesCreditNote';
  window.PURCHASE_CREDIT_NOTE = 'PurchaseCreditNote';

  window.getCustomerSupplierSelect = function(row) {
    return $(row).find('.customer_supplier_select');
  };

  window.getTaxRateFromTaxCodeSelect = function($select){
    return parseFloat($select.find('option:selected').data('tax-rate'));
  }

  window.populateInvoiceRefs = function(row, customers_or_suppliers) {
    var customer_supplier_id = getCustomerSupplierSelect(row).val();
    if (customer_supplier_id) {
      getJsonOrShowMaintenanceModal('/' + customers_or_suppliers + '/' + customer_supplier_id +
        '/get_outstanding_invoice_refs.json?exclude_id=' + getEditingInvoiceId(), function(data) {
        $(row).find('#invoice_ref_selector').selectPopulator(data);
      });
    } else {
      $(row).find('#invoice_ref_selector').selectPopulator([]);
    }
  };

  window.buttonSpinnerOnClick = function(id){
    $('#print-button-'+id).html('<i class="zmdi zmdi-rotate-right zmdi-hc-spin zmdi-hc-fw"></i>')
  };

  window.bulkButtonSpinnerOnClick = function(id){
    $('.bulk-print').html('<i class="zmdi zmdi-rotate-right zmdi-hc-spin zmdi-hc-fw"></i> Printing')
  };

  window.getCustomerSupplierUrl = function(row, customerSupplierId) {
    var customerSupplierType = getCustomerSupplierType(row).toLowerCase();
    if ( customerSupplierType.length === 0) { return; }
    return '/' + customerSupplierType + 's/' + customerSupplierId + '/get_' + customerSupplierType + '.json';
  };

  window.preprocessSplitModalsForSubmit = function() {
    // If a row has data in the line items modal, but is not split (ie we've edited from split to not split),
    // flag the line items as deleted
    $.each(
      $('.is_split_field[value="false"]').closest('tr.invoice_row').find('.splits-modal').find('.flagged_for_deletion'),
      function(i, line_item_deleted_flag) {
        $(line_item_deleted_flag).val(true);
      }
    )
  }

  function getCustomerSupplierType(row) {
    return $(row).find('.customer_supplier_type').val();
  }

  function getEditingInvoiceId() {
    return $('.edit_invoice').data('invoice-id');
  }

  window.showOutOfStockPopover = function(arg){
    if (arg.stock_item_field.val() == "" || arg.type_field.val() == "PurchaseInvoice" || !window.stockItemInfoExists()) { return; }
    if (!selectedStockItemInfo){ return; }
    var stockInfo = window.parseStockItemInfo();
    var selectedStockItemInfo = stockInfo[arg.stock_item_field.val()];
    var $popover = arg.stock_item_field.closest('#out-of-stock-popover')
    destroyPopover($popover);
    if (selectedStockItemInfo['quantity'] == 0 && selectedStockItemInfo['is_tracked']){
      var warning = selectedStockItemInfo['name_plural'] + ' are currently out of stock.';
      hideAllPopovers();
      showPopoverWithContent($popover, warning);
    }
  }

  window.showQuantityWarningPopover = function(arg){
    if (arg.stock_item_field.val() == "" || arg.type_field.val() == "PurchaseInvoice" || !window.stockItemInfoExists()) { return; }
    if (!selectedStockItemInfo) { return; }
    var stockInfo = window.parseStockItemInfo();
    var selectedStockItemInfo = stockInfo[arg.stock_item_field.val()];
    var $popover = arg.quantity_field.closest('#stock-quantity-popover')
    destroyPopover($popover);
    if (selectedStockItemInfo['quantity'] < arg.quantity_field.val()){
      if (!selectedStockItemInfo["is_tracked"]) { return; }
      var warning = 'You do not have enough ' + selectedStockItemInfo['name_plural'] + ' in stock. You have ' + selectedStockItemInfo['quantity'] + ' available to sell.'
      hideAllPopovers();
      showPopoverWithContent($popover, warning);
    }
  }

  /*
    @param {Object} arg
    @param {jQuery} arg.price_field
    @param {jQuery} arg.quantity_field
    @param {jQuery} arg.net_field
  */

  window.setNetFromQuantityAndPrice = function(arg){
    var price_amount = getValAsFloat(arg.price_field);
    var quantity = getValAsFloat(arg.quantity_field);
    if (!price_amount || !quantity) return;
    var net_amount = price_amount*quantity
    arg.net_field.val(net_amount.toFixed(2));
    arg.price_field.val(price_amount.toFixed(2));
  }

  /*
    @param {Object} arg
    @param {jQuery} arg.category_field
    @param {jQuery} arg.stock_item_field
    @param {jQuery} arg.type_field
  */
  window.setNominalsFromStockAndType = function(arg){
    if (arg.type_field.val() == 'PurchaseInvoice'){
      _populatePurchaseNominals(arg.category_field, arg.stock_item_field)
    }else if(arg.type_field.val() == 'SalesInvoice'){
      _populateSalesNominals(arg.category_field, arg.stock_item_field)
    }
  }

  function _populatePurchaseNominals(category_field, stock_item_field) {
    var is_tracked = (stock_item_field.length > 0) ? stock_item_field.find('option:selected').data('isTracked') : undefined
    _populateNominalsOfType('Purchases', category_field, is_tracked);
  }

  function _populateSalesNominals(category_field) {
    _populateNominalsOfType('Sales', category_field);
  }

  function _populateNominalsOfType(type, category_field, is_tracked) {
    var url = "/companies/get_" + type.toLowerCase() + "_nominals.json"
    if (is_tracked){
      url += "?"
      url += $.param({ 'is_tracked': true })
    }

    getJsonOrShowMaintenanceModal(url, function (data) {
      _setAllowedNominalTypes(category_field, type);
      category_field.selectPopulator(data);
    });
  }

  function _setAllowedNominalTypes(category_field, type) {
    switch(type) {
      case "Sales":
        $(category_field).data("allowed-nominal-types", "Revenue");
        break;
      case "Purchases":
        $(category_field).data("allowed-nominal-types","Asset-Expense");
        break;
    }
    setLinkWithAllowedNominalTypes(category_field);
  }
})();
