(function() {
  'use strict';

  window.checkCompaniesHouseOnChange = function() {
    initCompaniesHouseSearch($('#company_company_name'), companyNameChanged, resetChanges)
    initChosenDropdown($('#company_company_name'), $('#companies_house_search'));
    $('#company_company_reg_no').change(companyRegChanged);
    $('#company_date_incorporated').on('dp.change', checkDateIncorporatedAgainstCompaniesHouse);
    onceAfterDatepickersComponentHaveBeenInitialised(addDateTimePickerEventListeners);
  };

  function companyNameChanged() {
    clearCompanyData();
    var $companyName = $('#companies_house_search_select_field');
    if ($companyName.val() != "nil" && $companyName.val() != ""){
      var $reg_val = $companyName.find('option:selected').data('company-number');
      autofillRegistrationNumber($reg_val);
      companyRegChanged();
    }
  }

  function clearCompanyData(){
    $('#company_company_reg_no').val("");
    $('#company_prog_date_start_for_ch').val("");
    $('#company_prog_date_end_for_ch').val("");
    $('#company_date_incorporated').val("");
    removeOfficers('.directors', 1);
    $('.js-director-name').val("")
    removeOfficers('.partners', 1);
    $('.js-partner-name').val("")
    removeOfficers('.trustees', 1);
    $('.js-trustee-name').val("")
  }

  function autofillRegistrationNumber(reg_val){
    $('#company_company_reg_no').val(reg_val);
  }

  function resetChanges() {
    clearCompanyData();
  }

  // Financial Period
  window.checkFinancialPeriodAgainstCompaniesHouse = function(e, response) {
    var $reg_field = $('#company_company_reg_no');

    // We want to be able to check this on the Nominal Ledger, where it is hidden
    if ((!$reg_field.is(':visible') || $reg_field.val().length === 0)
      && $('#nominal_ledger_table').length === 0
    ) { return }
    var reg_val = checkRegForLength(stripWhitespace($reg_field.val()));
    if (reg_val === undefined) { return }
    var $date_start_field = $('#company_prog_date_start');
    var $date_end_field = $('#company_prog_date_end');
    var sdfv = getDateFromDateTimePicker($date_start_field);
    var edfv = getDateFromDateTimePicker($date_end_field);

    if (response && reg_val.length > 0 && sdfv && edfv) {
      generateHelpMessageForWrongFinancialPeriod(response, sdfv, edfv);
    } else if (reg_val.length > 0 && sdfv && edfv) {
      callCompanyInfoEndpoint(reg_val, function(response) { 
        generateHelpMessageForWrongFinancialPeriod(response, sdfv, edfv);
      }, null); 
    }
  };

  function isNonGlobal(){
    return  ["Limited Company", "Limited Liability Partnership (LLP)"].includes($("#company_typer_id").find('option:selected').text());
  }

  function stripWhitespace(str) {
    return str.replace(/ /g, '');
  }

  function companyRegChanged() {
    if (!isNonGlobal()) { return }
    removeBlankSpaceFromField($('#company_company_reg_no'));
    autofillCompaniesHouseInformation();
  }

  function removeBlankSpaceFromField(field) {
    field.val(stripWhitespace(field.val()));
  }

  // Autofill
  function autofillCompaniesHouseInformation() {
    var $reg_field = $('#company_company_reg_no');
    if (!$reg_field.is(':visible') || $reg_field.val().length === 0) { return }
    var reg_val = checkRegForLength(stripWhitespace($reg_field.val()));
    if (reg_val === undefined || reg_val.length === 0) { return }
    callCompanyInfoEndpoint(reg_val, setCompanyInformation, null);
  }

  function checkRegForLength(reg_val) {
    if (reg_val === undefined) { return; }
    if (reg_val.length === 7) {
      if ($.isNumeric(reg_val.charAt(0))) {
        reg_val = '0' + reg_val;
      } else {
        reg_val = reg_val.slice(0, 2) + '0' + reg_val.slice(2);
      }
    }
    return reg_val
  }

  function setCompanyInformation(response) {
    setCompanyNamesValues(response);
    setCompanyInfoValues(response);
    checkCompanyNameAgainstCompaniesHouse(null, response);
    checkDateIncorporatedAgainstCompaniesHouse(null, response);
    if(isFeatureToggleActive('financial_year_in_setup')){
      $('#company_prog_date_end_for_ch').attr('readonly', true);
    } else {
      checkFinancialPeriodAgainstCompaniesHouse(null, response);
    }
    checkFilingHistoryAgainstCompaniesHouse(null, response);
    autofillOfficerInformation(response);
  }

  function setCompanyNamesValues(response){
    var new_company_name = response['company_name'];
    setChosenSelectCompanyNameField(new_company_name);
    $('#company_company_name').val(new_company_name);
  }

  function setChosenSelectCompanyNameField(name){
    $('#companies_house_search_select_field_chosen > a > span').html('<option>' + name + '</option>').trigger("chosen:updated");
  }

  function setCompanyInfoValues(response) {
    var date_of_creation = parseAndFormatDate(response['date_of_creation']);
    var date_start = parseAndFormatDate(response['accounts']['programme_start_date']);
    var date_end = parseAndFormatDate(response['accounts']['programme_end_date']);
    var next_financial_year_end = parseAndFormatDate(response['accounts']['next_financial_year_end']);

    updateFieldToMatchCompaniesHouse($('#company_date_incorporated'), date_of_creation);
    if(isFeatureToggleActive('financial_year_in_setup')){
      updateFieldToMatchCompaniesHouse($('#company_prog_date_end_for_ch'), next_financial_year_end);
    } else {
      updateFieldToMatchCompaniesHouse($('#company_prog_date_start_for_ch'), date_start);
      updateFieldToMatchCompaniesHouse($('#company_prog_date_end_for_ch'), date_end);
    }
  }

  window.parseAndFormatDate = function(str) {
    return formatDateForCompany(parseDateFromXhrResponse(str));
  }

  window.parseDateFromXhrResponse = function(str) {
    return moment(str, 'YYYY-MM-DD');
  }

  function updateFieldToMatchCompaniesHouse($field, response_val) {
    $field.val(response_val);
  }

  // Validation
  // Company Name
  function checkCompanyNameAgainstCompaniesHouse(e, response) {
    if (!isNonGlobal()) { return }
    var $reg_field = $('#company_company_reg_no');
    if (!$reg_field.is(':visible') || $reg_field.val().length === 0) { return }
    var reg_val = checkRegForLength(stripWhitespace($reg_field.val()));
    if (reg_val === undefined) { return }
    var $name_field = $('#company_company_name');
    var name_val = titleize($name_field.val());

    if (response && name_val.length && reg_val.length) {
      generateHelpMessageForWrongCompanyName(response, $name_field);
    } else if (name_val.length && reg_val.length) {
      callCompanyInfoEndpoint(reg_val, function(response) {
        generateHelpMessageForWrongCompanyName(response, $name_field);
      }, null);
    }
  }

  function generateHelpMessageForWrongCompanyName(response, $name_field) {
    if (response && response['company_name']) {
      var name = (response['company_name']).toLowerCase();
      var input_name = ($name_field.val()).toLowerCase();

      if (name.indexOf(input_name) === -1) {
        generateHelpMessage($name_field, 'Registration Number');
      }
    }
  }

  // Date Incorporated
  function checkDateIncorporatedAgainstCompaniesHouse(e, response) {
    if (!isNonGlobal()) { return }
    var $reg_field = $('#company_company_reg_no');
    if (!$reg_field.is(':visible') || $reg_field.val().length === 0) { return }
    var reg_val = checkRegForLength(stripWhitespace($reg_field.val()));
    if (reg_val === undefined) { return }
    var $name_field = $('#company_company_name');
    var $date_field = $('#company_date_incorporated');
    var name_no_spaces = stripWhitespace($name_field.val());

    if ($date_field.val().length > 0) {
      if (response && reg_val.length > 0) {
        checkDateIncorporatedMatchesCompaniesHouseUsingNumber(response, $date_field);
      } else if (reg_val.length > 0) {
        callCompanyInfoEndpoint(reg_val, function(response) {
          checkDateIncorporatedMatchesCompaniesHouseUsingNumber(response, $date_field);
        }, null);
      } else if (response && name_no_spaces.length > 0) {
        checkDateIncorporatedMatchesCompaniesHouseUsingName(response, $date_field);
      } else if (name_no_spaces.length > 0) {
        callCompaniesSearchEndpoint(name_no_spaces, function(response) {
          checkDateIncorporatedMatchesCompaniesHouseUsingName(response, $date_field);
        }, null);
      }
    }
  }

  function datePairsAreSame() {
    var dates = Array.apply(null, arguments);

    if (dates.length === 2) {
      return datesAreSame(dates[0], dates[1]);
    } else if (dates.length > 2) {
      return datesAreSame(dates[0], dates[1]) && datePairsAreSame.apply(null, dates.slice(2))
    }
  }

  function datesAreSame(d1, d2) {
    return d1.isSame(d2);
  }

  function generateHelpMessageForWrongFinancialPeriod(response, date_start, date_end) {
    if (response && response['accounts']) {
      var retrieved_date_start = parseDateFromXhrResponse(response['accounts']['programme_start_date']);
      var retrieved_date_end   = parseDateFromXhrResponse(response['accounts']['programme_end_date']);

      if (!datePairsAreSame(retrieved_date_start, date_start, retrieved_date_end, date_end)) {
        addHelpMessageUnlessExists(
          'Companies House', HELP_WARNING,
          formatWrongFinancialPeriodMessage(retrieved_date_start, retrieved_date_end),
          'not-a-year'
        );
      }
    }
  }

  function formatWrongFinancialPeriodMessage(date_start, date_end) {
    return 'Financial Period does not match Companies House for this Company Registration Number, expecting '
      + formatDateForCompany(date_start) + ' - ' + formatDateForCompany(date_end);
  }

  function addDateTimePickerEventListeners() {
    if ($('#company_prog_date_end').length === 0) { return; } // Don't fire on subsequent pages unless relevant
    $('#company_prog_date_end').on('dp.change', checkFinancialPeriodAgainstCompaniesHouse);
    if(!isFeatureToggleActive('financial_year_in_setup')){
      checkFinancialPeriodAgainstCompaniesHouse();
    }
  }

  // Filing History
  function checkFilingHistoryAgainstCompaniesHouse(e, response) {
    var has_filing_history = (response['accounts']['in_first_accounting_year'] == 'false');
    var start_up = $('#company_is_startup');

    if (has_filing_history) {
      start_up.prop('checked', false);
      start_up.prop('disabled', true);
    } else {
      start_up.prop('disabled', false);
    }
  }

  function checkDateIncorporatedMatchesCompaniesHouseUsingNumber(response, $date_field) {
    if (response && response['date_of_creation']) {
      var date = parseAndFormatDate(response['date_of_creation']);
      var date_val = $date_field.val();

      if (date !== date_val) {
        generateHelpMessage($date_field, 'Registration Number, expecting ' + date);
      }
    }
  }

  function checkDateIncorporatedMatchesCompaniesHouseUsingName(response, $date_field) {
    if (response && response['items']) {
      var available_dates = [];
      var date_val = $date_field.val();

      $.each([response['items']], function(i, v) {
        available_dates.push(parseAndFormatDate(v[i]['date_of_creation']));
      });

      if ($.inArray(date_val, available_dates) === -1) {
        generateHelpMessage($date_field, 'Company Name');
      }
    }
  }

  // Misc
  function generateHelpMessage(field, attribute) {
    addHelpMessageUnlessExists(
      'Companies House', HELP_WARNING,
      field.data().helpTitle + ' does not match Companies House for this Company ' + attribute,
      'ch-' + field.attr('id')
    );
  }
})();


