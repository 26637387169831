import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submitButton', 'nameField']

  static values = {
    clientId: String,
    publishableKey: String
  }

  connect() {
    const stripe = Stripe(this.publishableKeyValue, { stripeAccount: this.clientIdValue })
    const elements = stripe.elements()
    // Setup the 3 card fields
    const numberElement = window.mountStripeElement(elements, 'cardNumber', '')
    window.addCardJsStyling(numberElement, false, '.jp-card-number', window.checkCardType)

    const expiryElement = window.mountStripeElement(elements, 'cardExpiry', 'mm/yy')
    window.addCardJsStyling(expiryElement, false, '.jp-card-expiry')

    const cvcElement = window.mountStripeElement(elements, 'cardCvc', '')
    window.addCardJsStyling(cvcElement, true, '.jp-card-cvc')

    const controller = this
    // Handle form behaviour
    this.submitButtonTarget.addEventListener('click', function (event) {
      event.preventDefault()
      controller.submitButtonTarget.disabled = true
      stripe.createPaymentMethod('card', numberElement,
        { billing_details: { name: controller.nameFieldTarget.value } }
      ).then(controller.stripeInvoiceResponseHandler)
      return false
    }, false)
  }

  stripeInvoiceResponseHandler(response) {
    const $form = $('#invoice_payment_form')
    if (response.error) {
      window.showErrorsOnInvoicePaymentForm(response.error.message)
    } else {
      const token = response.paymentMethod.id
      $form.append($('<input type="hidden" name="invoice_payment[payment_method_id_stripe]" id="invoice_payment_stripe_token">').val(token))
      $form.trigger('submit.rails')
    }
  }

  unsuccessfulPaymentHandler(event) {
    const stripe = Stripe(this.publishableKeyValue, { stripeAccount: this.clientIdValue })
    stripe
      .handleCardAction(event.detail.client_secret)
      .then(function (result) {
        if (result.error) {
          window.showErrorsOnInvoicePaymentForm(result.error.message)
        } else {
          $.ajax({ url: event.detail.on_success_url, method: 'PATCH' })
        }
      })
  }
}
