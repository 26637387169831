(function() {
  'use strict';

  window.getTaxRateAsFloat = function($select){
    return parseFloat(assertJQuery($select).find('option:selected').data('tax-rate'));
  }

  window.assertJQuery = function($elem) {
    if ($elem instanceof jQuery) {
      return $elem;
    } else {
      throw new TypeError($elem.toString()  + ' is not a jQuery instance!')
    }
  }

  window.performAmountChecksAndUpdateBalances = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    var total_amount_field = getTotalAmountField(row_id);

    checkForSmallAssetAmounts(row_id, total_amount_field);
    updateRunningBalances(row_id);
    checkInvoiceExistsWithAmountsAndNominalAccount(event.currentTarget);
    hideFieldIndicators($(event.target).parents('.form-group'));
  }

  // when total amount changes, set net and tax
  window.updateBalancesAndCheckForInvoices = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    updateRunningBalances(row_id);
    checkInvoiceExistsWithAmountsAndNominalAccount(event.currentTarget);
    return false; // stop propagation
  };

  function getTotalAmountField(row_id) {
    return $('#total_amount_field'+row_id);
  }
})();