// A home for JS shared between the split bank transactions 'split modal' and the split invoices 'line items modal'
(function() {
  'use strict';

  /*
    Sets the parent row's description from the split descriptions

    @param {Object} obj
    @param {jQuery} obj.modal - the modal containing the 'splits'
    @param {jQuery} obj.row - the parent row we want to set description in
    @param {string} obj.description - describes the type of thing we're splitting eg 'invoice'

  */
  // PANDLE: Overridden to set the tooltip description
  window.splitsSetDescriptionForParentRow = function(obj){
    var description = _getNewDescriptionForParentRow(obj.modal, obj.description)
    var parentDescriptionElement = obj.row.find('.description_tf, .stock_description_hidden');
    var $popover = parentDescriptionElement.closest('span[data-controller="popover"]')

    parentDescriptionElement.val(description);

    $popover[0].dispatchEvent(new CustomEvent('update-popover-content', {
      detail: {
        content: description
      }
    }));
  }

  /*
    Sets the parent row's amounts from the split modal balances
  */
  window.splitsSetNetTaxTotalAmountsForParentRow = function($row, parent_row_class) {
    ['net', 'tax', 'total'].forEach(function(ntt) {
      var parent_field = $row.find('.' + ntt + parent_row_class)
      var split_balance = $row.find('.split_' + ntt).find('span').text();
      parent_field.val(formatWithoutDelimiter(split_balance).toFixed(2));
    });
  }

  /*
    Given a split_row within the modal, updates the balances displayed at the bottom of the modal
  */
  window.updateSplitBalance = function(row) {
    updateSplitBalanceForModal($(row).closest('.modal'));
  }

  /*
    Given a splits modal, update the balances displayed at the bottom of the modal
  */
  window.updateSplitBalanceForModal = function($modal){
    ['net', 'tax', 'total'].forEach(function(ntt) {
      setTimeout(function() { // There is a race condition with Field State so this has to be a bit patient :(
        setBalanceInModal($modal, ntt);
      }, 5) //FARMPLAN: Increase duration as it seems there is a rece condition with the stimulus.js files. The stimulus.js files HAS to finish running first before this one otherwise there will be no data for the setBalanceInModal to get it's
      //info from. Simplest fix.
    });
  }

  /*
    Given an event, find the split row in which it occurred
  */
  window.getClosestSplitRowToEvent = function(event){
    var $row = $(event.target).closest('.split_row');
    if ( $row.length ) {
      return $row;
    } else {
      throwRowNotFoundError('getClosestSplitRowToEvent', event)
    }
  }

  /*
    Given a splits modal, remove all the split rows from it (and re-init typeahead on the main row's description field)
  */
  window.removeSplitsFromSplitModal = function($modal, $description_field){
    $.each(
      $modal.find('.split_row'),
      clearSplitRowValues
    );
    typeAheadInput($description_field, $('.typeahead_descriptions'));
  }

  window.returnElementOrRaiseError = function(element, description, row){
    if ( element.length ) {
      return element;
    } else {
      throw new SplitTransactionModalError(description + 'not found for row '+ row)
    }
  }

  window.addNewSplitRow = function($modal, modalInitFunction, additionalParams){
    checkTimeSinceLastClickAndContinue($('#splits_add_last_click'), function(){
      // FARMPLAN: using 50 split line transactions
      if ( $modal.find('.split_row').length >= 50 ) {
        // Use notify instead of addHelpMessage so they can see it without having to close the modal
        notify('When splitting categorisation you cannot have more than 50 splits', 'warning', 'Error! ');
      } else {
        _cloneRowAsLastInModal($modal, modalInitFunction, additionalParams);
      }
    })
  }

  window.removeSplitRow = function(event, $modal, $row, description, row_removal_function){
    checkTimeSinceLastClickAndContinue($('#splits_remove_last_click'), function(){
      applyMinimumRowsForForm({
        container: $modal,
        add_new_class: 'split_row',
        row_class: 'split_row',
        min_allowed: 2
      });
      row_removal_function(event);
      splitsSetDescriptionForParentRow({
        row: $row,
        modal: $modal,
        description: description
      });
      updateSplitBalanceForModal($modal);
      return false;
    })
  }

  function throwRowNotFoundError(method_name, event){
    throw new SplitTransactionModalError(
      method_name + ': No row found for event type ' + event.type + ' with target ' + event.target.id
    );
  }

  /*
    PANDLE OVERRIDE: Add quantity and price fields to be cleared when
    adding a new split line (otherwise they are duplicated from above)
    ---
    @param {integer} index - unused
    @param {jQuery} split_row
  */
  function clearSplitRowValues(_index, split_row, clear_ids) {
    if ( typeof(clear_ids) === 'undefined') { clear_ids = false; }
    [ '.split_description_tf',
      '.split_quantity_field',
      '.split_price_field',
      '.split_net_amount_field',
      '.split_tax_amount_field',
      '.split_total_amount_field',
      '.split_stock_id_field'
    ].forEach(_resetTextVal(split_row));
    if ( clear_ids ) {
      _resetTextVal(split_row)('.split_id_tf');
    }
    [ '.invoice_line_items_select',
      '.split_account',
      // FARMPLAN: Add enterprise
      '.enterprise_selector',
      // FARMPLAN END
      '.split_tax_code'
    ].forEach(_resetChosenVal(split_row));
  }

  function _cloneRowAsLastInModal($modal, modalInitFunction, additionalParams){
    var $last_row = $modal.find('.split_row').last();
    var $last_visible_row = $modal.find('.split_row:visible').last();
    var clone = $last_visible_row.clone();
    clearSplitRowValues(null, clone, true);
    incrementSplitRowIndex($last_visible_row.data('splitRowIndex'), clone, additionalParams.index_key, $last_row.data('splitRowIndex') + 1);

    $last_row.after(clone);
    clone.find('div.chosen-container').remove(); // Remove dropdowns, they get re-init later
    clone.find('div.waves-ripple').remove(); // Remove waves divs that are making the buttons look clicked
    modalInitFunction(clone);
    initChosenSelects(clone);
  }


  function _getNewDescriptionForParentRow($modal, description){
    var desc = 'Split ' + description + ': ' + _getJoinedSplitsDescription($modal);
    if ( desc.length > 250 ) {
      // Truncate excessively long descriptions
      desc = desc.substring(0,248) + '...'
    }
    return desc
  }

  function _getJoinedSplitsDescription($modal) {
    var desc = [];
    var $split_rows = $modal.find('.split_row')
    var $descriptions_from_split_rows_not_deleted = $split_rows.not('.flagged_for_deletion').find('.split_description_tf')
    $.each($descriptions_from_split_rows_not_deleted, _addDescriptionToArrayIfPresent(desc));
    return desc.join(', ');
  };

  function _addDescriptionToArrayIfPresent(desc){
    return  function (_, desc_field){
      var split_description = $(desc_field).val();
      if (split_description.length > 0) {
        desc.push(split_description);
      }
    }
  }

  function _resetTextVal(split_row){
    return function(selector){
      $(split_row).find(selector).val('');
    }
  }

  function _resetChosenVal(split_row){
    return function(selector){
      $(split_row)
      .find(selector)
      .find('option:first-child')
        .prop('selected', true)
      .end() // Go back to the collection before the most recent find operation
      .trigger('chosen:updated');
    }
  }

  function setBalanceInModal($modal, ntt) {
    getSplitNttBalanceFieldInModal($modal, ntt).text(_calculateBalanceInModal($modal, ntt));
  }

  function _calculateBalanceInModal($modal, ntt){
    var sum = 0;

    getNttAmountFieldsInModal($modal, ntt).each(function() {
      sum += Number($(this).val());
    });

    return formatAsCurrency(sum);
  }

  function getNttAmountFieldsInModal($modal, ntt){
    return $modal.find('tr:not(.flagged_for_deletion)').find('.split_' + ntt + '_amount_field');
  }

  function getSplitNttBalanceFieldInModal($modal, ntt){
    return $modal.find('.split_' + ntt + ' span');
  }

  /**
   * Raised when anything goes wrong to do with the BT splits modal.
   *
   * @constructor
   * @extends Error
   */
  function SplitTransactionModalError(message){
    if(typeof message !== 'undefined') {
      this.message = message;
    }

    this.stack = new Error().stack;
  }

  SplitTransactionModalError.prototype = Object.create(Error.prototype);
  SplitTransactionModalError.prototype.constructor = SplitTransactionModalError;
  SplitTransactionModalError.prototype.name = 'SplitTransactionModalError';
  SplitTransactionModalError.prototype.message = 'An error occurred relating to the Splits modal';

  window.SplitTransactionModalError = SplitTransactionModalError;
})();
