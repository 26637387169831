import CONVERTER from './_bootgrid_converters.js'
(function() {
  'use strict';

  window.initAccountTable = function() {
    $('#account_table').bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up',
        iconExport: 'csv-export-icon',
        iconLoading: 'zmdi-rotate-right zmdi-hc-spin'
      },
      ajaxSettings: { method: 'GET' },
      multiSort: false,
      csvExport: true,
      labels: { noResults: 'No amounts found' }
    }).on("loaded.rs.jquery.bootgrid", function(e) {
      balanceColumnVisibility();
    })
  }

  function balanceColumnVisibility() {
    var balanceColumn = $("#account_table").find('[data-column-id="balance"]');
    if (balanceColumn.length === 0 ) { return; }
    var colIndex = balanceColumn[0].cellIndex + 1;

    if (balanceValuesAvailable()) {
      balanceColumn.show();
      $('#account_table').find("tr > td:nth-child("+colIndex+")").show();
      $('#account_table_balance_col_select').removeClass("disable-check");
      $('#account_table_balance_col_select_inp').attr("disabled", false);
    } else {
      balanceColumn.hide();
      $('#account_table').find("tr > td:nth-child("+colIndex+")").hide();
      $('#account_table_balance_col_select').addClass("disable-check");
      $('#account_table_balance_col_select_inp').attr("disabled", true);
    }
  }

  function balanceValuesAvailable() {
    var colIndex = $('#account_table').find('[data-column-id="balance"]')[0].cellIndex + 1,
      balanceValues = $.unique(
      $("#account_table > tbody > tr td:nth-child("+colIndex+")").map(function() { return this.innerHTML; })
    )
    return !(balanceValues.length == 1 && balanceValues[0] == "&nbsp;");
  }

  window.initNonPersistedAccountTable = function() {
    $('#account_table').bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up'
      },
      converters: {
        order_by_link_text: CONVERTER.order_by_link_text,
        order_by_date: CONVERTER.order_by_date
      }
    });
  }
})();
