function NumberComparisonResultJson(
  sourceElement,
  targetElement,
  sourceNumber,
  targetNumber,
  comparisonResult
){
  this.sourceElement = sourceElement;
  this.targetElement = targetElement;
  this.sourceNumber = sourceNumber;
  this.targetNumber = targetNumber;
  this.comparisonResult = comparisonResult;
}

NumberComparisonResultLens = {
  "sourceElement": function(numberComparisonResultJson){
    return numberComparisonResultJson.sourceElement;
  },
  "targetElement": function(numberComparisonResultJson){
    return numberComparisonResultJson.targetElement;
  },
  "sourceNumber": function(numberComparisonResultJson){
    return numberComparisonResultJson.sourceNumber;
  },
  "targetNumber": function(numberComparisonResultJson){
    return numberComparisonResultJson.targetNumber;
  }
}