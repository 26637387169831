(function() {
  "use strict";

  var GGA_TAB_MAP = {
    "companies": {
      options_id: "companies_gga",
      table_id: "companies_gga_table",
      csv_export: false
    },
    "agents": {
      options_id: "agents_gga",
      table_id: "agents_gga_table",
      csv_export: false
    }
  };

  document.addEventListener("turbo:load", errors.tryFn(function() {
    if ($("#content.government_gateway_accounts").length === 0) { return }
    initNavTabsWithTables({
      tabbable_id: "#gga_tabs",
      tab_map: GGA_TAB_MAP,
      user_preference_key: "gga_tab_id"
    });
  }));
})();
