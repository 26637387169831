/**
 * Displays a confirmation modal with a customizable message and title.
 * If the user confirms, executes a callback function.
 *
 * @export
 * @param {string} text - The message to display in the modal. This will be displayed in bold.
 * @param {Function} confirmationCallback - The function to call when the user confirms.
 * @param {string} [title="Are you sure?"] - The title of the modal. Defaults to "Are you sure?".
 *
 * @example
 * confirmationModal(
 *   "Do you want to delete this item?",
 *   () => { console.log("Item deleted."); },
 *   "Delete Item"
 * );
 */
export function confirmationModal(
  text,
  confirmationCallback,
  title = 'Are you sure?'
) {
  sweetAlert(
    {
      title,
      html: true,
      text: `<strong>${text}</strong>`,
      type: 'warning',
      showCancelButton: true,
      customClass: 'sweet-farmplan'
    },
    (isConfirmed) => {
      if (isConfirmed) {
        confirmationCallback()
      }
    }
  )
}
