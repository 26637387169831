import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    tableId: String
  }

  show_spinner() {
    const tableBody = document.getElementById(this.tableIdValue)

    tableBody.innerHTML =
      `<tr style="background-color: transparent;">
        <td colspan="14" style="padding: 20px 0px 83px;">
          <div class="preloader-container">
            <div class="preloader pl-lg">
              <svg class="pl-circular" viewBox="25 25 50 50">
                <circle class="plc-path" cx="50" cy="50" r="20"></circle>
              </svg>
            </div>
          </div>
        </td>
      </tr>`
  }
}
