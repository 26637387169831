(function() {
    'use strict';
  
    // Used when an invoice's customer/supplier or nominal account changes, to set the tax code to the default.
    // In order of priority:
    //   1. stock item default tax code
    //   2. appropriate EU tax code if customer/supplier uses EU VAT
    //   3. nominal account default tax code
    //   4. nominal account last used tax code
    window.InvoiceTaxCodeDefaulting = function($customer_supplier_field, $nominal_account_field, $tax_code_fields, $stock_item_field, invoice_type) {
      this.$customer_supplier_field = $customer_supplier_field;
      this.$nominal_account_field = $nominal_account_field;
      this.$stock_item_field = $stock_item_field;
      this.$tax_code_fields = $tax_code_fields;
      this._invoice_type = invoice_type;
    }
  
    InvoiceTaxCodeDefaulting.prototype.call = function() {
      if (this._isInLockedVatReturn()) { return; } //FARMPLAN: Added check for being in a locked vat return
      if (!this._hasMultipleTaxCodesAvailable()) { return; }
      if (!this._invoiceTypeShouldHaveNominal()) { return; }
      var selected_customer_supplier = this.$customer_supplier_field.find('option:selected');
      if (this._hasStockItem() && (this.$stock_item_field.val().length > 0)){
        this._setTaxCodeFromStockItem();
      }else if (this._customerSupplierUsesEuVat(selected_customer_supplier)) {
        this._setTaxCodeToEuVat();
      } else {
        this._setTaxCodeFromNominalAccount();
      }
    }
  
    InvoiceTaxCodeDefaulting.prototype._hasMultipleTaxCodesAvailable = function(){
      return this.$tax_code_fields.first().find('option').length > 1;
    }
  
    InvoiceTaxCodeDefaulting.prototype._invoiceTypeShouldHaveNominal = function(){
      // We don't want to do anything for credit notes
      return this._invoice_type == 'SalesInvoice' ||
        this._invoice_type == 'PurchaseInvoice' ||
        this._invoice_type == 'CustomerGroupInvoice' ||
        $('form.quotes').length > 0;
    }
  
    InvoiceTaxCodeDefaulting.prototype._customerSupplierUsesEuVat = function(selected_customer_supplier){
      return selected_customer_supplier.data('usesEuVat')
    }
  
    InvoiceTaxCodeDefaulting.prototype._setTaxCodeToEuVat = function(){
      this.$tax_code_fields.find('option:contains("' + this._defaultEuTaxCode() + '")').prop('selected', true);
      this.$tax_code_fields
        .trigger('change')
        .trigger('chosen:updated');
    }
  
    //If in a locked vat return
    InvoiceTaxCodeDefaulting.prototype._isInLockedVatReturn = function(){
      return this.$tax_code_fields.closest("tr").attr("data-line-item-row-locked-value") === "true"
    }
  
    InvoiceTaxCodeDefaulting.prototype._defaultEuTaxCode = function(){
      if (this._onBatchForm() || this._probablySellingCountableObjects()) {
        return 'EG'
      } else {
        return 'ES'
      }
    }
  
    InvoiceTaxCodeDefaulting.prototype._onBatchForm = function(){
      return $('#invoice_form').length > 0;
    }
  
    InvoiceTaxCodeDefaulting.prototype._probablySellingCountableObjects = function(){
      return $('.quantity_tf:visible').length > 0;
    }
  
    InvoiceTaxCodeDefaulting.prototype._setTaxCodeFromNominalAccount = function(){
      var default_tax_code = this.$nominal_account_field.find('option:selected').data('defaultTaxCodeId');
      if (typeof(default_tax_code) === 'number') {
       this._setTaxCode(default_tax_code); // Will be the default tax code if there is one, or last used tax code otherwise
      }
    }
  
    InvoiceTaxCodeDefaulting.prototype._hasStockItem = function(){
      if (this.$stock_item_field.length == 0) { return false }
      return this.$stock_item_field.val().length > 0;
    }
  
    InvoiceTaxCodeDefaulting.prototype._setTaxCodeFromStockItem = function(){
      if(this._invoice_type == 'SalesInvoice'){
        var default_tax_code = this.$stock_item_field.data('salesTaxCode');
      } else if(this._invoice_type == 'PurchaseInvoice'){
        var default_tax_code = this.$stock_item_field.data('purchaseTaxCode'); // Will be the default tax code if there is one, or last used tax code otherwise
      }
  
      if (typeof(default_tax_code) === 'number') {
       this._setTaxCode(default_tax_code);
      }
    }
  
    InvoiceTaxCodeDefaulting.prototype._setTaxCode = function(tax_code_id){
      const $tax_code_fields = this.$tax_code_fields;
      // Select the first option available if tax_code_id is not found
      if ($tax_code_fields.find('option[value="' + tax_code_id + '"]').length == 0) { 
        tax_code_id = $tax_code_fields.first('option:not([value=""])').val();
      }
      $tax_code_fields.val(tax_code_id).trigger('change').trigger('chosen:updated');
    }
  })();
  