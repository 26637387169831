import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['counter']

  decrementTabCounter(event) {
    if (!window.isFeatureToggleActive('tab_counter')) { return }
    const newValue = this.getValue() - 1
    this.setValue(newValue)
  }

  incrementTabCounter(event) {
    if (!window.isFeatureToggleActive('tab_counter')) { return }
    const newValue = this.getValue() + 1
    this.setValue(newValue)
  }

  setTabCounterValue(event) {
    if (!window.isFeatureToggleActive('tab_counter')) { return }
    const newValue = event.detail.new_value
    this.setValue(newValue)
  }

  getValue() {
    return parseFloat(this.counterTarget.innerHTML)
  }

  setValue(newValue) {
    if (newValue < 0) { newValue = 0 }
    this.counterTarget.innerHTML = newValue

    if (newValue <= 0 && !this.counterTarget.hidden) {
      this.counterTarget.hidden = true
    } else if (newValue > 0 && this.counterTarget.hidden) {
      this.counterTarget.hidden = false
    }
  }
}
