window.initLoadSingleCustomerButton = function() {
  var elem = $('div.bootstrap-select.customer_supplier_select');
  if (canChangeToSingleCustomerOption(elem)) {
    addLoadSingleCustomerOption(elem);
  }
}

function addLoadSingleCustomerOption(element) {
  if (element.find('button.js-load-single-customer-button').length === 0) {
    if (element.find('div.customer-select-custom-options').length === 0) {
      createCustomOptionsDiv(element);
    }
    element.find('div.customer-select-custom-options').prepend("<button type='button' class='btn btn-primary btn-xs pull-right m-r-5 js-load-single-customer-button' tabindex='-1'><i class='zmdi zmdi-account'></i> Select Individual</button>");
    loadSingleCustomerClickListener(element);
  }
}

function loadSingleCustomerClickListener(element) {
  element.find('button.js-load-single-customer-button').click(function() {
    switchBackToSingleCustomerSelect(element);
  });
}

function switchBackToSingleCustomerSelect(element) {
  var $customer_select_field = $(document).find('div.customer_select_fields');
  $customer_select_field.find('.customer_multiple_select').addClass('hidden');
  $customer_select_field.find('.customer_multiple_select .multi-selectpicker').prop('disabled', true).selectpicker('refresh');
  $customer_select_field.find('.customer_single_select').removeClass('hidden');
  $(document).find('.customer_group_id').empty();
  $customer_select_field.find('.customer_single_select select').prop('disabled', false).trigger('change');
}

function canChangeToSingleCustomerOption(elem) {
  return $('#invoice_type').hasClass('chosen-select') && elem.length > 0;
}
