(function() {
  'use strict';

  window.showLineItemsModal = function(event) {
    var batchRow = getAndWrapClosestInvoiceRowToEvent(event);
    batchRow.getDescriptionTf().typeahead('destroy');
    var $modal = batchRow.getLineItemsModal();
    $modal.modal('show');
    _initSplitModalCells(batchRow.getRow(), $modal);
    return false;
  }

  window.removeInvoiceSplits = function(batchRow, $modal) {
    removeSplitsFromSplitModal($modal, batchRow.getDescriptionTf());
  }

  function _initSplitModalCells($row, $modal) {
    _addSplitModalEventListeners($modal, withRowAndModal);
    _addSplitRowEventListeners($row, $modal, withRowAndModal);
    var cells = $row.find('td');
    checkNeedForNewNominalOption(cells);
    updateSplitBalanceForModal($modal);

    /*
    * We're adding lots of event listeners that need to know about $row
    * This lets us do that succinctly
    */
    function withRowAndModal(fn) {
      return function(e) {
        fn(e, $row, $modal)
      }
    }
  }

  function _addSplitModalEventListeners($modal, withRowAndModal) {
    $modal.find('.modal-save-btn').off().on('click', withRowAndModal(_setNetTaxTotalAmountsForParentRow));
    $modal.find('.modal-close-btn').off().on('click', withRowAndModal(_unsplitParentRow));
  }

  function _setNetTaxTotalAmountsForParentRow(event, $row, $modal) {
    splitsSetNetTaxTotalAmountsForParentRow($row, '_amount_tf');

    // FARMPLAN: stimulus won't listen to jQuery events. See line-item-row
    $row.find('.is_split_field')[0].dispatchEvent(new Event('switch'))
  }

  function _unsplitParentRow(event, $row, $modal) {
    $row.find('.is_split_field').val(false).trigger('change');
    $modal.find('.line-item-allocatable-id').val('');
    $modal.find('.line-item-allocatable-type').val('');

    // FARMPLAN: stimulus won't listen to jQuery events. See line-item-row
    $row.find('.is_split_field')[0].dispatchEvent(new Event('switch'))
  }

  function _addSplitRowEventListeners($row, $modal, withRowAndModal) {
    [
      ['.invoice_line_items_select', 'change', withRowAndModal(_splitParentLineItemOnChange) ],
      [ ".split_account", 'change', withRowAndModal(_splitNominalAccountOnChange) ],
      [ '.split_description_tf', 'change', withRowAndModal(_splitDescriptionOnChange) ],
      [ '.split_net_amount_field', 'focus', highlightCell ],
      [ '.split_tax_amount_field', 'focus', highlightCell ],
      [ '.split_total_amount_field', 'focus', highlightCell ],
      [ '.split_net_amount_field', 'change', withRowAndModal(_splitNetAmountOnChange) ],
      [ '.split_tax_code_field', 'change', withRowAndModal(_splitTaxCodeOnChange)],
      [ '.split_tax_amount_field', 'change', withRowAndModal(_splitTaxAmountOnChange) ],
      [ '.split_total_amount_field', 'change', withRowAndModal(_splitTotalAmountOnChange) ],
      [ '.split_price_field', 'change', withRowAndModal(_splitPriceChange) ],
      [ '.add_new_split_row', 'click', withRowAndModal(_addNewSplitRow) ],
      [ '.remove_split_row', 'click', withRowAndModal(_removeSplitRow) ],
      [ '.split_stock_item', 'change', withRowAndModal(_stockOnChange) ],
      [ '.split_stock_item_quantity_tf', 'change', withRowAndModal(_stockOnChange) ],
    ].forEach(function(args) {
      errors.tryOn($modal.find(args[0]).off(args[1]).on(args[1], args[2]));
    });
  }

  function _splitPriceChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);
    splitSetNetFromQuantityAndPrice(split_row);
    _splitNetAmountOnChange(event, $row, $modal);
  }

  function _splitParentLineItemOnChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);
    _setLineItemValuesFromParentLineItem(split_row);
    updateSplitBalanceForModal($modal);
  }

  function _setLineItemValuesFromParentLineItem(split_row) {
    var $parent_li = split_row.selectedLineItem()
    var parent_li_data = $parent_li.data();
    split_row.taxCodeField().val(parent_li_data['taxCodeId']).trigger('chosen:updated');
    split_row.accountField().val(parent_li_data['nominalAccountId']).trigger('chosen:updated');
    // FARMPLAN : Copy Enterprise selection
    split_row.enterpriseField().val(parent_li_data['enterpriseId']).trigger('chosen:updated');
    // FARMPLAN END
    split_row.netAmountField().val(parent_li_data['netAmount']);
    split_row.taxAmountField().val(parent_li_data['taxAmount']);
    split_row.totalAmountField().val(parent_li_data['totalAmount']);
    split_row.descriptionField().val(parent_li_data['description']).change();
    split_row.allocatableIdField().val($parent_li.val());
    split_row.allocatableTypeField().val('LineItem');
  }

  function buildClosestModalLineItemRow(event) {
    return new ModalLineItemRow(getClosestSplitRowToEvent(event));
  }

  function _stockOnChange(event, $row, _$modal){
    var batchRow = wrapBatchInvoiceRow($row);
    var split_row =  buildClosestModalLineItemRow(event);
    splitShowOutOfStockPopover(split_row, batchRow)
    splitShowQuantityWarningPopover(split_row, batchRow)
    splitSetNominalsFromStockAndType(split_row, batchRow);
    splitSetPriceFromStockItemAndType(split_row, batchRow);
    splitSetNetFromQuantityAndPrice(split_row)
    splitSetCategoryFromStock(split_row, batchRow);
    splitSetTaxCodeFromStock(split_row, batchRow);
    splitSetDescriptionFromStock(split_row, batchRow);
    _splitNetAmountOnChange(event, $row, _$modal);
  }

  function splitSetNetFromQuantityAndPrice(split_row){
    setNetFromQuantityAndPrice({
      net_field: split_row.netAmountField(),
      quantity_field: split_row.stockQuantityField(),
      price_field: split_row.priceField()
    })
  }

  function splitShowOutOfStockPopover(split_row, batchRow){
    showOutOfStockPopover({
      stock_item_field: split_row.stockItemField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitShowQuantityWarningPopover(split_row, batchRow){
    showQuantityWarningPopover({
      stock_item_field: split_row.stockItemField(),
      quantity_field: split_row.stockQuantityField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitSetNominalsFromStockAndType(split_row, batchRow){
    setNominalsFromStockAndType({
      stock_item_field: split_row.stockItemField(),
      category_field: split_row.accountField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitSetCategoryFromStock(split_row, batchRow){
    setCategoryFromStock({
      category_field: split_row.accountField(),
      stock_item_field: split_row.stockItemField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitSetTaxCodeFromStock(split_row, batchRow){
    setTaxCodeFromStock({
      tax_code_field: split_row.taxCodeField(),
      stock_item_field: split_row.stockItemField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitSetDescriptionFromStock(split_row, batchRow){
    setDescriptionFromStock({
      description_field: split_row.descriptionField(),
      stock_item_field: split_row.stockItemField(),
      type_field: batchRow.getInvoiceTypeSelect()
    })
  }

  function splitSetPriceFromStockItemAndType(split_row, batchRow){
    setPriceFromStockItemAndType({
      net_amount_field: split_row.netAmountField(),
      stock_item_field: split_row.stockItemField(),
      type_field: batchRow.getInvoiceTypeSelect(),
      price_field: split_row.priceField()
    })
  }

  function _splitNominalAccountOnChange(event, $row, _$modal){
    var batchRow = wrapBatchInvoiceRow($row);
    var split_row =  buildClosestModalLineItemRow(event);
    new InvoiceTaxCodeDefaulting(
      batchRow.getCustomerSupplierSelect(),
      split_row.accountField(),
      split_row.taxCodeField(),
      split_row.stockItemField(),
      batchRow.getInvoiceTypeSelect().val()
    ).call();
  }

  function _splitDescriptionOnChange(event, $row, $modal) {
    splitsSetDescriptionForParentRow({
      row: $row,
      modal: $modal,
      description: 'invoice'
    });
    updatePopoverText(event);
  }

  function _splitNetAmountOnChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);

    if (!split_row.isReadOnlyTotal()) {
      setTaxAndTotalFromNetAndTaxRate({
        tax_rate: split_row.taxRate(),
        net_amount_field: split_row.netAmountField(),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    } else { // LI is or was imported
      setTaxToTotalMinusNet({
        net_amount_field: split_row.netAmountField(),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    }

    updateSplitBalanceForModal($modal);
    splitAddNoVatSchemeWarning(split_row, $row);
  }

  function _splitTaxCodeOnChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);

    if (!split_row.isReadOnlyTotal()) {
      setTaxAndTotalFromNetAndTaxRate({
        tax_rate: split_row.taxRate(),
        net_amount_field: split_row.netAmountField(),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    } else { // LI is or was imported
      setNetAndTaxFromTotalAndTaxRate({
        tax_rate: split_row.taxRate(),
        net_amount_field: split_row.netAmountField(),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    }

    updateSplitBalanceForModal($modal);
    splitAddNoVatSchemeWarning(split_row, $row);
  }

  function _splitTaxAmountOnChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);

    if (!split_row.isReadOnlyTotal()) {
      setTotalToNetPlusTax({
        net_amount: getValAsFloat(split_row.netAmountField()),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    } else { // LI is or was imported
      setNetToTotalMinusTax({
        net_amount_field: split_row.netAmountField(),
        tax_amount_field: split_row.taxAmountField(),
        total_amount_field: split_row.totalAmountField()
      });
    }

    updateSplitBalanceForModal($modal);
    splitAddNoVatSchemeWarning(split_row, $row);
  }

  function _splitTotalAmountOnChange(event, $row, $modal) {
    var split_row = buildClosestModalLineItemRow(event);
    setNetAndTaxFromTotalAndTaxRate({
      tax_rate: split_row.taxRate(),
      net_amount_field: split_row.netAmountField(),
      tax_amount_field: split_row.taxAmountField(),
      total_amount_field: split_row.totalAmountField()
    });
    updateSplitBalanceForModal($modal);
  }

  function splitAddNoVatSchemeWarning(split_row, $row) {
    addNoVatSchemeWarning({
      tax_amount_field: split_row.taxAmountField(),
      tax_code_field: split_row.taxCodeField(),
      datepicker: getDateTimePicker($row.find('.datepicker-component'))
    });
  }

  function _addNewSplitRow(event, $row, $modal) {
    addNewSplitRow(
      $modal,
      _initSplitModalCellsForNewRow($row, $modal),
      { index_key: 'line_items_attributes' }
    );
  }

  function _initSplitModalCellsForNewRow($row, $modal) {
    return function(clone) {
      _initSplitModalCells($row, $modal);
    }
  }

  function _removeSplitRow(event, $row, $modal) {
    removeSplitRow(event, $modal, $row, 'invoice', _flagClosestLineItemForDestruction);
  }

  function _flagClosestLineItemForDestruction(event) {
    _flagLineItemForDestruction(getClosestSplitRowToEvent(event));
  }

  function _flagLineItemForDestruction($row) {
    var split_row = new ModalLineItemRow($row);
    split_row.destroyField().val(true);
    $row.addClass('flagged_for_deletion');
  }
})();
