function initAreYouSureBankTransactions() {
  window.bt_are_you_sure_controller = checkFormChangedOnPageUnload('#bank_transaction_form');
}

(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#bank_transaction_form').length === 0) { return }
    initBatchBankTransactionsForm();
    initRunningBalances();
    initAreYouSureBankTransactions();
  }));
})();
