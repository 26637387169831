(function(){
  window.initNewVatReturnForm = function() {
    // Auto-calculate three month VAT period
    $('#vat_return_date_end').on('dp.change', onVatReturnEndDateChange);
  }

  window.onVatReturnEndDateChange = function() {
    var $start_date_field = $('#vat_return_date_start');
    var $end_date_field = $('#vat_return_date_end');
    if ($end_date_field.length === 0 ) { return; } // Turbolinks means we might have left the page by the time this is happening
    var number_of_months = $start_date_field.data('vatReportingPeriodMonths');
    if ( !Number(number_of_months) > 0) { return; }
    if (isVatReturnDateRangeNotExpectedLength($start_date_field, $end_date_field, number_of_months)) {
      notify(I18n.t('vat_returns.new.expected_vat_report_duration', { number_of_months: number_of_months }), HELP_WARNING, 'Warning! ');
    }
  }

  function computeAndSetEndDate(startDate, endDatePicker, number_of_months) {
    if (startDate) {
      endDatePicker.date(addSomeMonths(startDate, number_of_months));
    }
  }

  function isVatReturnDateRangeNotExpectedLength($start_date_field, $end_date_field, number_of_months) {
    var startDate = getDateFromDateTimePicker($start_date_field);
    var endDate = getDateFromDateTimePicker($end_date_field);
    if (startDate && endDate) {
      return !datesEqual(addSomeMonths(startDate, number_of_months), endDate);
    } else {
      return false; // They haven't finished filling it in yet, no need to warn
    }
  }

  function addSomeMonths(startDate, number_of_months) {
    return startDate.add(number_of_months, 'months').subtract(1, 'days');
  }

  function datesEqual(date1, date2) {
    return date1.dayOfYear() == date2.dayOfYear() && date1.year() == date2.year();
  }
})();
