/*
  Mimics the rails I18n methods, along with our overrides from the initializer
*/
(function(){
  'use strict';

  window.I18n = window.I18n || {};

  I18n.t = function(key, interpolation_values){
    if(!key){
      return '';
    }

    var keys = key.split(".");
    var translation = window.I18n.data;
    $(keys).each(function(_, value) {
      if(translation){
        translation = translation[value];
      }
    });

    if(!translation){
      logMessage("No translation found for key: " + key);
      return "N/A";
    }

    if ( typeof(translation) === 'string') {
      translation = replace_placeholders_with_values(translation, interpolation_values);
    }

    return translation;
  };

  function replace_placeholders_with_values(translation, interpolation_values) {
    if(typeof(interpolation_values) === 'undefined'){
      interpolation_values = {};
    }
    if(typeof(companySettings) != 'undefined'){
      interpolation_values['country_sales_tax_code'] = window.companySettings.country.sales_tax_code;
      interpolation_values['country_vat_report_noun_singular'] = window.companySettings.country.vat_report_noun;
      interpolation_values['country_vat_report_noun_plural'] = window.companySettings.country.vat_report_noun_plural;
    }
    $(Object.keys(interpolation_values)).each(function(_, key){
      translation = translation.replace(new RegExp('%'+key, 'g'), interpolation_values[key]);
    });
    return translation;
  }

})();