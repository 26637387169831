/**
 * @fileoverview
 * Decorates the modal jQuery plugin, supporting a 'maximised' height configuration.
 */
(function() {
  'use strict';

  var _modal = $.fn.modal;
  $.fn.modal = modalDecorated;

  /**
   * Extends behaviour in
   * @link{http://getbootstrap.com/javascript/#modals-usage} with an optional
   * maximised key in the options object.
   *
   * This option defaults to false. If set to true, then the modal's height
   * will be set to viewport height, with a symmetrical margins on the top
   * and bottom, derived from the default top margin.
   *
   * @param options
   */
  function modalDecorated(options) {
    if (options && options.maximise) {
      decorate(this);
    }
    return _modal.apply(this, arguments);
  }

  function decorate($modal) {
    var _onResize = throttleFn(onResize($modal));

    window.addEventListener('resize', _onResize);

    $modal.on('hidden.bs.modal', function() {
      window.removeEventListener('resize', _onResize);
      clearContentHeight($modal);
    });

    maximise($modal);
  }

  function onResize($modal) {
    return function() {
      maximise($modal);
      $modal.trigger('resize');
    }
  }

  function maximise($modal) {
    setContentHeight($modal, getMaximisedHeight($modal));
  }

  function getMaximisedHeight($modal) {
    return window.innerHeight - (2 * getTopMargin(getModalDialogElem($modal)));
  }

  function getModalDialogElem($modal) {
    return $modal.find('.modal-dialog');
  }

  function getTopMargin($elem) {
    return parseFloat(getComputedStyle($elem.get()[ 0 ])['margin-top']);
  }

  function setContentHeight($modal, height) {
    $modal.find('.modal-content').css({ height : height + 'px' });
  }

  function clearContentHeight($modal) {
    $modal.find('.modal-content').css({ height: '' });
  }
})();
