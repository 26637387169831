import { Controller } from '@hotwired/stimulus'
import { splitDateToFormatArray, getSeparatorFromDateFormat } from 'helpers/date_helper'

export default class extends Controller {
  static targets = ['endDate', 'startDate']
  static values = {
    format: String,
    month: Number
  }

  connect() {
    this.startDate = this.startDateTarget
    this.endD = this.endDateTarget
    this.date_separator = getSeparatorFromDateFormat(this.formatValue)
  }

  startDateSet() {
    const dateText = this.startDate.value
    if (dateText === '') { return }
    const formattedDateText = this.formatStringDate(dateText)
    const endDate = this.getEndDate(formattedDateText)
    this.endD.value = endDate.toLocaleDateString()
  }

  getEndDate(dateText) {
    // eslint-disable-next-line no-undef
    const endDate = moment(dateText).add(this.monthValue, 'M').subtract(1, 'days').toDate()
    const currentDate = new Date()
    if (currentDate < endDate) {
      notify('Your end date has been set to today as it cannot be in the future', 'success', '')
      return currentDate
    }
    return endDate
  }

  formatStringDate(date) {
    const separator = this.date_separator
    const splitFormat = this.formatValue.split(separator)
    const dateArray = splitDateToFormatArray(date, separator, splitFormat)
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]} 00:00:00`
  }
}
