(function() {
  'use strict';

  window.initBankRuleForm = function() {
    // FARMPLAN: non vat registered companies always have a hidden default tax code
    // so we check for the options length instead
    if (_taxCodeField()[0].length > 1) {
      $("#bank_rule_global_category").change(setDefaultTaxCodeForBankRuleCategory);
    }
    // FARMPLAN
    $('#bank_rule_name').focus();
  }

  function _taxCodeField() {
    return $("#bank_rule_tax_code_id");
  }

  function setDefaultTaxCodeForBankRuleCategory(event) {
    const $dropdown = $(event.target);
    const url = Routes.get_tax_codes_for_account_companies_path({
      format: 'json',
      global_id: $dropdown.val()
    });

    getJsonOrShowMaintenanceModal(url, function(data) {
      updateTaxCodesDropdown($dropdown, data)
    });
  };

  function updateTaxCodesDropdown($dropdown, data) {
    if (data == null) { return; }
    _taxCodeField().selectPopulator(data.tax_codes);

    const default_tax_code = $dropdown.find(':selected').data('default-tax-code-id');
    if ( typeof(default_tax_code) === 'undefined' ) { return; }
    _taxCodeField()
      .val(default_tax_code)
      .trigger('change')
      .trigger('chosen:updated');
  }
})();
