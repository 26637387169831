window.initQuickConverter = function(section) {
  var $section = $(section);
  if ($section.find('.calculator-button').length === 0) { return }

  [ [ '.calculator-button', 'click', showQuickConversionModal ],
    [ '.qc_submit', 'click', updateConversionField ],
    [ '.qc_origin_total_amount', 'input', updateModalResultField ],
    [ '.qc_base_total_amount', 'input', updateModalResultField ]
  ].forEach(function(args) {
    addQuickConverterEventListener.apply(null, [ $section ].concat(args));
  });
}

function addQuickConverterEventListener(section, selector, event, fn) {
  errors.tryOn(section.find(selector), event, fn);
}

function showQuickConversionModal(event) {
  var row_id = $(event.currentTarget).closest('tr').data('id');
  var inv_modal = $('#calculator-modal'+row_id);
  var total = $('#total_amount_field'+row_id).val();
  var original_amount_field = $('.qc_origin_total_amount');
  var row_id_field = $('.qc_row_id');
  var currency_field = $('#currency_field'+row_id);
  var rate = $('#conversion_rate_field'+row_id).val();
  var base_amount_field = $('.qc_base_total_amount');
  var result_field = $('.conversion_results');

  if (rate > 0) {
    base_amount = Math.floor((total * rate) * 100 + 0.0000001) / 100.0;
    base_amount_field.val(base_amount);
    result_field.val(rate);
  }

  if (currency_field.val() == companySettings.base_currency.id) {
    original_amount_field.val("");
    base_amount_field.val(total);
  } else {
    original_amount_field.val(total);
    base_amount_field.val("");
  }

  row_id_field.val(row_id);
  $('.qc_base_total_amount').focus();
}

var PRECISION = 1000000000000.0;
function updateConversionField() {
  var row_id = $('.qc_row_id').val();
  var rate_field = $('#conversion_rate_field'+row_id);
  var old_amount = $('#qc_origin'+row_id).val();
  var new_amount = $('#qc_base'+row_id).val();
  var rate = Math.floor((new_amount / old_amount) * PRECISION + 0.00000000001) / PRECISION;
  rate_field.val(rate).trigger('change');
}

function updateModalResultField() {
  var result_field = $('.conversion_results:visible');
  var old_amount = $('.qc_origin_total_amount:visible').val();
  var new_amount = $('.qc_base_total_amount:visible').val();
  // rate 6dp
  var rate = Math.floor((new_amount / old_amount) * PRECISION + 0.00000000001) / PRECISION;
  if (old_amount != "" && new_amount != "" && rate > 0 && rate != Infinity) {
    result_field.val(rate);
    $('.qc_submit').removeAttr('disabled');
  } else if (old_amount != "" && new_amount != "" && rate == 0) {
    result_field.val('Conversion Rate is 0');
    $('.qc_submit').attr('disabled', 'disabled');
  } else {
    result_field.val('Value missing or invalid...');
    $('.qc_submit').attr('disabled', 'disabled');
  }
}
