//= require helpers/forms/field_state_selector
(function() {
  'use strict';

  window.BatchBtFieldStateSelector = function(formObject) {
    this._formObject = formObject;
  };

  BatchBtFieldStateSelector.prototype = Object.create(FieldStateSelector.prototype);

  FieldStateSelector.prototype._selectFieldStateSelectOption = function($field, field_state) {
    switch(field_state) {
      case 0:
        this._selectFieldStateSelectOptionEnabled($field);
        return;
      case 2:
        this._selectFieldStateSelectOptionDisabled($field);
        return;
      default:
        throw('Unexpected field_state ' + field_state + ' in _selectFieldStateSelectOption')
    }
  };

  BatchBtFieldStateSelector.prototype._selectFieldStateTextReadonly = function($field) {
    $field.attr('readonly', true);
    if (!this._formObject._changingTransactionType) { return; }
    if (this._formObject.getFlagValue('isImported') && !this._formObject.getFlagValue('isInvoiceBased') && $field.hasClass('total_amount_field')) {
      // For an imported bank transaction, the total field is always readonly, but we've reset the net and tax to default
      // values in setFieldStateEnabled so need to do the same for the total field
      this._resetToDefaultValue($field);
    }
  };

  BatchBtFieldStateSelector.prototype._selectFieldStateSelectOptionEnabled = function($field) {
    if ($field.prop('disabled') === true ) { // Only trigger chosen updated if the value has actually changed
      $field.prop('disabled', false);
      this._formObject.queueSelectForUpdate($field.closest('select').attr('id'));
    }
  };

  BatchBtFieldStateSelector.prototype._selectFieldStateSelectOptionDisabled = function($field) {
    if ($field.prop('disabled') === false ) { // Only trigger chosen updated if the value has actually changed
      $field.prop('disabled', true);
      $field.prop('selected', false);
      this._formObject.queueSelectForUpdate($field.closest('select').attr('id'));
    }
  };

  FieldStateSelector.prototype._selectFieldStateCheckbox = function($field, field_state) {
    switch(field_state) {
      case 0:
        this._selectFieldStateCheckboxEnabled($field);
        return;
      case 2:
        this._selectFieldStateCheckboxDisabled($field);
        return;
      default:
        throw('Unexpected field_state ' + field_state + ' in _selectFieldStateCheckbox')
    }
  };

  BatchBtFieldStateSelector.prototype._selectFieldStateCheckboxEnabled = function($checkbox) {
    if ($checkbox.prop('disabled') === true ) {      
      var $totalAmountField = $('#total_amount_field'+$checkbox.closest('tr').data('id'));
      var $typeField = $('#type_field'+$checkbox.closest('tr').data('id'));
      $totalAmountField.css('color', '#939192');
      $totalAmountField.removeAttr('title');

      $checkbox.prop('disabled', false);
      if (btTypeIsBankTransfer($typeField.val())) { $checkbox.prop('checked', false).click(); }
      $checkbox.removeAttr('title');
    }
  };

  BatchBtFieldStateSelector.prototype._selectFieldStateCheckboxDisabled = function($checkbox) {
    if ($checkbox.prop('disabled') === false ) {
      var $totalAmountField = $('#total_amount_field'+$checkbox.closest('tr').data('id'))
      var $typeField = $('#type_field'+$checkbox.closest('tr').data('id'));
      $totalAmountField.css('color', '#F44336');
      $totalAmountField.attr('title', 'The total amount is expected to be ' + $totalAmountField.data('expected-total'));

      $checkbox.prop('disabled', true);
      if (btTypeIsBankTransfer($typeField.val())) { $checkbox.prop('checked', true).click(); }
      $checkbox.attr('title', 'The total amount is expected to be ' + $totalAmountField.data('expected-total'));
      $checkbox.removeAttr('checked');
    }
  };
})();
