var CONTROLLER_URL = '/third_party/companies_house/'
var GET_COMPANIES_SEARCH_URL = CONTROLLER_URL + 'get_companies?company_name=';
var GET_COMPANY_INFO_URL = CONTROLLER_URL + 'get_company_info?company_reg_no=';

window.callCompanyInfoEndpoint = function(reg_val, response_function, reset_function){
  getDataFromCompaniesHouseAndCallFunction(GET_COMPANY_INFO_URL + reg_val, response_function, reset_function)
}

window.callCompaniesSearchEndpoint = function(selectedSearchTerm, response_function, reset_function){
  getDataFromCompaniesHouseAndCallFunction(GET_COMPANIES_SEARCH_URL + selectedSearchTerm.toUpperCase(), response_function, reset_function)
}

function getDataFromCompaniesHouseAndCallFunction(url, response_function, reset_function){
  getJsonOrShowMaintenanceModal(url, function(response) {
    if (response && !$.isEmptyObject(response)) {
      if ( !response.error && response.result != "company-profile-not-found" ) {
        response_function(response);
      }
    } else {
      if(reset_function != null && reset_function != 'undefined' && typeof reset_function === "function") {
        reset_function();
      }
    }
  });
}
