(function() {
  'use strict';

  function initBulkActionForm() {
    toggleFormSubmitAction('#bank_rule_transactions_bulk_action_form', '#bulk_edit_confirmed_transactions_button');
    toggleFormSubmitAction('#bank_rule_transactions_bulk_action_form', '#bulk_destroy_confirmed_transactions_button');
  }

  function _getAcBtIndexTable() {
    return $('#auto_confirm_bank_transactions_table');
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if (_getAcBtIndexTable().length === 0) { return }
    initTransactionsTable(_getAcBtIndexTable());
    initBulkActionForm();
  }));
})();