(function() {
  var DEFAULT_DICT_MESSAGE = (
    "<div class='dz_upload_message'>" +
      "<i class='zmdi zmdi-upload zmdi-hc-3x'></i>" +
      "<p>Drag & drop a file here to upload</p>" +
      "<button type='button' class='btn btn-sm btn-primary btn-icon-text waves-effect'>" +
        "<i class='zmdi zmdi-search m-r-10'></i>" +
        "Browse files" +
      "</button>" +
    "</div>"
  );

  var PREVIEW_TEMPLATE = (
    "<div class='dz-preview dz-file-preview'>" +
      "<div class='dz-details'>" +
        "<div class='preloader pl-lg upload_preloader'>" +
          "<svg class='pl-circular' viewBox='25 25 50 50'>" +
            "<circle class='plc-path' cx='50' cy='50' r='20'></circle>" +
          "</svg>" +
        "</div>" +
      "</div>" +
      "<div class='dz-progress'>" +
        "<div class='dz-upload upload_text' data-dz-uploadprogress>" +
          "Uploading file..." +
        "</div>" +
      "</div>" +
    "</div>"
  );

  /**
  * This function initialize the Modal for receipt uploads.
  * It should be called once.
  */
  window.initNewAttachmentsModal = function($modal) {
    initModalDropzone($modal);
    initAttachmentsList($modal);
    buildAvailableUploads($modal); // Necessary when reloading the form
  }

  function initModalDropzone($modal) {
    var $dropzone = $modal.find('.dropzone');
    var existingDropzone = $dropzone[0].dropzone;

    if (existingDropzone) {
      existingDropzone.destroy();
    }

    var $submit_button = $modal.find('button[type="submit"]');

    $dropzone.dropzone({
      url: '/third_party/google_cloud/storages.js',
      dataType: 'script',
      uploadMultiple: false,
      parallelUploads: 1,
      maxFiles: 1,
      dictDefaultMessage: DEFAULT_DICT_MESSAGE,
      previewTemplate: PREVIEW_TEMPLATE,

      sending: function() {
        $submit_button.prop("disabled",true);
      },

      success: function(data) {
        var upload_id = data.xhr.responseText; // Response Text, from gcs/uploads/create.js

        if (upload_id.length > 0) {
          addNewFileToUploadIds(upload_id, $modal);
          addToAllUploads(upload_id);
          $modal.find('.new-uploads-list')
                .append('<div class="col-sm-6 col-sm-offset-2">' +
                          '<h4><i class="zmdi zmdi-check-all zmdi-hc-fw c-green zmdi-hc-lg"></i>' +
                            data.upload.filename +
                          '</h4>' +
                        '</div>');
        } else {
          // Dropzone succeeded with its task,
          // but the file failed model validation
          notify('File failed to upload', "danger", "Error! ");
        }
      },

      complete: function(file) {
        this.removeFile(file);
        $submit_button.prop("disabled",false);
      }
    });
  }

  function addNewFileToUploadIds(id, $modal) {
    var $upload_ids = getSelectedUploadIdsElement($modal);
    var upload_ids = parseCsvToArray($upload_ids.val());
    upload_ids.push(id);
    updateUploadIds($upload_ids, upload_ids, true);
  }

  function getSelectedUploadIdsElement($modal) {
    var upload_elements = $modal.find('.upload_ids');
    if (upload_elements.length > 0) {
      return upload_elements;
    } else {
      return $($modal.data('current_record_upload_ids'));
    }
  }

  function initAttachmentsList($modal) {
    var $upload_ids = $modal.find('.modal-body').find('.upload_ids');

    $upload_ids.on('change', function(event, isNewFile) {
      if (isNewFile) {
        notify('File uploaded', "success", "Success! ");
      }
    });
  }

  function addToAllUploads(upload_id) {
    var value = $('body').data('all_upload_ids');
    var available_uploads = value || [];
    available_uploads.push(upload_id);
    $('body').data('all_upload_ids', available_uploads);
  }

  function buildAvailableUploads($modal) {
    var available_uploads = $modal.find('.upload_ids')
      .map(function() { return this.value; })
      .get().join().split(',')
      .filter(Boolean); // to remove empty string

    $('body').data('all_upload_ids', available_uploads);
  }

  window.removeFromAllUploads = function(upload_id) {
    var value = $('body').data('all_upload_ids');
    var available_uploads = value || [];
    available_uploads = available_uploads.filter(function(id) { return id != upload_id });
    $('body').data('all_upload_ids', available_uploads);
  }

  /**
  * This function initialize the Edit Modal for receipt uploads.
  * It should be called each time a new edit form is open.
  */
  window.initEditAttachmentsModal = function($modal) {
    initExistingFilesTab($modal);
    initModalDropzone($modal);
    initAttachmentsList($modal);

    selectFirstTab($modal);
  }

  window.initExistingFilesTab = function($modal) {
    var $upload_ids = getSelectedUploadIdsElement($modal);

    var $container = $modal.find('.available-uploads');
    $container.html('');

    var $loader = $modal.find('.available-uploads-loader');
    $loader.show();

    $.ajax({
      url: generateAvailableUploadsUrl(1, $modal, $upload_ids),
      method: 'get',
      dataType: 'html'
    }).done(function(html) {
      $container.data('last-page', 1);

      $container.html(html);
      initCheckboxes($modal, $upload_ids);
      initModalScrolling($modal);
      $container.show();

      $loader.hide();
    });
  }

  function generateAvailableUploadsUrl(page, $modal, $upload_ids) {
    var uploads_checked_ids = parseCsvToArray($upload_ids.val());
    var uploads_hidden_ids = $($('body').data('all_upload_ids')).not($(uploads_checked_ids)).get();
    return '/uploads/available_uploads?page=' + page + '&hidden=' + uploads_hidden_ids.join('-') + '&checked=' + uploads_checked_ids.join('-');
  }

  function initCheckboxes($modal, $upload_ids) {
    $modal.find('.available_file').unbind('change');
    initCheckboxListener($modal);
    $modal.find('.available_file').change(function() {
      var selected_id = this.name;

      if (this.checked) {
        addIdToUploadIds(selected_id, $upload_ids);
        addToAllUploads(selected_id);
      } else {
        removeIdFromUploadIds(selected_id, $upload_ids);
        removeFromAllUploads(selected_id);
      }
    });
  }

  function initCheckboxListener($modal) {
    $modal.find('.available_upload_card').each(function(_i, elem) {
      elem.removeEventListener('click', onUploadCheckboxToggled, true);
      elem.addEventListener('click', onUploadCheckboxToggled, true)
    })
  }

  function onUploadCheckboxToggled() {
    var $checkbox = $(this).find('.available_file');
    var isChecked = $checkbox.prop('checked');
    $checkbox.prop("checked", !isChecked).trigger('change');
    return false;
  }

  function addIdToUploadIds(id, $upload_ids) {
    var upload_ids = parseCsvToArray($upload_ids.val());
    upload_ids.push(id);
    updateUploadIds($upload_ids, upload_ids, false);
  }

  function removeIdFromUploadIds(id, $upload_ids) {
    var upload_ids = parseCsvToArray($upload_ids.val());
    upload_ids = upload_ids.filter(function(id) { return id != id });
    updateUploadIds($upload_ids, upload_ids, false);
  }

  function selectFirstTab($modal){
    if ($modal.find('.upload_ids').val() !== "" && $modal.is('#modal-upload-edit')) {
      $modal.find('a[href*="#show-files-tab-edit"]').tab('show');
    } else if ($modal.hasClass('message-attachment-modal')) {
      $modal.find('a[href*="#attach-files-tab-"]').tab('show');
    } else {
      $modal.find('a[href*="#select-files-tab-"]').tab('show');
    }
  }

  /**
  * This function get the result for the next page of the existing files tab.
  */
  window.getExistingFilesResult = function($modal) {
    var $upload_ids = getSelectedUploadIdsElement($modal);

    var $container = $modal.find('.available-uploads');
    var page = $container.data('last-page');
    page++;

    var $loader = $modal.find('.available-uploads-loader');
    $loader.show();

    $.ajax({
      url: generateAvailableUploadsUrl(page, $modal, $upload_ids),
      method: 'get',
      dataType: 'html'
    }).done(function(html) {
      $container.data('last-page', page);

      var isLastPage = new RegExp("No Files Available").exec(html);
      if (!isLastPage) {
        $container.append(html);
        initCheckboxes($modal, $upload_ids);
        initModalScrolling($modal);
      }
      $loader.hide();
    });
  }

  /**
  * Called after updating, deleting or detaching files.
  */
  window.reloadAttachments = function(url) {
    var $attached_uploads_div = $('.attached-uploads');
    var $attached_uploads_loader_div = $('.attached-uploads-loader');

    $attached_uploads_div.html('');
    $attached_uploads_loader_div.show();

    $.ajax({
      url: url,
      method: 'get',
      dataType: 'html'

    }).done(function(html) {
      $attached_uploads_div.html(html);
      $attached_uploads_loader_div.hide();
    });
  }

  /**
    * This function setup the current upload_ids and open the Modal.
  */
  window.openBatchUploadsModal = function(event) {
    var rowId = $(event.currentTarget).closest('tr').data('id');
    initAttachmentsModalForSelection(rowId, '#modal-upload-new');
    return false;
  }

  function initAttachmentsModalForSelection(rowId, modal_id) {
    var $modal = $(modal_id);
    $modal.data('current_record_upload_ids', "#upload_ids" + rowId);
    initExistingFilesTab($modal);
    $('.new-uploads-list').html('')

    selectFirstTab($modal);
    $modal.modal('show');
  }

  /**
    * This function setup the upload_ids for non batch document and open the Modal.
  */
  window.openNonBatchUploadsModal = function() {
    initAttachmentsModalForSelection('', '#modal-upload-new');
    return false;
  }

  /**
    * This function sets up the upload_ids for a message and opens the Modal.
  */
  window.openMessageAttachmentsModal = function() {
    initAttachmentsModalForSelection('', '#modal-message-attachment-new');
    return false;
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#modal-upload-new').length === 0) { return }
    initNewAttachmentsModal($('#modal-upload-new'));
  }));
})();
