(function(){

window.initLineChart = function(div, line_data, tooltipContent) {
  $.plot(div, line_data, {
    grid: {
      borderWidth: 1,
      borderColor: '#eee',
      show : true,
      hoverable : true,
      clickable : true
    },
    yaxis: {
      tickColor: '#eee',
      tickDecimals: 0,
      font: {
        lineHeight: 13,
        style: 'normal',
        color: '#9f9f9f',
      },
      shadowSize: 0
    },
    xaxis: {
      mode: 'categories',
      tickColor: '#fff',
      tickDecimals: 0,
      font: {
        lineHeight: 13,
        style: 'normal',
        color: '#9f9f9f'
      },
      shadowSize: 0,
    },
    series: {
      lines: { show: true },
      points: {
        radius: 3,
        fill: true,
        show: true
      }
    },
    tooltip: true,
    tooltipOpts: {
      content: tooltipContent,
      shifts: {
        x: 20,
        y: 0
      }
    }
  });
}


window.initLineChartOrDisplayNoData = function(chart, tooltipContent) {
  var chart_data = chart.data('value');

  if (chart_data && chart_data.length > 0) {
    initLineChart(chart, chart_data, tooltipContent);
  } else {
    initLineChart(chart, [ { 'data' : [] } ], "");
  }
}

})()
