window.initSummernote = function() {
  $('.summernote').summernote({
    toolbar : [
      // [groupName, [list of button]]
      [ 'style', [ 'bold', 'italic', 'underline', 'clear' ] ],
      [ 'para', [ 'ul', 'ol' ] ],
      [ 'insert', [ 'link' ] ]
    ],
    disableResizeEditor: true,
    disableDragAndDrop: true,
  });
  $('.note-statusbar').hide();
}

window.initSummernoteForNotes = function() {
  $('.summernote').summernote({
    toolbar : [
      // [groupName, [list of button]]
      [ 'style', [ 'bold', 'italic', 'underline' ] ],
      [ 'font', [ 'strikethrough' ] ],
      [ 'para', [ 'ul', 'ol' ] ]
    ],
    toolbarContainer: '#note_toolbar',
    disableResizeEditor: true,
    disableDragAndDrop: true,
    placeholder: 'Enter your text here...'
  });
  $('.note-statusbar').hide();
}
