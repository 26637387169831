/**
* @fileoverview
*
* Use this method in the case where an AJAX request can gets the HTML maintenance page back.
* Displays a modal, and stops us getting errors in Sentry when trying to parse it
*/
(function() {
  'use strict';

  /*
    Wraps $.getJSON so we can override the JSON.parse to check for maintenance
  */
  window.getJsonOrShowMaintenanceModal = function(url, data, success){
    // shift arguments if data argument was omitted
    if ($.isFunction(data)) {
      success = data;
      data = undefined;
    }

    return $.ajax({
      dataType: 'json',
      url: url,
      data: data,
      success: function(result){
        if ( result == 'maintenance_mode') {
          // Do nothing more
        } else {
          success(result);
        }
      },
      converters: {
        'text json': function(result) {
          return JSON.showMaintenanceModalOrParse(result);
        }
      }
    });
  };

  /*
    Wrap JSON.parse
  */
  JSON.showMaintenanceModalOrParse = function(text, reviver) {
    if ( text.startsWith("<!DOCTYPE html>") && text.match('maintenance') ){
      showMaintenanceModal();
      return 'maintenance_mode';
    } else {
      return JSON.parse(text, reviver);
    }
  }

  function showMaintenanceModal(){
    logMessage("A user saw the maintenance modal...", { extra: {
      location: window.location.href
    }});
    errors.showModal(
      document.createTextNode("We'll be right back!"),
      createMaintenanceModal());
  }

  function createMaintenanceModal(){
    return [
      $('<div>').addClass('modal-body').html(
        '<p>' +
          "We're sorry for the inconvenience but we're just updating our servers to improve your experience." +
        '</p>' +
        '<p>' +
          'Please try again in a few minutes or ' +
          'try <a href="' + window.location.href + '">refreshing the page</a>' +
        '</p>' +
      '</div>'),
      $('<div>').addClass('modal-footer').html(
        '<button class="btn-danger btn waves-effect" data-dismiss="modal" id="">' +
          '<i class="zmdi zmdi-close zmdi-hc-fw m-r-10"></i>Close' +
        '</button>' +
      '</div>')
    ];
  }

})();