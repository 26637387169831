FancyEllipsisIconComponent = {
  'render': function(){
    return '<div class="fancy-ellipsis-icon" data-active="false" />'
  },
  'jQuerySelector': function(){
    return 'div.fancy-ellipsis-icon';
  },
  'setupOn': function(elementId, shouldDeactivateAfterMouseoutFunction, onClickFunction){
    var elementSelector = "#" + elementId;
    
    $(elementSelector).on('mouseover', 'div.fancy-ellipsis-icon', function(event) { 
      FancyEllipsisIconComponentState.makeActive(event.target); 
    });
    
    $(elementSelector).on('mouseout', 'div.fancy-ellipsis-icon', function(event) { 
      var shouldDeactivate = shouldDeactivateAfterMouseoutFunction ?
        shouldDeactivateAfterMouseoutFunction(event.target) :
        true
      ;
      
      if(shouldDeactivate) FancyEllipsisIconComponentState.makeInactive(event.target);
    });
    
    $(elementSelector).on('click', 'div.fancy-ellipsis-icon', function(event) { onClickFunction(event.target) });
  },
  'activate': function(element){ 
    FancyEllipsisIconComponentState.makeActive(element); 
  },
  'deactivate': function(element){ 
    FancyEllipsisIconComponentState.makeInactive(element); 
  },
  'isActive': function (element){ 
    return FancyEllipsisIconComponentState.isActive(element); 
  }
}