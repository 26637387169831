window.typeAheadInput = function(input_field, matcher_field) {
  if (cancelTypeahead(input_field)) { return }

  input_field.typeahead({
    hint: false,
    minLength: 1
  },
  {
    name: 'descriptions',
    source: substringMatcher(input_field, setArray(matcher_field))
  });
};

function substringMatcher(input_field, strs) {
  return function findMatches(q, cb) {
    var matches, substringRegex;

    // an array that will be populated with substring matches
    matches = [input_field.val()];

    // Escape special characters - http://stackoverflow.com/a/9310752
    q = q.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

    // regex used to determine if a string contains the substring `q`
    substrRegex = new RegExp(q, 'i');

    // iterate through the pool of strings and for any string that
    // contains the substring `q`, add it to the `matches` array
    $.each(strs, function(i, str) {
      if (substrRegex.test(str)) {
        matches.push(str);
      }
    });

    for(i = 0; i < matches.length; i++) {
      matches[i] = matches[i].replace(/\\r\\n/g, '\r\n');
    }

    triggerAutoSize();

    cb(matches);
  };
};

function setArray(matcher_field) {
  var matchers = matcher_field.text();
  matchersArray = matchers.substring(2, matchers.length-2).split('", "');
  return matchersArray;
};

function triggerAutoSize() {
  $('.typeahead').bind('typeahead:select', function(ev, suggestion) {
    autosize.update($('.auto-size'));
  });
}

function cancelTypeahead(field) {
  return $(field).is('[readonly]') || $(field).is('[disabled]');
}
