/**
* @fileoverview Class and helper method to trigger browser 'are you sure' alerts
*               when the user attempts to leave a page with unsaved data.
*/

(function() {
  'use strict';

  /**
   * Legacy interface
   * @param form_parent_id
   */
  window.checkFormChangedOnPageUnload = function(form_parent_id) {
    // return new AreYouSureController($(form_parent_id)).initialize();
  };
  /**
   * Class that takes a form element and FormChangeObserver object and adds an
   * event listener to trigger a browser dialogue requesting user confirmation
   * if they try to unload the page after the form has been changed.
   *
   * This class can be disposed, which will remove any attached event listeners
   * and those of the FormChangeObserver via its own dispose method.
   *
   * @param $form_parent {jQuery}
   * @param observer {FormChangeObserver=} defaults to FormChangeObserver
   * @constructor
   */
  window.AreYouSureController = function($form_parent, observer) {
    observer = observer || new FormChangeObserver($form_parent);
    this._observer = observer;
    this._pageExitMessage = null;
    this._listeners = [];
    this._isDisposed = false;
  }
  /**
   * @type {string}
   */
  AreYouSureController.defaultPageExitMessage = 'You have entered data into a form on this page.';
  /**
   *
   * @returns {AreYouSureController}
   */
  AreYouSureController.prototype.initialize = function() {
    this._addPageUnloadEventListener(); // for non-turbo requests
    this._addTurboEventListener(); // for turbo requests
    return this;
  };
  /**
  * Set a non-default page exit message
  */
  AreYouSureController.prototype.setPageExitMessage = function(message) {
    this._pageExitMessage = message;
  }
  /**
   * Clean up form observer.
   */
  AreYouSureController.prototype.dispose = function() {
    if(!this._isDisposed) {
      this._observer.dispose();
      this._observer = null;
      this._pageExitMessage = null;
      this._removeEventListeners();
      this._isDisposed = true;
    }
  };
  /**
   *
   * @private
   */
  AreYouSureController.prototype._addPageUnloadEventListener = function() {
    this._addEventListener(window, 'beforeunload', this._onBeforeUnload.bind(this));
  };
  /**
   * When this callback is fired, the browser will display a confirmation
   * dialogue so the user can interrupt page unloading. This only happens if a
   * string is returned. Some browsers will display the contents of this string,
   * however the latest versions of Chrome and Firefox will display a generic
   * message instead.
   *
   * @param event
   * @returns {string|void} Message to display in browser alert window, if
   *                        supported
   * @private
   */
  AreYouSureController.prototype._onBeforeUnload = function(event) {
    if (this._observer.isStale()) {
      this.dispose();

      var msg = AreYouSureController.defaultPageExitMessage;
      AreYouSureController._setEventReturnValue(event, msg);

      return msg;
    }
  };
  /**
   * This is to cope with browser quirks.
   *
   * @param event
   * @param msg {string}
   * @returns {string}
   * @private
   * @static
   */
  AreYouSureController._setEventReturnValue = function(event, msg) {
    (event || window.event).returnValue = msg;
  };
  /**
   * @private
   */
  AreYouSureController.prototype._addTurboEventListener = function() {
    this._addEventListener(document, 'turbo:before-visit', this._onBeforeVisit.bind(this));
  };
  /**
   * When this callback is fired, the browser will display a confirmation
   * dialogue so the user can interrupt page unloading.
   *
   * @param event
   * @private
   */
  AreYouSureController.prototype._onBeforeVisit = function(event) {
    if (this._observer.isStale()) {
      var msg = this._pageExitMessage || AreYouSureController.defaultPageExitMessage;
      this.dispose();

      if ( window.hasOwnProperty('confirm')) {
        // window.confirm is optional, but exists in all modern browsers
        // In IE7, we just won't have an 'are you sure' - seems acceptable?
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/confirm
        var are_they_sure = confirm('Are you sure you want to leave this page? \n ' + msg);

        AreYouSureController._setEventReturnValue(event, are_they_sure);
      }
    }
  };
  /**
   * Add an event listener and store the arguments so we can remove it later
   * @param target
   * @param type
   * @param handler
   * @private
   */
  AreYouSureController.prototype._addEventListener = function(target, type, handler) {
    target.addEventListener(type, handler);
    this._listeners.push({ target: target, type: type, handler:  handler});
  };
  /**
   *
   * @private
   */
  AreYouSureController.prototype._removeEventListeners = function() {
    this._listeners.forEach(function(ob) {
      ob.target.removeEventListener(ob.type, ob.handler);
    });
  };
})();
