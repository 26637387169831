(function() {

  window.copyToClipboardOnClick = function() {
    if ($('.copy-link-button').length === 0) { return; }
    $('.copy-link-button').click(function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      var link = $(this).attr('data-transaction-link');
      var dummy_span = $('<input>').val(link).appendTo('body').select();
      document.execCommand("Copy");
      $(dummy_span).remove();
      notify('Link Copied to Clipboard!', 'success');
    });
  }
})();