(function() {
  'use strict';

  window.HELP_SUCCESS = 'success';
  window.HELP_INFO = 'info';
  window.HELP_WARNING = 'warning';
  window.HELP_ERROR = 'danger';

  var HELP_BADGES = {
    success : '<span class="badge bgm-green"><i class="zmdi zmdi-check-circle"></i></span>',
    info : '<span class="badge bgm-blue"><i class="zmdi zmdi-info-outline"></i></span>',
    warning : '<span class="badge bgm-orange"><i class="zmdi zmdi-alert-circle"></i></span>',
    danger : '<span class="badge bgm-red"><i class="zmdi zmdi-close-circle"></i></span>'
  };

  /**
   * Create a help alert for the given element.
   *
   * Displays the errorMessage in the help pane and adds
   * a notification to the pane.
   *
   * The title of the errorMessage is taken from the data-help-title
   * of the given element.
   *
   * @param element Element that the error originated from
   * @param errorLevel The error level. Either 'error', 'warning' or 'info'
   * @param errorMessage The message to be displayed in the notifications tab
   */
  window.addHelpMessage = function(element, errorLevel, errorMessage) {
    _addNotification(_createNotificationMarkup(errorLevel, errorMessage));
  };
  /**
   * Create a help alert.
   *
   * Displays the errorMessage in the help pane and adds
   * a notification to the pane.
   *
   * @param title Error message title
   * @param errorLevel The error level. Either 'error', 'warning' or 'info'
   * @param errorMessage The message to be displayed in the notifications tab
   */
  window.addHelpMessageWithTitle = function(title, errorLevel, errorMessage) {
    _addNotification(_createNotificationMarkup(errorLevel, errorMessage, title, null));
  };
  /**
   * Create a help alert, unless one with this id is already shown
   *
   * Displays the errorMessage in the help pane and adds
   * a notification to the pane.
   *
   * @param title Error message title
   * @param errorLevel The error level. Either 'error', 'warning' or 'info'
   * @param errorMessage The message to be displayed in the notifications tab
   * @param id The id to check for
   */
  window.addHelpMessageUnlessExists = function(title, errorLevel, errorMessage, id) {
    if (!_notificationIsRendered(id)) {
      _addNotification(_createNotificationMarkup(errorLevel, errorMessage, title, id));
    }
  };
  /**
  * If a modal is open, create a growl notification
  * Otherwise, create a help message unless the identical message is already being shown
  **/
  window.addHelpMessageOrNotification = function(helpMessageErrorLevel, notificationErrorLevel, errorMessage, id){
    if($('.modal:visible').length){
      notify(errorMessage, notificationErrorLevel);
    } else {
      if (!_notificationIsRendered(id)) {
        _addNotification(_createNotificationMarkup(helpMessageErrorLevel, errorMessage, undefined, id));
      }
    }
  }

  window.setErrorOrAnnouncementIconValue = function(item_count) {
    $('#item-count-container').html(item_count);
    _errorOrAnnouncementCountChange(item_count);
  }

  function _errorOrAnnouncementCountChange(item_count) {
    if (item_count > 0) {
      _showCountIcon();
    } else {
      _hideCountIcon();
    }
    if (!$('#items-content').find('.lv-item').length) {
      _hideClearAllButton();
    }
  }

  /**
   * @param errorLevel {string}
   * @param errorMessage {string}
   * @param title {string=}
   * @param id {string=}
   * @returns {string}
   * @private
   */
  function _createNotificationMarkup(errorLevel, errorMessage, title, id){
    return _createOpenTags(id, errorLevel) + _createBody(title, errorMessage) + _createCloseTags();
  }

  /**
   * @param id {string}
   * @param errorLevel {string}
   * @returns {string}
   * @private
   */
  function _createOpenTags(id, errorLevel){
    return '<div class="lv-item"' + _createIdAttribute(id) +'>' +
      '<div class="media">' +
        '<div class="pull-left">' + HELP_BADGES[errorLevel] + '</div>' +
        '<div class="pull-right">' +
          '<button class="clear-notification-button btn btn-success btn-sm waves-effect waves-float" title="Dismiss this notification">' +
            '<i class="zmdi zmdi-check"></i>' +
          '</button>' +
        '</div>' +
        '<div class="media-body">';
  }
  /**
   *
   * @param id
   * @returns {string}
   * @private
   */
  function _createIdAttribute(id){
    return id ? 'id=' + id : '';
  }
  /**
   *
   * @param title
   * @param msg
   * @returns {string}
   * @private
   */
  function _createBody(title, msg){
    return _createTitle(title) + msg;
  }
  /**
   *
   * @param title
   * @returns {string}
   * @private
   */
  function _createTitle(title){
    return title ? '<div class="lv-title">'+title+'</div>' :'';
  }
  /**
   *
   * @returns {string}
   * @private
   */
  function _createCloseTags(){
    return '</div></div></div>';
  }
  /**
   *
   * @param html
   * @private
   */
  function _addNotification(html){
    $('#items-content').append(html);
    _addClearNotificationButtonListener();

    var count = Number($('#item-count-container').text());
    count++;
    setErrorOrAnnouncementIconValue(count);
    _addGrowlForNotification();
  }

  function _addGrowlForNotification() {
    if ($(".growl-animated").text().indexOf('New Notifications') === -1) {
      notify('New Notifications!', 'success', '', '<i class="tm-icon zmdi zmdi-notifications"></i>', '', 100);
    }
  }

  /**
   *
   * @param id
   * @returns {boolean}
   * @private
   */
  function _notificationIsRendered(id){
    return $('#items-content #'+id).length > 0;
  }

  function _showCountIcon() {
    $('#notifications').removeClass('empty');
    $('#item-count-container').fadeIn();
    _showClearAllButton();
  }

  function _hideCountIcon() {
    clearAnnouncementsCounter();
    $('#notifications').addClass('empty');
  }

  /**
   *
   * @private
   */
  function _addClearNotificationButtonListener() {
    $('.clear-notification-button').last().click(_notificationClearButtonClick);
  }
  /**
   *
   * @param event
   * @private
   */
  function _notificationClearButtonClick(event) {
    $(event.currentTarget).closest('.lv-item').remove();
    var count = Number($('#item-count-container').text());
    if (count > 0) {
      count -= 1;
      setErrorOrAnnouncementIconValue(count);
    }

    if (!$('#items-content').find('.lv-item').length) {
      _hideClearAllButton();
    }

    event.stopImmediatePropagation();
  }

  function _showClearAllButton() {
    _getClearAllButton()
      .removeClass('hidden')
      .on('click', notificationClearAllButtonClick);
  }

  function _hideClearAllButton() {
    _getClearAllButton().fadeOut(_onNotificationButtonHidden);
  }

  function _getClearAllButton() {
    return $('#notifications').find('> .lv-header > .actions > li');
  }

  function _onNotificationButtonHidden(){
    $(this).addClass('hidden').css({ display: '' });
  }

  function notificationClearAllButtonClick(e) {
    e.preventDefault();

    var $container = $(this).closest('.listview');
    var $items = $container.find('.lv-item');
    var count = $items.size();

    $container.find('.list-group').prepend('<i class="grid-loading hide-it"></i>');
    $container.find('.grid-loading').fadeIn(1500);

    var delayOffset = 0;
    $items.each(function() {
      var z = $(this);
      setTimeout(function() {
        removeHelpNotification(z);
      }, delayOffset+=150);
    });

    //Popup empty message
    setTimeout(function() {
      _errorOrAnnouncementCountChange();
    }, (count*150)+200);

    _hideClearAllButton();
  };

  function removeHelpNotification(z) {
    z.addClass('animated fadeOutRightBig').delay(1000).queue(function() {
      z.hide();
    });
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    _getClearAllButton().on('click', notificationClearAllButtonClick);
  }));

})();
