(function() {
  'use strict';

  window.showPopover = function($popover) {
    $popover[0].dispatchEvent(new CustomEvent('show-popover'));
  }

  window.showPopoverWithContent = function($popover, content) {
    $popover[0].dispatchEvent(new CustomEvent('show-popover-with-content', {
      detail: {
        content: content
      }
    }));
  }

  window.showPopoverWithContainer = function($popover, container) {
    $popover[0].dispatchEvent(new CustomEvent('show-popover-with-container', {
      detail: {
        container: container
      }
    }));
  }

  window.updatePopoverText = function(event) {
    const $element = $(event.target);
    const $popover = $element.closest('span[data-controller="popover"]');  

    $popover[0].dispatchEvent(new CustomEvent('update-popover-content', {
      detail: {
        content: event.target.value
      }
    }));

    hideFieldIndicators($element.parents('.form-group'));
  };

  window.hideAllPopovers = function() {
    window.dispatchEvent(new CustomEvent('hide-all-popovers'));
  }

  window.destroyPopover = function($popover) {
    $popover[0].dispatchEvent(new CustomEvent('destroy-popover'));
  }
})();
