(function() {
  'use strict';

  function getProfitAllocation(row) {
    const profitShare = parseFloat($(row).find('.partner-share').val()) / 100;
    const capital = parseFloat($('#capital').val());
    const profitAllocation = capital * profitShare;
    const totalShares = Array.from($(".partner-share")).reduce((S,el) => S + Number(el.value),0);
    const totalAllocations = Array.from($(".partner-allocation")).reduce((S,el) => S + Number(el.value),0) - $(row).find('.partner-allocation').val();
    return totalShares === 100 ?  capital - totalAllocations : profitAllocation;
  }

  function setProfitAllocation(row, profitAllocation) {
    $(row).find('.partner-allocation').val(isNaN(profitAllocation) ? '' : profitAllocation.toFixed(2));
    return profitAllocation;
  }

  function getPartnerCapitalAccountId(row) {
    return $(row).data('capitalAccountId');
  }

  function setTransactionCreditAmount(account_id, amount) {
    $($('#amounts select option[value="'+ account_id +'"]:selected').closest('tr')[0]).find('.credit_amount').val(amount.toFixed(2));
    $($('#amounts select option[value="'+ account_id +'"]:selected').closest('tr')[0]).find('.debit_amount').val('');
  }

  function setTransactionDebitAmount(account_id, amount) {
    $($('#amounts select option[value="'+ account_id +'"]:selected').closest('tr')[0]).find('.debit_amount').val(-amount.toFixed(2));
    $($('#amounts select option[value="'+ account_id +'"]:selected').closest('tr')[0]).find('.credit_amount').val('');
  }

  function inProfit(amount) {
    if (amount < 0) {
      return false
    }
    return true;
  }

  function partnerShareChange(event) {
    const row = $(event.currentTarget).closest('.row');
    const profitAllocation = getProfitAllocation(row);
    setProfitAllocation(row, profitAllocation);
    if (inProfit(profitAllocation)) {
      setTransactionCreditAmount(getPartnerCapitalAccountId(row), profitAllocation);
    } else {
      setTransactionDebitAmount(getPartnerCapitalAccountId(row), profitAllocation);
    }
  }

  function requestProfitToBeAllocated(dateStart, dateEnd) {
    getJsonOrShowMaintenanceModal(getProfitToBeAllocatedUrl(dateStart, dateEnd), onProfitToBeAllocatedResponse);
  }

  function profitAllocationDateChange() {
    validateStartAndEndDates(
      $('#profit_allocation_date_start').val(),
      $('#profit_allocation_date_end').val()
    );
  }

  function validateStartAndEndDates(start, end) {
    if (start && end) {
      setJournalEntryDateAndRequestProfitToBeAllocated(start, end);
    } else {
      addProfitAllocationHelpMessage();
    }
  }

  function setJournalEntryDateAndRequestProfitToBeAllocated(start, end) {
    requestProfitToBeAllocated(start, end);
    if($('#journal_entry_date').val()){
      $('#journal_entry_date').val(end);
    }
  }


  function getProfitToBeAllocatedUrl(start, end) {
    return '/profit_allocation_journal_entries/profit_to_be_allocated.json?' +
      'date_start=' + encodeURIComponent(start) + '&date_end=' + encodeURIComponent(end);
  }

  function onProfitToBeAllocatedResponse(profitToBeAllocated)  {
    $('#capital').val(profitToBeAllocated);

    if (profitToBeAllocated >= 0) {
      $($('#amounts select option:contains("Profit to be Allocated"):selected').closest('tr')[0]).find('.debit_amount').val(profitToBeAllocated);
      $($('#amounts select option:contains("Profit to be Allocated"):selected').closest('tr')[0]).find('.credit_amount').val('');
    } else {
      $($('#amounts select option:contains("Profit to be Allocated"):selected').closest('tr')[0]).find('.debit_amount').val('');
      $($('#amounts select option:contains("Profit to be Allocated"):selected').closest('tr')[0]).find('.credit_amount').val(-profitToBeAllocated);
    }
    $('.partner-share').change();
  }

  function addProfitAllocationHelpMessage() {
    addHelpMessage(
      $('#profit_allocation_date_start'),
      HELP_ERROR,
      DATE_POPOVER_ERROR_CONTENT.substr(3, DATE_POPOVER_ERROR_CONTENT.length - 4)
    );
  }

  function initAreYouSureProfitAllocationWizard() {
    checkFormChangedOnPageUnload('#new_journal_entry');
  }

  function addProfitAllocationEventListeners() {
    $('#new_journal_entry').on('dp.change', profitAllocationDateChange);
    $('.partner-share').change(partnerShareChange);
    $('.partner-share').change();
  }

  function parseCapitalAmount() {
    const capital_field = $('#capital');
    const capital_amt = capital_field.val();
    capital_field.val(parseFloat(capital_amt).toFixed(2));
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#profit-allocation-journal-fields').length === 0) { return }
    addProfitAllocationEventListeners();
    parseCapitalAmount();
    initAreYouSureProfitAllocationWizard();
  }));
})();
