CreditableInvoiceModalDomSetter = {
  "setCreditTotalAndTotalAsComparisonSourceAndTarget": function(tableId){
    TableHelper
      .getTableRows(tableId)
      .each(function(){
        var totalElement = CreditableInvoiceModalTableComponent.getTotalElementFromRow(this);
        var creditTotalElement = CreditableInvoiceModalTableComponent.getCreditTotalElementFromRow(this);
  
        var comparisonSetId = $(creditTotalElement).attr('id');
        NumberComparison.setupElementAsAComparisonSource(creditTotalElement, comparisonSetId);
        NumberComparison.setupElementAsAComparisonTarget(totalElement, comparisonSetId);
      });
  }
}