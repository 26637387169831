/* Code that relates to adding stock items anywhere */
window.checkNeedForNewStockItemOption = function(section) {
  if (section) {
    var elements = section.find('select.js-add-new-stock-item');
  } else {
    var elements = $('select.js-add-new-stock-item');
  }

  if (elements.length === 0) { return }
  elements.on('chosen:ready', function(event, chosen) {
    var select = $(event.target);
    var chosen_container = select.siblings('div.chosen-container');
    addNewStockItemOption(chosen_container);
    setLinkToAddNewStockItem(select);
  });
}

function addNewStockItemOption(chosen_container) {
  if (chosen_container.find('button.js-new-stock-item-button').length === 0) {
    chosen_container.find('ul.chosen-results').after("<button class='btn btn-primary btn-xs pull-right js-new-stock-item-button' tabindex='-1'>+ Add new</button>")
    registerNewStockItemClickListener(chosen_container);
  }
}

function setLinkToAddNewStockItem(select) {
  select.siblings('a.add-new-stock-item-link').remove();
  select.after("<a href='/stock_items/new' data-remote='true' class='add-new-stock-item-link' tabindex='-1'><i class='add-new-stock-item-link'></i></a>")
}

function registerNewStockItemClickListener(chosen_container) {
  chosen_container.find('button.js-new-stock-item-button').click(function() {
    $('.chosen-with-drop').removeClass('chosen-with-drop');
    newStockItemButtonClicked($(this));
  });
}

function newStockItemButtonClicked(button) {
  button.addClass('new-stock-item-button-clicked');
  hideAllPopovers();
  showNewStockItemFormInModal(button);
}

function showNewStockItemFormInModal(button) {
  button.closest('div.chosen-container').siblings('a.add-new-stock-item-link').find('i').click();
}