(function() {
  'use strict';

  window.initProgramDateFields = function() {
    if(isFeatureToggleActive('financial_year_in_setup')){
      setFinancialYearDatePicker();
    } else {
      $('#company_prog_date_start').on('dp.change', onStartDateChange);
      $('#company_prog_date_end').on('dp.change', checkFinancialPeriod);
      checkFinancialPeriod();
    }
  };

  window.vatSchemeTypeChange = function(event) {
    var $element = $(event.currentTarget);
    var is_flat_rate_scheme = $element.find('option:selected').data('isFlatRate');
    showOrHideFormSectionWithBoolean(is_flat_rate_scheme, '.flat-rate-pct')
  };

  window.updateAddressFormWhenCountryChanges = function(country_field_selector){
    $(country_field_selector).on('change', adjustAddressFormOnCountryChange);
  }

  window.getProgramEndDateFromStart = function(date) {
    return date.add({ year : 1 }).subtract({ day : 1 });
  };

  function _setDisabled(disabled) {
    return function() {
      $(this).prop('disabled', disabled);
    }
  }

  function onStartDateChange(event) {
    setEndDate(getDateFromDateTimePicker($(event.target)));
  }

  function setEndDate(startDate) {
    if (startDate) {
      getDateTimePicker($('#company_prog_date_end')).date(getProgramEndDateFromStart(startDate));
    }
  }

  window.setFinancialYearDatePicker = function() {
    var company_type = getCompanyType();
    if(company_type === undefined){ return }
    if (["Limited Company", "Limited Liability Partnership (LLP)"].includes(company_type)) {
      setFinancialYearForLtdCompany();
    } else {
      setFinancialYearForNonLtdCompany();
    }
  }
  
  function getCompanyType(){
    var companyTyperId = $('#company_typer_id').val();
    if (companyTyperId) {
      return $('#company_typer_id').data('type-info')[companyTyperId];
    } else {
      return $('#company_company_type_name').val();
    }
  }

  function setFinancialYearForLtdCompany(){
    if($('#company_current_financial_year_date_end').length){
      $('#company_current_financial_year_date_end').attr('readonly', true);
    }
  }

  function setFinancialYearForNonLtdCompany(){
    var financial_year_selector = $('#company_prog_date_end').length ? '#company_prog_date_end' : '#company_current_financial_year';
    var $datePicker = getDateTimePicker($(financial_year_selector));
    $datePicker.minDate(moment());
    $datePicker.maxDate(moment().add(18, 'months'));
  }
})();
