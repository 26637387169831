/**
* @fileoverview
* Decorates the modal jQuery plugin, moving the bootgrid actions container into the header element if it exists
*/

(function() {
  'use strict';

  var _modal = $.fn.modal;
  $.fn.modal = modalDecorated;

  /**
   * Extends behaviour in
   * @link{http://getbootstrap.com/javascript/#modals-usage} with an optional
   * bootgrid_header key in the options object. This key defaults to true. If set to false, the bootgrid actions
   * container will not be moved to the modal's header.
   *
   * @param options
   */
  function modalDecorated(options) {
    if (!options || options.bootgridActions !== false) {
      decorate(this);
    }
    return _modal.apply(this, arguments);
  }

  /**
   * The bootgrid table may not be instantiated yet, and we need it to be in order to verify that the table is in fact
   * decorated by bootgrid, and so we can move the actions container if it has been rendered.
   *
   * We can work around this by allowing the current call stack to complete using the window.setTimeout trick.
   */
  function decorate($modal) {
    window.setTimeout(onDecorateTimeout($modal), 0)
  }

  function onDecorateTimeout($modal) {
    return function() {
      var $table = getBootgridTable($modal);

      if ($table.length) {
        decorateBootgridModal($modal, $table);
      }
    }
  }

  function getBootgridTable($modal) {
    return $modal.find('table.bootgrid-table')
  }

  function decorateBootgridModal($modal, $table) {
    var $actions = getBootgridActions($modal, $table);

    if ($actions.length) {
      getHeaderActionsContainer($modal).append($actions);

      $modal
        .off('hidden.bs.modal', emptyBootgridActionsHeader)
        .on('hidden.bs.modal', emptyBootgridActionsHeader);
    }
  }

  function getBootgridActions($modal, $table) {
    return $modal.find('#' + getBootgridActionsElemId($table))
  }

  function getBootgridActionsElemId($table) {
    return 'bootgrid_actions__' + $table.attr('id');
  }

  function getHeaderActionsContainer($modal) {
    return $modal.find('.modal-header .bootgrid_actions-container')
  }

  function emptyBootgridActionsHeader(e){
    getHeaderActionsContainer($(e.currentTarget)).empty();
  }
})();
