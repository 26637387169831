(function() {
  'use strict';

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('.admin-subscription-index').length === 0) { return }
    initBootgridTable($('#subscription_table'));
    initBarChartOrDisplayNoData($('#subscriptions-bar-chart'), false, true);
    initBarChartOrDisplayNoData($('#accountant-bar-chart'), false, true);
    $('.search').hide();
  }));
})();
