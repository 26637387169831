import consumer from './consumer'

window.createSubscriptionToBackgroundExports = function (id, exportButtonSpan, initialButtonClass, callback) {
  consumer.subscriptions.create({ channel: 'BootgridTableBackgroundExportChannel', id }, {
    connected: function () {
      // Called when the subscription is ready for use on the server
      callback()
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      onBackgroundExportPollResponse(data)
      restoreCsvButton(exportButtonSpan, initialButtonClass)
      consumer.subscriptions.remove(this)
    }
  })

  function onBackgroundExportPollResponse(response) {
    if (response.status === 'Complete') {
      notify('Your report is ready!', 'success', 'Success! ')
      const win = window.open(Routes.bootgrid_table_background_export_path(response.id, { format: 'html' }), '_blank')
      if (win) {
        // Browser has allowed it to be opened
        win.focus()
      } else {
        // Browser has blocked it
        // eslint-disable-next-line no-undef
        alert('Please allow popups for this website so that your export can be downloaded')
      }
    } else {
      notify('An error occurred calculating your report, please try again or contact support', 'danger', 'Error ')
    }
  }

  function restoreCsvButton(exportButtonSpan, oldButtonClass) {
    exportButtonSpan.attr('class', oldButtonClass)
    exportButtonSpan.parent().attr('disabled', false)
  }
}
