(function() {
  window.BankFeedsTableController = function($grid) {
    this._$table            = $grid;
    this._column_ids        = null;
    this._row_controllers   = {};
    this._channel           = null;
    this._last_request_data = null;
    this._bound_function    = null;
  };
  /**
   * Entry point, add a global event listener for all incoming xhr responses.
   */
  BankFeedsTableController.prototype.init = function() {
    this._bound_function = this._onBootgridXhrComplete.bind(this);
    $(document).ajaxSuccess(this._bound_function);
  };
  /***

  */
  BankFeedsTableController.prototype.destroy = function() {
    $(document).unbind('ajaxSuccess', this._bound_function);
  };
  /**
   * This is a bit of a hack: here we check the xhr responses to see if they're for this bootgrid table by testing the
   * url. Proceed when such a response is found.
   *
   * @param event
   * @param jqXhr
   * @param ajaxOptions
   * @private
   */
  BankFeedsTableController.prototype._onBootgridXhrComplete = function(event, jqXhr, ajaxOptions) {
    if (isImportsUrl(ajaxOptions.url)) {
      this._handleInitialBootgridXhrResponse(getRowsFromResponseText(jqXhr.responseText));
    }
  };
  /**
   * If the user has made changes to the bootgrid table's settings since we last initialised then we need to disconnect
   * the channel and reinitialise, as our row mappings will now be invalid.
   *
   * If we aren't currently watching any rows, check to see if any are pending bank feed imports and poll the server
   * if so.
   *
   * @param event
   * @param rows The rows parsed from the bootgrid xhr response
   * @private
   */
  BankFeedsTableController.prototype._handleInitialBootgridXhrResponse = function(rows) {
    if (this._hasBootgridStateChanged()) {
      this._reset();
    }

    this._initialiseIfNoRowControllers(rows);
  };

  BankFeedsTableController.prototype._hasBootgridStateChanged = function() {
    return this._last_request_data && !areBootgridStatesEqual(this._getRequestData(), this._last_request_data);
  };

  BankFeedsTableController.prototype._reset = function() {
    this._column_ids        = null;
    this._row_controllers   = {};
    this._last_request_data = null;

    this._channel.disconnect();
  };

  BankFeedsTableController.prototype._initialiseIfNoRowControllers = function(rows) {
    if (!this._hasRowControllers()) {
      this._decorateRowsAndOpenChannel(rows);
    }
  };

  BankFeedsTableController.prototype._decorateRowsAndOpenChannel = function(rows) {
    rows.forEach(this._decorateRowIfPendingOrWaiting, this);

    if (this._hasRowControllers()) {
      this._openChannel();
    }
  };
  /**
   * Creates a BankFeedsRowController that can be used to update the required cells when the bank feed pending state
   * changes, and store it against the row's index number in the _row_controllers map.
   *
   * @param row
   * @param row_index
   * @private
   */
  BankFeedsTableController.prototype._decorateRowIfPendingOrWaiting = function(row, row_index) {
    if (isRowPendingOrWaiting(row)) {
      this._row_controllers[ row_index ] = this._createRowController(row_index);
    }
  };
  /**
   * Searches the table for the cells we need to update and passes them to a new BankFeedsRowController.
   *
   * @param row_index
   * @returns {Window.BankFeedsRowController}
   * @private
   */
  BankFeedsTableController.prototype._createRowController = function(row_index) {
    return new BankFeedsRowController(this._createRowElementMap(row_index));
  };

  BankFeedsTableController.prototype._createRowElementMap = function(row_index) {
    return bootgrid.queryHelpers.getRowCellMap(row_index, this._getColumnIds(), this._$table);
  };

  BankFeedsTableController.prototype._getColumnIds = function() {
    if (this._column_ids === null) {
      this._column_ids = bootgrid.queryHelpers.getHeaderColumnIds(this._$table);
    }

    return this._column_ids;
  };

  BankFeedsTableController.prototype._hasRowControllers = function() {
    return Object.keys(this._row_controllers).length > 0
  };
  /**
   * Creates a polling channel using the bootgrid table's current request data to watch for server side changes.
   * @private
   */
  BankFeedsTableController.prototype._openChannel = function() {
    var requestData = this._getRequestData();

    this._last_request_data = extractBootgridStateFromRequest(requestData);

    this._channel = new PollingChannel(
      {
        url  : this._$table.data('url'),
        data : requestData
      }
    );

    this._channel.subscribe(this._onChannelUpdate.bind(this));
    this._channel.connect();
  };

  BankFeedsTableController.prototype._getRequestData = function() {
    return Bootgrid.getRequest.call(this._$table.data('.rs.jquery.bootgrid'));
  };

  BankFeedsTableController.prototype._onChannelUpdate = function(responseText) {
    this._updateRows(getRowsFromResponseText(responseText));
  };

  BankFeedsTableController.prototype._updateRows = function(rows) {
    rows.forEach(this._updateRow, this);

    if (!rows.some(isRowPendingOrWaiting)) {
      this._reset(rows);
    }
  };

  BankFeedsTableController.prototype._updateRow = function(row, i) {
    var controller = this._row_controllers[ i ];

    if (controller) {
      controller.update(row);
    }
  };

  function isImportsUrl(url) {
    return url.indexOf('imports.bootgrid') > 0;
  }

  function getRowsFromResponseText(text) {
    var rows = JSON.parse(text).rows;

    if (Array.isArray(rows)) {
      return rows;
    } else {
      throw new Error('Bootgrid xhr response does not contain an array of rows');
    }
  }

  /**
   * See method 'status_text' in
   * app/bootgrid_tables/bootgrid_table/imports_index/hash_builder.rb
   *
   * @param {Object} row
   * @returns {boolean}
   */
  function isRowPendingOrWaiting(row) {
    var status = row.status;
    return status === 'Waiting' || status.indexOf('Pending') === 0;
  }

  function extractBootgridStateFromRequest(ob) {
    return {
      current       : ob.current,
      rowCount      : ob.rowCount,
      filterOptions : ob.filterOptions.map(extractFilterOptionState)
    };
  }

  function extractFilterOptionState(ob) {
    return {
      visible : ob.visible
    }
  }

  function areBootgridStatesEqual(a, b) {
    return (
      a.current === b.current &&
      a.rowCount === b.rowCount &&
      areFilterOptionStatesEqual(a.filterOptions, b.filterOptions)
    )
  }

  function areFilterOptionStatesEqual(a, b) {
    return a.every(
      function(item, i) {
        return item.visible === b[ i ].visible;
      }
    );
  }
})()
