(function() {
  'use strict';

  window.setAddressCountryFromCountryField = function(event){
    var $country_select = $(event.target);
    var selected_country_locode = $country_select.find('option:selected').data('unLocode');
    $('#country_select').val(selected_country_locode).trigger('chosen:updated').change();
  };

  window.adjustAddressFormOnCountryChange = function(event){
    initPostcodeInputMaskWithSection($('#address_form_fields'));
    alterCountyInputType(event, $('#address_form_fields .county_input_field'));
  };

})();