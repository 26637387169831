TableHelper = {  
  "getColumnHeaderCell": function(tableId, index){
    return $('#' + tableId).find('th')[index];
  },
  "doesCellContentOverflowCell": function(cellContent){
    return cellContent.scrollWidth < cellContent.parentElement.clientWidth;
  },
  "getBody": function(tableId){
    return $('#' + tableId).find('tbody');
  },
  "getRowThatElementIsContainedIn": function(element){
    return $(element)
      .parents('tr')
      [0]
    ;
  },
  "getDataForRowThatElementIsContainedIn": function(element){
    return $(element).parents('tr').data();
  },
  "getRowCell": function(row, cellIndex){
    return $($(row).children('td')[cellIndex]);
  },
  "getTableRows": function(tableId){
    return $('#' + tableId)
      .children('tbody')
      .children('tr')
    ;
  },
  "getRow": function(tableId, rowIndex){
    return $('#' + tableId).find("tr[data-row-id]")[rowIndex];
  }
}