import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['netAmountField', 'taxAmountField', 'taxCodeField', 'totalAmountField']

  // FARMPLAN: Added to fix issue recalculating tax amount when changing category/taxcode
  connect() {
    $(this.taxCodeFieldTarget).on('change', function (event) {
      this.updateAmountsBasedOnNet()
      if (event.target.closest('.split_row')) window.splitSetAmountsFromTaxCode(event)
      window.performAmountChecksAndUpdateBalances(event)
    }.bind(this))
  }
  // \FARMPLAN

  updateAmountsBasedOnNet() {
    if (this.amountsNotEditable()) { return }
    if (this.totalIsEditable()) {
      window.setTaxAndTotalFromNetAndTaxRate(this.transactionAmountsHash())
    } else {
      window.setNetAndTaxFromTaxRateAndTotal(this.transactionAmountsHash())
    }
  }

  updateAmountsBasedOnTax() {
    if (this.amountsNotEditable()) { return }
    if (this.totalIsEditable()) {
      window.setTotalToNetPlusTax(this.transactionAmountsHash())
    } else {
      window.setNetToTotalMinusTax(this.transactionAmountsHash())
    }
  }

  updateAmountsBasedOnTotal() {
    if (this.amountsNotEditable()) { return }
    window.setNetAndTaxFromTotalAndTaxRate(this.transactionAmountsHash())
  }

  // Private

  totalIsEditable() {
    return !this.totalAmount().is('[readonly]')
  }

  netIsEditable() {
    return !this.netAmount().is('[readonly]')
  }

  taxIsEditable() {
    if (this.includesTaxCalculation()) {
      return !this.taxAmount().is('[readonly]')
    } else {
      return false
    }
  }

  amountsNotEditable() {
    return !(this.totalIsEditable() && this.netIsEditable() && this.taxIsEditable())
  }

  transactionAmountsHash() {
    return {
      tax_rate: this.taxRate(),
      net_amount: parseFloat(this.netAmount().val()),
      net_amount_field: this.netAmount(),
      tax_amount_field: this.taxAmount(),
      total_amount_field: this.totalAmount()
    }
  }

  netAmount() {
    return $(this.netAmountFieldTarget)
  }

  taxAmount() {
    if (this.hasTaxAmountFieldTarget) {
      return $(this.taxAmountFieldTarget)
    } else {
      return null
    }
  }

  taxRate() {
    if (this.hasTaxCodeFieldTarget) {
      return window.getTaxRateFromTaxCodeSelect($(this.taxCodeFieldTarget))
    } else {
      return null
    }
  }

  totalAmount() {
    return $(this.totalAmountFieldTarget)
  }

  includesTaxCalculation() {
    return this.hasTaxAmountFieldTarget
  }
}
