CreditableInvoiceModalComponentInvoiceSelectedCount = {
  "updateInvoiceSelectedCount": function($element, count, extended_text) {
    if (count) {
      $element.text(count + extended_text);
    } else {
      $element.text("");
    }
  },
  "enableDisableConfirmButton": function(count) {
    $('#invoice-credit-selection-confirmation').attr('disabled', Boolean(count == 0));
  }
}
