import * as Sentry from '@sentry/browser';

var sendSentryErrors = $('body').data('sendSentryErrors')

if (sendSentryErrors == "true") {
  Sentry.init({
     //dsn: "https://1862dd78cded4796867a3d47e25a92f1@sentry.io/139603", // Pandle?
     dsn: '<%= Rails.application.credentials[Rails.env == "qa" ? :staging : Rails.env.to_sym][:sentry_js_dsn] %>',
    ignoreErrors: [
      'Can\'t execute code from a freed script'
    ],
    denyUrls: [
      new RegExp(/js\.intercomcdn\.com/),
      new RegExp(/frame\..*\.js/), // Attempt to ignore Intercom's error-riddled javascript
      new RegExp(/shim\..*\.js/),
      new RegExp(/vendor\..*\.js/)
    ]
  });

  window.logError = function (error, options = {}) {
    Sentry.captureException(error, options);
  }

  window.logMessage = function (message, options = {}) {
    Sentry.captureMessage(message, options);
  }
} else {
  window.logError = function (_, __ = {}) {}
  window.logMessage = function (_, __ = {}) { }
}
