(function() {
  'use strict';

  window.BatchBtRow = function(bt_row_id) {
    this._rules = window.bt_field_enabling_rules;
    this._btRowId = bt_row_id;
    this._rowHasAlreadyBeenInitialized = $('#bt__row'+bt_row_id).data('init');
    this._isSplit = null;
    this._isImported = null;
    this._canBeImported = null;
    this._isMultiCurrency = null;
    this._btType = null;
    this._isInvoiceBased = null;
    this._changingTransactionType = false;
    this._$fields = {};
    this._chosen_select_ids_to_update = [];
    this._taxRateIsZero = null;
  };

  /*
  * Call this method when a change has occurred in the form, to update fields according to the rules
  *
  * @param {jQuery} event_target
  */
  BatchBtRow.prototype.update = function(event_target) {
    if ( this._btRowId ) {
      this._setFlagsFromEventTarget(event_target);
      this._setFlagsFromBtRow();
    }
  };

  BatchBtRow.prototype.after_update = function(event_target) {
    this._updateRelevantChosenSelects();
    this._flagRowAsInitialized(event_target);
  }

  BatchBtRow.prototype.getFlagValue = function(flag) {
    return this["_"+flag];
  }

  BatchBtRow.prototype.getField = function(selector) {
    if(!this._$fields[selector]) {
      this._$fields[selector] = $(selector.replace('ROW_ID', this._btRowId));
    }
    return this._$fields[selector];
  }

  BatchBtRow.prototype.getSplitField = function() {
    return this.getField('#is_split_fieldROW_ID');
  }

  BatchBtRow.prototype.getRules = function() {
    return this._rules;
  }

  BatchBtRow.prototype.queueSelectForUpdate = function(chosen_select_id){
    this._chosen_select_ids_to_update.push(chosen_select_id);
  }

  /*
  * @param {jQuery} target
  */
  BatchBtRow.prototype._setFlagsFromEventTarget = function(target) {
    if ( $(target).hasClass('bt_type_field') ) {
      this._changingTransactionType = true;
    }
  }

  BatchBtRow.prototype._setFlagsFromBtRow = function() {
    this._isSplit = this._checkIsSplit();
    this._isImported = this._checkIsImported();
    this._canBeImported = this._checkCanBeImported();
    this._isMultiCurrency = this._checkIsMultiCurrency();
    this._btType = this._checkBtType();
    this._isInvoiceBased = this._checkIsInvoiceBased();
    this._taxRateIsZero = this._toggleTaxAmountReadOnlyBasedOnTaxRate();
  }

  BatchBtRow.prototype._checkIsSplit = function() {
    return this.getSplitField().val() === "true"
  }

  BatchBtRow.prototype._checkIsImported = function() {
    return this.getField('#imp_bt_idROW_ID').val().length > 0
  }

  BatchBtRow.prototype._checkCanBeImported = function() {
    return this._categorySelectedIfNeeded() && this._totalAmountMatchesIbt(this._btRowId);
  }

  BatchBtRow.prototype._categorySelectedIfNeeded = function() {
    var account_value = this.getField('#account_fieldROW_ID').val() || "";
    var split_field_value = this.getSplitField().val() || "";

    return (account_value.length > 0 || split_field_value === "true");
  }

  BatchBtRow.prototype._totalAmountMatchesIbt = function(row_id) {
    function notUsingBankAccountCurrency(row_id) {
      var $currency_field = $('#currency_field'+row_id);
      return $currency_field.val() != $currency_field.data('bank-account-currency')
    }

    if (notUsingBankAccountCurrency(row_id)) {
      return true; // Rely on the model validation if not base currency
    } else if ($('#total_amount_field'+row_id).val() != $('#total_amount_field'+row_id).data('expected-total')) {
      return false;
    } else {
      return true;
    }
  }

  BatchBtRow.prototype._checkBtType = function() {
    return this.getField('#type_fieldROW_ID').val();
  }

  BatchBtRow.prototype._checkIsInvoiceBased = function() {
    return this._btType == SALES_RECEIPT || this._btType == PURCHASE_PAYMENT;
  }

  BatchBtRow.prototype._checkIsMultiCurrency = function() {
    if ( !this.getField('#currency_fieldROW_ID').length ) {
      return false
    } else {
      return !conversionRateFieldShouldBeDisabled(
        this.getField('#bank_account_fieldROW_ID').data('currencyId'),
        this._getAccountCurrencyForRow(),
        companySettings.base_currency.id);
    }
  }

  BatchBtRow.prototype._getAccountCurrencyForRow = function() {
    if ( this._isSplit ) {
      return this.getField('#split_currency_fieldROW_ID').val();
    } else {
      return this.getField('#account_fieldROW_ID').find("option:selected").data("currencyId");
    }
  }

  BatchBtRow.prototype._flagRowAsInitialized = function(target) {
    // When a new row is added, or the form is first loaded, we trigger change on the type field to set some defaults
    // Once that is finished, we want to tag the row so we know it has been initialized
    // Subsequent changes of 'type' are actual changes, and so we reset some field values that we don't want to reset
    // on pageload.
    if ( this._changingTransactionType ) {
      this.getField('#bt__rowROW_ID').data('init', true);
    }
  }

  /*
    We might have multiple reasons to trigger updating the same chosen select, so it makes sense to wait until
    we know all of them and then call this method to update them all
  */
  BatchBtRow.prototype._updateRelevantChosenSelects = function(){
    $.each($.unique(this._chosen_select_ids_to_update), function(){ $('#'+this).trigger('chosen:updated') });
  }

  BatchBtRow.prototype._toggleTaxAmountReadOnlyBasedOnTaxRate = function() {
    var tax_rate = getTaxRateAsFloat($('#tax_code_field' + this._btRowId));
    return (tax_rate == 0);
  }
})();
