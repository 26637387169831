/*
  Useage:
  Given three select boxes, #select-1, #select-2, and #select-3
  adding `data-remove-selected-from="#select-3"` to either/both select-1 or
  select-2 will disable the option in #select-3 with the same name as the
  currently selected option in #select-1 or #select-2.
  Changing option will reenable the previously disabled option in #select-3
  unless it is currently selected by another select tag with a matching
  data-remove-selected-from.
  Optionaly a json array can be provided under the remove-selected-match data
  attribute for each option tag which, if present, will be used to determine
  the matching options to disable in the target tag.
*/


(function() {
  'use strict';

  window.initSelectPicker = function(){
    $("[data-remove-selected-from]").each(function(i, element) {
      var $select = $(element);
      updateTargetSelects($select);
    });
    $("[data-remove-selected-from]").on("change", function(e) {
      var $select = $(e.target);
      updateTargetSelects($select);
    });
  }

  function optionMatchers($option) {
    if ($option.data("remove-selected-match")) {
      return $option.data("remove-selected-match");
    } else {
      return [$option.text()];
    }
  }

  function isBlankPlaceholder($option) {
    return $option.text() == "";
  }

  function shouldDisable($selects, $option) {
    var selectedOptions = $selects.map(function(i, select) {
      return $(select).find("option").filter(":selected");
    });

    var matchers = selectedOptions.map(function(i, o) {
      return optionMatchers($(o));
    });

    var flatMatchers = $.map(matchers, function(m) { return m });

    if (isBlankPlaceholder($option)) { return false }

    return $.inArray($option.text(), flatMatchers) != -1;
  }

  function updateTargetSelects($select) {
    var targetSelector = $select.data("remove-selected-from");
    var $allSelectorsWithSameTarget = $('[data-remove-selected-from="' + targetSelector + '"]');
    var $targets = $(targetSelector);

    $targets.find("option").each(function(i, option) {
      var $option = $(option);

      if (shouldDisable($allSelectorsWithSameTarget, $option)) {
        $option.hide();
        $option.attr("disabled", "disabled");
        if ($option.prop("selected")) {
          $option.prop("selected", false);
        }
      } else {
        $option.show();
        $option.attr("disabled", false);
      }
    });

    $(".selectpicker").selectpicker('refresh');
  }

  $(document).on("turbo:load shown.bs.modal nested:fieldAdded", function(e) {
    initSelectPicker();
  });

  $(document).on("nested:fieldRemoved", function(e) {
    var $nestedField = $(e.target);
    var $select = $nestedField.find("[data-remove-selected-from]");
    if ($select.length) {
      $select.find("option:selected").prop("selected", false);
      updateTargetSelects($select);
    }
  });
})();
