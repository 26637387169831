(function() {
  'use strict';

  /**
  * @fileoverview
  * Global function to display a confirmation modal, prompting the user to
  * confirm or cancel an action.
  */
  var $modal;
  var $confirmButton;
  var $cancelButton;
  var MODAL_OPTIONS = {
    keyboard: false,
    backdrop: false
  };
  /**
   *  Finds the confirm modal on the page
   */
  window.initConfirmModal = function() {
    $modal = $('#modalConfirm');
    $confirmButton = $modal.find('#confirm');
    $cancelButton = $modal.find('#cancel');
  }
  /**
   * This triggers a modal in the main view that displays above other modals.
   * It contains two buttons, Confirm and Cancel in the footer that will always
   * hide the modal.
   *
   * It can be configured with the following properties:
   *
   * @param options Options to configure the modal, see below
   * @param options.content {string|Element|JQuery.Element} Message or DOM
   * subtree to render inside the modal's body.
   * @param options.onConfirm {=Function} Callback invoked when the Confirm
   * button is pressed.
   * @param options.onCancel {=Function} Callback invoked after the cancel
   * button is pressed.
   * @param options.afterConfirm {=Function} Optional callback invoked after
   * the Confirm button has been pressed and the modal hide transition has
   * completed.
   * @param options.afterCancel {=Function} Optional callback invoked after the
   * Cancel button has been pressed and the modal hide transition has been
   * completed.
   */
  
  window.confirmModal = function(options) {
    $modal.find('.modal-body').empty().append(options.content);
    $modal.children().addClass('modal-sm');
    _populateModalWithHtml("Yes", "No");
    _addButtonEventListeners(options);
    $modal.modal(MODAL_OPTIONS);
  };

  window.recurringTransactionsModal = function(options) {
    $modal.find('.modal-body').empty().append(options.content);
    $modal.children().addClass('p-10').removeClass('modal-sm');
    _populateModalWithHtml(options.buttonsHtml.confirm, options.buttonsHtml.cancel);
    _addButtonEventListeners(options);
    $modal.modal(MODAL_OPTIONS);
  };
  /**
   * @param fn
   * @param handler
   * @returns {Function}
   * @private
   */
  function _decorateHandler(fn, handler) {
    return function(e) {
      fn.call(this, e, $modal);
      if (handler) {
        $modal.one('hidden.bs.modal', handler);
      }
      $modal.modal('hide');
    }
  }

  function _populateModalWithHtml(confirmMsg, cancelMsg) {
    $confirmButton.html(confirmMsg);
    $cancelButton.html(cancelMsg);
  }

  /**
   * @param options
   * @returns {Function}
   * @private
   */
  function _addButtonEventListeners(options) {
    var onConfirm = _decorateHandler(_optionalFn(options.onConfirm), _optionalFn(options.afterConfirm));
    var onCancel = _decorateHandler(_optionalFn(options.onCancel), _optionalFn(options.afterCancel));
    $confirmButton.one('click', onConfirm);
    $cancelButton.one('click', onCancel);
    $modal.one('hidden.bs.modal', _removeButtonCallbacks(onConfirm, onCancel));
    return onCancel;
  }

  function _optionalFn(fn) {
    return fn ||function() {};
  }

  /**
   * @param onConfirm
   * @param onCancel
   * @returns {Function}
   * @private
   */
  function _removeButtonCallbacks(onConfirm, onCancel) {
    return function() {
      $confirmButton.off('click', onConfirm);
      $cancelButton.off('click', onCancel);
      $('body').addClass('modal-open'); // adds the class back to the original modal, allowing to scroll again on it
    };
  }
})();
